import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonActivity() {
  return (
    <div>
      <tr>
        <td style={{ maxWidth: "130px" }}>
          <div className="table-user-image">
            <Skeleton
              className="table-data-user-img"
              circle={true}
              width={80}
              height={80}
            />
            <h6>
              <Skeleton />
            </h6>
          </div>
        </td>
        <td style={{ width: "100%" }}>
          <Skeleton className="td-user-location">
            <p>
              <Skeleton />
            </p>
          </Skeleton>
          <span className="user-activity-images">
            <span className="act-icon">
              <Skeleton className="activity-page-link">
                <span className="activity-icons" style={{ border: "none" }}>
                  <Skeleton style={{ width: "30px", height: "30px" }} />
                </span>
                <p>
                  <Skeleton />
                </p>
              </Skeleton>
            </span>
            <span className="act-icon">
              <Skeleton className="activity-page-link">
                <span className="activity-icons" style={{ border: "none" }}>
                  <Skeleton style={{ width: "30px", height: "30px" }} />
                </span>
                <p>
                  <Skeleton />
                </p>
              </Skeleton>
            </span>
            <span className="act-icon">
              <span className="activity-icons" style={{ border: "none" }}>
                <Skeleton style={{ width: "30px", height: "30px" }} />
              </span>
              <p>
                <Skeleton />
              </p>
            </span>
            <span className="act-icon">
              <span className="activity-icons" style={{ border: "none" }}>
                <Skeleton style={{ width: "30px", height: "30px" }} />
              </span>
              <p>
                <Skeleton />
              </p>
            </span>
            <span className="act-icon">
              <span className="activity-icons" style={{ border: "none" }}>
                <Skeleton style={{ width: "30px", height: "30px" }} />
              </span>
              <p>
                <Skeleton />
              </p>
            </span>
            <span className="act-icon">
              <span className="activity-icons" style={{ border: "none" }}>
                <Skeleton style={{ width: "30px", height: "30px" }} />
              </span>
              <p>
                <Skeleton />
              </p>
            </span>
            <span className="act-icon">
              <span className="activity-icons" style={{ border: "none" }}>
                <Skeleton style={{ width: "30px", height: "30px" }} />
              </span>
              <p>
                <Skeleton />
              </p>
            </span>
            <span className="act-icon">
              <span className="activity-icons" style={{ border: "none" }}>
                <Skeleton style={{ width: "30px", height: "30px" }} />
              </span>
              <p>
                <Skeleton />
              </p>
            </span>
            <span className="act-icon">
              <Skeleton className="attendance-page-link">
                <span className="activity-icons" style={{ border: "none" }}>
                  <Skeleton style={{ width: "30px", height: "30px" }} />
                </span>
                <p>
                  <Skeleton />
                </p>
              </Skeleton>
            </span>
          </span>
        </td>
      </tr>
    </div>
  );
}

export default SkeletonActivity;
