import React from "react";
import inputIcon from "../../../images/icons/input.png";
import headingIcon from "../../../images/icons/heading.png";
import paragraphIcon from "../../../images/icons/paragraph.png";
import taskIcon from "../../../images/icons/task.png";
import checkIcon from "../../../images/icons/check.png";
import selectIcon from "../../../images/icons/select.png";
import folderIcon from "../../../images/icons/folder.png";
import calendarIcon from "../../../images/icons/calendar.png";
import okbuttonIcon from "../../../images/icons/ok-button.png";

export const FieldNamesIcons = [
  {
    id: 1,
    name: "Text Field",
    icon: inputIcon,
    inputData: {
      type: "text",
      label: "Label",
      name: "input-field",
      className: "dynamic-input-type",
      placeHolder: "Enter Text",
      value: "",
      autoComplete: "off",
      required: true,
    },
  },
  {
    id: 2,
    name: "Header",
    icon: headingIcon,
    inputData: {
      align: "center",
      text: "Header",
      name: "headertext",
      className: "dynamic-header",
      type: "h1",
    },
  },
  {
    id: 3,
    name: "Paragraph",
    icon: paragraphIcon,
    inputData: {
      align: "left",
      name: "paragraphtxt",
      content: "Pragraph Text...",
      className: "dynamic-paragraph",
      type: "p",
    },
  },
  {
    id: 4,
    name: "Radio Buttons",
    icon: taskIcon,
    inputData: {
      type: "radio",
      // required: false,
      name: "radio-group-selection",
      label: "Radio Group",
      inline: true,
      className: "radio-groups",
      values: [
        {
          label: "Option 1",
          value: "option-1",
        },
        {
          label: "Option 2",
          value: "option-2",
        },
        {
          label: "Option 3",
          value: "option-3",
        },
      ],
    },
  },
  {
    id: 5,
    name: "Check Box",
    icon: checkIcon,
    inputData: {
      type: "checkbox",
      name: "checkbox-group-selection",
      required: false,
      label: "Checkbox Group",
      className: "checkbox-groups",
      // toggle: false,
      inline: true,
      values: [
        {
          label: "Option 1",
          value: "option-1",
          selected: false,
        },
        {
          label: "Option 2",
          value: "option-2",
          selected: false,
        },
        {
          label: "Option 3",
          value: "option-3",
          selected: false,
        },
      ],
    },
  },
  {
    id: 6,
    name: "Select",
    icon: selectIcon,
    inputData: {
      type: "select",
      name: "select-dropdown",
      placeHolder: "Select Field",
      required: false,
      label: "Select",
      className: "react-select-list",
      search: true,
      values: [
        {
          label: "Option 1",
          value: "option-1",
        },
        {
          label: "Option 2",
          value: "option-2",
        },
        {
          label: "Option 3",
          value: "option-3",
        },
      ],
    },
  },
  {
    id: 7,
    name: "File Upload",
    icon: folderIcon,
    inputData: {
      type: "file",
      name: "file-upload",
      label: "Upload File",
      className: "file-upload",
      multiple: false,
      // required: false,
    },
  },
  {
    id: 8,
    name: "Date Field",
    icon: calendarIcon,
    inputData: {
      type: "date",
      name: "date-field",
      required: false,
      label: "Select Date",
      className: "form-control date-field",
    },
  },
  {
    id: 9,
    name: "Button",
    icon: okbuttonIcon,
    inputData: {
      type: "button",
      name: "button-field",
      label: "Button",
      variant: "primary",
      className: "btn-default",
    },
  },
  //   {
  //     id: 10,
  //     name: "Button",
  //     icon: inputIcon,
  //   },
];
