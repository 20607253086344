import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import "react-datepicker/dist/react-datepicker.css";
import AccessDenied from "../../AccessDenied";
import { useNavigate } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LeavesApplied from "./LeavesApplied";
import LeaveBalance from "./LeaveBalance";
import Footer from "../Footer";

function Leaves() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const loggedInRole = loginDetails?.desingation?.role?.roleID;

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  {/* <Col md={3} className="cat-col"> */}
                  <Tabs
                    defaultActiveKey="applied"
                    id="uncontrolled-tab-example"
                    className="local-claim-nav-tab"
                  >
                    <Tab
                      eventKey="back"
                      title={
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                      }
                    >
                      Back
                    </Tab>
                    <Tab title="LEAVES" className="local-claim-head">
                      LEAVES
                    </Tab>
                    <Tab eventKey="applied" title="Applied Leaves">
                      <LeavesApplied />
                    </Tab>
                    {loggedInRole == 1 ? (
                      <Tab eventKey="balance" title="Leave Balance">
                        <LeaveBalance />
                      </Tab>
                    ) : (
                      ""
                    )}
                  </Tabs>
                </div>
                {/* Filter and Header Section */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />

          {/* /* ADD LEAVE UPDATE STATUS MODEL END */}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Leaves;
