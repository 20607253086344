import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";
import {
  loadPostApiCredits,
  loadSingleApiData,
  loadSubscription,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import moment from "moment";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import DatePicker from "react-datepicker";
import { formatDateToIST } from "../../FormattedAmount";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

function AddApiCredits(props) {
  let apiDataId = props.apiDataId;

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();

  const { subcriptionList } = useSelector((state) => state.data);
  const { apiNameDropdown } = useSelector((state) => state.data);
  const { apiSingleData } = useSelector((state) => state.data);

  useEffect(() => {
    if (apiDataId) {
      dispatch(loadSingleApiData(apiDataId));
    }
  }, [apiDataId]);

  console.log(apiSingleData, "apiSingleData");

  const [userList, setUserList] = useState([]);
  useEffect(() => {
    if (subcriptionList) {
      let optiondata = [];
      subcriptionList.map((data) => {
        if (data.subscriptionId != null) {
          optiondata.push({
            value: data.subscriptionId,
            label: data.subscriptionId,
            client: data?.company?.companyName,
          });
        }
      });
      setUserList(optiondata);
    }
  }, [subcriptionList]);

  const [selectSubscriptionId, setSubscriptionId] = useState({
    value: "",
    label: "",
  });
  const [selectApiName, setSelectApiName] = useState({ value: "", label: "" });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [postItem, setPostItem] = useState({
    subscriptionID: "",
    apiName: "",
    apiKey: "",
    from: "",
    to: "",
    totalCredits: "",
    remainingCredits: "",
    updatedBy: resPassData.fullName,
    lastUpdated: "",
  });

  useEffect(() => {
    if (apiDataId && Object.keys(apiSingleData).length !== 0) {
      setPostItem(apiSingleData);
      let fromDate = formatDateToIST(apiSingleData.from);
      let toDate = formatDateToIST(apiSingleData.to);
      setFromDate(new Date(fromDate));
      setToDate(new Date(toDate));
      userList.map((user) => {
        if (user.value === apiSingleData?.subscriptionID) {
          setSubscriptionId(user);
        }
      });
      apiNameDropdown.map((api) => {
        if (api.value === apiSingleData?.apiName) {
          setSelectApiName(api);
        }
      });
    }
  }, [apiDataId, apiSingleData]);
  console.log(selectApiName, "selectApiName");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "totalCredits") {
      if (value) {
        setPostItem({
          ...postItem,
          [name]: parseInt(value),
          remainingCredits: parseInt(value),
        });
      } else {
        setPostItem({ ...postItem, [name]: "", remainingCredits: "" });
      }
    } else {
      setPostItem({ ...postItem, [name]: value });
    }
  };

  const handleSelectChange = (value, type) => {
    let listData = { ...postItem };
    if (type === "subscripton") {
      setSubscriptionId(value);
      listData.subscriptionID = value.value;
    } else if (type === "apiName") {
      setSelectApiName(value);
      listData.apiName = value.value;
    } else if (type === "From-date") {
      console.log(value, "type");
      setFromDate(value);
      listData.from = moment(new Date(value)).format("DD/MM/YYYY");
    } else if (type === "To-date") {
      setToDate(value);
      listData.to = moment(new Date(value)).format("DD/MM/YYYY");
    }
    setPostItem(listData);
  };

  useEffect(() => {
    dispatch(loadSubscription());
    // if(selected && selected.length !== 0) {
    //   postItem.motName = selected[0].label;
    //   postItem.motID = selected[0].value;
    // }
  }, []);

  const closeAddModal = () => {
    props.handleClose();
    props.setApiDataId("");
    setPostItem({
      subscriptionID: "",
      apiName: "",
      apiKey: "",
      from: "",
      to: "",
      totalCredits: "",
      remainingCredits: "",
      updatedBy: resPassData.fullName,
      lastUpdated: "",
    });
    props.fetchAPICredits();
  };

  // const getOptionValue = ({ label }) => (
  //   <div>
  //       <div>{label.value}</div>
  //   </div>
  // )

  const formatOptionLabel = ({ label, client }) => (
    <div className="menu-item-column">
      <div style={{ fontSize: "11px" }}>
        <b>{label}</b>
      </div>
      <div style={{ fontSize: "10px" }}>{client}</div>
    </div>
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const saveApiCredits = () => {
    if (
      postItem.subscriptionID &&
      postItem.apiName &&
      postItem.from &&
      postItem.to
    ) {
      postItem.lastUpdated = moment(new Date()).format("DD/MM/YYYY");
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify(postItem),
      };
      console.log(postItem, "postItem");
      // if(setBasefareId){
      //   console.log(postItem, "addBaseFareDetails")
      //   dispatch(loadUpdateBasefare(requestOption, closeAddModal))
      // } else {
      dispatch(loadPostApiCredits(requestOption, closeAddModal, apiDataId));
      // }
    } else {
      setShowAlert(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          {/* <h6 className="add-edit-basefare-head">{props.clickType ? "Base fare Details" : <>{setBasefareId ? "EDIT BASE FARE" : "ADD BASE FARE"}</>}</h6> */}
          <h6 className="add-edit-basefare-head">
            {apiDataId ? "EDIT API CREDITS" : "ADD API CREDITS"}
          </h6>
          <i className="fa fa-times" onClick={closeAddModal} />
        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">Subscription</label>
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={userList && userList}
                  placeholder="Select Subscription"
                  value={selectSubscriptionId.value && selectSubscriptionId}
                  onInputChange={userList && userList.label}
                  formatOptionLabel={formatOptionLabel}
                  onChange={(data) => handleSelectChange(data, "subscripton")}
                  isSearchable={true}
                  //   isDisabled={!motDepartment.value || props.clickType}
                />
                {showAlert === true && selectSubscriptionId.value === "" && (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message} Subscription
                  </small>
                )}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">Api Name</label>
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={apiNameDropdown && apiNameDropdown}
                  placeholder="Select Api Name"
                  value={selectApiName.value && selectApiName}
                  onInputChange={apiNameDropdown && apiNameDropdown.label}
                  onChange={(data) => handleSelectChange(data, "apiName")}
                  isSearchable={true}
                />
                {showAlert === true && selectApiName.value === "" && (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message} Api Name
                  </small>
                )}
              </div>
              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">Api Key</label>
                <input
                  type="text"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  name="apiKey"
                  value={postItem?.apiKey}
                  placeholder="Enter Api Key"
                  onChange={handleInputChange}
                />
                {showAlert === true && postItem?.apiKey === "" && (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message} Api Key
                  </small>
                )}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">API Credits</label>
                <input
                  type="tel"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  name="totalCredits"
                  value={postItem?.totalCredits ? postItem?.totalCredits : ""}
                  placeholder="Enter Api Credits"
                  onChange={handleInputChange}
                />
                {showAlert === true && postItem?.totalCredits === "" && (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message} Api Credits
                  </small>
                )}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">From Date</label>
                <div
                  className="d-flex"
                  style={{ width: "100%", position: "relative" }}
                >
                  <DatePicker
                    selected={fromDate && fromDate}
                    className="select from-date"
                    id="from-datepicker"
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    selectsEnd
                    onChange={(date) => handleSelectChange(date, "From-date")}
                  />
                  <i className="fa fa-calendar-o calander-icon" />
                </div>
                {showAlert === true && !fromDate && (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message} From Date
                  </small>
                )}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">To Date</label>
                <div
                  className="d-flex"
                  style={{ width: "100%", position: "relative" }}
                >
                  <DatePicker
                    selected={toDate && toDate}
                    className="select from-date"
                    id="from-datepicker"
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    selectsEnd
                    onChange={(date) => handleSelectChange(date, "To-date")}
                  />
                  <i className="fa fa-calendar-o calander-icon" />
                </div>
                {showAlert === true && !toDate && (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message} From Date
                  </small>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn
            onClickEffect={saveApiCredits}
            btnName={apiDataId ? "Update" : "Save"}
          />
          <SbCancelBtn btnName="Cancel" onClickEffect={closeAddModal} />
        </div>
      </Modal>
    </div>
  );
}

export default AddApiCredits;
