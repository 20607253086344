import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../HeaderAuthentication";
import { addCommissionData, loadProductDropdownData, loadSingleCommissionData, loadUserReferralsData } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";

function AddCommission(props) {

    let referelProfileId = props.referelProfileId;

    let dispatch = useDispatch();
    const { productsData } = useSelector((state) => state.data);
    const { userReferralsDataDropDown } = useSelector((state) => state.data);
    const { singleCommissionData } = useSelector((state) => state.data);

    const productOptions = productsData.map(product => ({
        value: product.ProductCode,
        label: product.ProductName,
        MRP: product.MRP,
        Pid: product.Pid,
        ProductName: product.ProductName,
        ProductAddedOn: product.ProductAddedOn,
        ProductCategory: product.ProductCategory,
        ProductCode: product.ProductCode,
        ProductDescription: product.ProductDescription,
        ProductImageURL: product.ProductImageURL,
        ProductInstruction: product.ProductInstruction,
        ProductStatus: product.ProductStatus,
        ProductStockQuantity: product.ProductStockQuantity,
        SellingPrice: product.SellingPrice,
    }));

    const userOptions = userReferralsDataDropDown.map((user) => ({
        value: user.addedByID,
        label: user.userName,
        addedByID: user.addedByID,
        addedOn: user.addedOn,
        contactPersonId: user.contactPersonId,
        countryCode: user.countryCode,
        email: user.email,
        gfRefProfileID: user.gfRefProfileID,
        gfRefProdCommitionID: user.gfRefProdCommitionID,
        image: user.image,
        isVerified: user.isVerified,
        mobileNumber: user.mobileNumber,
        projectName: user.projectName,
        referelCode: user.referelCode,
        status: user.status,
        subscriptionID: user.subscriptionID,
        type: user.type,
        updatedOn: user.updatedOn
    }))


    const [user, setUser] = useState({ value: "", label: "" });
    const [product, setProduct] = useState({ value: "", label: "" });

    const [commisionData, setCommisionData] = useState({
        gfRefProfileID: "",
        gfRefProdCommitionID: "",
        subscriptionID: "",
        userID: "",
        addedByID: "",
        contactPersonId: "",
        userName: "",
        email: "",
        isVerified: "",
        mobileNumber: "",
        countryCode: "",
        image: "",
        referelCode: "",
        projectName: "",
        type: "",
        addedOn: "",
        updatedOn: "",
        status: "",
        prodName: "",
        prodCode: "",
        prodImage: "",
        pid: "",
        description: "",
        prodPrice: "",
        commPer: "",
        commValue: "",
        qrName: "",
        urL1: "",
        urL2: "",
        qrImage: ""
    })

    useEffect(() => {
        if (referelProfileId) {
            dispatch(loadSingleCommissionData(referelProfileId))
        }
    }, [referelProfileId])

    useEffect(() => {
        if (referelProfileId && singleCommissionData) {
            if (Object.keys(singleCommissionData).length !== 0) {
                const userOption = userOptions.find(option => option.label === singleCommissionData.userName);
                const productOption = productOptions.find(option => option.label === singleCommissionData.prodName);
                if (userOption) {
                    setUser(userOption)
                }
                setCommisionData(singleCommissionData)
                if (productOption) {
                    setProduct(productOption)
                }
            }
        }
    }, [referelProfileId, singleCommissionData])

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        let calculatedValue = "";
        if (value) {
            if (name === "commPer") {
                if (value && product && product.SellingPrice) {
                    const percentage = parseFloat(value);
                    const sellingPrice = parseFloat(product.SellingPrice);

                    if (!isNaN(percentage) && !isNaN(sellingPrice)) {
                        calculatedValue = ((percentage / 100) * sellingPrice).toFixed(2);
                        // setCommisionData({...commisionData, commValue: calculatedValue.toFixed(2)});
                    }
                }
            }
            setCommisionData({ ...commisionData, [name]: parseFloat(value), commValue: parseFloat(calculatedValue) })
            return;
        }
        setCommisionData({ ...commisionData, [name]: value, commValue: calculatedValue })

    }

    const setSelectUser = (user) => {
        setUser(user)
        setCommisionData({
            ...commisionData,
            gfRefProfileID: user.gfRefProfileID,
            gfRefProdCommitionID: user.gfRefProdCommitionID,
            subscriptionID: user.subscriptionID,
            userID: user.value,
            addedByID: user.addedByID,
            contactPersonId: user.contactPersonId,
            userName: user.label,
            email: user.email,
            isVerified: user.isVerified,
            mobileNumber: user.mobileNumber,
            countryCode: user.countryCode,
            image: user.image,
            referelCode: user.referelCode,
            projectName: user.projectName,
            type: user.type,
            addedOn: user.addedOn,
            updatedOn: user.updatedOn,
            status: user.status
        })
    }

    const setSelectProduct = (product) => {
        setProduct(product)
        setCommisionData({
            ...commisionData,
            prodName: product.ProductName,
            prodCode: product.ProductCode,
            prodImage: product.ProductImageURL,
            pid: product.Pid,
            description: product.ProductDescription,
            prodPrice: parseFloat(product.SellingPrice)
        })
    }
    const closeAddModal = () => {
        props.applyFilters();
        props.setReferelProfileId('');
        props.handleClose();
    };

    useEffect(() => {
        dispatch(loadProductDropdownData())
        dispatch(loadUserReferralsData());
    }, []);

    const [userNameError, setUserNameError] = useState(false);
    const [productError, setProductError] = useState(false);
    const [commissionPerError, setCommissionPerError] = useState(false);

    const validateUserName = (userName) => {
        const valid = userName !== "";
        setUserNameError(!valid);
        return valid;
    };

    const validateProduct = (product) => {
        const valid = product !== "";
        setProductError(!valid);
        return valid;
    };

    const validateCommissionPer = (commissionPer) => {
        const valid = commissionPer !== "";
        setCommissionPerError(!valid);
        return valid;
    };

    const postCommissionData = (e) => {
        e.preventDefault()

        // Validate fields before proceeding
        let hasErrors = false;

        if (!validateUserName(commisionData.userName)) {
            hasErrors = true;
        }

        if (!validateProduct(commisionData.prodName)) {
            hasErrors = true;
        }

        if (!validateCommissionPer(commisionData.commPer)) {
            hasErrors = true;
        }

        // If any field has an error, stop the submission
        if (hasErrors) {
            return;
        }

        let postData = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(commisionData)
        }
        dispatch(addCommissionData(postData, closeAddModal, referelProfileId))
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                className="main_model add-edit-basefare-model"
            >
                <div className="add-edit-basefare-heading">
                    <h6 className="add-edit-basefare-head">
                        {referelProfileId ? "EDIT COMMISSION" : "ADD COMMISSION"}
                    </h6>

                    <i className="fa fa-times" onClick={closeAddModal} />

                </div>
                <form>
                    <div className="container add-edit-basefare">
                        <div className="product-inputs">

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Select User"
                                    className={`${user.value !== ""
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={userOptions}
                                        placeholder="Select User"
                                        value={user.value && user}
                                        onInputChange={userOptions.label}
                                        onChange={(data) => {
                                            setSelectUser(data);
                                            setUserNameError("");
                                        }}
                                        isSearchable={true}
                                        required
                                    />
                                </FloatingLabel>
                                {userNameError && (
                                    <p className="text-danger" style={{ marginTop: "8px" }}>
                                        Select UserName.
                                    </p>
                                )}
                            </div>

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Select Product"
                                    className={`${product.value !== ""
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={productOptions}
                                        placeholder="Select Product"
                                        value={product.value && product}
                                        onInputChange={productsData.label}
                                        onChange={(data) => {
                                            setSelectProduct(data);
                                            setProductError("");
                                        }}
                                        isSearchable={true}
                                        required
                                    />
                                </FloatingLabel>
                                {productError && (
                                    <p className="text-danger" style={{ marginTop: "8px" }}>
                                        Select ProductName.
                                    </p>
                                )}
                            </div>

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter Price"
                                    className={`${product && product.SellingPrice
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="number"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Price"
                                        name="price"
                                        readOnly={true}
                                        value={product ? product.SellingPrice : ""}
                                        // onChange={(e) => {
                                        //     setValue(e.target.value)
                                        // }}
                                        disabled={props.clickType}
                                        required
                                    />
                                </FloatingLabel>
                            </div>

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Commission %"
                                    className={`${commisionData.commPer
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="tel"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Commission %"
                                        name="commPer"
                                        value={commisionData.commPer}
                                        onChange={onChangehandler}
                                        required
                                    />
                                </FloatingLabel>
                                {!commisionData.commPer && commissionPerError && (
                                    <p className="text-danger" style={{ marginTop: "8px" }}>
                                        Enter Commission %.
                                    </p>
                                )}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Commission Value"
                                    className={`${commisionData.commValue
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="number"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Commission Value"
                                        name="commValue"
                                        value={commisionData.commValue}
                                        onChange={onChangehandler}
                                        disabled={props.clickType}
                                        readOnly
                                        required
                                    />
                                </FloatingLabel>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="btns-save-cancel">
                    {!props.clickType && (
                        <SBSaveUpdateBtn
                            btnName={referelProfileId ? "Update" : "Save"}
                            onClickEffect={postCommissionData}
                        />

                    )}
                    <SbCancelBtn
                        onClickEffect={closeAddModal}
                        btnName="Cancel" />
                </div>
            </Modal>
        </div>
    );
}

export default AddCommission;





