import * as types from "./actionType";

const initialState = {
  // Topbar page breadcome
  theme: "",
  pageData: "",
  access: false,
  supportAccess: false,
  totalCount: "",
  // Topbar page breadcome
  mainModules: [],
  sbcrBySubscriber: [],
  allMenus: [],
  subcriptionList: [],
  subCrDropdown: [],
  rolesDropdown: [],
  allSubcriptions: [],
  allSubsById: [],
  trackData: [],
  distanceMatrix: {},
  successMsg: {},

  // Dashboard Page Graph Api
  graphData: [],
  graphCount: [],
  // Dashboard Page Graph Api

  // Client page
  companyData: [],
  companyReportingData: [],
  singleClient: {},
  otherContacts: [],
  contactByCompanyDrop: [],
  clientDropdown: [],
  // Client page

  // Category page
  clientCategoryData: [],
  singleClientCategoryData: {},
  clientReportingCategoryData: [],
  categoryDropdown: [],
  // Category page
  // Stage page
  clientStageData: [],
  stageDropdown: [],
  // Stage page

  // Custom fields page
  customFields: [],
  singleField: {},
  // Custom fields page

  // Holiday List page
  holidayList: [],
  singleHoliday: {},
  holidayTypes: [],

  // Holiday List page

  // shift page
  shiftData: [],
  // shift page

  // Client Complaint Page
  clientComplaints: [],
  singleClientCompaint: {},
  // Client Complaint Page

  // Products page
  productData: [],
  singleProduct: {},
  productDropdown: [],
  cartList: [],
  // Products page

  // Orders Page
  oderDetails: [],
  singleOrder: [],
  productsByOrder: [],
  orderHistory: [],
  // Orders Page

  // Meeting Page
  meetingData: [],
  meetingDetailsData: [],
  meetingCountsData: {},
  // Meeting Page

  // Product Category Page
  prodCategoryDrop: [],
  prodSubcategory: [],
  // Product Category Page

  // Designation page
  desigationdrop: [],
  // Designation page

  // Basefare page
  // mot dropdown
  motDrop: [],
  // mot dropdown
  baseFareList: [],
  singleBaseFire: {},
  // Basefare page

  // Department page
  departmentDrop: [],
  departDesignation: [],
  // Department page

  // Get Apis for ZOone, State, Country, City
  countryCodes: [],
  zoneList: [],
  stateList: [],
  cityList: [],
  // Get Apis for ZOone, State, Country, City

  // Upload Images
  imgUrl: {},
  // Upload Images

  // Super admin Pages
  // Google credit page
  googleCreditList: [],
  apiDropdown: [],
  creditCount: [],
  ApiCreditList: [],
  apiNameDropdown: [],
  apiSingleData: {},
  apiExceptionData: [],
  apiMobileExceptionData: [],
  otpData: "",
  // Google credit page

  // Leads Data
  getLeadsCardData: [],
  getLeadsTableData: [],
  // Leads Data
  // Super admin Pages

  // User Page
  userDropdown: [],
  userRegionResult: [],
  allUserDetails: [],
  userDropdownByCompany: [],
  // User Page

  managerDropdown: [],

  // Google Hit Count
  // hitCount: [],
  // Google Hit Count

  // Subscription Pages
  subscriptionTypeDropdown: [],
  // Subscription Pages
  // Refferal Pages
  salesBeeRefferal: [],
  apiSingleRefferalData: {},
  // Refferal Pages

  // ClientCategoryMap
  clientCategoryMap: [],
  // ClientCategoryMap

  // ProductFilter Page
  productFilterData: [],
  singleProductFilter: {},
  // ProductFilter Page
  // Hierarchy Page
  hierarchyData: [],
  // Hierarchy Page

  // UserReferrals Page
  userReferralsData: [],
  userReferralsDataDropDown: [],
  singleUserReferralsData: {},
  userReferralsDropdown: [],
  // UserReferrals Page

  //   Referral Product Page
  productReferralData: [],
  //   Referral Product Page

  // Commission Page
  commissionData: [],
  productsData: [],
  singleCommissionData: {},
  // Commission Page

  // Passbook Page
  cardData: [],
  transactionData: [],
  userBankData: [],
  userPassbookDetails: {},
  userPassbookSummeryData: [],
  userPassbookSummeryById: [],
  userTransactionDetails: {},
  // Passbook Page

  // Payment Page
  PaymentData: [],
  singlePaymentData: {},
  // Payment Page

  // SignupSibscription Page
  signUpSubscriptionData: [],
  // SignupSibscription Page

  // CreateSubscription Page
  getReferredByDropdownData: [],
  // CreateSubscription Page

  // Payroll Page
  getPayrollData: [],
  // Payroll Page

  // Region Page
  getCountryName: [],
  getDropdownState: [],
  getAllStateName: [],
  getAllCityName: [],
  // Region Page

  // OrthoAttendence page
  getOrthoAttendence: [],
  // OrthoAttendence page

  // TravelReport Page
  getTravelAttachments: [],
  getSingleTravelReportData: {},
  // TravelReport Page

  // Route Deviation page
  getRouteDeviation: [],
  // Route Deviation page

  // Claim Hirarchy Page
  getClaimHirarchy: [],
  // Claim Hirarchy Page

  // User Activity Page
  getUserActivity: [],
  // User Activity

  // Kpa Page
  getKpa: [],
  getSingleKpaData: {},
  // Kpa Page
  // Machines Page
  getMachinesData: [],
  // Machines Page
  // AnnualContract Page
  getAmcData: [],
  getSingleAmcData: {},
  // AnnualContract Page
  // Escalation Page
  getEscalationData: [],
  getSingleMachineData: {},
  // Escalation Page

  getCustomFieldsForCompany: [],
  getSingleCustomFieldsForCompany: [],

  loading: true,
};

const designReducers = (state = initialState, action) => {
  switch (action.type) {
    // Topbar page Breadcome
    case "Theme_Color":
      return {
        ...state,
        theme: action.payload,
        loading: false,
      };
    case types.Get_BreadCome_Data:
      return {
        ...state,
        pageData: action.payload,
        loading: false,
      };
    case types.Get_Admin_Access:
      return {
        ...state,
        access: action.payload,
        loading: false,
      };
    case types.Get_Support_Access:
      return {
        ...state,
        supportAccess: action.payload,
        loading: false,
      };
    case types.Get_Total_Data:
      return {
        ...state,
        totalCount: action.payload,
        loading: false,
      };
    // Topbar page Breadcome
    // Upload Images
    case types.Upload_Single_Image:
      return {
        ...state,
        imgUrl: action.payload,
        loading: false,
      };
    // Upload Images

    case types.Add_Designation:
    case types.Get_Main_Modules:
      return {
        ...state,
        mainModules: action.payload,
        loading: false,
      };
    case types.Get_Subscription_Module:
      return {
        ...state,
        sbcrBySubscriber: action.payload,
        loading: false,
      };
    case types.Get_All_Menus:
      return {
        ...state,
        allMenus: action.payload,
        loading: false,
      };
    case types.Post_Menus_Subscription:
    case types.Get_Subscription:
      return {
        ...state,
        subcriptionList: action.payload,
        loading: false,
      };
    case types.Get_Subscr_Dropdown:
      return {
        ...state,
        subCrDropdown: action.payload,
        loading: false,
      };
    case types.Get_Roles_DropDown:
      return {
        ...state,
        rolesDropdown: action.payload,
        loading: false,
      };
    case types.Get_All_Subscription:
      return {
        ...state,
        allSubcriptions: action.payload,
        loading: false,
      };
    case types.Get_All_Id_Subs:
      return {
        ...state,
        allSubsById: action.payload,
        loading: false,
      };
    case types.Get_Track_User:
      return {
        ...state,
        trackData: action.payload,
        loading: false,
      };
    case types.Get_Distance_Matrix:
      return {
        ...state,
        distanceMatrix: action.payload,
        loading: false,
      };
    case types.Get_Successs_Msg:
      return {
        ...state,
        successMsg: action.payload,
        loading: false,
      };

    // Dashboard page graph API's
    case types.Get_GraphData:
      return {
        ...state,
        graphData: action.payload,
        loading: false,
      };
    case types.Get_GraphCount:
      return {
        ...state,
        graphCount: action.payload,
        loading: false,
      };
    // Dashboard page graph API's

    // Category page
    case types.Update_Client_Category:
    case types.Post_Client_Category:
    case types.Edit_Client_Category:
    case types.Get_Client_Category:
      return {
        ...state,
        clientCategoryData: action.payload,
        loading: false,
      };
    case types.Get_Single_Client_Category:
      return {
        ...state,
        singleClientCategoryData: action.payload,
        loading: false,
      };
    case types.Get_Client_Reporting_Category:
      return {
        ...state,
        clientReportingCategoryData: action.payload,
        loading: false,
      };
    case types.Client_Category_DropDown:
      return {
        ...state,
        categoryDropdown: action.payload,
        loading: false,
      };

    // Category page
    // Stage page
    case types.Get_Client_Stage:
      return {
        ...state,
        clientStageData: action.payload,
        loading: false,
      };
    case types.Update_Client_Stage:
    case types.Post_Client_Stage:
    case types.Stage_DropDown:
      return {
        ...state,
        stageDropdown: action.payload,
        loading: false,
      };

    // Stage page

    // Custom Fields Page
    case types.Post_Custom_Fields:
    case types.Get_Custom_Fields:
      return {
        ...state,
        customFields: action.payload,
        loading: false,
      };
    case types.Update_Custom_Fields:
    case types.Get_Single_Filed:
      return {
        ...state,
        singleField: action.payload,
        loading: false,
      };
    // Custom Fields Page

    // Company page
    case types.Get_Company_Data:
      return {
        ...state,
        companyData: action.payload,
        loading: false,
      };
    case types.Get_Reporting_Client_Data:
      return {
        ...state,
        companyReportingData: action.payload,
        loading: false,
      };
    case types.Post_Comapy_Data:
    case types.Update_Company_Data:
    case types.Get_Single_Client:
      return {
        ...state,
        singleClient: action.payload,
        loading: false,
      };
    case types.Post_Other_Contact:
    case types.Get_Other_Contact:
      return {
        ...state,
        otherContacts: action.payload,
        loading: false,
      };
    case types.ContactBy_Company_Drop:
      return {
        ...state,
        contactByCompanyDrop: action.payload,
        loading: false,
      };
    case types.Client_Dropdown:
      return {
        ...state,
        clientDropdown: action.payload,
        loading: false,
      };
    // Company page

    // Holiday List Page
    case types.Get_Holiday_List:
      return {
        ...state,
        holidayList: action.payload,
        loading: false,
      };
    case types.Hliday_Types:
      return {
        ...state,
        holidayTypes: action.payload,
        loading: false,
      };
    case types.Post_Holiday_List:
    case types.Update_Holiday_Data:
    case types.Single_Holiday_Data:
      return {
        ...state,
        singleHoliday: action.payload,
        loading: false,
      };
    // Holiday List Page

    // Shift page
    case types.Post_Shift_List:
    case types.Get_Shift_List:
      return {
        ...state,
        shiftData: action.payload,
        loading: false,
      };
    // Shift page

    // Client Compalint Page
    case types.Post_Client_Complaint:
    case types.Update_Client_Complaint:
    case types.Get_Client_Complains:
      return {
        ...state,
        // clientComplaints: action.payload,
        clientComplaints: action.payload,
        loading: false,
      };
    case types.Simgle_Client_Complaint:
      return {
        ...state,
        singleClientCompaint: action.payload,
        loading: false,
      };
    // Client Compalint Page

    // Products Page
    case types.Post_Product_Data:
    case types.Update_Product_Data:
    case types.Get_Products_List:
      return {
        ...state,
        productData: action.payload,
        loading: false,
      };
    case types.Get_Single_Product:
      return {
        ...state,
        singleProduct: action.payload,
        loading: false,
      };
    case types.Get_Product_DropDown:
      return {
        ...state,
        productDropdown: action.payload,
        loading: false,
      };
    case types.Get_Cart_List:
      return {
        ...state,
        cartList: action.payload,
        loading: false,
      };
    // Products Page

    // Orders Page
    case types.Get_Orders:
      return {
        ...state,
        oderDetails: action.payload,
        loading: false,
      };
    case types.Get_Single_Orders:
      return {
        ...state,
        singleOrder: action.payload,
        loading: false,
      };
    case types.Get_ProductsBy_Order:
      return {
        ...state,
        productsByOrder: action.payload,
        loading: false,
      };
    case types.Update_Product_Qty:
    case types.Get_Order_History:
      return {
        ...state,
        orderHistory: action.payload,
        loading: false,
      };
    // Orders Page

    // Meeting Page
    case types.Get_Meetings_Data:
      return {
        ...state,
        meetingData: action.payload,
        loading: false,
      };
    case types.Get_MeetingsDetails_Data:
      return {
        ...state,
        meetingDetailsData: action.payload,
        loading: false,
      };

    case types.Get_MeetingsCount_Data:
      return {
        ...state,
        meetingCountsData: action.payload,
        loading: false,
      };
    // Meeting Page

    // Product category page
    case types.Get_ProdCategory_Dropdown:
      return {
        ...state,
        prodCategoryDrop: action.payload,
        loading: false,
      };
    case types.Get_Prod_Sub_Category:
      return {
        ...state,
        prodSubcategory: action.payload,
        loading: false,
      };
    // Product category page

    // Designation Page
    case types.Get_Designation_Dropdown:
      return {
        ...state,
        desigationdrop: action.payload,
        loading: false,
      };
    case types.Get_Designation_Department:
      return {
        ...state,
        departDesignation: action.payload,
        loading: false,
      };
    // Designation Page

    // Basefare page
    // mot dropdown
    case types.Get_Mot_Dropdown:
      return {
        ...state,
        motDrop: action.payload,
        loading: false,
      };
    // mot dropdown
    case types.Post_Basefare:
    case types.Get_BaseFare_List:
      return {
        ...state,
        baseFareList: action.payload,
        loading: false,
      };
    case types.Update_Basefare:
    case types.Get_Single_BaseFare:
      return {
        ...state,
        singleBaseFire: action.payload,
        loading: false,
      };
    // Basefare page

    // Department page
    case types.Get_Department_Dropdown:
      return {
        ...state,
        departmentDrop: action.payload,
        loading: false,
      };
    // Department page

    // Get Api's for State, country, Zone, City
    case types.Get_CountryCode_List:
      return {
        ...state,
        countryCodes: action.payload,
        loading: false,
      };
    case types.Get_Zones:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case types.Get_States_ByZoneId:
      return {
        ...state,
        stateList: action.payload,
        loading: false,
      };
    case types.Get_City_ByStateID:
      return {
        ...state,
        cityList: action.payload,
        loading: false,
      };
    // Get Api's for State, country, Zone, City

    // Super Admin Pages
    // Google credits
    case types.Post_Google_Credits:
    case types.Post_Api_Credits:
    case types.Get_Google_Credits:
      return {
        ...state,
        googleCreditList: action.payload,
        loading: false,
      };
    case types.Get_API_Dropdown:
      return {
        ...state,
        apiDropdown: action.payload,
        loading: false,
      };
    case types.Get_Credits_Count:
      return {
        ...state,
        creditCount: action.payload,
        loading: false,
      };
    case types.Get_API_Credits:
      return {
        ...state,
        ApiCreditList: action.payload,
        loading: false,
      };
    case types.GET_APT_HITS:
      return {
        ...state,
        ApiHitList: action.payload,
        loading: false,
      };
    case types.Get_GoogleApiName_Drop:
      return {
        ...state,
        apiNameDropdown: action.payload,
        loading: false,
      };
    case types.Get_Single_ApiCredit:
      return {
        ...state,
        apiSingleData: action.payload,
        loading: false,
      };
    case types.Get_Exception:
      return {
        ...state,
        apiExceptionData: action.payload,
        loading: false,
      };
    case types.Get_Mobile_Exception:
      return {
        ...state,
        apiMobileExceptionData: action.payload,
        loading: false,
      };
    case types.Get_Otp_Data:
      return {
        ...state,
        otpData: action.payload,
        loading: false,
      };
    // Google credits

    // Leads Data
    case types.get_leads_card_data:
      return {
        ...state,
        getLeadsCardData: action.payload,
        loading: false,
      };
    case types.get_leads_table_data:
      return {
        ...state,
        getLeadsTableData: action.payload,
        loading: false,
      };
    // Leads Data
    // Super Admin Pages

    // User Page
    case types.Get_User_Dropdown:
      return {
        ...state,
        userDropdown: action.payload,
        loading: false,
      };
    case types.Get_User_Dropdown_By_Company:
      return {
        ...state,
        userDropdownByCompany: action.payload,
        loading: false,
      };
    case types.Get_User_Regions:
      return {
        ...state,
        userRegionResult: action.payload,
        loading: false,
      };
    case types.Get_Allser_Details:
      return {
        ...state,
        allUserDetails: action.payload,
        loading: false,
      };
    // User Page

    case types.Get_Manager_Dropdown:
      return {
        ...state,
        managerDropdown: action.payload,
        loading: false,
      };

    // Googel Hit Count
    // case types.Get_Hit_Count:
    //     return {
    //         ...state,
    //         hitCount: action.payload,
    //         loading: false
    //     }
    // Googel Hit Count

    // Subscription Pages
    case types.Update_Subscription:
    case types.get_Subscription_Type_Dropdown:
      return {
        ...state,
        subscriptionTypeDropdown: action.payload,
        loading: false,
      };
    // Subscription Pages

    // Refferal Page
    case types.post_SalesBee_Refferal:
    case types.update_SalesBee_Refferal:
    case types.get_SalesBee_Refferal:
      return {
        ...state,
        salesBeeRefferal: action.payload,
        loading: false,
      };
    case types.Get_Single_Refferal_Data:
      return {
        ...state,
        apiSingleRefferalData: action.payload,
        loading: false,
      };
    // Refferal Page

    // ClientCategoryMap
    case types.post_clientCategory_Map:
    case types.get_clientCategory_Map:
      return {
        ...state,
        clientCategoryMap: action.payload,
        loading: false,
      };

    // ClientCategoryMap end

    // Hierarchy Page
    case types.get_Hierarchy_Data:
      return {
        ...state,
        hierarchyData: action.payload,
        loading: false,
      };
    // Hierarchy Page

    // ProductFilter Page
    case types.post_productFilter_Data:
    case types.update_productFilter_Data:
    case types.get_productFilter_Data:
      return {
        ...state,
        productFilterData: action.payload,
        loading: false,
      };
    case types.get_Single_productFilter_Data:
      return {
        ...state,
        singleProductFilter: action.payload,
        loading: false,
      };
    // ProductFilter Page

    // UserReferrals Page
    case types.post_UserReferrals_BankDetails:
    case types.get_UserReferrals_Data:
      return {
        ...state,
        userReferralsData: action.payload,
        loading: false,
      };
    case types.get_UserReferrals_DataDropDown:
      return {
        ...state,
        userReferralsDataDropDown: action.payload,
        loading: false,
      };
    case types.get_SingleUserReferrals_Data:
      return {
        ...state,
        singleUserReferralsData: action.payload,
        loading: false,
      };
    case types.get_UserReferrals_DropDown:
      return {
        ...state,
        userReferralsDropdown: action.payload,
        loading: false,
      };
    // UserReferrals Page
    // Referral Product Page
    case types.get_referralProduct_Data:
      return {
        ...state,
        productReferralData: action.payload,
        loading: false,
      };
    // Referral Product Page

    // Commission Page
    case types.post_commission_Data:
    case types.get_commission_Data:
      return {
        ...state,
        commissionData: action.payload,
        loading: false,
      };
    case types.get_ProductDropdown_Data:
      return {
        ...state,
        productsData: action.payload,
        loading: false,
      };
    case types.upDate_Commission_Data:
    case types.get_SingleCommission_Data:
      return {
        ...state,
        singleCommissionData: action.payload,
        loading: false,
      };
    // Commission Page

    // Passbook Page
    case types.get_PassbookCard_Data:
      return {
        ...state,
        cardData: action.payload,
        loading: false,
      };
    case types.get_Transaction_Data:
      return {
        ...state,
        transactionData: action.payload,
        loading: false,
      };
    case types.get_userBank_Data:
      return {
        ...state,
        userBankData: action.payload,
        loading: false,
      };
    case types.get_userPassbookDetails:
      return {
        ...state,
        userPassbookDetails: action.payload,
        loading: false,
      };
    case types.get_PassbookSummery_Data:
      return {
        ...state,
        userPassbookSummeryData: action.payload,
        loading: false,
      };
    case types.get_PassbookSummery_ById:
      return {
        ...state,
        userPassbookSummeryById: action.payload,
        loading: false,
      };
    case types.get_userTransaction_Details:
      return {
        ...state,
        userTransactionDetails: action.payload,
        loading: false,
      };
    // Passbook Page

    // Payment Page
    case types.add_Payment_Data:
    case types.get_Payment_Data:
      return {
        ...state,
        PaymentData: action.payload,
        loading: false,
      };
    case types.get_Single_Payment_Data:
      return {
        ...state,
        singlePaymentData: action.payload,
        loading: false,
      };
    // Payment Page

    // SignupSubscription Page
    case types.get_SignupSubscription_Data:
      return {
        ...state,
        signUpSubscriptionData: action.payload,
        loading: false,
      };
    // SignupSubscription Page

    // CreateSubscription Page
    case types.get_ReferredBy_Dropdown:
      return {
        ...state,
        getReferredByDropdownData: action.payload,
        loading: false,
      };
    // CreateSubscription Page

    // Payroll Page
    case types.post_Employee_Payroll:
    case types.post_FixedSalary_Data:
    case types.delete_Employee_Payroll:
    case types.get_Payroll_Data:
      return {
        ...state,
        getPayrollData: action.payload,
        loading: false,
      };
    // Payroll Page

    // Region Page
    case types.Add_State_Name:
    case types.Add_City_Name:
    case types.get_Country_Name:
      return {
        ...state,
        getCountryName: action.payload,
        loading: false,
      };
    case types.get_Dropdown_State_Name:
      return {
        ...state,
        getDropdownState: action.payload,
        loading: false,
      };
    case types.get_AllState_Name:
      return {
        ...state,
        getAllStateName: action.payload,
        loading: false,
      };
    case types.get_AllCity_Name:
      return {
        ...state,
        getAllCityName: action.payload,
        loading: false,
      };
    // Region Page

    // OrthoAttendence page
    case types.get_OrthoAttendence:
      return {
        ...state,
        getOrthoAttendence: action.payload,
        loading: false,
      };
    // OrthoAttendence page
    // TravelReport Page
    case types.get_TravelReport_Attachment:
      return {
        ...state,
        getTravelAttachments: action.payload,
        loading: false,
      };
    case types.get_Single_TravelReport_Data:
      return {
        ...state,
        getSingleTravelReportData: action.payload,
        loading: false,
      };
    // TravelReport Page

    // Route Deviation Page
    case types.get_Route_Deviation_Data:
      return {
        ...state,
        getRouteDeviation: action.payload,
        loading: false,
      };
    // Route Deviation Page

    // Claim Hirarchy Page
    case types.get_Claim_Hirarchy_Data:
      return {
        ...state,
        getClaimHirarchy: action.payload,
        loading: false,
      };
    // Claim Hirarchy Page

    // User Activity Page
    case types.get_User_Activity_Data:
      return {
        ...state,
        getUserActivity: action.payload,
        loading: false,
      };
    // User Activity Page

    // Kpa Page
    case types.post_Kpa_Data:
    case types.get_Kpa_Data:
      return {
        ...state,
        getKpa: action.payload,
        loading: false,
      };
    case types.get_Single_Kpa_Data:
      return {
        ...state,
        getSingleKpaData: action.payload,
        loading: false,
      };
    // Kpa Page

    // Account Settings Page
    case types.post_Direct_Visit_Data:
    case types.post_BaseLocation_CheckIn_Radius:
    case types.post_BaseLocation_CheckOut_Radius:
    // Account Settings Page

    // Machines Page
    // eslint-disable-next-line no-fallthrough
    case types.post_Machines_Data:
    case types.get_Machines_Data:
      return {
        ...state,
        getMachinesData: action.payload,
        loading: false,
      };
    // Machines Page

    // AnnualContract Page
    case types.post_AMC_Data:
    case types.get_AMC_Data:
      return {
        ...state,
        getAmcData: action.payload,
        loading: false,
      };
    case types.get_Single_AMC_Data:
      return {
        ...state,
        getSingleAmcData: action.payload,
        loading: false,
      };
    // AnnualContract Page

    // Escalation Page
    case types.post_Escalation_Data:
    case types.get_Escalation_Data:
      return {
        ...state,
        getEscalationData: action.payload,
        loading: false,
      };
    case types.get_Single_Machine_Data:
      return {
        ...state,
        getSingleMachineData: action.payload,
        loading: false,
      };
    // Escalation Page

    case types.post_Custom_Fields_For_Company:
    case types.get_Custome_Fields_For_Company:
      return {
        ...state,
        getCustomFieldsForCompany: action.payload,
        loading: false,
      };
    case types.get_Single_Custome_Fields_For_Company:
      return {
        ...state,
        getSingleCustomFieldsForCompany: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default designReducers;
