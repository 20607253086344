import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { loggedInRole } from "../LoginRole";
import SkeletonAttendance from "../Skeleton Loading/SkeletonAttendance";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import Footer from "../Footer";
import AttendanceBreakUps from "./AttendanceBreakUps";

const statusOptions = [
  { value: "present", label: "Present" },
  { value: "leaves", label: "On Leave" },
  { value: "absent", label: "Not Logged In" },
  { value: "all", label: "All" },
];

function AttendanceFullday() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterUserId, setFilterUserId] = useState("");
  const [attendenceFullDayFilter, setAttendenceFullDayFilter] = useState(
    statusOptions[0]
  );
  const [manager, setManager] = useState("");
  const [stateName, setStateName] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [attendanceByDay, setAttendanceByDay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  let tableHeader = ["NAME", "DATE", "LOGIN", "LOGOUT", "TIME SPENT"];

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [loading, setLoading] = useState(false);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  //* PAGINATION END *//

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/User/BindManagerDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Zone/GetStates/`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setStateList(
            res.result.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          );
        } else {
          setStateList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DROPDOWN DATA END *//

  //* FETCH ATTENDANCE DATA START *//
  useEffect(() => {
    fetchAttendanceByDay();
  }, [
    fromDate,
    toDate,
    filterUserId,
    currentPage,
    PageSize,
    manager,
    stateName,
    attendenceFullDayFilter,
  ]);

  const fetchAttendanceByDay = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetAttendanceListAll?CurrentPage=${currentPage}&PageSize=${PageSize}&UserId=${filterUserId}&IncludeFilter=${attendenceFullDayFilter.value
      }&FromDate=${moment(fromDate).format("DD/MM/YYYY")}&ToDate=${moment(
        toDate
      ).format("DD/MM/YYYY")}&managerId=${manager}&stateId=${stateName}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAttendanceByDay(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res?.recordCount[0]);
          setPageCount(Math.ceil(res?.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setAttendanceByDay(res.result);
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH ATTENDANCE DATA END *//

  const [showModal, setShowModal] = useState(false);
  const [meetingID, setMeetingID] = useState("");
  const [userName, setUserName] = useState("");
  const [attenDate, setAttenDate] = useState("");

  const handleView = (meetID, name, date) => {
    setShowModal(true);
    setMeetingID(meetID);
    setUserName(name);
    setAttenDate(date);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManager("");
    setStateName(0);
    setFromDate(new Date());
    setToDate(new Date());
    setAttendenceFullDayFilter(statusOptions[0]);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelAttendance = () => {
    exportExcel(
      `${baseURL}/api/Export/AttendanceExcelExport?UserID=${filterUserId}&ManagerId=${manager}&StateID=${stateName}&IncludeFilter=${attendenceFullDayFilter.value
      }&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      `Attendance-FullDay${filterUserId}.xlsx`
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    {/* Filter and Header Section */}
                    <div className="user-table">
                      <Row className="search-row">
                        <Col md={8} className="cat-col">
                          <div className="page-header-text-div">
                            <div className="back-btn" onClick={goToBackPage}>
                              <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                              />
                            </div>
                            <h6 className="page-header-text">
                              {" "}
                              ATTENDANCE-FULLDAY{" "}
                            </h6>
                          </div>
                        </Col>
                        {/* <Col md={5} className="cat-col">
                          <div className="input-div">
                            <input
                              type="search"
                              id="metricInfo_search_bar"
                              placeholder="Search By Mobile number / User-Code"
                              className="form-control setting-input"
                            // value={searchValue}
                            // onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <i className="fa fa-search search-icon" />
                          </div>
                        </Col> */}
                        <Col md={4} className="cat-col">
                          <div
                            className="search-bar-header"
                            style={{ float: "right" }}
                          >
                            <div className="page-top-bar-dropdowns page-top-Filters">
                              <div
                                className="header-filter-btn"
                                onClick={() => setFilter(!filter)}
                              >
                                <p className="card-head">
                                  <i className="bi bi-sliders" />
                                  &nbsp;&nbsp;Filters
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {filter && (
                        <div className="page-filter-section">
                          <div style={{ width: "100%" }}>
                            <Row>
                              <Col md={12}>
                                <div className="clients-filter-input-boxs filter-box-div">
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                  >
                                    <Select
                                      className="react-select-container-list"
                                      classNamePrefix="react-select-list"
                                      options={userList}
                                      placeholder="Select User"
                                      value={
                                        filterUserId === ""
                                          ? ""
                                          : userList.value
                                      }
                                      onInputChange={userList.label}
                                      onChange={(data) =>
                                        setFilterUserId(data.value)
                                      }
                                      isSearchable={true}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                  >
                                    {loggedInRole !== 2 && (
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={managerList}
                                        placeholder="Select Manager"
                                        value={
                                          manager === ""
                                            ? ""
                                            : managerList.value
                                        }
                                        onInputChange={managerList.label}
                                        onChange={(data) =>
                                          setManager(data.value)
                                        }
                                        isSearchable={true}
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                  >
                                    <Select
                                      className="react-select-container-list"
                                      classNamePrefix="react-select-list"
                                      options={stateList}
                                      placeholder="Select State"
                                      value={
                                        stateName === 0 ? "" : stateList.value
                                      }
                                      onInputChange={stateList.label}
                                      onChange={(data) =>
                                        setStateName(data.value)
                                      }
                                      isSearchable={true}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                    className="input-group from-to-date-select"
                                  >
                                    <span
                                      className="input-group-text"
                                      id="from-label"
                                    >
                                      From - To
                                    </span>
                                    <span className="date-picker-with-icon">
                                      <DatePicker
                                        selected={fromDate}
                                        className="select from-date date-filter"
                                        id="date-range-picker"
                                        dateFormat="dd/MM/yyyy"
                                        selectsRange
                                        startDate={fromDate}
                                        endDate={toDate}
                                        onChange={(date) =>
                                          setSelectSearchDrop(date)
                                        }
                                        maxDate={new Date()}
                                      />
                                    </span>
                                    <span>
                                      <i
                                        className="fa fa-calendar-o calander-icon"
                                        style={{ right: "15px" }}
                                      />
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                  >
                                    <Select
                                      className="react-select-container-list"
                                      classNamePrefix="react-select-list"
                                      options={statusOptions}
                                      placeholder="Select"
                                      value={attendenceFullDayFilter}
                                      onInputChange={statusOptions.label}
                                      onChange={(data) =>
                                        setAttendenceFullDayFilter(data)
                                      }
                                      isSearchable={true}
                                    />
                                  </div>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <SbCancelBtn
                                      onClickEffect={clearSerachBySelect}
                                      btnName="Clear"
                                    />
                                  </div>
                                </div>
                              </Col>
                              {/* <Col md={2}>
                                <div className="clients-filter-input-boxs filter-box-div">
                                  <SbCancelBtn
                                    onClickEffect={clearSerachBySelect}
                                    btnName="Clear"
                                  />
                                </div>
                              </Col> */}
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Filter and Header Section */}

                    {/* USER TABLE START */}
                    <div className="attendance-table">
                      <div
                        className="table-responsive attendance-daily-scroll"
                        onScroll={(e) =>
                          handleScroll(
                            e,
                            attendanceByDay,
                            totalData,
                            setLoading,
                            setPageSize,
                            PageSize
                          )
                        }
                      >
                        <table className="table attendance-list-table">
                          {/* <thead className="thead-light attendance-list-thead">
                            <tr>
                              <th>NAME</th>
                              <th>DATE</th>
                              <th>LOGIN</th>
                              <th>LOGOUT</th>
                              <th>TIME SPENT</th>
                            </tr>
                          </thead> */}
                          <SbTableHeader tableHeader={tableHeader} />
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                              </>
                            ) : attendanceByDay != "" ? (
                              attendanceByDay?.map((data, i) => (
                                <tr
                                  className="attendance-list-row-data cursor-pointer"
                                  key={i}
                                  onClick={() =>
                                    handleView(
                                      data.attendanceID,
                                      data?.userFullName,
                                      data?.attendanceDate
                                    )
                                  }
                                >
                                  <td>
                                    <span className="attendance-list-name d-flex">
                                      <img
                                        className="table-data-attendance-imgages"
                                        src={profileimg}
                                        alt="John Doe"
                                      />
                                      <div>
                                        <div className="table-row-head-Text color-green">
                                          {data?.userFullName}
                                        </div>
                                        <div className="table-row-sub-text">
                                          {data?.userCode}
                                        </div>
                                        <div className="table-row-sub-text">
                                          {data?.stateName}
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{data?.attendanceDate}</td>
                                  <td style={{ maxWidth: "200px" }}>
                                    <span className="login-details d-flex">
                                      <div className="table-row-head-Text">
                                        <b>
                                          {data?.inTime ??
                                            data.attendanceStatus}
                                        </b>
                                      </div>
                                    </span>

                                    {/* {data?.checkInLoc.location}
                                    <span className="login-time-location">
                                      <div className="table-row-sub-text">
                                        <i className="bi bi-geo-alt"></i>
                                        {data?.checkInLoc?.latitude},
                                        {data?.checkInLoc?.longitude}
                                      </div>
                                    </span> */}
                                    {data?.checkInLoc && (
                                      <span className="login-time-location">
                                        {data.checkInLoc.location && (
                                          <>
                                            <div className="table-row-sub-text">
                                              <i className="bi bi-geo-alt"></i>
                                              {data.checkInLoc.latitude},{" "}
                                              {data.checkInLoc.longitude}
                                            </div>
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </td>
                                  <td style={{ maxWidth: "200px" }}>
                                    <span className="logout-details d-flex">
                                      <div className="table-row-head-Text">
                                        <b>{data?.outTime}</b>
                                      </div>
                                    </span>

                                    {data?.checkOutLoc?.location}
                                    <span className="logout-time-location">
                                      <div className="table-row-sub-text">
                                        <i className="bi bi-geo-alt"></i>
                                        {data?.checkOutLoc?.latitude},
                                        {data?.checkOutLoc?.longitude}
                                      </div>
                                    </span>
                                  </td>
                                  <td>{data?.duration}</td>
                                </tr>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                <td colSpan={5}>------ NO DATA FOUND ------</td>
                              </tr>
                            )}
                            {loading && <OnScrollLoading />}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    <div className="download-and-pagination">
                      <SbDownloadBtn onClickEffect={exportExcelAttendance} />
                    </div>

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
                <>
                  {showModal && (
                    <AttendanceBreakUps
                      showModal={showModal}
                      meetingID={meetingID}
                      userName={userName}
                      attenDate={attenDate}
                      handleCloseModal={handleCloseModal}
                    />
                  )}
                </>

                {/* MAIN BODY END */}
              </div>
              <Footer />
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default AttendanceFullday;
