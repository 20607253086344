import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditDepartment(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [deptDetails, setDeptDetails] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [enableStatus, setEnableStatus] = useState(null);

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useEffect(() => {
    getDepartmentById();
  }, [props.deptId]);

  const getDepartmentById = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Department/Get/${props.deptId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDeptDetails(res.result);
          setDepartmentName(res?.result?.name);
          setEnableStatus(res?.result?.statusID);
        } else {
          setDeptDetails("");
          setDepartmentName("");
        }
      });
  };

  const editDepartment = () => {
    if (departmentName !== "") {
      const departmentDetail = {
        id: props.deptId,
        name: departmentName,
        statusID: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(departmentDetail),
      };
      fetch(`${baseURL}/api/Department/UpdateDepartment`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchDepartments();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            // props.handleClose();
            // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
            toast.error(res.message);
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Enter the Department Name",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.editDepartmentModel}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">EDIT DEPARTMENT</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-2">
                <FloatingLabel label='Department Name' className={`${departmentName ? "float-input-visible" : "float-hidden float-input"}`}>
                  <input
                    type="text"
                    className="form-control form add-basefare-input"
                    placeholder="Department Name"
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                  />
                </FloatingLabel>
                {fieldReq && departmentName == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={editDepartment} btnName="Update" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
