import React, { useState } from "react";
import "../styles/Login.scss";
import { Button, Col, Image, Row } from "react-bootstrap";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import travelize from "../images/metric-favicon.svg";
import InternetIssue from "../Components/InternetIssue";

function UnAutherized() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [loginResponse, setLoginResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let themeColor = localStorage.getItem("theme");

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <div
            className="main-body"
            style={{ backgroundImage: 'url("images/Login_Page/bg4.jpg")' }}
          >
            <div className="login-page-body">
              {/* <div className="beta-tag">
                <h6>Beta</h6>
              </div> */}
              <Row className="sb-login-row">
                <Col md={5} className="sb-gif-col col-left">
                  <div className="w-100">
                    <div className="sb-login-logo-div">
                      <Image
                        src={travelizeSalesBeeLogo}
                        alt="Now Travelize is SalesBee"
                        className="sb-login-logo"
                      />
                    </div>
                    <div className="sb-Acess-section">
                        <img src="../../images/UnAuthorized/Acess.png" alt="" />
                    </div>
                    <div className="sb-signin-text">
                      <p style={{ fontWeight: "700", fontSize: "14px", textAlign: "center"}}>This module is not accessible during free trial</p>
                    </div>
                  </div>
                </Col>
                <Col md={2} className="col-right">
                  <div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                    <div className="travelize-log">
                      <Image
                        src={travelize}
                        alt="Now Travelize is SalesBee"
                        className="sb-trv-logo"
                      />
                    </div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                  </div>
                </Col>
                <Col md={5} className="sb-gif-col col-right">
                  <div>
                    <div className="sb-login-bgimg">
                      <Image
                        src={`../images/Login/laptop-motion-${themeColor ? themeColor : "light"}.gif`}
                        height="350"
                        alt="Now Travelize is SalesBee"
                        className="w-100"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UnAutherized;
