import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import moment from 'moment';
import { dateFormatLocal } from '../../FormattedAmount';

function ApiCreditsTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='credits-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} className={`thead-cell-products ${(name === "ACTION" || name === "SL.NO") ? "action-class" : name === "AVAILABLE CREDITS" ? "avail-credit" : ""}`}
                                    >
                                        {name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <TableBody>
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                            </TableBody>
                            :
                            <>
                                {props.ApiCreditList && props.ApiCreditList.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell colSpan={props.tableHeader.length}>
                                                No data found...!
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.ApiCreditList && props.ApiCreditList.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data'>
                                                <TableCell align="center">
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell className='company-subscriptionId'>
                                                    <div className="table-row-head-Text">
                                                        {data.Company}
                                                    </div>
                                                    <div className="table-row-sub-text">
                                                        {data.SubscriptionID}
                                                    </div>
                                                </TableCell>
                                                <TableCell >
                                                    <span className='primary-color' style={{ cursor: "pointer" }} onClick={props.onClickNavigate}><b>{data.ApiName}</b></span>
                                                </TableCell>
                                                <TableCell>
                                                    {dateFormatLocal(data.FromDate)}
                                                </TableCell>
                                                <TableCell>
                                                    {dateFormatLocal(data.ToDate)}
                                                </TableCell>
                                                <TableCell>
                                                    {data.TotalCreadits}
                                                </TableCell>
                                                <TableCell className='remaining-credits'>
                                                    {
                                                        data.RemainingCreadits <= 0 ?
                                                            <span style={{ color: "red" }}>{data.RemainingCreadits}</span>
                                                            : <span>{data.RemainingCreadits}</span>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {((parseFloat(data.TotalCreadits) - parseFloat(data.RemainingCreadits)) > data.TotalCreadits) ?
                                                        <span className='color-secondary'>{(parseFloat(data.TotalCreadits) - parseFloat(data.RemainingCreadits))} <br /><span style={{ color: "red", fontSize: "11px" }}>Exceeded</span></span> :
                                                        <span className='color-secondary'>{(parseFloat(data.TotalCreadits) - parseFloat(data.RemainingCreadits))}</span>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {data.DaysLeft < 0 ? (<span style={{ color: "red" }}>0 Days</span>) : data.DaysLeft <= 7 ? (<span style={{ color: "red" }}>{data.DaysLeft} Days</span>)
                                                        : (<span>{data.DaysLeft} Days</span>)}
                                                </TableCell>

                                                <TableCell className='action-class'>
                                                    {props.supportAccess === false && <button
                                                        onClick={() => props.editAPICredit(data.ApiCreaditID)}
                                                        className="btn client-history-link" >
                                                        <span><i className="bi bi-pencil-fill"></i> Edit</span>
                                                    </button>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default ApiCreditsTable;