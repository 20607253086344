import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

function OTPTimer({ expiryTimeInSeconds, onTimerExpire, showTimer, getOtpForSuperAdmin }) {
  const [seconds, setSeconds] = useState(expiryTimeInSeconds);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      onTimerExpire();
    }
  }, [seconds, onTimerExpire]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const remainingSeconds = timeInSeconds % 60;
    const formattedTime =
      minutes.toString().padStart(2, '0') +
      ':' +
      remainingSeconds.toString().padStart(2, '0');
    return formattedTime;
  };

  return (
    <div>
      <Button className='resend-otp' onClick={getOtpForSuperAdmin} disabled={showTimer}>Resend OTP ?</Button> 
      { showTimer && <span>{formatTime(seconds)}</span> }
    </div>
  );
}

export default OTPTimer;