import React, { useState } from "react";
import "../../src/styles/SignIn.scss";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import travelize from "../images/metric-favicon.svg";
import { Form, Button, Col, Image, Row, Carousel } from "react-bootstrap";
import { baseURL } from "./BaseUrl";
import * as swal from "../Components/Consturl/SwalAlert";
import { toast } from "react-toastify";

const UpdatedForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  let themeColor = localStorage.getItem("theme");

  let navigate = useNavigate();

  const forgotpassword = (e) => {
    const usernameDta = {
      UserName: email,
    };

    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(usernameDta),
    };
    fetch(`${baseURL}/api/Authentication/ForgotPassword`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //   setResetPasswordId(res.result);
          swal.showAlert("Success", `${res.message}`, "success");
          const forgotPassData = { email: email, id: res.result };
          sessionStorage.setItem(
            "forgotPassData",
            JSON.stringify(forgotPassData)
          );
          setTimeout(() => navigate("/"), 1000);
        } else {
          toast.error(res.errorMessage);
        }
      });
    e.preventDefault();
  };
  return (
    <div className="container-fluid">
      <div className="sigin-page-body">
        {/* <Row> */}
        <div className="dflex-flow-row">
          <Col lg={5} md={5} sm={12} className="signIn-container-left-div">
            <div className="signIn-container-logo">
              <Image
                src={travelizeSalesBeeLogo}
                alt="MetricInfo"
                className="signIn-logo"
              />
            </div>
            <div className="signIn-container-gif">
              <Image
                src="../images/sign-animate.gif"
                alt="MetricInfo Motion Logo"
                className="signIn-moving-img"
              />
              <div className="text-sliding-below-img">
                <Carousel
                  style={{ width: "100%" }}
                  controls={false}
                  indicators={false}
                  interval={3000}
                >
                  <Carousel.Item>
                    <h6>
                      <b>Geolocation Monitoring</b>
                    </h6>
                    <p>Real-time tracking to determine precise location</p>
                  </Carousel.Item>
                  <Carousel.Item>
                    <h6>
                      <b>Reporting and Analytics</b>
                    </h6>
                    <p>
                      Offering valuable insights for informed decision-making
                    </p>
                  </Carousel.Item>
                  <Carousel.Item>
                    <h6>
                      <b>Visits and Expenses</b>
                    </h6>
                    <p>Ensuring optimal resource allocation and cost control</p>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="devider">
              <div className="v1"></div>
            </div>
          </Col>
          <Col lg={5} md={5} sm={12} className="signIn-container-right-div">
            <div className="metricinfo-logo">
              <Image
                src={travelize}
                alt="MetricInfo Logo"
                className="sb-trv-logo"
              />
            </div>
            <div className="signin-header forgotpass-part">
              <div className="forgotpass-header-part">
                <h2>Forgot Password</h2>
                <div>
                  <p>
                    Enter your email and we'll send you a link to reset your
                    password.
                  </p>
                </div>
              </div>
              <Form onSubmit={forgotpassword} className="forgotpass-form-data">
                <div className="form-group">
                  <div className="icon-with-input before">
                    <i className="fa fa-envelope"></i>
                    <input
                      type="email"
                      className="form-control signIn-form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="signin-button dflex-j-between mt5">
                  <div className="back-to-signin">
                    <Link to="/" className="color-yellow">
                      Back to Sign In
                    </Link>
                  </div>
                  <Button type="submit" className="btn btn-signin-button">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </div>
        {/* </Row> */}
      </div>
    </div>
  );
};

export default UpdatedForgotPasswordPage;
