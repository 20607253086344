import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";
import { FormattedAmount } from "../FormattedAmount";
import { useDispatch, useSelector } from "react-redux";
import { loadPassbookSummeryData } from "../../redux/action";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useReactToPrint } from "react-to-print";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

function PaymentPaidTable(props) {
    let tableHeader = [
        { name: "Transaction Details", align: "left", width: "200px" },
        { name: "Passbook Id", align: "left", width: "120px" },
        { name: "User Name", align: "left", width: "150px" },
        { name: "Referral Code", align: "left", width: "150px" },
        { name: "BDE", align: "left", width: "150px" },
        // { name: "Transaction Date", align: "left", width: "150px" },
        // { name: "Entry Type", align: "left", width: "150px" },
        { name: "Payment Mode", align: "left", width: "150px" },
        { name: "Paid", align: "left", width: "150px" },
        // { name: "Status", align: "center", width: "100px" },
        // { name: "Comment", align: "left", width: "400px" }
    ];

    let dispatch = useDispatch();
    const { userPassbookSummeryData } = useSelector((state) => state.data);
    const { totalCount } = useSelector((state) => state.data);
    console.log(userPassbookSummeryData, "userPassbookSummeryData")
    console.log(totalCount, "totalCount")
    const [isLoading, setIsLoading] = useState(true);
    const [filterArray, setFilterArray] = useState([]);
    const [PageSize, setPageSize] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     dispatch(loadPassbookSummeryData(setIsLoading))
    // }, [])

    const fethPaymentData = () => {
        dispatch(loadPassbookSummeryData(filterArray, PageSize, currentPage, setIsLoading, setLoading));
    };

    useEffect(() => {
        fethPaymentData(filterArray,  PageSize, currentPage)
    }, [filterArray,  PageSize, currentPage])

    const componentRef = useRef();
    const pdfDownload = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {/* <h4 className="accounts-header" >Passbook</h4> */}
            <div className="sb-table-div sb-table-setion">
                <Paper
                    sx={{ width: "100%", overflow: "hidden" }}
                    className="table-main-div"
                >
                    <TableContainer sx={{ maxHeight: 440 }}
                        onScroll={(e) =>
                            handleScroll(
                                e,
                                userPassbookSummeryData,
                                totalCount,
                                setLoading,
                                setPageSize,
                                PageSize
                            )
                        }
                    >
                        <Table
                            stickyHeader
                            aria-label="sticky table table-responsive"
                            className="basefare-table"
                            ref={componentRef}
                        >
                            <TableHead className="custom-table-header">
                                <TableRow>
                                    {tableHeader.map((name, i) => (
                                        <TableCell
                                            key={i}
                                            align={name.align}
                                            className={`thead-cell-products`}
                                            style={{ minWidth: name.width }}
                                        >
                                            {name.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {isLoading ? (
                                <>
                                    {tableHeader.map((name, i) => (
                                        <SkeletonMeetPurpose key={i} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {userPassbookSummeryData &&
                                        userPassbookSummeryData.length === 0 ? (
                                        <TableBody>
                                            <TableRow align="center">
                                                <TableCell align="center" colSpan={tableHeader.length}>
                                                    <div style={{ padding: "20px 20px" }}>
                                                        <img
                                                            src="../../../../images/DefaultImages/NodataFound.jpg"
                                                            alt=""
                                                            style={{ width: "10%" }}
                                                        />
                                                        <br />
                                                        <span>No Data Found...!</span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {userPassbookSummeryData && userPassbookSummeryData.filter(data => data.paid !== 0).map((data, index) => (
                                                <TableRow
                                                    align="center"
                                                    key={index}
                                                    className="client-list-row-data"
                                                >
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.transactionDate}
                                                        </div>
                                                        <div className="table-row-head-Text">
                                                            {data.bankTransactionID ? data.bankTransactionID : '-'}
                                                        </div>
                                                        <div className="table-row-sub-text">
                                                            {data.comment ? data.comment : '-'}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.gfRefPassbookID}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.userName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-sub-text">
                                                            {data.referelCode}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-sub-text">
                                                            {data.bde}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-sub-text">
                                                            {data.paymentMode ? data.paymentMode : '-'}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-sub-text">
                                                            <span className="green-Color"><i className="fa fa-inr" aria-hidden="true">
                                                            </i> {FormattedAmount(data.paid)}</span>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {loading && <OnScrollLoading />}
                                        </TableBody>
                                    )}
                                </>
                            )}
                        </Table>
                    </TableContainer>
                </Paper>
            </div >
            <div className="download-and-pagination">
                <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
            </div>
        </>
    );
}

export default PaymentPaidTable;
