export const optionsRoleList = [
  { value: "", label: "Select Role" },
  { value: "Manager", label: "Manager" },
  { value: "User", label: "User" },
];

export const optionsRoleListModel = [
  { value: "", label: "Select Role" },
  { value: 2, label: "Manager" },
  { value: 3, label: "User" },
];

export const optionsLeaveStatusUpdate = [
  { value: "", label: "Select Status" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
];

export const optionsMapByListModel = [
  { value: 0, label: "Added By User" },
  { value: 1, label: "Map By Country" },
  { value: 2, label: "Map By Zone" },
  { value: 3, label: "Map By State" },
  { value: 4, label: "Map By City" },
  { value: 5, label: "Map By Subscription" },
];

export const optionsStatusList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const warrantyStatus = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
export const priorityStatus = [
  { value: 3, label: "High" },
  { value: 2, label: "Medium" },
  { value: 1, label: "Low" },
];

export const optionsPausePlayList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Play" },
  { value: 0, label: "Pause" },
];

export const optionsRoutPlanStatus = [
  { value: "", label: "Select Status" },
  { value: "Planned", label: "Planned" },
  { value: "Pending", label: "Pending" },
  { value: "Completed", label: "Completed" },
];

export const priceRange = [
  { value: "1,500", label: "Below 500" },
  { value: "500,1000", label: "500 to 1000" },
  { value: "1000,10000", label: "1000 to 10000" },
  { value: "10000,50000", label: "10000 to 50000" },
  { value: "Other", label: "Other Range" },
];

export const optionsMeetStatusList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Active" },
  { value: 0, label: "InActive" },
];

export const optionsLeaveTypes = [
  { value: 1, label: "Sick Leave" },
  { value: 2, label: "Casual Leave" },
  { value: 3, label: "Earned Leave" },
  { value: 4, label: "Rest" },
];

export const optionsLeaveStatusList = [
  { value: "Requested", label: "Requested" },
  { value: "Approved", label: "Approved" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Rejected", label: "Rejected" },
];

export const optionsMOTTypes = [
  { value: "Local", label: "Local MOT" },
  { value: "OS", label: "Outstation MOT" },
];

export const travelingOptionswithIcon = [
  { value: "Flight", label: "Flight", icon: "flight" },
  { value: "Train", label: "Train", icon: "train" },
  { value: "Bus", label: "Bus", icon: "directions_bus" },
  { value: "Cab", label: "Cab", icon: "local_taxi" },
  { value: "Hotel", label: "Hotel", icon: "hotel" },
  { value: "Advance_Cash", label: "Advance Cash", icon: "payments" },
  { value: "Other", label: "Other", icon: "other_admission" },
];

export const travelOptionforTripPlan = [
  { value: "Flight", label: "Flight", icon: "flight" },
  { value: "Train", label: "Train", icon: "train" },
  { value: "Bus", label: "Bus", icon: "directions_bus" },
  { value: "Cab", label: "Cab", icon: "local_taxi" },
  { value: "Hotel", label: "Hotel", icon: "hotel" },
];

export const tripTypes = [
  { value: 1, label: "One Way" },
  { value: 2, label: "Round" },
  { value: 3, label: "Multi City" },
];

export const travelingBy = [
  { value: 1, label: "Single", icon: "person" },
  { value: 2, label: "Team", icon: "group" },
  { value: 3, label: "Partial Team", icon: "group" },
];

export const modeOfPaymentOption = [
  { value: 1, label: "UPI" },
  { value: 2, label: "Debit/Credit Card" },
  { value: 3, label: "Net Banking" },
];

export const policySetToOption = [
  { value: 0, label: "No Limit" },
  { value: 1, label: "Daily" },
  { value: 2, label: "Weekly" },
  { value: 3, label: "Monthly" },
  { value: 4, label: "Quarterly" },
];

export const limitRestrictionOptions = [
  { value: 1, label: "Fixed limit", description: "Fixed expences for the selected category(s)" },
  { value: 2, label: "Allow to exceed the limit", description: "Expense can be exceed the defined set limit" },
  { value: 3, label: "Restrict limit", description: "Restrict the expenses to defined set limit" },
];

export const policyAppliesToOption = [
  { value: 1, label: "Policy will be same for everyone" },
  { value: 2, label: "I want to add Specific per team / people" },
];

// EXTRA DATA
export const policyOptions = [
  { value: 1, label: "Food" },
  { value: 2, label: "Hotel" },
  { value: 3, label: "Courier" },
  { value: 4, label: "Stationery" },
];

export const optionsTravelTypes = [
  { value: "Local", label: "Local" },
  { value: "OS", label: "Outstation" },
];

export const optionsReimburseTypes = [
  { value: 1, label: "Lodging" },
  { value: 2, label: "Food" },
  { value: 3, label: "Local Conveyance" },
  { value: 4, label: "Others" },
];

export const optionsMeetingStatusList = [
  { value: "", label: "Select Type" },
  { value: "Completed", label: "Completed" },
  { value: "Follow up", label: "Follow-Up" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "PCancelled", label: "Pre-Cancelled" },
  { value: "ReSchedule", label: "Re-Scheduled" },
  { value: "Pending", label: "Pending" },
];

export const optionsMeetingTypeList = [
  { value: "Scheduled", label: "Scheduled" },
  { value: "Direct Visit", label: "Direct Visit" },
];

export const optionsUnitList = [
  { value: "Per KM", label: "Kilometer" },
  { value: "1", label: "Ticket" },
];

export const optionsPageSizeList = [
  { value: 20, label: "Show Per Page" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
];

export const optionsDistanceList = [
  { value: 1, label: "1" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 300, label: "300" },
  { value: 400, label: "400" },
  { value: 500, label: "500" },
  { value: 1000, label: "1000" },
];

export const UOMList = [
  { value: 1, label: "Kilometer (km)" },
  { value: 2, label: "Inch (in)" },
  { value: 3, label: "Liter (L)" },
  { value: 4, label: "Kilogram (kg)" },
  { value: 5, label: "Others" },
];

// ADD CUSTOM FIELD PAGES
export const customPageNames = [
  { value: 1, label: "Meetings" },
  { value: 2, label: "Company" },
  { value: 3, label: "User" },
  { value: 4, label: "Product" },
  { value: 5, label: "Machine" },
  { value: 6, label: "Complaints" },
  { value: 7, label: "Claims" },
  { value: 8, label: "AMC" },
];
// ADD CUSTOM FIELD PAGES

// for complaints page
export const compStatus = [
  { value: 0, label: "Select Status" },
  { value: 1, label: "Pending" },
  { value: 2, label: "Follow Up" },
  { value: 3, label: "Resolved" },
  { value: 4, label: "Closed" },
  { value: 5, label: "Hold" },
];
// for complaints page

// whatsapp configuration header type option
export const headerType = [
  { value: "none", label: "none" },
  { value: "Header Text", label: "Header Text" },
  { value: "Header Image", label: "Image" },
];
export const recieverType = [
  { value: "All Device", label: "All Device" },
  { value: "Particular", label: "Particular" },
];
export const notificationFrequency = [
  { value: "One Time", label: "One Time" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];
export const attachmentTypes = [
  { value: "Count Data", label: "Count Data" },
  { value: "Detailed Data", label: "Detailed Data" },
  // { value: 'Locations', label: "Locations" },
  // { value: 'Contacts', label: "MonContactsthly" },
];
// whatsapp configuration header type option
