import React, { useEffect, useRef, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import Footer from "../Footer";
import "../../styles/Dashboard.scss";
import "../../styles/CommonStyle.scss";
import { Link } from "react-router-dom";
import { baseURL } from "../BaseUrl";
import "react-loading-skeleton/dist/skeleton.css";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "react-bootstrap";
import { MeetingsChart } from "./BarChartGraph";
import { Carousel } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  loadClientComplaint,
  loadGraphCount,
  loadGraphData,
  loadMeetingData,
  loadOrders,
  loadPageBreadCome,
} from "../../redux/action";
import RadialChartPage from "./RadialChartPage";
import ActivitySlider from "./ActivitySlider";
import { FormattedAmount } from "../FormattedAmount";

function Dashboard() {
  /* FETCH - STORE SESSION DATA START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const userLgnDtls = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const distanceMatrixID = userLgnDtls?.subscription?.distanceMatrix;
  sessionStorage.setItem("distanceMatrixID", distanceMatrixID);
  let themeColor = localStorage.getItem("theme");

  let daysLeft = userLgnDtls?.subscription?.daysleft;
  sessionStorage.setItem("daysLeft", daysLeft);
  /* FETCH - STORE SESSION DATA END */

  /* FETCH USING REDUX START */
  let dispatch = useDispatch();

  const { graphData } = useSelector((state) => state.data);
  const { graphCount } = useSelector((state) => state.data);
  /* FETCH USING REDUX START */

  const chartRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    // let displayDate = moment(new Date()).format("ddd, MMM D");
    let breadCome = { mainpage: "DashBoard", page: "", subPage: "" };
    dispatch(loadPageBreadCome(breadCome));
  }, []);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userId, setUserId] = useState("");
  const [dashBoardData, setDashBoardData] = useState("");
  const [selectBy, setSelectBy] = useState("By Week");
  const [userActivity, setUserActivity] = useState(false);
  const [dBMeetingDetails, setDBMeetingDetails] = useState([]);
  const [dBMeetingGrap, setDBMeetingGrap] = useState({});
  const [recentActivities, setRecentActivities] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [directCount, setDirectCount] = useState("");
  const [scheduleCount, setScheduleCount] = useState("");
  const [myVisitCount, setMyVisitCount] = useState("");

  useEffect(() => {
    if (dBMeetingGrap) {
      setDirectCount(dBMeetingGrap.directVisitMeetings);
      setScheduleCount(dBMeetingGrap.scheduleMeetings);
      setMyVisitCount(dBMeetingGrap.myVisitMeetings);
    }
  }, [dBMeetingGrap]);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const goBacktodashboard = () => {
    setUserActivity(!userActivity);
  };

  /* FETCH CARD DATA START  */
  useEffect(() => {
    getDashboardCounts();
  }, []);

  const getDashboardCounts = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Travelize_Authentication: userDetails,
      },
    };

    fetch(`${baseURL}/api/DashBoard/GetCounts`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDashBoardData(res.result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setDashBoardData("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH CARD DATA END  */

  /* FETCH MEETINGS COUNT DATA START  */
  useEffect(() => {
    sortMeetingBy();
    sortMeetingByGrap();
  }, [selectBy]);

  const sortMeetingBy = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(
      `${baseURL}/api/DashBoard/GetMeetingCounts/${selectBy}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDBMeetingDetails(res.result);
          setIsLoading(false);
        } else {
          setDBMeetingDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH MEETINGS COUNT DATA END  */

  /* FETCH RECENT ACTIVITY DATA START  */
  useEffect(() => {
    fetchRecentActivities();
  }, []);

  const sortMeetingByGrap = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(
      `${baseURL}/api/DashBoard/GetMeetingCounts/${selectBy}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDBMeetingGrap(res.result[0]);
          setIsLoading(false);
        } else {
          setDBMeetingDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH MEETINGS COUNT DATA END  */

  /* FETCH RECENT ACTIVITY DATA START  */
  useEffect(() => {
    fetchRecentActivities();
  }, []);

  const fetchRecentActivities = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(`${baseURL}/api/DashBoard/GetRecentActivities`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRecentActivities(res.result);
        } else {
          setRecentActivities("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };

  // On click event for graph
  const [graphName, setGraphName] = useState("meeting");
  const datForGraph = (event) => {
    setIsGraphLoading(true);
    setGraphName(event);
    if (event === "complaints") {
      dispatch(loadClientComplaint());
    } else if (event === "meetings") {
      dispatch(loadMeetingData());
    }
  };

  useEffect(() => {
    if (graphName) {
      if (graphName === "complaints") {
        dispatch(loadOrders());
      }
    }
    dispatch(loadGraphData(graphName, selectBy, setIsGraphLoading));
    dispatch(loadGraphCount(selectBy));
  }, [graphName, selectBy]);
  // On click event for graph

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}
              <div className="page-wrapper">
                {/* MAIN BODY START */}
                {!userActivity ? (
                  <div className={`main-body ${toggleshift.style}`}>
                    {/* PAGE CONTENT START */}

                    <div className="page-content-dash">
                      {/* FIRST ROW START */}
                      <div className="sb-card-row">
                        <div className="card card-dashboard">
                          {isLoading ? (
                            <Skeleton className="card-body" />
                          ) : (
                            <div className="card-body first-card">
                              <Carousel
                                style={{ width: "100%" }}
                                controls={false}
                                indicators={false}
                                interval={3000}
                              >
                                <Carousel.Item>
                                  <div className="row card-row">
                                    <div className="col-md-3 card-left-img">
                                      <span className="material-symbols-outlined">
                                        inventory_2
                                      </span>
                                    </div>
                                    <div className="col-md-9 card-right-data">
                                      <div className="verticle-line"></div>
                                      <Link className="act-body" to="/products">
                                        <p className="card-head">
                                          Total Products
                                        </p>
                                        <p>
                                          <b className="act-num">
                                            {dashBoardData?.productCount ?? 0}
                                          </b>
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                  <div className="row card-row">
                                    <div className="col-md-3 card-left-img">
                                      <span className="material-symbols-outlined">
                                        shopping_cart
                                      </span>
                                    </div>
                                    <div className="col-md-9 card-right-data">
                                      <div className="verticle-line"></div>
                                      <Link className="act-body" to="/orders">
                                        <p className="card-head">
                                          Total Orders
                                        </p>
                                        <p>
                                          <b className="act-num">
                                            {dashBoardData?.orderCount ?? 0}
                                          </b>
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                  <div className="row card-row">
                                    <div className="col-md-3 card-left-img">
                                      <span className="material-symbols-outlined">
                                        group_add
                                      </span>
                                    </div>
                                    <div className="col-md-9 card-right-data">
                                      <div className="verticle-line"></div>
                                      <Link className="act-body" to="/users">
                                        <p className="card-head">Total Users</p>
                                        <p>
                                          <b className="act-num">
                                            {dashBoardData?.totalCountOfUsers ??
                                              0}
                                          </b>
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                </Carousel.Item>
                              </Carousel>
                            </div>
                          )}
                        </div>
                        <div className="card card-dashboard">
                          {isLoading ? (
                            <Skeleton className="card-body" />
                          ) : (
                            <div className="card-body">
                              <div className="row card-row">
                                <div className="col-md-3 card-left-img">
                                  <span className="material-symbols-outlined">
                                    diversity_3
                                  </span>
                                </div>
                                <div className="col-md-9 card-right-data">
                                  <div className="verticle-line"></div>

                                  <Link
                                    className="act-body d-flex align-items-center"
                                    to="/meetings"
                                  >
                                    <div className="meeting-card">
                                      <p className="card-head">Meetings</p>
                                      <div className="desk-view">
                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box1"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span>Total </span>&nbsp;&nbsp;
                                            <span>
                                              {dashBoardData.totalMeetings}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box2"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span>Completed </span>
                                            &nbsp;&nbsp;
                                            <span>
                                              {dashBoardData.completedMeetings}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box3"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span>Pending </span>
                                            &nbsp;&nbsp;
                                            <span>
                                              {dashBoardData.pendingMeetings}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="tab-view d-none">
                                        <Carousel
                                          style={{ width: "100%" }}
                                          controls={false}
                                          indicators={false}
                                          interval={3000}
                                        >
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">Total</p>
                                              <p className="fz14">
                                                <b>
                                                  {dashBoardData.totalMeetings}
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">
                                                Completed
                                              </p>
                                              <p className="fz14">
                                                <b>
                                                  {
                                                    dashBoardData.completedMeetings
                                                  }
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">
                                                Pending
                                              </p>
                                              <p className="fz14">
                                                <b>
                                                  {
                                                    dashBoardData.pendingMeetings
                                                  }
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                        </Carousel>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="card card-dashboard">
                          {isLoading ? (
                            <Skeleton className="card-body" />
                          ) : (
                            <div className="card-body">
                              <div className="row card-row">
                                <div className="col-md-3 card-left-img">
                                  <span className="material-symbols-outlined">
                                    calendar_month
                                  </span>
                                </div>
                                <div className="col-md-9 card-right-data">
                                  <div className="verticle-line"></div>
                                  <Link
                                    className="act-body d-flex align-items-center"
                                    to="/attendance/monthly"
                                  >
                                    <div className="attend-card">
                                      <p className="card-head">Attendance</p>
                                      <div className="desk-view">
                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box1"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span>Leave </span>&nbsp;&nbsp;
                                            <span>
                                              {dashBoardData.leaveCount}
                                            </span>
                                          </p>
                                        </div>

                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box2"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span>Present </span>&nbsp;&nbsp;
                                            <span>
                                              {
                                                dashBoardData.todayAttendanceCount
                                              }
                                            </span>
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box3"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span>Absent </span>&nbsp;&nbsp;
                                            <span>
                                              {dashBoardData.absentCount}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="tab-view d-none">
                                        <Carousel
                                          style={{ width: "100%" }}
                                          controls={false}
                                          indicators={false}
                                          interval={3000}
                                        >
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">Leave</p>
                                              <p className="fz14">
                                                <b>
                                                  {dashBoardData.leaveCount}
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">
                                                Present
                                              </p>
                                              <p className="fz14">
                                                <b>
                                                  {
                                                    dashBoardData.todayAttendanceCount
                                                  }
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">
                                                Absent
                                              </p>
                                              <p className="fz14">
                                                <b>
                                                  {dashBoardData.absentCount}
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                        </Carousel>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="card card-dashboard">
                          {isLoading ? (
                            <Skeleton className="card-body" />
                          ) : (
                            <div className="card-body">
                              <div className="row card-row">
                                <div className="col-md-3 card-left-img">
                                  <span className="material-symbols-outlined">
                                    handshake
                                  </span>
                                </div>
                                <div className="col-md-9 card-right-data">
                                  <div className="verticle-line"></div>

                                  <Link className="act-body" to="/company">
                                    <div className="company-card">
                                      <div>
                                        <p className="card-head fz16">
                                          Company
                                        </p>
                                      </div>
                                      <div className="desk-view">
                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box1"></span>{" "}
                                          &nbsp;&nbsp;{" "}
                                          <p className="meet-detls">
                                            <span>Total </span>&nbsp;&nbsp;
                                            <span>
                                              {dashBoardData?.clientCount >=
                                              1000
                                                ? (
                                                    dashBoardData?.clientCount /
                                                    1000
                                                  ).toFixed(1) +
                                                  " " +
                                                  "K"
                                                : dashBoardData?.clientCount}
                                            </span>
                                          </p>
                                        </div>

                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box2"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span className="desktop-view">
                                              Active Client{" "}
                                            </span>
                                            &nbsp;&nbsp;
                                            <span>
                                              {dashBoardData.activeClientCount >=
                                              1000
                                                ? (
                                                    dashBoardData.activeClientCount /
                                                    1000
                                                  ).toFixed(1) +
                                                  " " +
                                                  "K"
                                                : dashBoardData.activeClientCount}
                                            </span>
                                          </p>
                                        </div>

                                        <div className="d-flex align-items-center sb-grap-text">
                                          <span className="grap-box box3"></span>{" "}
                                          &nbsp;&nbsp;
                                          <p className="meet-detls">
                                            <span>Recent Client </span>
                                            &nbsp;&nbsp;
                                            <span>
                                              {dashBoardData.todaysNewClients}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="tab-view d-none">
                                        <Carousel
                                          style={{ width: "100%" }}
                                          controls={false}
                                          indicators={false}
                                          interval={3000}
                                        >
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">Total</p>
                                              <p className="fz14">
                                                <b>
                                                  {dashBoardData?.clientCount}
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">
                                                Active Client
                                              </p>
                                              <p className="fz14">
                                                <b>
                                                  {
                                                    dashBoardData.activeClientCount
                                                  }
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                            <div className="sb-grap-text">
                                              <p className="card-head">
                                                Recent Client
                                              </p>
                                              <p className="fz14">
                                                <b>
                                                  {
                                                    dashBoardData.todaysNewClients
                                                  }
                                                </b>
                                              </p>
                                            </div>
                                          </Carousel.Item>
                                        </Carousel>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="card card-dashboard">
                          {isLoading ? (
                            <Skeleton className="card-body" />
                          ) : (
                            <div className="card-body">
                              <div className="row card-row">
                                <div className="col-md-3 card-left-img">
                                  <span className="material-symbols-outlined">
                                    note_alt
                                  </span>
                                </div>

                                <div className="col-md-9 card-right-data">
                                  <div className="verticle-line"></div>
                                  <Carousel
                                    style={{ width: "100%" }}
                                    controls={false}
                                    indicators={false}
                                    interval={8000}
                                  >
                                    <Carousel.Item>
                                      <Link
                                        className="act-body"
                                        to="/localclaim"
                                      >
                                        <div className="claim-card">
                                          <div>
                                            <p className="card-head">
                                              Local Claims
                                            </p>
                                          </div>
                                          <div className="desk-view">
                                            <div className="d-flex align-items-center sb-grap-text">
                                              <span className="grap-box box1"></span>{" "}
                                              &nbsp;&nbsp;
                                              <p className="meet-detls">
                                                <span>Applied </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {
                                                    dashBoardData.appliedClaimCount
                                                  }
                                                </span>
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center sb-grap-text">
                                              <span className="grap-box box2"></span>{" "}
                                              &nbsp;&nbsp;
                                              <p className="meet-detls">
                                                <span>Approved </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {
                                                    dashBoardData.approvedClaimCount
                                                  }
                                                </span>
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center sb-grap-text">
                                              <span className="grap-box box3"></span>{" "}
                                              &nbsp;&nbsp;
                                              <p className="meet-detls">
                                                <span>Rejected </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {
                                                    dashBoardData.rejectedClaimCount
                                                  }
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="tab-view d-none">
                                            <Carousel
                                              style={{ width: "100%" }}
                                              controls={false}
                                              indicators={false}
                                              interval={2500}
                                            >
                                              <Carousel.Item>
                                                <div className="sb-grap-text">
                                                  <p className="card-head">
                                                    Applied
                                                  </p>
                                                  <p className="fz14">
                                                    <b>
                                                      {
                                                        dashBoardData.appliedClaimCount
                                                      }
                                                    </b>
                                                  </p>
                                                </div>
                                              </Carousel.Item>
                                              <Carousel.Item>
                                                <div className="sb-grap-text">
                                                  <p className="card-head">
                                                    Approved
                                                  </p>
                                                  <p className="fz14">
                                                    <b>
                                                      {
                                                        dashBoardData.approvedClaimCount
                                                      }
                                                    </b>
                                                  </p>
                                                </div>
                                              </Carousel.Item>
                                              <Carousel.Item>
                                                <div className="sb-grap-text">
                                                  <p className="card-head">
                                                    Rejected
                                                  </p>
                                                  <p className="fz14">
                                                    <b>
                                                      {
                                                        dashBoardData.rejectedClaimCount
                                                      }
                                                    </b>
                                                  </p>
                                                </div>
                                              </Carousel.Item>
                                            </Carousel>
                                          </div>
                                        </div>
                                      </Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                      <Link
                                        className="act-body"
                                        to="/outstationclaim"
                                      >
                                        <div className="claim-card">
                                          <div>
                                            <p className="card-head desk-view">
                                              Out Station Claims
                                            </p>
                                            <p className="card-head tab-view d-none">
                                              O/S Claims
                                            </p>
                                          </div>
                                          <div className="desk-view">
                                            <div className="d-flex align-items-center sb-grap-text">
                                              <span className="grap-box box1"></span>{" "}
                                              &nbsp;&nbsp;
                                              <p className="meet-detls">
                                                <span>Applied </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {
                                                    dashBoardData.osAppliedClaimCount
                                                  }
                                                </span>
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center sb-grap-text">
                                              <span className="grap-box box2"></span>{" "}
                                              &nbsp;&nbsp;
                                              <p className="meet-detls">
                                                <span>Approved </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {
                                                    dashBoardData.osApprovedClaimCount
                                                  }
                                                </span>
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center sb-grap-text">
                                              <span className="grap-box box3"></span>{" "}
                                              &nbsp;&nbsp;
                                              <p className="meet-detls">
                                                <span>Rejected </span>
                                                &nbsp;&nbsp;
                                                <span>
                                                  {
                                                    dashBoardData.osRejectedClaimCount
                                                  }
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="tab-view d-none">
                                            <Carousel
                                              style={{ width: "100%" }}
                                              controls={false}
                                              indicators={false}
                                              interval={2500}
                                            >
                                              <Carousel.Item>
                                                <div className="sb-grap-text">
                                                  <p className="card-head">
                                                    Applied
                                                  </p>
                                                  <p className="fz14">
                                                    <b>
                                                      {
                                                        dashBoardData.osAppliedClaimCount
                                                      }
                                                    </b>
                                                  </p>
                                                </div>
                                              </Carousel.Item>
                                              <Carousel.Item>
                                                <div className="sb-grap-text">
                                                  <p className="card-head">
                                                    Approved
                                                  </p>
                                                  <p className="fz14">
                                                    <b>
                                                      {
                                                        dashBoardData.osApprovedClaimCount
                                                      }
                                                    </b>
                                                  </p>
                                                </div>
                                              </Carousel.Item>
                                              <Carousel.Item>
                                                <div className="sb-grap-text">
                                                  <p className="card-head">
                                                    Rejected
                                                  </p>
                                                  <p className="fz14">
                                                    <b>
                                                      {
                                                        dashBoardData.osRejectedClaimCount
                                                      }
                                                    </b>
                                                  </p>
                                                </div>
                                              </Carousel.Item>
                                            </Carousel>
                                          </div>
                                        </div>
                                      </Link>
                                    </Carousel.Item>
                                  </Carousel>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* FIRST ROW END */}

                      {/* Second row start */}
                      <div className="row-devider">
                        <Row className="sb-data-grap">
                          <Col
                            md={7}
                            className=" sb-data-col user-activity-data"
                          >
                            <Row className="mb-3">
                              <Col md={9} className="p-0">
                                <div className="table-div-dashboard meeting-graph">
                                  <div className="meeting-table-header">
                                    <div>
                                      <p className="card-head">
                                        {isLoading ? (
                                          <Skeleton width={60} height={10} />
                                        ) : (
                                          <>
                                            {graphName === "order"
                                              ? "ORDERS"
                                              : graphName === "complaint"
                                              ? "COMPLAINTS"
                                              : graphName === "meeting"
                                              ? "MEETINGS"
                                              : graphName === "localclaim"
                                              ? "LOCAL CLAIMS"
                                              : graphName === "osclaim" &&
                                                "OUT STATION CLAIMS"}
                                            &nbsp;&nbsp;
                                          </>
                                        )}
                                      </p>
                                    </div>
                                    <div className="sort-div">
                                      <p className="card-head">SORT</p>
                                      <select
                                        className="meetings-sort select-by-dropdown"
                                        value={selectBy}
                                        onChange={(e) =>
                                          setSelectBy(e.target.value)
                                        }
                                      >
                                        <option value="By Day">By Day</option>
                                        <option value="By Week">By Week</option>
                                        <option value="By Month">
                                          By Month
                                        </option>
                                        <option value="By 3 Month">
                                          By 3 Month
                                        </option>
                                        <option value="By 6 Month">
                                          By 6 Month
                                        </option>
                                      </select>
                                      {/* <i className="bi bi-sliders" /> */}
                                    </div>
                                  </div>
                                  <div className="user-activity-data-col">
                                    <div
                                      className="meetings-graph"
                                      ref={chartRef}
                                    >
                                      <MeetingsChart
                                        isLoading={isGraphLoading}
                                        dBMeetingDetails={
                                          dBMeetingDetails && dBMeetingDetails
                                        }
                                        graphName={graphName}
                                        graphData={graphData && graphData}
                                        width={"100%"}
                                        // style={{overflowX: 'auto'}}
                                      />
                                      {/* <ClaimsChart width={chartWidth} /> */}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={3} className="p-0">
                                <div className="table-div-dashboard graph-card">
                                  <div className="ord-comp-meet-claim-data">
                                    <div
                                      className={`title-n-data ${
                                        graphName === "order"
                                          ? "selected-graph"
                                          : ""
                                      }`}
                                      onClick={() => datForGraph("order")}
                                    >
                                      <p className="data-title">
                                        <b>Orders</b>
                                      </p>
                                      <p className="data-value">
                                        <i className="fa fa-rupee" />
                                        {isLoading ? (
                                          <Skeleton width={50} />
                                        ) : (
                                          <>
                                            {FormattedAmount(
                                              graphCount[0]?.OrderAmount
                                            )}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                    <hr className="hr-tag" />
                                    <div
                                      className={`title-n-data ${
                                        graphName === "complaint"
                                          ? "selected-graph"
                                          : ""
                                      }`}
                                      onClick={() => datForGraph("complaint")}
                                    >
                                      <p className="data-title">
                                        <b>Complaints</b>
                                      </p>
                                      <p className="data-value">
                                        {isLoading ? (
                                          <Skeleton width={50} />
                                        ) : (
                                          <>{graphCount[0]?.ComplaintCount}</>
                                        )}
                                      </p>
                                    </div>
                                    <hr className="hr-tag" />
                                    <div
                                      className={`title-n-data ${
                                        graphName === "meeting"
                                          ? "selected-graph"
                                          : ""
                                      }`}
                                      onClick={() => datForGraph("meeting")}
                                    >
                                      <p className="data-title">
                                        <b>Meetings</b>
                                      </p>
                                      <p className="data-value">
                                        {isLoading ? (
                                          <Skeleton width={50} />
                                        ) : (
                                          <>
                                            {graphCount[0]?.TotalMeetingCount}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                    <hr className="hr-tag" />
                                    <div>
                                      <Carousel
                                        controls={false}
                                        indicators={false}
                                        interval={2000}
                                      >
                                        <Carousel.Item>
                                          <div
                                            className={`title-n-data ${
                                              graphName === "localclaim"
                                                ? "selected-graph"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              datForGraph("localclaim")
                                            }
                                          >
                                            <p className="data-title">
                                              <b>Local Claims</b>
                                            </p>
                                            <p className="data-value">
                                              <i className="fa fa-rupee" />
                                              {isLoading ? (
                                                <Skeleton width={50} />
                                              ) : (
                                                <>
                                                  {FormattedAmount(
                                                    graphCount[0]
                                                      ?.LocalClaimAmount
                                                  )}
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                          <div
                                            className={`title-n-data ${
                                              graphName === "osclaim"
                                                ? "selected-graph"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              datForGraph("osclaim")
                                            }
                                          >
                                            <p className="data-title">
                                              <b>Out Station Claims</b>
                                            </p>
                                            <p className="data-value">
                                              <i className="fa fa-rupee" />
                                              {isLoading ? (
                                                <Skeleton width={50} />
                                              ) : (
                                                <>
                                                  {FormattedAmount(
                                                    graphCount[0]?.OSClaimAmount
                                                  )}
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </Carousel.Item>
                                      </Carousel>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} className="p-0">
                                <div className="table-div-dashboard third-row-div">
                                  <div className="dashbord-img-box">
                                    <img
                                      // src={`../images/dashboard/Gif-images/laptop-motion-${
                                      //   themeColor ? themeColor : "light"
                                      // }.gif`}
                                      src={`../../images/sign-animate.gif`}
                                      alt="metricinfo-image"
                                      width={"100%"}
                                      className="dashboard-pop-img"
                                    />
                                    <div className="carousel-div">
                                      <Carousel
                                        fade
                                        pause="hover"
                                        controls={false}
                                        indicators={false}
                                        interval={2000}
                                      >
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/products">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Top Selling Products
                                              </p>
                                              <p className="carousel-text1">
                                                {dashBoardData?.productCount}
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/orders">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Today's Orders
                                              </p>
                                              <p className="carousel-text1">
                                                {dashBoardData?.todayOrderCount}
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/complaints">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Today's Complaints
                                              </p>
                                              <p className="carousel-text1">
                                                {
                                                  dashBoardData?.todayComplaintCount
                                                }
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                      </Carousel>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              {/* <Col md={6} className="p-0">
                                <div className="table-div-dashboard third-row-div">
                                  <div className="dashbord-img-box">
                                    <img
                                      src={`../images/dashboard/Gif-images/laptop-motion-${
                                        themeColor ? themeColor : "light"
                                      }.gif`}
                                      alt=""
                                      width={"100%"}
                                      className="dashboard-pop-img"
                                    />
                                    <div className="carousel-div">
                                      <Carousel
                                        fade
                                        pause="hover"
                                        controls={false}
                                        indicators={false}
                                        interval={2000}
                                      >
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/products">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Top Selling Products
                                              </p>
                                              <p className="carousel-text1">
                                                {dashBoardData?.productCount}
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/orders">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Today's Orders
                                              </p>
                                              <p className="carousel-text1">
                                                {dashBoardData?.todayOrderCount}
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/complaints">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Today's Complaints
                                              </p>
                                              <p className="carousel-text1">
                                                {
                                                  dashBoardData?.todayComplaintCount
                                                }
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                      </Carousel>
                                    </div>
                                  </div>
                                </div>
                              </Col> */}
                              <Col md={6} className="pr-0 pb-0 pt-0 pl-2">
                                <div className="table-div-dashboard third-row-div">
                                  <div className="meeting-table-header">
                                    <div>
                                      <p className="card-head">
                                        MEETINGS COUNT
                                      </p>
                                    </div>
                                  </div>
                                  <Row>
                                    <div className="user-activity-data-map">
                                      <div className="meetings-status-chart">
                                        {directCount &&
                                          scheduleCount &&
                                          myVisitCount && (
                                            <RadialChartPage
                                              series={[
                                                directCount,
                                                scheduleCount,
                                                myVisitCount,
                                              ]}
                                              width={"100%"}
                                              height={340}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            md={5}
                            className="sb-data-col user-activity-data"
                          >
                            <div className="table-div-dashboard activity-doard">
                              <div className="meeting-table-header">
                                <div className="hv-center">
                                  <p className="card-head">ACTIVITIES</p>
                                  &nbsp;
                                  <Link
                                    to="/settings/viewonmap"
                                    className="see-all-check-ins"
                                    title="Check-In Details"
                                  >
                                    <span
                                      className="material-symbols-outlined"
                                      id="growing-animation"
                                    >
                                      person_pin_circle
                                    </span>
                                  </Link>
                                </div>
                                <div className="sort-div">
                                  <button
                                    className="refresh-button"
                                    onClick={fetchRecentActivities}
                                  >
                                    <i
                                      className="fa fa-refresh refresh-activity"
                                      title="Refresh"
                                    />
                                  </button>
                                  <div className="hv-center dashboard-activity-search">
                                    <input
                                      type="text"
                                      className="form-control activity-search"
                                      placeholder="Search...."
                                      aria-label="Sizing example input"
                                      aria-describedby="inputGroup-sizing-sm"
                                      onChange={(e) =>
                                        setSearchVal(e.target.value)
                                      }
                                    />
                                    <i className="fa fa-search search-icon" />
                                  </div>
                                </div>
                              </div>
                              <div className="user-activity-data">
                                <div className="user-activity-data-col">
                                  <div className="user-activity-rowdata">
                                    <ActivitySlider
                                      isLoading={isLoading}
                                      recentActivities={recentActivities}
                                      searchVal={searchVal}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* Second row end */}
                    </div>

                    {/* PAGE CONTENT END*/}
                    {/* MAIN BODY END */}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Footer />
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
