import React, { useState } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import SbAddBtn from "../SbButtons/SbAddBtn";
import AddWhiteLabelingModal from "./AddWhiteLabelingModal";

function WhiteLabeling() {

    const navigate = useNavigate();

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [show, setShow] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [isLoading, setIsLoading] = useState(true);

    let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    // Page navigation
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
                <>
                    {/* SIDEBAR START */}
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    {/* SIDEBAR END */}

                    {/* TOPBAR START */}
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    {/* TOPBAR END */}

                    {/* MAIN BODY START */}
                    <div className="page-wrapper">
                        {/* PAGE CONTENT START */}
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content">
                                <div style={{ position: "relative" }}>
                                    <Row className='search-row'>
                                        <Col md={10} className='cat-col'>
                                            <div className='page-header-text-div'>
                                                <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                                <h6 className='page-header-text' >White Labeling</h6>
                                            </div>
                                        </Col>
                                        <Col md={2} className='cat-col'>
                                            <div className="search-bar-header" style={{ float: "right" }}>
                                                <div className="page-top-bar-dropdowns page-top-Filters">
                                                    {/* <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                                                        <p className="card-head">
                                                            <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                                                        </p>
                                                    </div> */}
                                                    <SbAddBtn
                                                        onClickEffect={() => setShow(!show)} />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>


                                </div>

                                {/* ADD WhiteLabeling MODEL START */}

                                {show && (
                                    <AddWhiteLabelingModal
                                        show={show}
                                        handleClose={handleClose}
                                    />
                                )}
                                {/* ADD WhiteLabeling MODEL END */}

                                {/* PAGE CONTENT END*/}
                            </div>
                        </div>

                        {/* MAIN BODY END */}
                    </div>
                </>
            ) : (
                <AccessDenied />
            )}
        </div>
    );
}

export default WhiteLabeling;


