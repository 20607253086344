import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../styles/ViewClient.scss";
import profileimg from "../../images/profile-1.png";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleUserRefferalData } from "../../redux/action";

function UserBankDetailsModal(props) {

  let dispatch = useDispatch();
  const { singleUserReferralsData } = useSelector((state) => state.data)

  const gfReferelProfileId = props.gfReferelProfileId;

  useEffect(() => {
    if (gfReferelProfileId) {
      dispatch(loadSingleUserRefferalData(gfReferelProfileId))
    }
  }, [gfReferelProfileId])

  const handleCloseModal = () => {
    props.setShowModal(false);
  };


  return (
    <div>
      {props.showModal && (
        <div className="modal-backdrop">
          <div className="modal-right-fade-bankdetails">
            <Modal.Dialog className="right-fade-modal-dialog">
              <div>
                <Modal.Header closeButton onHide={handleCloseModal}>
                  <Modal.Title className="client-modal-header card-head">
                    {/* <h6 className='page-header-text' >Bank Details</h6> */}
                    <button
                      onClick={() => props.addBankDetails(gfReferelProfileId)}
                      className="btn client-edit-link"
                    >
                      <i className="bi bi-pencil-fill" /> {singleUserReferralsData && Object.keys(singleUserReferralsData).length === 0 ? "Add" : "Edit"}
                    </button>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {singleUserReferralsData && Object.keys(singleUserReferralsData).length === 0 ?
                    <div style={{ padding: "20px 20px" }}>
                      <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                      <span>No Data Found...!</span>
                    </div>
                    :
                    <div style={{ display: "flex" }}>
                      <div className="passbook-second-div" >
                        <div className="card-details">
                          <div className="pb-2">
                            {/* <h4 className="accounts-header" >My Cards</h4> */}
                          </div>
                          <div className="user-card-details">
                            <div className="passbook-sec-div-header">
                              PAN CARD
                            </div>
                            <div className="pan-card-details">
                              <div>
                                <img
                                  className="pan-card-image"
                                  src={profileimg}
                                  alt=""
                                />
                              </div>
                              <span className="pan-number">
                                <p>Permanent Acc No</p>{" "}
                                <div className='product-name'>
                                  <h1><span style={{ fontSize: "14px" }}>{singleUserReferralsData[0].panCard}</span></h1>
                                </div>
                              </span>
                            </div>
                            <div className="bank-acc-number d-flex">
                              <p>Name</p> :&nbsp;
                              <p>{singleUserReferralsData[0].accountName}</p>
                            </div>
                          </div>
                        </div>

                        <div className="card-details">
                          <div className="pb-2">
                            <h4 className="accounts-header" >Bank Details</h4>
                          </div>

                          <div className="user-card-details">
                            <div className="bank-details">
                              <div className="bank-acc-number">
                                <span className="pan-name">
                                  <p>Name</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].accountName}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>Bank Name</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].bankName}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>Acc.No</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].accountNumber}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>Aadhar.No</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].adharCard}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>GST.No</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].gsT_VAT_Number}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>Acc Type</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].accountType}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>IFSC Code</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].ifscCode}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>Branch</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].branch}</b></p>
                                </span>
                                <span className="pan-name">
                                  <p>UPI Id</p> :{" "}
                                  <p><b>{singleUserReferralsData[0].upiid}</b></p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  }
                </Modal.Body>
              </div>
            </Modal.Dialog>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserBankDetailsModal;
