import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonAttendance() {
  return (
    <tr>
      <td style={{ width: "120px" }}>
        <div className="d-flex">
          <Skeleton
            circle={true}
            className="pendingapproval-user-imgage"
            width={50}
            height={50}
          />
          <Skeleton
            className="pendingapproval-user-imgage"
            width={80}
            height={50}
          />
        </div>
      </td>
      <td style={{ width: "100px" }}>
        <Skeleton />
      </td>
      <td>
        <Skeleton count={4} />
      </td>
      <td>
        <Skeleton count={4} />
      </td>
      <td style={{ width: "100px" }}>
        <Skeleton />
      </td>
    </tr>
  );
}

export default SkeletonAttendance;
