import { useEffect, useState } from "react";
import "../../styles/AddUserDetails.scss";
import "../../styles/AddmeetingModel.scss";
import "../../styles/AddUserModel.scss";
import Select from "react-select";
import moment from "moment";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import SalesBee from "../../images/MatricLogo.svg";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { baseURL } from "../BaseUrl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { useDispatch, useSelector } from "react-redux";
import * as swal from "../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddMeeting(props) {
  let dispatch = useDispatch();

  const [showclientmodel, setShowclientmodel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [meetPurposeDropdown, setMeetPurposeDropdown] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [date, setDate] = useState(new Date());
  const [meetPurposeId, setMeetPurposeId] = useState("");
  const [mobilenum, setMobileNum] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [clientId, setClientId] = useState(-1);
  const [clientName, setClientName] = useState("");
  const [meetTime, setMeetTime] = useState({ val: null, time: null });
  const [assignMeetTime, setAssignMeetTime] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [fieldReq, setFieldReq] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  ///////////// TIMES SLOTS FOR ADD MEETING START

  const current = new Date();
  const dateNow = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const timeNow = Math.floor(`${current.getHours()}`);

  let x = 15; //minutes interval
  let times = []; // time array
  let tt = 0; // start time
  let ap = ["AM", "PM"]; // AM-PM

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    // let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let mm = tt % 60; // getting minutes of the hour in 0-55 format
    let hh12 = hh % 12;
    if (hh12 === 0) {
      hh12 = 12;
    }
    times[i] =
      ("0" + hh12).slice(-2) +
      ":" +
      ("0" + mm).slice(-2) +
      ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }

  // alert(times);
  ///////////////// TIMES SLOTS FOR ADD MEETING START

  const handleCloseclient = () => setShowclientmodel(!showclientmodel);

  const selectTime = (time) => {
    console.log("SELECT-TIME:", time);
    setMeetTime({ val: time, time: `${time.$H}:${time.$m}` });
    let timeS = time.$d.toTimeString().slice(0, 5);
    const currentTime = timeS.slice(0, 2);
    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setAssignMeetTime("0" + exactTime + " " + "PM");
      }
      // else {
      //   let str = timeS.split("");
      //   str.splice(0, 2, `${newTime}`);
      //   let exactTime = str.join("");
      //   setAssignMeetTime(exactTime + " " + "PM");
      // }
    } else if (currentTime === 12) {
      setAssignMeetTime(timeS + " " + "PM");
    }
    // else if (currentTime == 0) {
    //   let str = timeS.split("");
    //   str.splice(0, 2, 12);
    //   let exactTime = str.join("");
    //   setAssignMeetTime(exactTime + " " + "AM");
    // }
    else {
      setAssignMeetTime(timeS + " " + "AM");
    }
  };

  useEffect(() => {
    fetchDropdownData();
    setAddress("");
    setMobileNum("");
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
    fetch(
      `${baseURL}/api/MeetingPurpose/BindPurposeDropdown?status=${1}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("MEET-PURPOSE-DROPDOWN::", res.result);
          setMeetPurposeDropdown(
            res.result.map((data) => {
              return { value: data.purposeId, label: data.purpose };
            })
          );
        } else {
          setMeetPurposeDropdown([]);
        }
      });
  };

  useEffect(() => {
    fetchClientDropdown();
  }, [filterUserId]);

  const fetchClientDropdown = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/BindClientDropdown?userId=${filterUserId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("CLIENT-LIST-DROPDOWN::", res.result);
          setClientList(
            res.result.map((data) => {
              return { value: data.clientId, label: data.clientName };
            })
          );
        } else {
          setClientList([]);
        }
      });
  };

  useEffect(() => {
    fetchClientInfoById();
  }, [clientId]);

  const fetchClientInfoById = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Client/Get/${clientId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("CLIENT-BY-ID-DROPDOWN::", res);
        setAddress(res.result?.clientLoc?.location);
        setMobileNum(res.result?.mobile);
        setClientName(res.result?.clientName);
        setLocation({
          lat: res.result?.clientLoc?.latitude,
          lng: res.result?.clientLoc?.longitude,
        });
      });
  };

  // console.log("LOCATIO********", address);

  const addMeetings = (e) => {
    e.preventDefault();
    if (
      filterUserId !== "" &&
      clientName !== "" &&
      meetPurposeId !== "" &&
      meetTime !== null &&
      assignMeetTime !== ""
    ) {
      const addMeetingDetails = {
        userId: filterUserId,
        clientLoc: {
          latitude: location.lat,
          longitude: location.lng,
          location: address,
        },
        clientName: clientName,
        purposeId: JSON.parse(meetPurposeId),
        meetingDate: moment(date).format("DD/MM/YYYY"),
        meetingTime: assignMeetTime,
        contact: JSON.parse(mobilenum),
        status: "Pending",
      };
      // console.log("ADD-MEETING-JSON::", addMeetingDetails);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(addMeetingDetails),
      };
      fetch(`${baseURL}/api/Meeting/SaveMeeting`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("ADD-Meeting-RES::", res);
          if (res.success) {
            props.handleClose();
            props.getMeetings();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            // props.handleClose();
            // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };

  const isCurrentDate =
    date.getDay() + date.getMonth() + date.getFullYear() ==
    new Date().getDay() + new Date().getMonth() + new Date().getFullYear();

  const isTime = new Date().getHours() + ":" + (new Date().getMinutes() + 30);
  // console.log("TIME:", isCurrentDate ? `2023-01-30T${isTime}` : "NO");
  return (
    <div show={props.show} onHide={props.handleClose}>
      <div className="add-user-details-container">
        <div className="add-user-details-first-div">
          <div className="machines-logo-div">
            <div className="logotext">
              <div className="logo profile-mini">
                <Link className="travelize_logo" to="/dashboard">
                  <img
                    src={SalesBee}
                    alt="product-title-img"
                    className="salesbee-logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="add-user-details-menus"
            // onClick={toggleMachineDetails}
          >
            <div className="add-user-details-menu-numbers active">
              <p className="add-user-details-menu-number-one">
                <span>01</span>
              </p>
              <div className="add-user-details-menu-name"> Add Meetings</div>
            </div>
          </div>
        </div>
        <div className="add-user-details-second-div">
          <div className="add-user-details-second-div-header">
            <div className="back-btn" onClick={props.handleClose}>
              <img
                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                alt=""
              />
            </div>
            <div>
              <h4>Add Meetings</h4>
              <p>Please enter below infomation and proceed to the next step.</p>
            </div>
          </div>

          <div style={{ position: " relative" }} className="amc-single-image">
            <form onSubmit={addMeetings}>
              <div
                className="container model-add-meeting-body"
                style={{ width: "60%", float: "left" }}
              >
                <div className="add-meeting">
                  <div className="assign-to hv-center">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label input-model-label"
                      >
                        Assigned to
                      </label>
                    </div>
                    <div className="col-md-8 mb-3">
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={userList}
                        placeholder="Select User"
                        value={userList.value}
                        onInputChange={userList.label}
                        onChange={(data) => setFilterUserId(data.value)}
                        isSearchable={true}
                      />
                      {fieldReq && filterUserId == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="meeting-date hv-center">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label input-model-label"
                      >
                        Meeting Date
                      </label>
                    </div>
                    <div className="col-md-8 mb-3">
                      <div className="meeting-date-picker-with-icon">
                        <DatePicker
                          selected={date}
                          className="select-add-meeting-model meeting-date model-input"
                          dateFormat="dd/MM/yyyy"
                          // selectsEnd
                          // startDate={date}
                          // endDate={toDate}
                          minDate={new Date()}
                          onChange={(date) => setDate(date)}
                          // maxDate={new Date()}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </div>
                    </div>
                  </div>

                  <div className="meeting-time hv-center">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label input-model-label"
                      >
                        Meeting Time
                      </label>
                    </div>
                    <div className="col-md-8 mb-3">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          // label="Time"
                          className="form-control select-add-meeting-model"
                          value={meetTime.val}
                          onChange={(time) => {
                            selectTime(time);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          minTime={
                            isCurrentDate
                              ? dayjs(`2023-01-30T${isTime}`)
                              : dayjs("2023-01-30T09:00")
                          }
                          maxTime={dayjs("2023-01-31T22:00")}
                          minutesStep={15}
                        />
                      </LocalizationProvider>
                      {fieldReq && meetTime.time === null ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="meeting-purpose hv-center">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label input-model-label"
                      >
                        Meeting Purpose
                      </label>
                    </div>
                    <div className="col-md-8 mb-3">
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={meetPurposeDropdown}
                        placeholder="Select Purpose"
                        value={meetPurposeDropdown.value}
                        onInputChange={meetPurposeDropdown.label}
                        onChange={(data) => setMeetPurposeId(data.value)}
                        isSearchable={true}
                        // isClearable={true}
                      />
                      {fieldReq && meetPurposeId === "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="client-name hv-center">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label input-model-label"
                      >
                        Company Name
                      </label>
                    </div>
                    <div className="col-md-8 mb-3">
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={clientList}
                        placeholder="Select Company"
                        value={clientList.value}
                        onInputChange={clientList.label}
                        onChange={(data) => setClientId(data.value)}
                        isSearchable={true}
                      />
                      {fieldReq && clientId === null && clientName === "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="col-md-1 mb-3">
                  <button
                    data-toggle="modal"
                    data-target="#addclientModel"
                    className="btn btn-add-client-name"
                    onClick={showShowclientmodel}
                  >
                    <i className="fa fa-plus-circle"></i>
                  </button>
                </div> */}
                  </div>

                  <div className="mobile-number hv-center">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label input-model-label"
                      >
                        Mobile Number
                      </label>
                    </div>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        className="form-control form add-meeting-input"
                        id="exampleFormControlInput1"
                        placeholder="Mobile Number"
                        readOnly={true}
                        value={mobilenum}
                        onChange={(e) => setMobileNum(e.target.value)}
                        // required
                      />
                      {fieldReq && clientId === null && mobilenum === "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="location hv-center">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label input-model-label"
                      >
                        Location
                      </label>
                    </div>
                    <div className="col-md-8 mb-3">
                      <textarea
                        rows="3"
                        cols="20"
                        name="usrtxt"
                        wrap="hard"
                        className="form-control form add-meeting-input location"
                        placeholder="Street Address/House No./Locality"
                        readOnly={true}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      {/* <input
                    type="text"
                    className="form add-meeting-input location"
                    placeholder="Street Address/House No./Locality"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    // required
                  /> */}
                      {fieldReq && clientId === null && address === "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="add-page-save-cancel">
                  <SBSaveUpdateBtn btnName="Save" />
                  <SbCancelBtn
                    btnName="Cancel"
                    onClickEffect={() => {
                      props.handleClose();
                      setAddress("");
                      setMobileNum("");
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddMeeting;
