import React, { useState } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Reports.scss";
import ReactPaginate from "react-paginate";
import { Button, Form, Image, Dropdown, Row, Col } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { optionsPageSizeList } from "../../DropdownsData";
import { Link } from "react-router-dom";
import startDateImg from "../../../images/process-icon.png";
import userImage from "../../../images/person.png";
import Chart from "react-apexcharts";
import {
  donutChartOptions,
  roundChartOptions,
  radialBarChartOptions,
  roundChartPendingOptions,
  overallTaskReportOptions,
  overBudejetCostOptions,
  meetingsChartOptions,
} from "./ChartsOptions";
import ChatModel from "./ChatModel";
import AddPhasesGraphModel from "./AddPhasesGraphModel";
import ViewPhaseDetailsModel from "./ViewPhaseDetailsModel";

export default function ViewProjectGraph(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectGraphPhase, setSelectGraphPhase] = useState(false);
  const [graphPhaseDetails, setGraphPhaseDetails] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const [OpenChatModel, setOpenChatModel] = useState(false);
  // const [roundChart, setRoundChart] = useState(roundChartOptions);
  // const [roundChart1, setRoundChart1] = useState();

  const OpenChatModelfun = () => {
    setOpenChatModel(!OpenChatModel);
  };

  const openSelectShowGraphPhase = () => {
    setSelectGraphPhase(!selectGraphPhase);
  };

  const openPhaseDetails = () => {
    setGraphPhaseDetails(!graphPhaseDetails);
  };

  return (
    <div>
      <div className="project-report-by-graph">
        {/*  TOP BAR START  */}
        <div className="project-report-head">
          <div className="page-title">PROJECT 01</div>

          <div className="page-top-bar-dropdowns">
            <button
              type="button"
              className="btn btn-back"
              onClick={props.openProjectReportGraph}
            >
              {/* <span className="btn-inline-item d-flex"> */}
              <i className="fa fa-arrow-left"> </i>
              Back
              {/* </span> */}
            </button>
            <div className="date-picker-with-icon">
              <DatePicker
                selected={fromDate}
                className="select from-date"
                id="from-datepicker"
                dateFormat="dd/MM/yyyy"
                selectsEnd
                // startDate={fromDate}
                endDate={toDate}
                onChange={(date) => setFromDate(date)}
                maxDate={new Date()}
              />
              <div className="date-picker-icon-part">
                <i className="fa fa-calendar-o calander-icon" />
              </div>
            </div>
          </div>
        </div>
        {/*  TOP BAR END  */}

        {/*  CARD ROW-01 START  */}
        <div className="card-graph-by-phase-overall-progress">
          <div className="row">
            <div className="col-md-2">
              <div className="card card-overall-progress">
                <div>
                  <Chart
                    options={radialBarChartOptions?.options}
                    series={[67]}
                    type="radialBar"
                    className="range-chart-apex"
                  />
                  <p>Overall Progress</p>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card card-by-phase-report">
                <div
                  className="phase-name-with-graph"
                  onClick={openPhaseDetails}
                >
                  <p className="phase-name ph-1">Phase 01</p>
                  <Chart
                    options={roundChartOptions?.options}
                    series={[100]}
                    type="radialBar"
                    className="phase-range-chart-apex"
                  />
                  {/* <span className="verticle-line"></span> */}
                </div>
                <div className="phase-name-with-graph">
                  <p className="phase-name ph-2">Phase 02</p>
                  <Chart
                    options={roundChartPendingOptions?.options}
                    series={[60]}
                    type="radialBar"
                    className="phase-range-chart-apex"
                  />
                </div>
                <div className="phase-name-with-graph">
                  <p className="phase-name ph-3">Phase 03</p>
                  <Chart
                    options={roundChartPendingOptions?.options}
                    series={[40]}
                    type="radialBar"
                    className="phase-range-chart-apex"
                  />
                </div>
                <div className="phase-name-with-graph">
                  <p className="phase-name ph-4">Phase 04</p>
                  <Chart
                    options={roundChartPendingOptions?.options}
                    series={[20]}
                    type="radialBar"
                    className="phase-range-chart-apex"
                  />
                </div>
                <div
                  className="add-phase-icon"
                  onClick={openSelectShowGraphPhase}
                >
                  <i className="fa fa-plus-circle" />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card card-project-start-date">
                <div>
                  <Image
                    src={startDateImg}
                    className="project-start-date-img"
                    alt="project-start-img"
                  />
                  <p className="start-name">Project Start Date</p>
                  <p className="start-date">01 January 2023</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  CARD ROW-01 END  */}

        <div className="project-task-status-overall-task-report">
          <div className="row">
            <div className="col-md-5">
              <div className="card card-project-task-status">
                <div className="project-task-status">
                  <p className="project-task-status-head">
                    Project task by Status
                  </p>
                  <div className="chart-part">
                    <Chart
                      options={donutChartOptions?.options}
                      series={[30, 70, 30]}
                      type="donut"
                      className="project-status-chart-apex"
                    />
                    <p className="total-percent">100%</p>
                  </div>
                </div>
                <div className="project-summary-part">
                  <p className="project-summary-head">Project Summary</p>
                  <div className="project-summary-details">
                    <Row>
                      <Col className="p-0 br-1">
                        <div className="summary-detailby-row left ">
                          <span>
                            <strong>Project Start Date </strong>
                          </span>
                          <span> 03-01-2023</span>
                        </div>
                        <div className="summary-detailby-row left">
                          <span>
                            <strong>End Date </strong>
                          </span>
                          <span> 04-03-2023</span>
                        </div>
                        <div className="summary-detailby-row left">
                          <span>
                            <strong>Duration </strong>
                          </span>
                          <span> 2 Months</span>
                        </div>
                        <div className="summary-detailby-row left">
                          <span>
                            <strong>Overall Status </strong>
                          </span>
                          <span> Pending</span>
                        </div>
                      </Col>
                      <Col>
                        <div className="summary-detailby-row right">
                          <span>
                            <strong>No. Of Tasks </strong>
                          </span>
                          <span> 133</span>
                        </div>
                        <div className="summary-detailby-row right">
                          <span>
                            <strong>Completed Tasks </strong>
                          </span>
                          <span> 123</span>
                        </div>
                        <div className="summary-detailby-row right">
                          <span>
                            <strong>Pending Tasks </strong>
                          </span>
                          <span> 20</span>
                        </div>
                        <div className="summary-detailby-row right">
                          <span>
                            <strong>Overdue Tasks </strong>
                          </span>
                          <span> 33</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="card card-overall-task-report">
                <div className="overall-task-header-buttons">
                  <p className="overall-task-report-head">
                    Overall Task Report
                  </p>
                  <div className="month-year-buttons">
                    <Button className="btn-monthly task-report-btn">
                      Monthly
                    </Button>
                    <Button className="btn-yearly task-report-btn">
                      Yearly
                    </Button>
                  </div>
                </div>
                <div className="overall-task-report-chart">
                  <Chart
                    options={overallTaskReportOptions?.options}
                    series={[
                      {
                        name: "Total",
                        type: "column",
                        data: [80, 58, 90, 70, 75, 90, 65, 48, 72, 85, 80, 90],
                      },
                      {
                        name: "Completed",
                        type: "column",
                        data: [69, 45, 63, 63, 50, 85, 55, 35, 59, 70, 65, 60],
                      },
                    ]}
                    type="bar"
                    className="task-report-chart-apex"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project-budget-team-members">
          <div className="row">
            <div className="col-md-5">
              <div className="card card-project-budget">
                <div className="project-budget-status">
                  <p className="project-budget-head">Project Bedget</p>
                  <div className="budget-chart-part">
                    <Chart
                      options={overBudejetCostOptions?.options}
                      series={[
                        {
                          name: "Total Budget",
                          type: "column",
                          data: [100],
                        },
                        {
                          name: "Over Budget",
                          type: "column",
                          data: [20],
                        },
                      ]}
                      type="bar"
                      className="budget-chart-apex"
                    />
                  </div>
                  <p className="fz13 gryclr">
                    <strong>Total Budget : </strong>
                    <i className="fa fa-rupee" /> 100 &nbsp; &nbsp;
                    <strong>Over Budget : </strong>
                    <i className="fa fa-rupee" /> 20
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="card card-team-members">
                <p className="team-members-head">Team Members</p>
                <div className="team-members-table">
                  <div className="table-responsive team-members-scrollbar">
                    <table className="table team-table">
                      <thead className="thead-light team-members-thead">
                        <tr>
                          <th>NAME</th>
                          <th>RATE</th>
                          <th>EMAIL</th>
                          <th>PHONE NO.</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p className="image-with-name-designaton">
                              <img
                                className="team-member-image"
                                src={userImage}
                                alt="User-Image"
                              />
                              <p>
                                <p>
                                  <strong>Nataraj Kumar </strong>
                                </p>
                                <p>UI Developer</p>
                              </p>
                            </p>
                          </td>
                          <td>
                            <i className="fa fa-rupee" /> 2000
                          </td>
                          <td>usernatarj@gmail.com</td>
                          <td>+91 9080908070</td>

                          <td>
                            <p
                              className="team-member-chart"
                              onClick={OpenChatModelfun}
                            >
                              <p className="chat-icon">
                                <i className="fa fa-commenting-o" />
                              </p>
                              <p className="chat-title">Chat</p>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="image-with-name-designaton">
                              <img
                                className="team-member-image"
                                src={userImage}
                                alt="User-Image"
                              />
                              <p>
                                <p>
                                  <strong>Mahesh K </strong>
                                </p>
                                <p>Frontend Developer</p>
                              </p>
                            </p>
                          </td>
                          <td>
                            <i className="fa fa-rupee" /> 3000
                          </td>
                          <td>usernatarj@gmail.com</td>
                          <td>+91 9080908070</td>

                          <td>
                            <p
                              className="team-member-chart"
                              onClick={OpenChatModelfun}
                            >
                              <p className="chat-icon">
                                <i className="fa fa-commenting-o" />
                              </p>
                              <p className="chat-title">Chat</p>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="image-with-name-designaton">
                              <img
                                className="team-member-image"
                                src={userImage}
                                alt="User-Image"
                              />
                              <p>
                                <p>
                                  <strong>Kumar B </strong>
                                </p>
                                <p>Python Developer</p>
                              </p>
                            </p>
                          </td>
                          <td>
                            <i className="fa fa-rupee" /> 6000
                          </td>
                          <td>usernatarj@gmail.com</td>
                          <td>+91 9080908070</td>

                          <td>
                            <p
                              className="team-member-chart"
                              onClick={OpenChatModelfun}
                            >
                              <p className="chat-icon">
                                <i className="fa fa-commenting-o" />
                              </p>
                              <p className="chat-title">Chat</p>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="image-with-name-designaton">
                              <img
                                className="team-member-image"
                                src={userImage}
                                alt="User-Image"
                              />
                              <p>
                                <p>
                                  <strong>Samson L </strong>
                                </p>
                                <p>Android Developer</p>
                              </p>
                            </p>
                          </td>
                          <td>
                            <i className="fa fa-rupee" /> 5000
                          </td>
                          <td>usernatarj@gmail.com</td>
                          <td>+91 9080908070</td>

                          <td>
                            <p
                              className="team-member-chart"
                              onClick={OpenChatModelfun}
                            >
                              <p className="chat-icon">
                                <i className="fa fa-commenting-o" />
                              </p>
                              <p className="chat-title">Chat</p>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* EXTRA GRAPH FOR MEETING IN DASHBOARD START */}
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4"></div>
              <div className="col-md-2"></div>
            </div>
            {/* EXTRA GRAPH FOR MEETING IN DASHBOARD END */}
          </div>
        </div>
      </div>
      {OpenChatModel ? (
        <ChatModel
          OpenChatModel={OpenChatModel}
          OpenChatModelfun={OpenChatModelfun}
        />
      ) : (
        ""
      )}
      {selectGraphPhase ? (
        <AddPhasesGraphModel
          selectGraphPhase={selectGraphPhase}
          openSelectShowGraphPhase={openSelectShowGraphPhase}
        />
      ) : (
        ""
      )}
      {graphPhaseDetails ? (
        <ViewPhaseDetailsModel
          graphPhaseDetails={graphPhaseDetails}
          openPhaseDetails={openPhaseDetails}
        />
      ) : (
        ""
      )}
    </div>
  );
}
