import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/Designation.scss";
import AlertModel from "../../AlertModel";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditDesignation(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let subscriptionId = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [designation, setDesignation] = useState("");
  const [departmentId, setDepartmentId] = useState(0);
  const [reportingRole, setReportingRole] = useState(0);
  const [reportDesignation, setReportDesignation] = useState(0);
  const [designationDescription, setDesignationDescription] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [enableStatus, setEnableStatus] = useState(1);

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [reportingVal, setReportingVal] = useState("");
  const [departmentVal, setDepartmentVal] = useState("");
  const [reportDesigVal, setReportDesigVal] = useState("");
  const [roleVal, setRoleVal] = useState("");

  useEffect(() => {
    fetchDropdownsData();
  }, [props.editDesignationID]);

  const fetchDropdownsData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Designation/Get/${props.editDesignationID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignation(res.result.designationName);
          setDepartmentId(res.result.departmentID);
          setRoleId(res.result.role.roleID);
          setReportingRole(res.result.role.reportingRoleID);
          setReportDesignation(res.result.reportingDesignationID);
          setDesignationDescription(res.result.description);
          setEnableStatus(res.result.status);
        } else {
        }
      });
    fetch(`${baseURL}/api/Department/BindDepartmentDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartmentList(
            res.result.map((data) => {
              return { value: data.departmentID, label: data.departmentName };
            })
          );
        } else {
          setDepartmentList([]);
        }
      });
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result
              .filter((role) => role.roleID !== 1)
              .map((data) => {
                return { value: data.roleID, label: data.roleName };
              })
          );
        } else {
          setRoleList([]);
        }
      });
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
  };

  useEffect(() => {
    if (departmentId !== null) {
      departmentList.map((dept) => {
        if (departmentId == dept.value) {
          setDepartmentVal(dept);
        }
      });
    }

    if (roleId !== null) {
      roleList.map((role) => {
        if (roleId == role.value) {
          setRoleVal(role);
        }
      });
    }
    if (reportDesignation !== null) {
      designationList.map((designation) => {
        if (reportDesignation == designation.value) {
          setReportDesigVal(designation);
        }
      });
    }
  }, [
    departmentId,
    departmentList,
    roleList,
    roleId,
    reportDesignation,
    designationList,
  ]);

  const editDesignation = () => {
    if (
      designation !== "" &&
      departmentId !== 0 &&
      roleId !== 0 &&
      reportDesignation !== 0 &&
      subscriptionId !== ""
    ) {
      const designationDetails = {
        designationID: props.editDesignationID,
        designationName: designation,
        departmentID: departmentId,
        role: {
          roleID: roleId,
        },
        reportingDesignationID: reportDesignation,
        subscriptionID: subscriptionId,
        description: designationDescription,
        status: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(designationDetails),
      };
      fetch(`${baseURL}/api/Designation/UpdateDesignation`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchDesignations();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            // props.handleClose();
            // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          // console.log("ERROR:", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required!",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.editDesignation}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">EDIT DESIGNATION</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-3">
                <FloatingLabel
                  label="Designation Name"
                  className={`${designation
                      ? "float-input-visible"
                      : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="text"
                    className="form-control form add-basefare-input"
                    placeholder="Designation Name"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    required
                  />
                </FloatingLabel>
                {fieldReq && designation == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Select Department"
                  className={`${departmentList.value || departmentId
                      ? "float-visible-select"
                      : "float-hidden float-select"
                    }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={departmentList[null]}
                    options={departmentList}
                    placeholder="Select Department"
                    value={departmentVal}
                    onInputChange={departmentList.label}
                    onChange={(data) => setDepartmentId(data.value)}
                    isSearchable={true}
                    required
                  />
                </FloatingLabel>
                {fieldReq && departmentId == 0 ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Role"
                  className={`${roleList.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                    }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={roleList[null]}
                    options={roleList}
                    placeholder="Role"
                    value={roleVal}
                    onInputChange={roleList.label}
                    onChange={(data) => setRoleId(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && roleId == 0 ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Designation Description"
                  className={`${designationDescription
                      ? "float-input-visible"
                      : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control form add-input"
                    placeholder="Designation Description"
                    value={designationDescription}
                    onChange={(e) => setDesignationDescription(e.target.value)}
                  />
                </FloatingLabel>
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Reporting Designation"
                  className={`${designationList.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                    }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={designationList[null]}
                    options={designationList}
                    placeholder="Reporting Designation"
                    value={reportDesigVal}
                    onInputChange={designationList.label}
                    onChange={(data) => setReportDesignation(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && reportDesignation == 0 ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={editDesignation} btnName="Update" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
