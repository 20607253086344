import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "../../styles/Attendance.scss";
import { loadGetSingleClient } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import * as HeaderData from "../HeaderAuthentication";
import { baseURL } from "../BaseUrl";

function AttendanceBreakUps(props) {
  let meetingID = props.meetingID;
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();
  const formRef = useRef(null);
  const [attendanceBreakUpData, setAttendanceBreakUpData] = useState([]);

  useEffect(() => {
    if (meetingID) {
      getAttendanceBreakups();
    }
  }, [props.meetingID]);

  const getAttendanceBreakups = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: HeaderData.postTravelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetAttendanceBreakup?AttendanceID=${meetingID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAttendanceBreakUpData(res.result);
        } else {
          setAttendanceBreakUpData([]);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  return (
    <div>
      {props.showModal && (
        <div className="modal-backdrop-attendance">
          <div className="modal-right-fade">
            <Modal.Dialog className="right-fade-modal-dialog">
              {attendanceBreakUpData && (
                <div>
                  <Modal.Header closeButton onHide={props.handleCloseModal}>
                    <Modal.Title className="hv-center attendance_beakup modal-heading">
                      ATTENDANCE BREAK UP ({props.userName} - {props.attenDate})
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {attendanceBreakUpData &&
                      attendanceBreakUpData.map((data, i) => (
                        <Row className="details-row" key={i}>
                          <Col md={6}>
                            <div>
                              <span>
                                <span>
                                  {" "}
                                  <strong>Check-In Time :</strong>
                                </span>
                                &nbsp;
                                <strong className="color-green">
                                  {data?.checkInTime ?? "NA"}
                                </strong>
                              </span>
                            </div>
                            <div>
                              <span>
                                <span className="color-yellow">
                                  <i className="bi bi-geo-alt" />
                                </span>
                                &nbsp;
                                <span>{data?.checkInLocation ?? "NA"}</span>
                              </span>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <span>
                                <span>
                                  {" "}
                                  <strong>Check-Out Time :</strong>
                                </span>
                                &nbsp;
                                <strong className="color-green">
                                  {data?.checkOutTime ?? "NA"}
                                </strong>
                              </span>
                            </div>
                            <div>
                              <span>
                                <span className="color-yellow">
                                  <i className="bi bi-geo-alt" />
                                </span>
                                &nbsp;
                                <span>{data?.checkOutLocation ?? "NA"}</span>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ))}
                  </Modal.Body>
                </div>
              )}
            </Modal.Dialog>
          </div>
        </div>
      )}
    </div>
  );
}

export default AttendanceBreakUps;
