import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import { useDispatch, useSelector } from "react-redux";
import { loadUserDropdown } from "../../redux/action";
import { optionsLeaveTypes } from "../DropdownsData";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditLeaves(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */
  let dispatch = useDispatch();

  const { userDropdown } = useSelector((state) => state.data);
  const [filterUserId, setFilterUserId] = useState("");
  const [leaveTypeID, setLeaveTypeID] = useState(0);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveYear, setLeaveYear] = useState(null);
  const [leaveAllowed, setLeaveAllowed] = useState(0);
  const [leaveRemain, setLeaveRemain] = useState(0);
  const [leaveTaken, setLeaveTaken] = useState(null);
  const [enableStatus, setEnableStatus] = useState(1);
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useEffect(() => {
    fetchLeaveTypes();
  }, [props.leaveBalaneID]);

  const fetchLeaveTypes = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Leave/BindLeaveTypes`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("LEAVETYPES::", res.result);
        if (res.success) {
          setLeaveTypes(
            res.result.map((data) => {
              return {
                value: data.leaveTypeID,
                label: data.leaveTypeName,
              };
            })
          );
        } else {
          setLeaveTypes([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
    fetch(
      `${baseURL}/api/LeaveBalance/Get/${props.leaveBalaneID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setFilterUserId(res.result?.userID);
          setEnableStatus(res.result?.status);
          setLeaveYear(res.result?.year);
          setLeaveAllowed(+res.result?.allowed);
          //   setLeaveRemain(res.result?.remain);
          setLeaveTaken(+res.result?.taken);
          setLeaveTypeID(+res.result?.leaveTypeID);
        } else {
          setFilterUserId("");
          setEnableStatus("");
          setLeaveYear("");
          setLeaveAllowed("");
          //   setLeaveRemain("");
          setLeaveTaken("");
          setLeaveTypeID(null);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  useEffect(() => {
    dispatch(loadUserDropdown());
  }, []);

  const [userVal, setUserVal] = useState("");
  const [leaveTypeVal, setLeaveTypeVal] = useState("");

  useEffect(() => {
    if (filterUserId !== "" && filterUserId !== null) {
      userDropdown.map((users) => {
        if (filterUserId == users.value) {
          setUserVal(users);
        }
      });
    }
    if (leaveTypeID !== 0 && leaveTypeID !== null) {
      leaveTypes.map((leaves) => {
        if (leaveTypeID == leaves.value) {
          setLeaveTypeVal(leaves);
        }
      });
    }
  }, [filterUserId, userDropdown, leaveTypes, leaveTypeID]);

  // UPDATE LEAVES START
  const updateLeaves = () => {
    if (
      filterUserId !== "" &&
      leaveYear !== null &&
      leaveYear !== "" &&
      leaveTypeID !== 0 &&
      leaveAllowed !== 0 &&
      leaveTaken !== null &&
      leaveTaken !== ""
    ) {
      const leavesData = {
        leaveBalanceID: props.leaveBalaneID,
        leaveTypeID: leaveTypeID,
        userID: filterUserId,
        status: enableStatus,
        year: leaveYear,
        allowed: leaveAllowed,
        taken: leaveTaken,
        remain: leaveAllowed - leaveTaken,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(leavesData),
      };
      fetch(`${baseURL}/api/LeaveBalance/UpdateLeaveBalance`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchLeaveBalanceDetails();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            // props.handleClose();
            // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };
  // UPDATE LEAVES END

  return (
    <div>
      <Modal
        show={props.handleClose}
        // onHide={props.handleClose}
        className="main_model"
      >
        <div className="update-leavestatus-heading">
          <h6 className="page-header-text">EDIT LEAVES</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="update-leave-status">
              <div className="select-status mb-2">
                <div className="mt-3">
                  <FloatingLabel
                    label="Select User"
                    className={`${filterUserId
                        ? "float-visible-select"
                        : "float-hidden float-select"
                      }`}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      defaultValue={userDropdown[null]}
                      options={userDropdown}
                      placeholder="Select User"
                      value={userVal}
                      onInputChange={userDropdown.label}
                      onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                      required
                    />
                  </FloatingLabel>
                  {fieldReq && filterUserId == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-3">
                  <FloatingLabel
                    label="Year"
                    className={`${leaveYear
                        ? "float-input-visible"
                        : "float-hidden float-input"
                      }`}
                  >
                    <input
                      type="number"
                      className="form-control form add-meeting-input"
                      id="exampleFormControlInput1"
                      placeholder="Year"
                      value={leaveYear}
                      onChange={(e) => setLeaveYear(+e.target.value)}
                      required
                    />
                  </FloatingLabel>
                  {fieldReq && leaveYear == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-3">
                  <FloatingLabel
                    label="Leave Type"
                    className={`${filterUserId
                        ? "float-visible-select"
                        : "float-hidden float-select"
                      }`}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      defaultValue={leaveTypes[null]}
                      options={leaveTypes}
                      placeholder="Leave Type"
                      value={leaveTypeVal}
                      onInputChange={leaveTypes.label}
                      onChange={(data) => setLeaveTypeID(data.value)}
                      isSearchable={true}
                      required
                    />
                  </FloatingLabel>
                  {fieldReq && leaveTypeID == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-3">
                  <FloatingLabel
                    label="Allotted"
                    className={`${leaveAllowed
                        ? "float-input-visible"
                        : "float-hidden float-input"
                      }`}
                  >
                    <input
                      type="number"
                      className="form-control form add-meeting-input"
                      id="exampleFormControlInput1"
                      placeholder="Allotted"
                      value={leaveAllowed}
                      onChange={(e) => setLeaveAllowed(+e.target.value)}
                      required
                    />
                  </FloatingLabel>
                  {fieldReq && leaveAllowed == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-3">
                  <FloatingLabel
                    label="Availed"
                    className={`${leaveTaken !== null || leaveTaken !== ""
                        ? "float-input-visible"
                        : "float-hidden float-input"
                      }`}
                  >
                    <input
                      type="number"
                      className="form-control form add-meeting-input"
                      id="exampleFormControlInput1"
                      placeholder="Availed"
                      value={leaveTaken}
                      onChange={(e) => setLeaveTaken(+e.target.value)}
                      required
                    />
                  </FloatingLabel>
                  {fieldReq && (leaveTaken == "" || leaveTaken == null) ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-3">
                  <FloatingLabel
                    label="Available"
                    className={`${leaveRemain !== null
                        ? "float-input-visible"
                        : "float-hidden float-input"
                      }`}
                  >
                    <input
                      type="number"
                      className="form-control form add-meeting-input"
                      id="exampleFormControlInput1"
                      placeholder="Available"
                      value={leaveAllowed - leaveTaken}
                      // onChange={(e) => setLeaveRemain(+e.target.value)}
                      required
                    />
                  </FloatingLabel>
                  {/* {fieldReq && leaveAllowed - leaveTaken == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )} */}
                </div>
                <div className="mt-3">
                  <span className="enable-status d-flex">
                    <h6>Enable</h6>
                    <span className="enable-radio-client d-flex">
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={enableStatus == 1 ? true : false}
                          onChange={() => setEnableStatus(1)}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={enableStatus == 0 ? true : false}
                          onChange={() => setEnableStatus(0)}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          No
                        </label>
                      </div>
                    </span>
                  </span>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={updateLeaves} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
