import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";

export default function EditTextModel(props) {
  let newData = props.data;
  const [selectedData, setSelectedData] = useState(newData.inputData.type);
  const [required, setRequired] = useState(newData.inputData.required);
  // const [placeholder, setPlaceholder] = useState("");
  const [labelName, setLabelName] = useState(newData.inputData.label);

  const fieldTypes = [
    { value: "text", label: "Text" },
    { value: "email", label: "Email" },
    { value: "tel", label: "Tele" },
    { value: "number", label: "Number" },
    { value: "password", label: "Password" },
  ];

  const [typeVal, setTypeVal] = useState("");

  useEffect(() => {
    if (selectedData !== "" && selectedData !== null) {
      fieldTypes.map((item) => {
        if (selectedData == item.value) {
          setTypeVal(item);
        }
      });
    }
  }, [selectedData]);

  const offOnSwitch = () => {
    setRequired(!required);
  };

  const changeValue = (itemId) => {
    const newInputData = {
      id: itemId,
      name: "Text Field",
      icon: newData.icon,
      inputData: {
        type: selectedData,
        label: labelName,
        className: "dynamic-input-type",
        placeHolder: "",
        value: selectedData == "number" ? null : "",
        autoComplete: "off",
        required: required,
      },
    };
    const newArray = props.addedFieldNames.map((item) => {
      if (item.id == itemId) {
        return newInputData;
      }
      return item;
    });
    props.setAddedFieldNames(newArray);
    props.handleClose();
    // console.log("NEW_ARR", newArray);
  };

  return (
    <div>
      <>
        <Modal show={props.editTextField} className="main_model add-edit-model">
          <div className="add-edit-heading">
            <h6 className="add-edit-head">EDIT TEXT FIELD</h6>
            <i className="fa fa-times" onClick={props.handleClose} />
          </div>

          <form>
            <div className="container add-edit">
              <div className="change-text-fields-part">
                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="Label Name"
                    className={`${
                      labelName
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      id={props.data.id}
                      type="text"
                      className="change-model"
                      placeholder="Label Name"
                      autoComplete="off"
                      value={labelName}
                      required
                      onChange={(e) => setLabelName(e.target.value)}
                    />
                  </FloatingLabel>
                </div>

                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="Type"
                    className={`${
                      props.data.id || ""
                        ? "float-visible-select"
                        : "float-hidden float-select"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      defaultValue={fieldTypes[null]}
                      options={fieldTypes}
                      placeholder="Type"
                      value={typeVal}
                      onInputChange={fieldTypes.label}
                      onChange={(data) => setSelectedData(data.value)}
                      isSearchable={true}
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="model-inputs mb-3">
                  <div className="d-flex">
                    <Form.Label>Required</Form.Label> &nbsp; &nbsp;
                    <Form.Check
                      type="switch"
                      id={props.data.id}
                      label={required ? "Required" : "Optional"}
                      checked={required}
                      onChange={offOnSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="btns-save-cancel">
            <div className="model-save-button">
              <button
                onClick={() => changeValue(props.data.id)}
                type="button"
                className="btn btn-save-up"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
            <div className="model-cancel-button">
              <button
                onClick={props.handleClose}
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
}
