import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form, Row, Col } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";

export default function EditFileUpload(props) {
  let newData = props.data;
  const [selectedData, setSelectedData] = useState(newData.inputData.type);
  const [multipleUpload, setMultipleUpload] = useState(
    newData.inputData.multiple
  );
  const [labelName, setLabelName] = useState(newData.inputData.label);

  const offOnSwitch = () => {
    setMultipleUpload(!multipleUpload);
  };

  const changeValue = (itemId) => {
    const newInputData = {
      id: newData.id,
      name: "File Upload",
      icon: newData.icon,
      inputData: {
        type: selectedData,
        name: "file-upload",
        label: labelName,
        className: "file-upload",
        multiple: multipleUpload,
        required: false,
      },
    };
    const newArray = props.addedFieldNames.map((item) => {
      if (item.id == itemId) {
        return newInputData;
      }
      return item;
    });
    props.setAddedFieldNames(newArray);
    props.handleClose();
  };
  return (
    <div>
      {" "}
      <Modal show={props.editFileUpload} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">EDIT FILE UPLOAD</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>

        <form>
          <div className="container add-edit">
            <div className="change-text-fields-part">
              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Label Name"
                  className={`${
                    labelName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    id={props.data.id}
                    type="text"
                    className="change-model"
                    placeholder="Label Name"
                    autoComplete="off"
                    value={labelName}
                    required
                    onChange={(e) => setLabelName(e.target.value)}
                  />
                </FloatingLabel>
              </div>
              <div className="model-inputs mb-3">
                <div className="d-flex">
                  <Form.Label>Multiple Upload</Form.Label> &nbsp; &nbsp;
                  <Form.Check
                    type="switch"
                    id={props.data.id}
                    label={multipleUpload ? "Multiple Upload" : "Single Upload"}
                    checked={multipleUpload}
                    onChange={offOnSwitch}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="model-save-button">
            <button
              onClick={() => changeValue(props.data.id)}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="model-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
