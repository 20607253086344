import React, { useEffect, useLayoutEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GoogleMapComp from "../../GoogleMapComp";
import { baseURL } from "../../BaseUrl";
import GoogleAccessModal from "../../GoogleAccessModal";
import "../../../styles/ActivityOnMap.scss";

export default function ActivityOnMap(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [userName, setUserName] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientLocation, setClientLocation] = useState("");
  const [distance, setDistance] = useState("");

  const [opnInfoWindow, setOpnInfoWindow] = useState(true);
  const [delayTime, setDelayTime] = useState(800);
  const [playPause, setPlayPause] = useState(false);
  const [stepForward, setStepForward] = useState(null);
  const [stepBackward, setStepBackward] = useState(null);
  const [movingMarkerPosition, setMovingMarkerPosition] = useState({});
  const [movingIndex, setMovingIndex] = useState(1);
  const [centerLatLng, setCenterLatLng] = useState({
    lat: +props.activitiesByUser[0].cordLoc.latitude,
    lng: +props.activitiesByUser[0].cordLoc.longitude,
  });
  const [clientLatLng, setClientLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [userLatLng, setUserLatLng] = useState({
    lat: null,
    lng: null,
  });

  const [trackClientCenLatLng, setTrackClientCenLatLng] = useState({
    lat: null,
    lng: null,
  });

  let distanceMatrix = sessionStorage.getItem("distanceMatrixID");
  const [mapView, setMapView] = useState(false);
  const [accessModal, setAccessModal] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (distanceMatrix == 1) {
      setMapView(true);
    } else {
      setMapView(false);
    }
  }, [distanceMatrix]);

  const giveAccess = () => {
    setAccessModal(true);
  };

  const closeAccess = () => {
    setAccessModal(false);
  };

  const mapAccesss = () => {
    setAccessModal(false);
    setMapView(true);
  };

  const startFromFirst = () => {
    setMovingIndex(0);
  };

  useEffect(() => {
    {
      playPause && moveMarkerofActivity();
    }
  }, [movingIndex, playPause]);

  // GET PAGE START
  useEffect(() => {
    fetchRoutePlans();
  }, [props.viewRouteID]);

  const fetchRoutePlans = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Route/GetRouteClient?RouteId=2&clientID=1`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserName(res.result[0]?.userFullName);
          setUserLocation(res.result[0]?.baseLoc?.location);
          setClientName(res.result[0]?.clientName);
          setClientLocation(res.result[0]?.clientLoc?.location);
          setDistance(res.result[0]?.baseClientDistance);
          setClientLatLng({
            lat: parseFloat(res.result[0]?.clientLoc?.latitude),
            lng: parseFloat(res.result[0]?.clientLoc?.longitude),
          });
          setUserLatLng({
            lat: parseFloat(res.result[0]?.baseLoc?.latitude),
            lng: parseFloat(res.result[0]?.baseLoc?.longitude),
          });
        } else {
          setUserName("");
          setClientName("");
          setClientLatLng({ lat: null, lng: null });
          setUserLatLng({ lat: null, lng: null });
        }
      });
  };
  // GET PAGE END

  const moveMarkerofActivity = () => {
    let polyCords = props.activitiesByUser;
    let nextIndex = movingIndex + 1;
    if (nextIndex < polyCords.length) {
      setTimeout(() => {
        setMovingMarkerPosition({
          lat: +polyCords[nextIndex].cordLoc?.latitude,
          lng: +polyCords[nextIndex].cordLoc?.longitude,
          location: polyCords[nextIndex].cordLoc?.location,
          time: polyCords[nextIndex]?.onlyTime,
        });
        // setOpnInfoWindow(false);
        setMovingIndex(movingIndex + 1);
      }, delayTime);
      setOpnInfoWindow(false);
    }
    setPlayPause(movingIndex == polyCords.length - 1 ? false : true);
    // console.log("LENGTH-MATCH:", movingIndex == polyCords.length - 1);
    return;
  };

  // console.log("MOVING-INDEX:", movingIndex);

  return (
    <div>
      {" "}
      <Modal
        show={props.activityOnMap}
        // onHide={props.openGoogleMaps}
        className="main_model google-maps-activity-model"
      >
        <div>
          <div className="add-user-heading">
            <h6 className="add-user-head">ACTIVITY ON MAP</h6>
            <div className="play-pause-back-next-buttons hv-center">
              <div
                className={!playPause ? "btn-active" : "btn-disabled"}
                onClick={() => setMovingIndex(movingIndex - 1)}
                title="Backward"
              >
                <i className="fa fa-step-backward fz25" />
              </div>
              &nbsp; &nbsp;
              <div className="button-type play-pause">
                {!playPause ? (
                  <i
                    className="fa fa-play-circle-o fz25"
                    onClick={() => setPlayPause(true)}
                    title="Play"
                  />
                ) : (
                  <i
                    className="fa fa-pause-circle-o fz25"
                    onClick={() => setPlayPause(false)}
                    title="Pause"
                  />
                )}
              </div>
              &nbsp; &nbsp;
              <div
                className={!playPause ? "btn-active" : "btn-disabled"}
                onClick={() => setMovingIndex(movingIndex + 1)}
                title="Forward"
              >
                <i className="fa fa-step-forward fz25" />
              </div>
              &nbsp; &nbsp;
              <div
                className={!playPause ? "btn-active" : "btn-disabled"}
                onClick={() => startFromFirst()}
                title="Replay"
              >
                <i className="fa fa-repeat fz25" />
              </div>
            </div>

            <i
              className="fa fa-times"
              onClick={() => props.setActivityOnMap(false)}
            />
          </div>

          {props.activitiesByUser.length > 0 && (
            <div className="google-map-box">
              {mapView && movingMarkerPosition ? (
                <GoogleMapComp
                  centerLatLng={centerLatLng}
                  endLatLan={clientLatLng}
                  startLatLan={userLatLng}
                  pageType="ActivityOnMap"
                  playPause={playPause}
                  movingMarkerPosition={movingMarkerPosition}
                  opnInfoWindow={opnInfoWindow}
                  activitiesByUser={props.activitiesByUser}
                />
              ) : (
                <div className="google-map-box p-2 empty-map">
                  <div className="overlay-div">
                    <div className="over-lay-text-div">
                      <h6 style={{ color: "#ffb900" }}>
                        This Feature is not available in your Subscription...!
                      </h6>
                      <p className="mt-3">
                        If you still want to view the map{" "}
                        <span
                          className="complete-status status-badge"
                          style={{ cursor: "pointer" }}
                          onClick={giveAccess}
                        >
                          Click Here
                        </span>
                      </p>
                    </div>
                  </div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.56659496218!2d77.46612702802196!3d12.95428023487456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1692190317657!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <>
                    {accessModal && (
                      <GoogleAccessModal
                        accessModal={accessModal}
                        closeAccess={closeAccess}
                        mapAccesss={mapAccesss}
                      />
                    )}
                  </>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
