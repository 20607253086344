import React, { useState, useEffect, useLayoutEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import profileimg from "../../images/profile-1.png";
import Addmeetingmodel from "./Addmeetingmodel";
import Meetingdetails from "./Meetingdetails";
import TrackMeetings from "./TrackMeetings";
import ReactPaginate from "react-paginate";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import { loggedInRole } from "../LoginRole";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import SbAddBtn, { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import {
  loadManagerDropdown,
  loadUserDropdown,
  loadPageBreadCome,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatIST } from "../FormattedAmount";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import Footer from "../Footer";
import AddMeeting from "./AddMeeting";

const countOptions = [
  { value: "all", label: "All" },
  { value: "hasmeetings", label: "Has Meeting" },
  { value: "nomeetings", label: "No Meeting" },
];

function Meetings() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);
  const { managerDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [meetingdetails, setMeetingdetails] = useState(false);
  const [viewonmap, setViewonMap] = useState(false);
  const [filterUserId, setFilterUserId] = useState("");
  const [filterMeeting, setFilterMeeting] = useState(countOptions[1]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [manager, setManager] = useState("");
  const [userID, setUserID] = useState("");
  const [meetingdetailsDate, setMeetingdetailsDate] = useState("");
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [meetingsData, setMeetingsData] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalMeetingsData, setTotalMeetingsData] = useState({});

  let tableHeader = [
    "NAME",
    "DATE",
    "DIRECT/MY VISIT",
    "SCHEDULED VISIT",
    "TOTAL VISIT",
    // "DISTANCE",
    // "ACTION",
    ...(filterMeeting.value !== "all" && filterMeeting.value !== "nomeetings"
      ? ["DISTANCE", "ACTION"]
      : []),
  ];

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  //* FETCH DROPDOWN DATA START *//
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Complaints" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadUserDropdown());
    dispatch(loadManagerDropdown());
  }, []);

  //* FETCH DROPDOWN DATA END *//

  const handleClose = () => {
    setShow(false);
  };
  const showAddmeetingmodel = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const goBacktomeetings = () => {
    setMeetingdetails(!meetingdetails);
  };
  const goBacktomeetingsfromtrackuser = () => {
    setViewonMap(!viewonmap);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  // GET MEETINGS DATA START
  useEffect(() => {
    getMeetings();
  }, [
    PageSize,
    currentPage,
    filterUserId,
    manager,
    filterMeeting,
    fromDate,
    toDate,
  ]);

  const getMeetings = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&userId=${filterUserId}&managerId=${manager}&IncludeFilter=${
        filterMeeting.value
      }&FromDate=${moment(fromDate).format("DD/MM/YYYY")}&ToDate=${
        toDate != null ? moment(toDate).format("DD/MM/YYYY") : null
      }`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetingsData(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
          let totalDirectVisits = res.result.reduce(
            (data, { directVisit }) => data + directVisit,
            0
          );
          let totalScheduleVisit = res.result.reduce(
            (data, { scheduleVisit }) => data + scheduleVisit,
            0
          );
          let totalMeetCount = res.result.reduce(
            (data, { countOfMeetings }) => data + countOfMeetings,
            0
          );
          let totalDistanceTraveled = res.result.reduce(
            (data, { totalDistance }) => data + totalDistance,
            0
          );
          // console.log("TOTAL::", totalDirectVisits);
          setTotalMeetingsData({
            totalDirect_MyVisit: +totalDirectVisits,
            totalScheduleVisit: +totalScheduleVisit,
            totalVisits: +totalMeetCount,
            totalKilmtrs: +totalDistanceTraveled,
          });
        } else {
          setMeetingsData("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // GET MEETINGS DATA END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setFilterMeeting(countOptions[1]);
    setManager("");
    setFromDate(new Date());
    setToDate(new Date());
    setTotalData(0);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportAllMeetings = () => {
    exportExcel(
      `${baseURL}/api/Export/MeetingPageExcelExport?UserID=${filterUserId}&ManagerId=${manager}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Meetings.xlsx"
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            !show && !viewonmap ? (
              <>
                {/* SIDEBAR START */}
                <Sidenavbar
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />
                {/* SIDEBAR END */}

                {/* TOPBAR START */}
                <Topbar
                  toggleMenu={toggleMenu}
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />
                {/* TOPBAR END */}

                {/* MAIN BODY START */}
                <div className="page-wrapper">
                  {/* PAGE CONTENT START */}
                  {!meetingdetails ? (
                    <div className={`main-body ${toggleshift.style}`}>
                      <div className="page-content">
                        {/* Filter and Header Section */}
                        <div className="user-table">
                          <Row className="search-row">
                            <Col md={3} className="cat-col">
                              <div className="page-header-text-div">
                                <div
                                  className="back-btn"
                                  onClick={goToSettingPage}
                                >
                                  <img
                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                    alt=""
                                  />
                                </div>
                                <h6 className="page-header-text">MEETINGS</h6>
                              </div>
                            </Col>
                            <Col md={9} className="cat-col">
                              <div
                                className="search-bar-header"
                                style={{ float: "right" }}
                              >
                                <div className="page-top-bar-dropdowns page-top-Filters">
                                  <div
                                    className="header-filter-btn"
                                    onClick={() => setFilter(!filter)}
                                  >
                                    <p className="card-head">
                                      <i className="bi bi-sliders" />
                                      &nbsp;&nbsp;Filters
                                    </p>
                                  </div>
                                  <SbAddBtn
                                    onClickEffect={showAddmeetingmodel}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          {filter && (
                            <div className="page-filter-section">
                              <div style={{ width: "100%" }}>
                                <Row className="filters-row">
                                  <Col md={10}>
                                    <div className="clients-filter-input-boxs filter-box-div">
                                      <div
                                        style={{
                                          width: "100%",
                                          padding: "0px 5px",
                                        }}
                                      >
                                        {loggedInRole != 2 ? (
                                          <Select
                                            className="react-select-container-list"
                                            classNamePrefix="react-select-list"
                                            options={managerDropdown}
                                            placeholder="Select Manager"
                                            value={
                                              manager === ""
                                                ? ""
                                                : managerDropdown.value
                                            }
                                            onInputChange={
                                              managerDropdown.label
                                            }
                                            onChange={(data) =>
                                              setManager(data.value)
                                            }
                                            isSearchable={true}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          padding: "0px 5px",
                                        }}
                                      >
                                        <Select
                                          className="react-select-container-list"
                                          classNamePrefix="react-select-list"
                                          options={userDropdown}
                                          placeholder="Select User"
                                          value={
                                            filterUserId === ""
                                              ? ""
                                              : userDropdown.value
                                          }
                                          onInputChange={userDropdown.label}
                                          onChange={(data) =>
                                            setFilterUserId(data.value)
                                          }
                                          isSearchable={true}
                                        />
                                      </div>

                                      <div
                                        style={{
                                          width: "100%",
                                          padding: "0px 5px",
                                        }}
                                        className="input-group from-to-date-select"
                                      >
                                        <span
                                          className="input-group-text"
                                          id="from-label"
                                        >
                                          From - To
                                        </span>
                                        <span className="date-picker-with-icon">
                                          <DatePicker
                                            selected={fromDate}
                                            className="select from-date date-filter"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            startDate={fromDate}
                                            endDate={toDate}
                                            onChange={(date) =>
                                              setSelectSearchDrop(date)
                                            }
                                            // maxDate={new Date()}
                                          />
                                        </span>
                                        <span>
                                          <i
                                            className="fa fa-calendar-o calander-icon"
                                            style={{ right: "15px" }}
                                          />
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          padding: "0px 5px",
                                        }}
                                      >
                                        <Select
                                          className="react-select-container-list"
                                          classNamePrefix="react-select-list"
                                          options={countOptions}
                                          placeholder="Meeting Look Up"
                                          value={filterMeeting}
                                          onInputChange={countOptions.label}
                                          onChange={(data) =>
                                            setFilterMeeting(data)
                                          }
                                          isSearchable={true}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    <div className="clients-filter-input-boxs filter-box-div">
                                      <SbCancelBtn
                                        onClickEffect={clearSerachBySelect}
                                        btnName="Clear"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Filter and Header Section */}

                        {/* USER TABLE START */}
                        <div className="meetings-table">
                          <div
                            className="table-responsive meetings-scrollbar"
                            onScroll={(e) =>
                              handleScroll(
                                e,
                                meetingsData,
                                totalData,
                                setLoading,
                                setPageSize,
                                PageSize
                              )
                            }
                          >
                            <table className="table meetings-list-table">
                              <SbTableHeader tableHeader={tableHeader} />
                              <tbody>
                                {isLoading ? (
                                  <>
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                    <SkeletonTableData />
                                  </>
                                ) : meetingsData !== "" ? (
                                  meetingsData.map((data, i) => (
                                    <>
                                      <tr
                                        className="meeting-list-row-data"
                                        key={i}
                                      >
                                        <td>
                                          <span className="meeting-list-name d-flex">
                                            <img
                                              className="table-data-meeting-imgages"
                                              src={profileimg}
                                              alt="John Doe"
                                            />
                                            <div>
                                              <div className="table-row-head-Text">
                                                {data.fullName}
                                              </div>
                                              <div className="table-row-sub-text">
                                                {data?.userCode}
                                              </div>
                                            </div>
                                          </span>
                                        </td>

                                        <td>
                                          {dateFormatIST(data?.meetingDate)}
                                        </td>
                                        <td>{data?.directVisit}</td>
                                        <td>{data?.scheduleVisit}</td>
                                        <td>{data?.countOfMeetings}</td>
                                        {filterMeeting.value !== "all" &&
                                          filterMeeting.value !==
                                            "nomeetings" && (
                                            <td>
                                              {data?.totalDistance?.toFixed(2)}
                                            </td>
                                          )}
                                        {filterMeeting.value !== "all" &&
                                          filterMeeting.value !==
                                            "nomeetings" && (
                                            <td>
                                              <span className="meeting-action-td">
                                                <button
                                                  className="meeting-detail-link"
                                                  onClick={() => {
                                                    setMeetingdetails(
                                                      !meetingdetails
                                                    );
                                                    setUserID(data?.userId);
                                                    setMeetingdetailsDate(
                                                      data?.meetingDate
                                                    );
                                                  }}
                                                >
                                                  <i className="fa fa-info-circle"></i>{" "}
                                                  Details
                                                </button>

                                                <button
                                                  className="meeting-view-link"
                                                  onClick={() => {
                                                    setViewonMap(!viewonmap);
                                                    setUserID(data?.userId);
                                                    setMeetingdetailsDate(
                                                      data?.meetingDate
                                                    );
                                                  }}
                                                >
                                                  <i className="bi bi-geo-alt-fill" />{" "}
                                                  View On Map
                                                </button>
                                              </span>
                                            </td>
                                          )}
                                      </tr>
                                    </>
                                  ))
                                ) : (
                                  <tr>
                                    <td className="no-data-found" colSpan={7}>
                                      ------ NO DATA FOUND ------
                                    </td>
                                  </tr>
                                )}
                                {loading && <OnScrollLoading />}
                                <tr>
                                  <td></td>
                                  <td>
                                    <b className="color-green">TOTAL</b>
                                  </td>
                                  <td>
                                    <b>
                                      {totalMeetingsData.totalDirect_MyVisit}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {totalMeetingsData.totalScheduleVisit}
                                    </b>
                                  </td>
                                  <td>
                                    <b>{totalMeetingsData.totalVisits}</b>
                                  </td>
                                  {filterMeeting.value !== "all" &&
                                    filterMeeting.value !== "nomeetings" && (
                                      <td>
                                        <b>
                                          {totalMeetingsData.totalKilmtrs?.toFixed(
                                            2
                                          )}
                                          &nbsp;Kms
                                        </b>
                                      </td>
                                    )}
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="download-and-pagination">
                            <SbDownloadBtn onClickEffect={exportAllMeetings} />
                            {/* <div className="data-count-per-page">
                            {totalData === 0 ? 0 : pageDataFrom} -{" "}
                            {totalData === 0 ? 0 : pageDataTill} of {totalData}
                          </div> */}
                            {/* <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            // defaultValue={optionsRoleList[0].label}
                            options={optionsPageSizeList}
                            placeholder="Show Per Page"
                            value={optionsPageSizeList.value}
                            onInputChange={optionsPageSizeList.label}
                            onChange={(data) => setPageSize(data.value)}
                            isSearchable={true}
                            // isClearable={true}
                          /> */}
                          </div>
                        </div>
                        {/* USER TABLE END */}

                        {/* ADD MEETING MODEL START */}
                        {/* {show ? (
                          <Addmeetingmodel
                            show={show}
                            handleClose={handleClose}
                            getMeetings={getMeetings}
                            />
                          
                        ) : (
                          ""
                        )} */}
                        {/* ADD MEETING MODEL END */}

                        {/* PAGE CONTENT END*/}
                      </div>
                    </div>
                  ) : (
                    <>
                      {meetingdetails ? (
                        <Meetingdetails
                          meetingdetails={meetingdetails}
                          goBacktomeetings={goBacktomeetings}
                          toggleshift={toggleshift}
                          userID={userID}
                          meetingdetailsDate={meetingdetailsDate}
                        />
                      ) : (
                        ""
                      )}

                      {/* {viewonmap ? (
                        <TrackMeetings
                          viewonmap={viewonmap}
                          goBacktomeetings={goBacktomeetingsfromtrackuser}
                          toggleshift={toggleshift}
                          userID={userID}
                          meetingdetailsDate={meetingdetailsDate}
                        />
                      ) : (
                        ""
                      )} */}
                    </>
                  )}
                  {/* MAIN BODY END */}
                </div>
                <Footer />
              </>
            ) : (
              <>
                {show && (
                  <AddMeeting
                    show={show}
                    handleClose={handleClose}
                    getMeetings={getMeetings}
                  />
                )}

                {viewonmap && (
                  <TrackMeetings
                    viewonmap={viewonmap}
                    goBacktomeetings={goBacktomeetingsfromtrackuser}
                    toggleshift={toggleshift}
                    userID={userID}
                    meetingdetailsDate={meetingdetailsDate}
                  />
                )}
              </>
            )
          ) : (
            <>
              <AccessDenied />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Meetings;
