import React, { useEffect } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useState } from 'react';
// import * as HeaderData from '../../HeaderAuthentication';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

function ORModal(props) {

    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const cancelModale = () => {
        props.closeQrModal()
    }


    return (
        <div>
            <Modal show={props.openQrModal} onHide={props.closeQrModal} className="category-modal-div Qr-modal">
                <Row className='modale-header-div'>
                    <Col md={9} xs={9} className='cat-col'>
                    </Col>
                    <Col md={3} xs={3} className='cat-col' align="right">

                        <button onClick={cancelModale} className='bi bi-x close-popup'></button>
                    </Col>
                </Row>
                {/* <hr /> */}
                <div className='cat-col'>
                    <div className='qr-image'>
                        <img src="../../images/SalesBeeQr.svg" alt='' />
                    </div>
                    <div className='qr-image'>
                        <p>Scan to download MetricInfo App</p>
                    </div>
                    {/* <div className='qr-share'>
                        <img src="../../images/qrShare.png" alt='' />
                    </div> */}
                    <div className='store-imgs'>
                        <Link to="https://apps.apple.com/us/app/metricinfo/id6467395142" target='_blank'><img src="../../images/appstore.svg" alt='' height={52} /></Link>
                        <Link to="https://play.google.com/store/apps/details?id=com.lobotus.metricinfo" target='_blank'><img src="../../images/playstore.svg" alt='' height={52} /></Link>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default ORModal;
