import React, { useState, useEffect } from "react";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewClaimAttachmentmodel from "./ViewClaimAttachmentmodel";
import { exportExcel } from "../DownloadFile";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import SkeletonApproved from "../Skeleton Loading/SkeletonApproved";
import * as swal from "../Consturl/SwalAlert";
import { Col, Row } from "react-bootstrap";
import {
  SkeletonforClaimHead,
  SkeletonPendingClaimHead,
  SkeletonPendingTableData,
} from "../Skeleton Loading/SkeletonPendingClaim";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { SbDownloadBtn, SbUserwiseReportBtn } from "../SbButtons/SbAddBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

function ApprovedClaimAdmin(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [approvedClaimDeatils, setApprovedClaimDeatils] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [claimAttachmentmodel, setClaimAttachmentmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleCloseviewattachment = () => setClaimAttachmentmodel(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {
    fetchApprovedClaimData();
  }, [fromDate, toDate, PageSize, currentPage]);

  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS START
  const fetchApprovedClaimData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Claim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${
        props.claimUserId
      }&StatusIDList=43,47,48&SearchStatusType=Admin&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setApprovedClaimDeatils(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setApprovedClaimDeatils("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
        // setErrorMessage(error.message);
      });
  };
  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimDetailExcelExport?UserID=${
        props.claimUserId
      }&StatusIDList=43,47,48&SearchStatusType=Admin&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Approved-ClaimData.xlsx"
    );
  };

  const exportUserwiseReport = () => {
    exportExcel(
      `${baseURL}/api/Export/UserWiseClaimExcelExport?UserID=${
        props.claimUserId
      }&StatusID=48&SearchStatusType=Admin&FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "ApprovedClaim-UserwiseReport.xlsx"
    );
  };

  return (
    <div id="main-page">
      {props.approvedClaim ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {/* Filter and Header Section */}
            <div className="user-tables">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div
                      className="back-btn"
                      onClick={props.goBackToClaimfromApprovedPage}
                    >
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      Approved Claim by Admin
                    </h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div className="cliam-filter-section">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Col md={8}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <div
                                  style={{ padding: "0px 5px" }}
                                  className="input-group from-to-date-select"
                                >
                                  <span
                                    className="input-group-text"
                                    id="from-label"
                                  >
                                    From - To
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={fromDate}
                                      className="select from-date date-filter"
                                      id="date-range-picker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsRange
                                      startDate={fromDate}
                                      endDate={toDate}
                                      onChange={(date) =>
                                        setSelectSearchDrop(date)
                                      }
                                      maxDate={new Date()}
                                    />
                                  </span>
                                  <span>
                                    <i
                                      className="fa fa-calendar-o calander-icon"
                                      style={{ right: "15px" }}
                                    />
                                  </span>
                                </div>
                                <SbCancelBtn
                                  onClickEffect={clearSerachBySelect}
                                  btnName="Clear"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <SbUserwiseReportBtn
                                  onClickEffect={exportUserwiseReport}
                                  btnName="Userwise Report"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Filter and Header Section */}
            <div className="approvedclaim-table">
              {isLoading ? (
                <SkeletonforClaimHead />
              ) : (
                <div className="pendingapproval-records">
                  <div className="claim-user-details-img">
                    <img
                      className="pendingapproval-user-imgage"
                      src={profileimg}
                      alt="User Image"
                    />
                    <div className="pendingapproval-username-details">
                      <span>
                        <b>User Name :</b> &nbsp;{" "}
                        {approvedClaimDeatils[0]?.fullName}
                      </span>
                      <span>
                        <b>Email ID :</b> &nbsp;{" "}
                        {approvedClaimDeatils[0]?.email}
                      </span>
                      <span>
                        <b>Contact No.:</b> &nbsp;{" "}
                        {approvedClaimDeatils[0]?.countryCode}{" "}
                        {approvedClaimDeatils[0]?.contact}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="table-responsive approvedclaim-scroll"
                onScroll={(e) =>
                  handleScroll(
                    e,
                    approvedClaimDeatils,
                    totalData,
                    setLoading,
                    setPageSize,
                    PageSize
                  )
                }
              >
                {isLoading ? (
                  <SkeletonPendingTableData />
                ) : approvedClaimDeatils !== "" &&
                  approvedClaimDeatils.length > 0 ? (
                  approvedClaimDeatils?.map((data, i) => (
                    <table className="table attendance-list-table" key={i}>
                      <thead className="thead-light approved-claim-thead">
                        <tr>
                          <th>
                            {i + 1}. ClaimedDate :{" "}
                            {/* {moment(data?.claimDate).format("DD/MM/YYYY")} */}
                            {data?.claimDate}
                          </th>
                          <th>CheckIn : {data?.checkInTime}</th>
                          <th>CheckOut : {data?.checkOutTime}</th>
                          <th>HrsOnField : {data?.hrsOnField}</th>
                          <th>
                            Visits : {data?.noOfVisit} / KMs :{" "}
                            {data?.kMsTravelled}
                          </th>
                          <th>Status : {data?.byAdmin?.status}</th>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <th>Attachment</th>
                          <th>Applied </th>
                          <th>Approved </th>
                          <th>Remarks</th>
                          <th>Approved By</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="attendance-list-row-data">
                          <td>Local</td>
                          <td>
                            {" "}
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.localClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.localClaim?.amount}</td>
                          <td>
                            {data?.byAdmin?.approvedLocalClaimAmount ?? 0}
                          </td>
                          <td>{data?.localClaim?.remark ?? "NA"}</td>
                          <td>{data?.byAdmin?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Outstation By {data?.osClaim?.mot ?? "NA"}</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.osClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.osClaim?.amount}</td>
                          <td>{data?.byAdmin?.approvedOSClaimAmount ?? 0}</td>
                          <td>{data?.osClaim?.remark ?? "NA"}</td>
                          <td>{data?.byAdmin?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Food</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.foodClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.foodClaim?.amount}</td>
                          <td>{data?.byAdmin?.approvedFoodClaimAmount ?? 0}</td>
                          <td>{data?.foodClaim?.remark ?? "NA"}</td>
                          <td>{data?.byAdmin?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Hotel</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.hotelClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.hotelClaim?.amount}</td>
                          <td>
                            {" "}
                            {data?.byAdmin?.approvedHotelClaimAmount ?? 0}
                          </td>
                          <td>{data?.hotelClaim?.remark ?? "NA"}</td>
                          <td>{data?.byAdmin?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Others</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.othersClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.othersClaim?.amount}</td>
                          <td>
                            {data?.byAdmin?.approvedOthersClaimAmount ?? 0}
                          </td>
                          <td>{data?.othersClaim?.remark ?? "NA"}</td>
                          <td>{data?.byAdmin?.fullName}</td>
                        </tr>
                        <tr className="pendingapproval-row-data total-value">
                          <td>
                            {" "}
                            &nbsp; &nbsp;
                            <b>
                              Approved Date :{" "}
                              {/* {moment(data?.byAdmin?.date).format("DD/MM/YYYY")} */}
                              {data?.byAdmin?.date}
                            </b>
                          </td>
                          <td>
                            <b>TOTAL</b>
                          </td>
                          <td>
                            <b>{data?.totalAmount}</b>
                          </td>
                          <td>
                            <b>{data?.byAdmin?.approvedTotalClaimAmount}</b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  ))
                ) : (
                  <div>
                    <span className="text-center" style={{ width: "100%" }}>
                      --- NO DATA FOUND ---
                    </span>
                  </div>
                )}
                {loading && <OnScrollLoading />}
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <SbDownloadBtn onClickEffect={exportExcelClaim} />
            </div>

            {/* PAGE CONTENT END*/}

            {/* MAIN BODY END */}

            {claimAttachmentmodel ? (
              <ViewClaimAttachmentmodel
                claimAttachmentmodel={claimAttachmentmodel}
                handleCloseviewattachment={handleCloseviewattachment}
                attachmentUrl={attachmentUrl}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ApprovedClaimAdmin;
