import React, { useState } from 'react'
import Sidenavbar from './Sidenavbar'
import Topbar from './Topbar'

function CommonNavFile({setToggleshift, toggleshift}) {

const [menuCollapse, setMenuCollapse] = useState(false);
 
  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  return (
    <>
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
    </>
  )
}

export default CommonNavFile;
