import React from "react";
import Skeleton from "react-loading-skeleton";

export const SkeletonDepartment = () => {
  return (
    <tr className="meeting-purpose-row-data">
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
    </tr>
  );
};
