import React, { useState, useCallback, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { React_Google_API_Key } from "../GoogleApiKey";

// const libraries = process.env.REACT_APP_GOOGLE_MAP_LIBRARIES.split(",");

const defaultLatLng = {
  lat: 28.6448,
  lng: 77.216721,
};

function GoogleMapAddressModel(props) {
  const [markerPosition, setMarkerPosition] = useState(defaultLatLng);
  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);

  const containerStyle = {
    width: "100%",
    height: "370px",
  };

  const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `260px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `0px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `12px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    top: "10px",
    right: "60px",
  };

  const onLoad = useCallback((map) => (mapRef.current = map), []);
  const onSearchBoxLoad = useCallback(
    (ref) => (searchBoxRef.current = ref),
    []
  );

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    console.log("Place", places);
    if (places.length === 0) return;
    const place = places[0];

    const location = place.geometry.location;
    setMarkerPosition({
      lat: location.lat(),
      lng: location.lng(),
    });
    mapRef.current.panTo(location);
  };

  const onMarkerDragEnd = (event) => {
    console.log("Mark-Place", event);

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
  };

  console.log("markerPosition", markerPosition);

  return (
    <div>
      <div>
        <Modal
          show={props.googleMapsModel}
          onHide={props.openGoogleMaps}
          className="main_model google-maps-model"
        >
          <div className="add-user-heading">
            <h6 className="add-user-head">GOOGLE MAPS</h6>

            <button
              type="button"
              className="google-address-ok"
              onClick={props.addressFromGoogle}
              data-bs-dismiss="modal"
            >
              OK
            </button>
            <i className="fa fa-times" onClick={props.openGoogleMaps} />
          </div>
          <div className="google-map-box">
            {/* <h5>GOOGLE MAP VIEW</h5> */}
            <LoadScript
              googleMapsApiKey={React_Google_API_Key}
              libraries={["places"]}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={markerPosition}
                zoom={12}
                clickableIcons={true}
                onLoad={onLoad}
              >
                <>
                  <StandaloneSearchBox
                    onLoad={onSearchBoxLoad}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <input
                      type="text"
                      placeholder={
                        props.address !== "" ? props.address : "Search..."
                      }
                      style={inputStyle}
                      // value={props.searchVal}
                      // onChange={props.searchLocation}
                    />
                  </StandaloneSearchBox>
                  <Marker
                    position={markerPosition}
                    clickable={true}
                    draggable={true}
                    onDragEnd={onMarkerDragEnd}
                  />
                </>
              </GoogleMap>
            </LoadScript>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default GoogleMapAddressModel;
