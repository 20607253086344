import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Claims.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AccessDenied from "../../AccessDenied";
import * as swal from "../Consturl/SwalAlert";
import { useNavigate } from "react-router-dom";
import ProductFilterCategoryTable from "./ProductFilterCategoryTable";
import ProductFilterSubCategoryTable from "./ProductFilterSubCategoryTable";
import Footer from "../Footer";

function ProductFilter() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  var twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
  const loggedInRole = loginDetails?.desingation?.role?.roleID;

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const [activeTab, setActiveTab] = useState('Category');

  const handleTabSelect = (key) => {
    // Add your custom logic here when a tab is clicked
    setActiveTab(key);
  };
  
  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="claims-table">
                  <Tabs
                    defaultActiveKey="Category"
                    id="uncontrolled-tab-example"
                    className="local-claim-nav-tab"
                    onSelect={handleTabSelect}
                  >
                    <Tab
                      eventKey="back"
                      title={
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                      }
                    >
                      Back
                    </Tab>
                    {/* <Tab title="PRODUCT FILTER" className="local-claim-head">
                      PRODUCT FILTER
                    </Tab> */}
                    <Tab eventKey="Category" title="Category" >
                      <ProductFilterCategoryTable />
                    </Tab>
                      <Tab eventKey="Stage" title="Stage" >
                       {activeTab == "Stage" && <ProductFilterSubCategoryTable /> }
                      </Tab>
                  </Tabs>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default ProductFilter;



