import React, { useEffect, useState, useRef } from "react";
import "../../styles/AddUserDetails.scss";
import "../../styles/Products.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import SalesBee from "../../images/MatricLogo.svg";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import { SBSaveUpdateBtn, SbAddCustomBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPostProduct,
  loadProdCategoryDrop,
  loadProdSubCategory,
  loadUpdateProduct,
  loadUploadProductImages,
} from "../../redux/action";
import { UOMList } from "../DropdownsData";
import AddPakagingModel from "./AddPakagingModel";

export default function AddProduct(props) {
  let productId = props.productId;

  let dispatch = useDispatch();
  const { singleProduct } = useSelector((state) => state.data);

  const { prodCategoryDrop } = useSelector((state) => state.data);
  const { prodSubcategory } = useSelector((state) => state.data);

  const [localImage, setLocalImage] = useState("");
  const [categoryData, setCategoryData] = useState({ value: "", label: "" });
  const [subCategoryData, setSubCategoryData] = useState({
    value: "",
    label: "",
  });
  const [uomData, setUomData] = useState({ value: "", label: "" });
  const [productStatus, setProductStatus] = useState(true);
  const [localGallery, setLocalGallery] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [openUomInput, setOpenUomInput] = useState(false);
  const [addPakagingModel, setAddPakagingModel] = useState(false);

  const [imgFile, setImgFile] = useState("");

  const hiddenFileInput = useRef(null);
  const multipleFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  const uploadMultiple = (e) => {
    e.preventDefault();
    multipleFileInput.current.click();
  };

  useEffect(() => {
    dispatch(loadProdCategoryDrop());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productId) {
      if (Object.keys(singleProduct).length !== 0) {
        setProductdata(singleProduct);
        let galleryArray = [];
        if (singleProduct?.galaryImage) {
          singleProduct?.galaryImage.split(",").forEach((galimg) => {
            galleryArray.push(galimg);
            // fetch(galimg)
            // .then((response) => response.blob())
            // .then((blob) => {
            //   const fileName = galimg.substring(galimg.lastIndexOf('/') + 1);
            //   const file = new File([blob], fileName, {
            //     lastModified: new Date(),
            //     type: blob.type,
            //   });
            //   galleryArray.push(file);
            // });
          });
        }
        setGalleryImages(galleryArray);
        if (singleProduct.status === 1) {
          setProductStatus(true);
        } else {
          setProductStatus(false);
        }
        prodCategoryDrop.forEach((category) => {
          if (category.value === singleProduct?.category?.categoryID) {
            setCategoryData(category);
            dispatch(loadProdSubCategory(singleProduct?.category?.categoryID));
          }
        });
        const hasValue = UOMList.some(
          (item) => item.label === singleProduct?.unitOfMeasurement
        );
        UOMList.forEach((uom) => {
          if (singleProduct?.unitOfMeasurement) {
            if (hasValue) {
              if (uom.label === singleProduct?.unitOfMeasurement) {
                setUomData(uom);
              }
            } else if (!hasValue) {
              setOpenUomInput(true);
              setUomData({ value: 5, label: "Others" });
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, singleProduct]);

  useEffect(() => {
    if (Object.keys(singleProduct).length !== 0) {
      prodSubcategory.forEach((subCat) => {
        if (subCat.value === singleProduct?.subCategory?.categoryID) {
          setSubCategoryData(subCat);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodSubcategory]);

  const [productData, setProductdata] = useState({
    productID: 0,
    subscriptionID: "",
    category: {
      categoryID: "",
      name: "",
    },
    subCategory: {
      categoryID: "",
      name: "",
    },
    productCode: "",
    productName: "",
    description: "",
    instruction: "",
    image: "",
    images: [""],
    galaryImage: "",
    galaryImages: [""],
    color: "",
    shape: "",
    brand: "",
    size: "",
    height: "",
    width: "",
    length: "",
    weight: "",
    mrp: "",
    price: "",
    addedByID: "",
    unitOfMeasurement: "",
    validity: "",
    addedByUserFullName: "",
    addedOn: "",
    updatedByID: "",
    updatedByUserFullName: "",
    updatedOn: "",
    status: 1,
  });

  const {
    productName,
    productCode,
    color,
    shape,
    brand,
    unitOfMeasurement,
    size,
    height,
    width,
    length,
    weight,
    mrp,
    price,
    description,
    instruction,
  } = productData;

  const handleProductInput = (e) => {
    const { name, value } = e.target;
    setProductdata({ ...productData, [name]: value });
  };

  const handleDropDown = (data, type) => {
    let list = productData;
    if (type === "category") {
      dispatch(loadProdSubCategory(data.value));
      setCategoryData(data);
      setSubCategoryData({ value: "", label: "" });
      list.category.categoryID = parseInt(data.value);
      list.category.name = data.label;
    } else if (type === "subCategory") {
      setSubCategoryData(data);
      list.subCategory.categoryID = parseInt(data.value);
      list.subCategory.name = data.label;
    } else if (type === "uom") {
      if (data.label !== "Others") {
        setOpenUomInput(false);
        setUomData(data);
        list.unitOfMeasurement = data.label;
      } else if (data.label === "Others") {
        setUomData(data);
        list.unitOfMeasurement = "";
        setOpenUomInput(true);
      }
    }
    setProductdata(list);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };
  const deleteUploadLogo = () => {
    setLocalImage("");
  };

  const [selectLength, setSelectLength] = useState(false);
  const handleChangeMultipleImg = (event) => {
    if (event) {
      let images = [...localGallery];
      const fileUploaded = Array.from(event.target.files);
      if (fileUploaded.length + galleryImages.length + images.length > 6) {
        setSelectLength(true);
      } else {
        setSelectLength(false);
        if (images.length === 0) {
          fileUploaded.forEach((file) => {
            images.push(file);
          });
        } else {
          let filterImages = fileUploaded.filter((file) =>
            images.every((files) => !files.name.includes(file.name))
          );
          if (filterImages.length !== 0) {
            filterImages.forEach((file) => {
              images.push(file);
            });
          }
        }
      }
      setLocalGallery(images);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    let images = [...localGallery];
    const fileUploaded = Array.from(files);
    if (fileUploaded.length + galleryImages.length + images.length > 6) {
      setSelectLength(true);
    } else {
      setSelectLength(false);
      if (images.length === 0) {
        fileUploaded.forEach((file) => {
          images.push(file);
        });
      } else {
        let filterImages = fileUploaded.filter((file) =>
          images.every((files) => !files.name.includes(file.name))
        );
        if (filterImages.length !== 0) {
          filterImages.forEach((file) => {
            images.push(file);
          });
        }
      }
    }
    setLocalGallery(images);
  };

  const deleteImage = (name) => {
    setSelectLength(false);
    setLocalGallery(localGallery.filter((data) => data.name !== name.name));
  };

  const deleteGalImage = (name) => {
    setSelectLength(false);
    setGalleryImages(galleryImages.filter((data) => data !== name));
  };

  const selectStatus = (type) => {
    if (type === "true") {
      productData.status = 1;
      setProductStatus(true);
    } else if (type === "false") {
      productData.status = 0;
      setProductStatus(false);
    }
  };

  const closeModel = () => {
    props.setProductId("");
    setLocalImage("");
    setImgFile("");
    setLocalGallery([]);
    props.handleClose();
    props.fetchProductData();
  };

  const [submitError, setSubmitError] = useState(false);
  const addProductData = (e) => {
    e.preventDefault();
    if (
      !productName ||
      !productCode ||
      !productData.category.categoryID ||
      !productData.subCategory.categoryID ||
      !unitOfMeasurement ||
      !mrp ||
      !description ||
      !instruction ||
      !price
    ) {
      setSubmitError(true);
    } else {
      if (imgFile !== "" || localGallery.length !== 0) {
        const formData = new FormData();
        if (imgFile) {
          formData.append("Files", imgFile);
        }
        if (localGallery.length !== 0) {
          localGallery.forEach((imgfile) => {
            formData.append("Files", imgfile);
          });
        }
        formData.append("Directory", "Products");
        formData.append("FileNamePrefix", productName);
        let requestOption = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.salesbeeImgAuth,
          body: formData,
        };
        dispatch(
          loadUploadProductImages(
            requestOption,
            imgFile,
            productData,
            productId,
            closeModel,
            galleryImages
          )
        );
      } else {
        if (galleryImages.length !== 0) {
          productData.galaryImage = "";
          productData.galaryImages = galleryImages;
        }
        let postProduct = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.postTravelizeAuth,
          body: JSON.stringify(productData),
        };
        if (productId) {
          dispatch(loadUpdateProduct(postProduct, closeModel));
        } else {
          dispatch(loadPostProduct(postProduct, closeModel));
        }
      }
    }
  };

  const addPackagingModel = () => {
    setAddPakagingModel(!addPakagingModel);
  };
  return (
    <div>
      <div show={props.show} onHide={closeModel}>
        <div className="add-user-details-container">
          <div className="add-user-details-first-div">
            <div className="machines-logo-div">
              <div className="logotext">
                <div className="logo profile-mini">
                  <Link className="travelize_logo" to="/dashboard">
                    <img
                      src={SalesBee}
                      alt="product-title-img"
                      className="salesbee-logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="add-user-details-menus"
              // onClick={toggleMachineDetails}
            >
              <div
                className="add-user-details-menu-numbers active"
                //   ${
                // activeMenu === 1 ? "active" : "inactive"
                //   }`}
              >
                <p className="add-user-details-menu-number-one">
                  <span>01</span>
                </p>
                <div className="add-user-details-menu-name">
                  {" "}
                  {props.productId ? "Update Product" : "Add Product"}
                </div>
              </div>
            </div>
          </div>
          <div className="add-user-details-second-div">
            <div className="add-user-details-second-div-header">
              <div className="back-btn" onClick={props.handleClose}>
                <img
                  src="../../../../images/DefaultImages/back-btn-arrow.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>{props.productId ? "Update Product" : "Add Product"}</h4>
                <p>
                  Please enter below infomation and proceed to the next step.
                </p>
              </div>
            </div>

            <div style={{ position: " relative" }} className="amc-single-image">
              <form>
                <div
                  className="container add-edit-product"
                  style={{ marginLeft: "20px" }}
                >
                  <div className="product-inputs">
                    <Row>
                      <Col md={4}>
                        <div className="d-flex">
                          <div className="main-product-image">
                            {!localImage &&
                            Object.keys(singleProduct).length !== 0 &&
                            singleProduct?.image ? (
                              <img
                                className="product-images"
                                src={`${baseURL}/${singleProduct?.image}`}
                                alt="Product Images"
                              />
                            ) : (
                              <img
                                className="product-images"
                                src={localImage ? localImage : productimg1}
                                alt="Product Images"
                              />
                            )}
                            <div>
                              <button
                                type="button"
                                className="btn upload-logo"
                                onClick={uploadClick}
                              >
                                <i className="bi bi-camera-fill" />
                              </button>
                              {localImage && (
                                <div
                                  className="delete-upload-logo"
                                  onClick={deleteUploadLogo}
                                >
                                  <i className="fa fa-trash-o"></i>
                                </div>
                              )}
                              <input
                                id="getFile"
                                type="file"
                                name="filetobase64"
                                accept="image/*"
                                className="btn-primary upload"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </div>
                        <p style={{ fontSize: "10px", textAlign: "center" }}>
                          Supports Only JPG, JPEG, PNG
                        </p>
                      </Col>
                      <Col md={8}>
                        <div className="mb-3">
                          <FloatingLabel
                            label="Product Name"
                            className={`${
                              productName
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="text"
                              className="form-control form add-product-input"
                              placeholder="Product Name"
                              name="productName"
                              // readOnly={true}
                              value={productName || ""}
                              onChange={handleProductInput}
                            />
                          </FloatingLabel>
                          {submitError && !productName && (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              Please Enter Product Name
                            </small>
                          )}
                        </div>
                        <div className="mb-3">
                          <FloatingLabel
                            label="Product Code"
                            className={`${
                              productCode
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <input
                              type="text"
                              className="form-control form add-product-input"
                              placeholder="Product Code"
                              name="productCode"
                              value={productCode || ""}
                              onChange={handleProductInput}
                            />
                          </FloatingLabel>
                          {submitError && !productCode && (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              Please Enter Product Code
                            </small>
                          )}
                        </div>
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Category"
                            className={`${
                              categoryData.value !== ""
                                ? "float-visible-select"
                                : "float-hidden float-select"
                            }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              // defaultValue={designationList[null]}
                              name="categoryID"
                              options={prodCategoryDrop}
                              placeholder="Select Category"
                              value={categoryData.value ? categoryData : ""}
                              onInputChange={prodCategoryDrop.label}
                              onChange={(data) =>
                                handleDropDown(data, "category")
                              }
                              isSearchable={true}
                            />
                          </FloatingLabel>
                          {submitError && !productData.category.categoryID && (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              Please select Category
                            </small>
                          )}
                        </div>
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Sub Category"
                            className={`${
                              subCategoryData.value !== ""
                                ? "float-visible-select"
                                : "float-hidden float-select"
                            }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              // defaultValue={designationList[null]}
                              options={prodSubcategory}
                              name=""
                              placeholder="Sub Category"
                              value={
                                subCategoryData.value ? subCategoryData : ""
                              }
                              onInputChange={prodSubcategory.label}
                              onChange={(data) =>
                                handleDropDown(data, "subCategory")
                              }
                              isSearchable={true}
                              isDisabled={!productData.category.categoryID}
                            />
                          </FloatingLabel>
                          {submitError &&
                            productData.category.categoryID &&
                            !productData.subCategory.categoryID && (
                              <small
                                id={`Error`}
                                className="form-text text-muted "
                              >
                                Please select Category
                              </small>
                            )}
                        </div>

                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Units"
                            className={`${
                              uomData.value !== ""
                                ? "float-visible-select"
                                : "float-hidden float-select"
                            }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={UOMList}
                              placeholder="UOM"
                              value={uomData.value ? uomData : UOMList.value}
                              onInputChange={UOMList.label}
                              onChange={(data) => handleDropDown(data, "uom")}
                              isSearchable={true}
                            />
                          </FloatingLabel>
                          {submitError &&
                            uomData.label !== "Others" &&
                            !unitOfMeasurement && (
                              <small
                                id={`Error`}
                                className="form-text text-muted "
                              >
                                Please Enter Uom
                              </small>
                            )}
                        </div>

                        {openUomInput && (
                          <div className="mb-3">
                            <div style={{ width: "100%" }}>
                              <FloatingLabel
                                label="Enter UOM"
                                className={`${
                                  unitOfMeasurement
                                    ? "float-input-visible"
                                    : "float-hidden float-input"
                                }`}
                              >
                                <input
                                  type="text"
                                  className="form-control form add-product-input"
                                  placeholder="Enter UOM"
                                  name="unitOfMeasurement"
                                  value={unitOfMeasurement || ""}
                                  onChange={handleProductInput}
                                />
                              </FloatingLabel>
                            </div>
                            {submitError &&
                              uomData.label === "Others" &&
                              !unitOfMeasurement && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  Please Enter Uom
                                </small>
                              )}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3 d-flex">
                          <div style={{ marginRight: "10px", width: "100%" }}>
                            <FloatingLabel
                              label="Color"
                              className={`${
                                color
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Color"
                                name="color"
                                value={color || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                          <div className="ml-1" style={{ width: "100%" }}>
                            <FloatingLabel
                              label="Shape"
                              className={`${
                                shape
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Shape"
                                name="shape"
                                value={shape || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                        </div>

                        <div className="mb-3 d-flex">
                          <div style={{ marginRight: "10px", width: "100%" }}>
                            <FloatingLabel
                              label="Brand"
                              className={`${
                                brand
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Brand"
                                name="brand"
                                value={brand || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                          <div className="ml-1" style={{ width: "100%" }}>
                            <FloatingLabel
                              label="Size"
                              className={`${
                                size
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Size"
                                name="size"
                                value={size || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                        </div>

                        <div className="mb-3 d-flex">
                          <div style={{ marginRight: "10px", width: "100%" }}>
                            <FloatingLabel
                              label="Height"
                              className={`${
                                height
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Height"
                                name="height"
                                value={height || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                          <div style={{ marginRight: "10px", width: "100%" }}>
                            <FloatingLabel
                              label="Width"
                              className={`${
                                width
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Width"
                                name="width"
                                value={width || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                          <div className="ml-1" style={{ width: "100%" }}>
                            <FloatingLabel
                              label="Length"
                              className={`${
                                length
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Length"
                                name="length"
                                value={length || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                        </div>

                        <div className="mb-3 d-flex">
                          <div style={{ marginRight: "10px", width: "100%" }}>
                            <FloatingLabel
                              label="Weight"
                              className={`${
                                weight
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Weight"
                                name="weight"
                                value={weight || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                          </div>
                          <div style={{ marginRight: "10px", width: "100%" }}>
                            <FloatingLabel
                              label="MRP"
                              className={`${
                                mrp
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="MRP"
                                name="mrp"
                                value={mrp || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                            {submitError && !mrp && (
                              <small
                                id={`Error`}
                                className="form-text text-muted "
                              >
                                Please Enter MRP Rate
                              </small>
                            )}
                          </div>
                          <div className="ml-1" style={{ width: "100%" }}>
                            <FloatingLabel
                              label="Price"
                              className={`${
                                price
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-product-input"
                                placeholder="Price"
                                name="price"
                                value={price || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                            {submitError && !price && (
                              <small
                                id={`Error`}
                                className="form-text text-muted "
                              >
                                Please Enter Price Rate
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="mb-3 d-flex">
                          <div style={{ width: "100%" }}>
                            <FloatingLabel
                              label="Description"
                              className={`${
                                description
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <textarea
                                rows="3"
                                cols="20"
                                name="description"
                                wrap="hard"
                                className="form-control form-textarea add-product-input"
                                id="descriptionArea"
                                placeholder="Description"
                                value={description || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                            {submitError && !description && (
                              <small
                                id={`Error`}
                                className="form-text text-muted "
                              >
                                Please Enter Description
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <div style={{ width: "100%" }}>
                            <FloatingLabel
                              label="Instruction"
                              className={`${
                                instruction
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <textarea
                                rows="3"
                                cols="20"
                                name="instruction"
                                wrap="hard"
                                className="form-control form-textarea add-product-input"
                                id="descriptionArea"
                                placeholder="Instruction"
                                value={instruction || ""}
                                onChange={handleProductInput}
                              />
                            </FloatingLabel>
                            {submitError && !instruction && (
                              <small
                                id={`Error`}
                                className="form-text text-muted "
                              >
                                Please Enter Instruction
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="mb-3">
                          <div>
                            <button
                              type="button"
                              className="btn btn-add-product-img"
                            >
                              <span
                                style={{
                                  color: "#04A96D",
                                  fontSize: "13px",
                                  fontWeight: "700",
                                }}
                              >
                                <div
                                  className="d-flex justify-content-center"
                                  onDragOver={(e) => e.preventDefault()}
                                  onDrop={handleDrop}
                                >
                                  <>
                                    {galleryImages.length !== 0 && (
                                      <>
                                        {galleryImages.map((data, i) => (
                                          <div
                                            className="product-image-box"
                                            key={i}
                                          >
                                            <img
                                              className="product-images"
                                              src={`${baseURL}/${data}`}
                                              alt="Spare Images"
                                            />
                                            <div
                                              className="delete-image"
                                              onClick={() =>
                                                deleteGalImage(data)
                                              }
                                            >
                                              <i
                                                className="fa fa-times-circle delete-icon"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </>
                                  <>
                                    {localGallery &&
                                      localGallery.map((data, i) => (
                                        <div
                                          className="product-image-box"
                                          key={i}
                                        >
                                          <img
                                            className="product-images"
                                            src={
                                              localGallery
                                                ? URL.createObjectURL(data)
                                                : productimg1
                                            }
                                            alt="Spare Images"
                                          />
                                          <div
                                            className="delete-image"
                                            onClick={() => deleteImage(data)}
                                          >
                                            <i
                                              className="fa fa-times-circle delete-icon"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                </div>
                                {(galleryImages.length !== 0 ||
                                  localGallery.length !== 0) &&
                                  galleryImages.length + localGallery.length <
                                    6 && (
                                    <div className="drop-btn">
                                      <p>You can Upload up to 6 Images</p>
                                      <p>Supports Only JPG, JPEG, PNG</p>
                                      <SbCancelBtn
                                        btnName="Browse"
                                        onClickEffect={uploadMultiple}
                                      />
                                    </div>
                                  )}
                                {galleryImages.length === 0 &&
                                  localGallery.length === 0 && (
                                    <div
                                      className="drop-area"
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={handleDrop}
                                    >
                                      <h6>Upload Product Images</h6>
                                      <br />
                                      <img
                                        src="../../../images/DefaultImages/UploadImages.svg"
                                        alt=""
                                      />
                                      <p>Drag and drop images here</p>
                                      <p>OR</p>
                                      <SbCancelBtn
                                        btnName="Browse"
                                        onClickEffect={uploadMultiple}
                                      />
                                      <p style={{ fontSize: "11px" }}>
                                        Supports Only JPG, JPEG, PNG
                                      </p>
                                    </div>
                                  )}
                              </span>
                            </button>
                            <input
                              id="getFile"
                              type="file"
                              multiple
                              name="filetobase64"
                              accept="image/*"
                              className="btn-primary upload"
                              ref={multipleFileInput}
                              onChange={handleChangeMultipleImg}
                              style={{ display: "none" }}
                            />
                          </div>
                          {selectLength && (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              Please Select maximum 6 images
                            </small>
                          )}
                        </div>

                        {productId && (
                          <div className="mt-3 dflex-j-between">
                            <div className="enable-status d-flex">
                              <h6>Enable</h6>
                              <span className="enable-radio-client d-flex">
                                <div className="form-check enable-radio-status">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    onChange={() => selectStatus("true")}
                                    checked={productStatus}
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check enable-radio-status">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="htmlFor"
                                    onChange={() => selectStatus("false")}
                                    checked={!productStatus}
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                              </span>
                            </div>
                            <div className="add-packaging-btn">
                              <SbAddCustomBtn
                                btnName="Add Packaging"
                                onClickEffect={addPackagingModel}
                              />
                            </div>
                          </div>
                        )}
                        {/* </div> */}
                        <div className="add-page-save-cancel">
                          <SBSaveUpdateBtn
                            btnName={productId ? "Update" : "Save"}
                            onClickEffect={addProductData}
                          />
                          <SbCancelBtn
                            btnName="Cancel"
                            onClickEffect={closeModel}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
              {addPakagingModel ? (
                <AddPakagingModel
                  addPakagingModel={addPakagingModel}
                  addPackagingModel={addPackagingModel}
                  productData={productData}
                  closeModel={closeModel}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
