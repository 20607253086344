import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/PaymentDetails.scss";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import { optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";

export default function PaymentDetails() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="paymentdetails-table">
                  <div className="seleting-menus">
                    <div className="page-title">PAYMENT DETAILS</div>

                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        // options={optionsPageSizeList}
                        placeholder="Select"
                        // value={optionsPageSizeList.value}
                        // onInputChange={optionsPageSizeList.label}
                        // onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-clear-track"
                        // onClick={clearSerachBySelect}
                      >
                        <i className="fa fa-eraser" />
                        <div>Clear</div>
                      </button>

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Show Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-add-paymentdetails"
                        onClick={() => setShow(!show)}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-paperclip" /> &nbsp;
                          <h6>Upload</h6>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive paymentdetails-scrollbar">
                    <table className="table paymentdetails-table-data">
                      <thead className="thead-light paymentdetails-thead">
                        <tr>
                          <th>INVOICE NUMBER</th>
                          <th>PAYMENT DATE</th>
                          <th>AMOUNT</th>
                          <th>DUE DATE</th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                          </>
                        ) : paymentdetailss !== "" ? (
                          paymentdetailss.map((data, i) => ( */}
                        <tr>
                          <td>PF0024568</td>
                          <td>22/02/2023</td>
                          <td>
                            <i className="fa fa-rupee" />
                            &nbsp; 2,40,000
                          </td>
                          <td>22/03/2022</td>
                          <td>
                            <p className="status paid">Paid</p>
                          </td>
                          <td>
                            <button
                              // onClick={() => {
                              //   setEditDepartmentModel(!editDepartmentModel);
                              //   setDeptId(JSON.parse(data.id));
                              // }}
                              className="btn download-link"
                            >
                              <i className="fa fa-download" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>PF0036589</td>
                          <td>15/02/2023</td>
                          <td>
                            <i className="fa fa-rupee" />
                            &nbsp; 1,40,000
                          </td>
                          <td>21/03/2022</td>
                          <td>
                            <p className="status paid">Paid</p>
                          </td>
                          <td>
                            <button
                              // onClick={() => {
                              //   setEditDepartmentModel(!editDepartmentModel);
                              //   setDeptId(JSON.parse(data.id));
                              // }}
                              className="btn download-link"
                            >
                              <i className="fa fa-download" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>PF0045856</td>
                          <td>19/03/2023</td>
                          <td>
                            <i className="fa fa-rupee" />
                            &nbsp; 3,40,000
                          </td>
                          <td>25/04/2022</td>
                          <td>
                            <p className="status cancelled">Cancelled</p>
                          </td>
                          <td>
                            <button
                              // onClick={() => {
                              //   setEditDepartmentModel(!editDepartmentModel);
                              //   setDeptId(JSON.parse(data.id));
                              // }}
                              className="btn download-link"
                            >
                              <i className="fa fa-download" />
                            </button>
                          </td>
                        </tr>
                        {/* ))
                        ) : (
                          <tr> --- NO DATA FOUND --- </tr>
                        )} */}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                      // onClick={exportExcelActiivity}
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
