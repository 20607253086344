import React from 'react';
import Chart from 'react-apexcharts';

const RadialChartPage = (props) => {
  let themeColor = localStorage.getItem("theme");
  let colorArray = []
  if(themeColor == "dark"){
    colorArray = ["#04a96d", "#24e5a3", "#fcb939"]
  } else {
    colorArray = ["#5842bc", "#7e69db", "#ff847f"]
  }

  const chartOptions = {
    chart: {
      type: 'radialBar',
      height: "auto",
    //   width: '100%',
    },
    plotOptions: {
      radialBar: {
        
        dataLabels: {
            enabled: true,
          total: {
            show: true,
            label: 'Total',
            // value: {
                formatter: function (val, opts) {
                    let total = 0;
                    val.config.series.map(value => {
                        total += value;
                    })
                    return total;
                  },
            //   },
          },
          name: {
            fontSize: "14px",
          },
          value: {
            fontSize: "15px",
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    },
    stroke: {
        lineCap: 'round',
      },
    colors: colorArray,
    labels: ["Direct Visit", "Scheduled Visit", "My Visit"],
    legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '12px',
        offsetY: 0,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          width: 10,
          height: 10,
          radius: 10,
        },
      },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: '100%',
          },
        },
      },
    ],
  };

  return (
    <Chart options={chartOptions} series={props.series} type="radialBar" width={"100%"}
    height={340} />
  );
};

export default RadialChartPage;
