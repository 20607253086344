import React, { useState } from "react";
import { Modal, Form, FloatingLabel } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddCategory(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  // console.log("SUBSCRIPTION:", resPassData.subscriptionId);

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const addcategory = () => {
    if (categoryName !== "" && categoryName !== null) {
      const categoryDetails = {
        categoryID: 0,
        subscriptionID: resPassData.subscriptionId,
        name: categoryName,
        description: categoryDescription,
        addedByID: resPassData.userId,
        // addedByUserFullName: "string",
        parentCategoryID: 0,
        status: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(categoryDetails),
      };
      fetch(`${baseURL}/api/Category/AddCategory`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchProductCategory();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Enter Category Name",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.addCategory}
        onHide={props.handleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">ADD CATEGORY</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit">
            <div className="product-inputs">
              <div className="mb-3">
                <FloatingLabel
                  label="Category Name"
                  className={`${
                    categoryName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <input
                    type="text"
                    className="form-control form add-input"
                    placeholder="Category Name"
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </FloatingLabel>
                {fieldReq && categoryName == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <FloatingLabel
                  label="Description"
                  className={`${
                    categoryDescription
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    as="textarea"
                    rows="2"
                    cols="20"
                    name="usrtxt"
                    wrap="hard"
                    className="form-control form form-textarea add-input-area"
                    id="addressArea"
                    placeholder="Description"
                    required
                    // value={location}
                    onChange={(e) => setCategoryDescription(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={addcategory} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
