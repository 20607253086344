import React, { useEffect, useRef, useState } from 'react'
import Sidenavbar from '../../Sidenavbar';
import Topbar from '../../Topbar';
import { Col, Row } from 'react-bootstrap';
import '../../../styles/category.scss';
import '../../../styles/trainChatBot.scss';
import { useNavigate } from 'react-router-dom';
import SbCancelBtn from '../../SbButtons/SbCancelBtn';
import * as swal from "../../Consturl/SwalAlert";
import { SBSaveUpdateBtn } from '../../SbButtons/SbAddBtn';
import Footer from '../../Footer';

function TrainChatBot() {

    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };


    // Page navigation
    const Navigate = useNavigate();
    const goToBackPage = () => {
        Navigate(-1);
    };
    const viewPdfs = () => {
        Navigate('/settings/pdfFiles');
    };
    // page navigation

    const hiddenFileInput = useRef(null);

    const uploadClick = () => {
        hiddenFileInput.current.click();
    };

    const [localImage, setLocalImage] = useState(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files[0];
        setLocalImage(files);
    };

    const handleChange = (event) => {
        const file = event.target.files[0];
        console.log(file, "files")
        setLocalImage(file);
    };

    const uploadPdf = () => {
        const formData = new FormData();
        formData.append("trianfile", localImage);
        formData.append("sbn_id", resPassData.subscriptionId);
        var postRequest = {
            method: "POST",
            mode: "cors",
            body: formData,
        };
        fetch(`https://chatbot.metricinfo.com/dashboard/upload_file`, postRequest)
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status == 100) {
                    swal.showAlert(
                        "Success",
                        "File uploaded successfully",
                        "success"
                    );
                } else {
                    swal.showAlert(
                        "Fail",
                        "Something went wrong...!",
                        "warning"
                    );
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div id="main-page">

            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            SIDEBAR END

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>

                    <div className="page-content">
                        <Row className='search-row'>
                            <Col md={3} className='cat-col'>
                                <div className='page-header-text-div'>
                                    <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                    <h6 className='page-header-text' >Train ChatBot</h6>
                                </div>
                            </Col>
                            <Col md={9} className='cat-col'>
                                <div className="search-bar-header" style={{ float: "right" }}>
                                    <div className="page-top-bar-dropdowns page-top-Filters">
                                        <SBSaveUpdateBtn btnName="Previous Uploads" onClickEffect={viewPdfs} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="upload-pdf-chatbot">
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-add-product-img"
                                >
                                    <span
                                        style={{
                                            color: "#04A96D",
                                            fontSize: "13px",
                                            fontWeight: "700",
                                        }} >
                                        <div className="d-flex justify-content-center"
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={handleDrop}
                                        >

                                        </div>
                                        {localImage &&
                                            <div className="drop-btn">
                                                <iframe
                                                    src={localImage ? URL.createObjectURL(localImage) : ""}
                                                    title="PDF Preview"
                                                    width="100%"
                                                    height="500px"
                                                ></iframe>
                                            </div>
                                        }
                                        {!localImage &&
                                            <div className="drop-area"
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={handleDrop}
                                            >
                                                <h6>Train Your ChatBot</h6>
                                                <br />
                                                <img src="../../../images/DefaultImages/UploadImages.svg" alt="" />
                                                <p>Drag and drop pdf here</p>
                                                <p>OR</p>
                                                <SbCancelBtn btnName="Browse"
                                                    onClickEffect={uploadClick}
                                                />
                                                <p style={{ fontSize: "11px" }}>Supports Only pdf file</p>
                                            </div>
                                        }
                                    </span>
                                </button>
                                <input
                                    id="getFile"
                                    type="file"
                                    multiple
                                    name="filetobase64"
                                    accept="pdf/*"
                                    className="btn-primary upload"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{ display: "none" }}
                                />
                            </div>
                            {/* {selectLength && (
                  <small
                    id={`Error`}
                    className="form-text text-muted ">
                    Please Select maximum 6 images
                  </small>
                )} */}
                            <div className="btns-save-cancel product-btn-section">
                                <SBSaveUpdateBtn btnName="Save" onClickEffect={uploadPdf} />
                                {/* <SbCancelBtn btnName="Cancel" onClickEffect={closeModel} /> */}
                            </div>
                        </div>
                    </div></div>
            </div>
            <Footer />
        </div>
    )
}

export default TrainChatBot;
