import { useEffect, useState } from "react";
import "../../styles/AddUserModel.scss";
import { SbBulkUpload } from "../SbButtons/SbAddBtn";
import LoadingAnimation from "../LoadingAnimation";
import { baseURL } from "../BaseUrl";
import * as swal from "../Consturl/SwalAlert";

const BulkProductsUpload = (props) => {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };

  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [uploadExcelFile, setUploadExcelFile] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.addBulkUserModel, props.handleCloseBulkUserModel]);

  const addBulkUser = () => {
    if (uploadExcelFile !== null || uploadExcelFile !== "") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Import/ImportUser?Directory=BulkProductUploads`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
            props.handleCloseBulkUserModel();
            setIsLoading(false);
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
            setIsLoading(false);
          }
        });
    }
  };

  const closeModel = () => {
    props.handleCloseBulkUserModel();
  };

  return (
    <div>
      {/* Button to trigger the modal */}

      {/* Bootstrap Modal */}
      <div
        show={props.addBulkUserModel}
        // onHide={props.handleCloseBulkUserModel}
      >
        <div className="modal-dialog modal-fullscreen-xxl-down">
          <div className="modal-content add-machine-page-container">
            <div>
              <div className="bubble-image">
                <img src="../../images/BG/about_s4_bubble.png" alt="" />
              </div>
              <div className="circle-image">
                <img src="../../images/BG/choose_lines.svg" alt="" />
              </div>
              <div className="lines-image">
                <img src="../../images/BG/lines.png" alt="" />
              </div>
            </div>

            <div className="animation-bulk-container">
              <div className="bubble-image-small">
                <img src="../../images/BG/about_s4_bubble.png" alt="" />
              </div>
              <div className="circle-image-small">
                <img src="../../images/BG/choose_lines.svg" alt="" />
              </div>
            </div>

            <div className="modal-header add-user-title">
              <div className="back-btn" onClick={closeModel}>
                <img
                  src="../../../../images/DefaultImages/back-btn-arrow.svg"
                  alt=""
                />
              </div>
              <h4 className="modal-title">ADD BULK PRODUCTS</h4>
            </div>
            <div className="px-0 modal-body">
              <div className="container-fluid add-bulk-container">
                <div className="row hv-center">
                  <div className="col-lg-6 col bulk-upload-static-image">
                    <img src="../../images/BG/bulkUpload3.svg" alt="" />
                  </div>
                  <div className="col-lg-6 col">
                    <div className="upld-n-templt-downld flex-column">
                      <div className="mb-4 upload-part">
                        <input
                          className="form-control form add-meetpurpose-input"
                          id="bulkuserupload"
                          type="file"
                          name="bulkUserUpload"
                          accept=".xls, .xlsx"
                          onChange={handleChange}
                          placeholder="Add File"
                        />
                      </div>

                      <div className="dflex-j-between">
                        <div>
                          <a
                            href={`${baseURL}/TravelizeProData/SUB0000000001/Data/BulkProducts/Excel_20240207192005542.xlsx`}
                            className="btn template-download"
                            download={"BulkUploadTemplate"}
                          >
                            <i className="fa fa-download" /> &nbsp;Template
                            Download
                          </a>
                        </div>
                        <div>
                          <SbBulkUpload
                            btnName="Bulk Upload"
                            onClickEffect={addBulkUser}
                          />
                        </div>
                      </div>
                      <div className="bulk-temp-and-res-message">
                        {isLoading ? (
                          <>
                            <span className="color-green">
                              Checking excel file to upload please wait...
                            </span>
                            &nbsp;
                            <div className="m-1 pt-2">
                              <LoadingAnimation />
                            </div>
                          </>
                        ) : (
                          <span
                            className={
                              bulkUploadResponse.res
                                ? "upload-res-message success"
                                : "upload-res-message false"
                            }
                          >
                            {bulkUploadResponse.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkProductsUpload;
