import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

function ProductSkelrton() {
    const numberOfElements = 6;
  const elements = [];

  for (let i = 0; i < numberOfElements; i++) {
    elements.push(<Row>
    <Col md={6} key={i}>
        <div className="product-box-div">
          <Row className="product-details">
            <Col md={3}>
              <Skeleton />
            </Col>
            <Col md={9}>
              <div>
                <Skeleton />
                <Skeleton />
              </div>
              <div className="price-text-div">
              <Skeleton />
              <Skeleton />
              </div>
              <div className="sb-product-details-div">
                <div className="product-details-section">
                <Skeleton />
                <Skeleton />
                <Skeleton />
                </div>
                <Skeleton />
              </div>
            </Col>
            <div className="wish-list-section">
            <Skeleton />
            </div>
          </Row>
        </div>
      </Col>
      <Col md={6} key={i}>
        <div className="product-box-div">
          <Row className="product-details">
            <Col md={3}>
              <Skeleton />
            </Col>
            <Col md={9}>
              <div>
                <Skeleton />
                <Skeleton />
              </div>
              <div className="price-text-div">
              <Skeleton />
              <Skeleton />
              </div>
              <div className="sb-product-details-div">
                <div className="product-details-section">
                <Skeleton />
                <Skeleton />
                <Skeleton />
                </div>
                <Skeleton />
              </div>
            </Col>
            <div className="wish-list-section">
            <Skeleton />
            </div>
          </Row>
        </div>
      </Col>
      </Row>
      );
  }
  return (
    <div>{elements}</div>
  )
}

export default ProductSkelrton
