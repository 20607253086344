import React from "react";
import { Link } from "react-router-dom";
import "../src/styles/PageNotFound.scss";

function AccessDenied() {
  return (
    <div>
      <div className="acces-denied-page">
        <h2 className="acces-denied-head">Error 403 - Access Denied</h2>
        <p> You don't have permission to view this site.</p>
        <Link to="/" className="back-to-login">
          Back to Login Page
        </Link>
      </div>
    </div>
  );
}

export default AccessDenied;
