import React, { useEffect, useState } from "react";
import "../styles/Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Image, Row } from "react-bootstrap";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import { baseURL } from "./BaseUrl";
import * as swal from "../Components/Consturl/SwalAlert";
import travelize from "../images/metric-favicon.svg";
import InternetIssue from "../Components/InternetIssue";
import AccessDenied from "../AccessDenied";

function ResetPasswordPage() {
  let themeColor = localStorage.getItem("theme");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  // const baseURL = "https://api.travelize.in";
  // const { trackData } = useSelector(state => state.data);
  const [trackTkData, setTrackTkData] = useState("");
  const [trackStatus, setTrackStatus] = useState(false);
  const [emailValue, setEmail] = useState("");
  const [rvValue, setRvValue] = useState("");
  const [emptyEmail, setEmptyEmail] = useState("");

  useEffect(() => {
    var urlstr = window.location.href;
    const answer_array = urlstr.split("?").slice(1);
    if (answer_array.length != 0) {
      const splitByAnd = answer_array.toString().split("&");
      const regex = new RegExp("ue=", "g");
      const regex2 = new RegExp("rv=", "g");
      let email = splitByAnd[0].replace(regex, "");
      let rv = splitByAnd[1].replace(regex2, "");
      setEmail(email);
      setRvValue(rv);
    } else {
      setEmptyEmail(true);
    }
  }, []);

  let navigate = useNavigate();

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const sessionClear = () => {
    sessionStorage.clear("forgotPassData");
  };

  const resetPassword = (e) => {
    if (password === confirmPassword) {
      const resetPassDta = {
        UserName: emailValue,
        password: password,
        resetPswdFlag: rvValue,
      };

      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          // Accept: "application/json",
        },
        body: JSON.stringify(resetPassDta),
      };
      fetch(`${baseURL}/api/Authentication/ResetPassword`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            swal.showAlert("Success", `${res.message}`, "success");
            setTimeout(() => navigate("/"), sessionClear(), 1000);
          } else {
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        });
    } else {
      swal.showAlert("Fail", `Password Must be same`, "warning");
    }
    e.preventDefault();
  };

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {!emptyEmail ? (
            <div
              className="main-body"
              style={{ backgroundImage: 'url("images/Login_Page/bg4.jpg")' }}
            >
              <div className="login-page-body">
                {/* <div className="beta-tag">
              <h6>Beta</h6>
            </div> */}
                <Row className="sb-login-row">
                  <Col md={5} className="sb-gif-col col-left">
                    <div className="w-100">
                      <div className="sb-login-logo-div">
                        <Image
                          src={travelizeSalesBeeLogo}
                          alt="Now Travelize is SalesBee"
                          className="sb-login-logo"
                        />
                      </div>
                      <div className="sb-signin-text">
                        <h3 className="login-head-text">Reset Password</h3>
                        <p style={{ fontWeight: "600" }}>
                          to access MetricInfo
                        </p>
                      </div>
                      <div className="sb-form-section">
                        <form onSubmit={resetPassword} className="login-form">
                          {emptyEmail && (
                            <div className="userName-input">
                              <input
                                type="email"
                                className="form-control"
                                id="login-email-id"
                                placeholder="Email Id"
                                value={emailValue}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          )}
                          <div className="userName-input">
                            <input
                              type="password"
                              className="form-control login-email"
                              id="login-password"
                              placeholder="New Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                          <div className="password-input">
                            <input
                              type="password"
                              className="form-control login-password"
                              id="login-password"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              required
                            />
                          </div>

                          <Row>
                            <Col md={6} className="p-0">
                              <div style={{ padding: "3% 0px" }}>
                                <Link to="/" className="forgot-pass-text">
                                  Remembered? Login again
                                </Link>
                              </div>
                            </Col>
                            <Col md={6} className="p-0">
                              <div className="forget-pass-login-button">
                                <Button
                                  type="submit"
                                  className="btn btn-login-button"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} className="col-right">
                    <div>
                      <div className="devider">
                        <div className="v1"></div>
                      </div>
                      <div className="travelize-log">
                        <Image
                          src={travelize}
                          alt="Now Travelize is SalesBee"
                          className="sb-trv-logo"
                        />
                      </div>
                      <div className="devider">
                        <div className="v1"></div>
                      </div>
                    </div>
                  </Col>
                  <Col md={5} className="sb-gif-col col-right">
                    <div>
                      <div className="sb-login-bgimg">
                        <Image
                          src={`../images/Login/laptop-motion-${
                            themeColor ? themeColor : "light"
                          }.gif`}
                          height="350"
                          alt="Now Travelize is SalesBee"
                          className="w-100"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default ResetPasswordPage;
