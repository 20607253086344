import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import { addClientCategoryMapping, addRefferalData, loadClientCategoryDrop, loadClientStageGrop, loadDepartmentDrop, loadDesignationDrop, loadSingleRefferalData, loadUpdateSalesBeeRefferal } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";

function AddMapByCategory(props) {

  let dispatch = useDispatch();

  const { departmentDrop } = useSelector((state) => state.data)
  const { desigationdrop } = useSelector((state) => state.data)
  const { categoryDropdown } = useSelector((state) => state.data)
  const { stageDropdown } = useSelector((state) => state.data)
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  useEffect(() => {
    // dispatch(loadClientCategoryDrop())
    // dispatch(loadClientStageGrop())
    dispatch(loadDesignationDrop());
    dispatch(loadDepartmentDrop());
  }, []);

  const salesBeeReferelId = props.salesBeeReferelId

  const [subscriptionId, setSubscriptionId] = useState()

  // const [department, setDepartment] = useState()
  // const [designation, setDesignation] = useState()
  // const [category, setCategory] = useState()
  // const [stage, setStage] = useState()

  const closeAddModal = () => {
    props.handleClose();
  };

  // code to refill the input field for edit

  useEffect(() => {
    if (salesBeeReferelId) {
      dispatch(loadSingleRefferalData(salesBeeReferelId));
    }
  }, [salesBeeReferelId]);

  // useEffect(() => {
  //   if (salesBeeReferelId && apiSingleRefferalData) {
  //     if (Object.keys(apiSingleRefferalData).length !== 0) {
  //       setName(apiSingleRefferalData.name);
  //       console.log(apiSingleRefferalData.name)
  //       setMobileNum(apiSingleRefferalData.mobile);
  //       setEmail(apiSingleRefferalData.email);
  //       setNote(apiSingleRefferalData.note);
  //     }
  //   }
  // }, [salesBeeReferelId, apiSingleRefferalData]);


  // code to post the data

  const [selectDepartment, setSelectDepartment] = useState({value: "", label: ''});

  const [selectDesignation, setSelectDesignation] = useState([]);
  const [designationId, setDesignationId] = useState([])

  const [selectCategory, setSelectCategory] = useState([])
  const [categoryId, setCategoryId] = useState([])

  const [selectStage, setSelectStage] = useState([])
  const [stageId, setStageId] = useState([])

  const handleDesignationChange = (selectedOptions) => {
    setSelectDesignation(selectedOptions);
    // setDesignationId(selectedOptions.map((p) => p.designationID));
  };

  const [designationValue, setDesignationValue] = useState('')
  const [categoryValue, setCategoryValue] = useState('')
  const [stageValue, setStageValue] = useState('')
  useEffect(( )=> {
    if(selectDesignation.length != 0){
      let sendDesignation = selectDesignation.map(item => {
        return item.label
      })
      setDesignationValue(sendDesignation.join())
    }
    if(selectCategory.length != 0){
      let sendCategory = selectCategory.map(item => {
        return item.label
      })
      setCategoryValue(sendCategory.join())
    }
    if(selectStage.length != 0){
      let sendCategory = selectStage.map(item => {
        return item.label
      })
      setStageValue(sendCategory.join())
    }
  }, [selectDesignation,selectCategory, selectStage])

  

  const handleCategoryChange = (selectedOption) => {
    setSelectCategory(selectedOption)
    // setCategoryId(selectedOption.map((p) => p.categoryID));
  }

  const handleStageChange = (selectedOption) => {
    setSelectStage(selectedOption)
    // setStageId(selectedOption.map((p)=>(p.stageID)))
  }

  const [enableStatus, setEnableStatus] = useState(1);
  const [errMessage, setErrMessage] = useState(false);

  let postClientCategoryMappingData = (e) => {
    e.preventDefault()
    if(!selectDepartment.label || !categoryValue){
      setErrMessage(true)
    } else {
    
    let data = {
      subscriptionID: resPassData.subscriptionId,
      status: enableStatus,
      departmentName: selectDepartment.label,
      designationName: designationValue,
      categoryName: categoryValue,
      stageName: stageValue,
    };
    console.log(data)

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(data),
    };
    // dispatch(addClientCategoryMapping(requestOption, closeAddModal))
  }
  }


  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">
            {/* {props.clickType ? (
              "Base fare Details"
            ) : (
              <>{setBasefareId ? "EDIT BASE FARE" : "ADD BASE FARE"}</>
            )} */}
            Add ClientCategoryMapping
          </h6>

          <i className="fa fa-times" onClick={closeAddModal} />

        </div>
        {/* <div style={{margin:"auto", padding:"5px", color:"#FFB900"}}>
          <b>FLAT 5% COMMISSION ON BILLING AMOUNT</b>
        </div> */}
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">

              <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Department"
                  className={`${selectDepartment.value !== ''
                    ? "float-visible-select"
                    : "float-hidden float-select"
                    }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    placeholder="Department"
                    isSearchable={true}
                    options={departmentDrop}
                    onInputChange={departmentDrop.label}
                    value={selectDepartment.value && selectDepartment}
                    onChange={(selectedOption) => {
                      setSelectDepartment(selectedOption)
                    }
                    }
                  />
                </FloatingLabel>
                {errMessage && !selectDepartment.label && (
                      <small
                        id={`Error`}
                        className="form-text text-muted "
                      >
                        Please select department
                      </small>
                    )}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Select Designation"
                  className={`${selectDesignation.length !== 0
                    ? "float-visible-select"
                    : "float-hidden float-select"
                    }`}
                >
                  <Multiselect
                    className="select-add-user-model zone model-select"
                    showCheckbox={true}
                    displayValue="label" // Property name to display in the dropdown options
                    placeholder={"Select Designation"}
                    emptyRecordMsg="No options available"
                    avoidHighlightFirstOption={true}
                    closeIcon="cancel"
                    showArrow={true}
                    // selectionLimit={2}
                    closeOnSelect={true}
                    options={desigationdrop}
                    selectedValues={selectDesignation}
                    onSelect={handleDesignationChange}
                    onRemove={handleDesignationChange}
                  />
                </FloatingLabel>
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Select Category"
                  className={`${selectCategory.length !== 0
                    ? "float-visible-select"
                    : "float-hidden float-select"
                    }`}
                >
                  <Multiselect
                    className="select-add-user-model zone model-select"
                    showCheckbox={true}
                    displayValue="label" // Property name to display in the dropdown options
                    placeholder={"Select Category"}
                    emptyRecordMsg="No options available"
                    avoidHighlightFirstOption={true}
                    closeIcon="cancel"
                    showArrow={true}
                    selectionLimit={2}
                    closeOnSelect={true}
                    options={categoryDropdown}
                    selectedValues={selectCategory}
                    onSelect={handleCategoryChange}
                    onRemove={handleCategoryChange}
                  />
                </FloatingLabel>
                {errMessage && !categoryValue && (
                      <small
                        id={`Error`}
                        className="form-text text-muted "
                      >
                        Please select alteast one Category
                      </small>
                    )}
              </div>

              <div className="mb-2">
                <FloatingLabel
                  label="Select Stage"
                  className={`${selectStage.length !== 0
                    ? "float-visible-select"
                    : "float-hidden float-select"
                    }`}
                >
                  <Multiselect
                    className="select-add-user-model zone model-select"
                    showCheckbox={true}
                    displayValue="label" // Property name to display in the dropdown options
                    placeholder={"Select Stage"}
                    emptyRecordMsg="No options available"
                    avoidHighlightFirstOption={true}
                    closeIcon="cancel"
                    showArrow={true}
                    selectionLimit={2}
                    closeOnSelect={true}
                    options={stageDropdown}
                    selectedValues={selectStage}
                    onSelect={handleStageChange}
                    onRemove={handleStageChange}
                  />
                </FloatingLabel>
              </div>

            </div>
          </div>
        </form>

        <div className="btns-save-cancel">
          {!props.clickType && (
            <SBSaveUpdateBtn
              // btnName={salesBeeReferelId ? "Update" : "Save"}
              btnName="Save"
              onClickEffect={postClientCategoryMappingData} />
          )}
          <SbCancelBtn
            onClickEffect={closeAddModal}
            btnName="Cancel" />
        </div>
      </Modal>
    </div>
  );
}

export default AddMapByCategory;





