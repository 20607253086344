import React, { useState, useEffect } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  loadClientCategoryDrop,
  loadClientStageGrop,
  loadDepartmentDrop,
  loadDesignationDrop,
  loadMapClientCategory,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import DatePicker from "react-datepicker";
import AddMapByCategory from "./AddMapByCategory";
import MapByCategoryTable from "./MapByCategoryTable";
import Select from "react-select";
import { optionsMeetStatusList } from "../../DropdownsData";

function MapByCategory() {
  let tableHeader = [
    { name: "DEPARTMENT", align: "left", width: "100px" },
    { name: "DESIGNATION", align: "left", width: "100px" },
    { name: "CATEGORY", align: "left", width: "100px" },
    { name: "STAGE", align: "left", width: "10px" },
    { name: "STATUS", align: "center", width: "100px" }
  ];

  let dispatch = useDispatch();
  const { clientCategoryMap } = useSelector((state) => state.data)
  const { departmentDrop } = useSelector((state) => state.data);
  const { desigationdrop } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector(state => state.data);
  const { categoryDropdown } = useSelector(state => state.data);
  // console.log(categoryDropdown,'category')
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));


  useEffect(() => {
    dispatch(loadClientCategoryDrop())
    dispatch(loadClientStageGrop())
    dispatch(loadDesignationDrop());
    dispatch(loadDepartmentDrop());
  }, []);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));


  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };


  const [openFilterTab, setOpenFilterTab] = useState(false);

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation


  //code for edit

  const [salesBeeReferelId, setSalesBeeReferelId] = useState()
  const editRefferal = (refId) => {
    setSalesBeeReferelId(refId)
    setShow(!show)
  }


  //code for filter

  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  const [filterArray, setFilterArray] = useState([])

  //code for search bar

  const [searchValue, setSearchValue] = useState('')
  const [selectDepartment, setSelectDepartment] = useState({ value: "", label: "" });
  const [selectDesignation, setSelectDesignation] = useState({ value: "", label: "" }); 
  const [selectCategory, setSelectCategory] = useState({ value: "", label: "" })
  const [selectStage, setSelectStage] = useState({ value: "", label: "" })
  const [selectStatus, setSelectStatus] = useState({ value: "", label: "" });

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter(item => item.key !== "Text")
    } else {
      list = []
    }
    if (value) {
      list.push({ key: "Text", value: value })
    } else {
      list = filterArray.filter(item => item.key !== "Text")
    }
    setSearchValue(value)
  }

  const setSelectSearchDrop = (data, type) => {
    let list = []
    // const [start, end] = data;
    // let list = []
    //   if (filterArray.length !== 0) {
    //     if(start){
    //       list = filterArray.filter((item) => item.key !== "FromDate");
    //     }
    //     if(end){
    //       list = filterArray.filter((item) => item.key !== "ToDate");
    //     }
    //   }
    //   if(start && end == null){
    //     list.push({ key: "FromDate", value: moment(new Date(start)).format("DD/MM/YYYY") });
    //   }
    //   setFromDate(start);
    //   if(end){
    //     list.push({ key: "ToDate", value: moment(new Date(end)).format("DD/MM/YYYY") });
    //   } 
    //   setToDate(end);
    if (type === "Dapartment") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "DepartmentID");
      } else {
        list = [];
      }
      list.push({ key: "DepartmentID", value: data.value });
      setSelectDepartment(data);
    } else if (type === "Designation") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "DesignationID");
      } else {
        list = [];
      }
      list.push({ key: "DesignationID", value: data.value });
      setSelectDesignation(data);
    } else if (type === "Status") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Status");
      } else {
        list = [];
      }
      list.push({ key: "Status", value: data.value });
      console.log(data);
      setSelectStatus(data);
    } else if (type === "Category") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "CategoryID");
      } else {
        list = [];
      }
      list.push({ key: "CategoryID", value: data.value });
      setSelectCategory(data);
    } else if (type === "Stage") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "StageID");
      } else {
        list = [];
      }
      list.push({ key: "StageID", value: data.value });
      setSelectStage(data);
    }
    setFilterArray(list)

  };


  // const setSelectSearchDrop = (data, type) => {
  //   const [start, end] = data;

  //   // Create a copy of the existing filterArray
  //   let list = [...filterArray];

  //   // Remove items from the list based on type
  //   if (type === "FromDate" && start) {
  //     list = list.filter((item) => item.key !== "FromDate");
  //     list.push({ key: "FromDate", value: moment(new Date(start)).format("DD/MM/YYYY") });
  //     setFromDate(start);
  //   } else if (type === "ToDate" && end) {
  //     list = list.filter((item) => item.key !== "ToDate");
  //     list.push({ key: "ToDate", value: moment(new Date(end)).format("DD/MM/YYYY") });
  //     setToDate(end);
  //   } else if (type === "DepartmentID") {
  //     list = list.filter((item) => item.key !== "DepartmentID");
  //     list.push({ key: "DepartmentID", value: data.value });
  //     setSelectDepartment(data);
  //   } else if (type === "DesignationID") {
  //     list = list.filter((item) => item.key !== "DesignationID");
  //     list.push({ key: "DesignationID", value: data.value });
  //     setDesignation(data);
  //   } else if (type === "Status") {
  //     list = list.filter((item) => item.key !== "Status");
  //     list.push({ key: "Status", value: data.value });
  //     setStatus(data);
  //   } else if (type === "CategoryID") {
  //     list = list.filter((item) => item.key !== "CategoryID");
  //     list.push({ key: "CategoryID", value: data.value });
  //     setCategory(data);
  //   } else if (type === "StageID") {
  //     list = list.filter((item) => item.key !== "StageID");
  //     list.push({ key: "StageID", value: data.value });
  //     setStage(data);
  //   }

  // Update the filterArray with the modified list
  //   setFilterArray(list);
  // };


  const applyFilters = () => {
    dispatch(loadMapClientCategory(filterArray, PageSize, currentPage, setIsLoading));
  };

  useEffect(() => {
    applyFilters(filterArray, setIsLoading)
  }, [filterArray, setIsLoading])

  const clearSerachBySelect = () => {
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    setFilterArray([]); // Clear the filter array
  }

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>

              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className='search-row'>
                    <Col md={3} className='cat-col'>
                      <div className='page-header-text-div'>
                        <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <h6 className='page-header-text' >Client Category Mapping</h6>
                      </div>
                    </Col>
                    <Col md={5} className='cat-col'>
                      <div className='input-div'>
                        <input type="search" placeholder='Find a Referal Code' className='form-control setting-input'
                          value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className='fa fa-search search-icon' ></i>
                      </div>
                    </Col>
                    <Col md={4} className='cat-col'>
                      <div className="search-bar-header" style={{ float: "right" }}>
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                            <p className="card-head">
                              <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab &&
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={12}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  placeholder="Department"
                                  isSearchable={true}
                                  options={departmentDrop}
                                  value={selectDepartment.value && selectDepartment}
                                  onInputChange={departmentDrop.label}
                                  onChange={(data) => { setSelectSearchDrop(data, 'Dapartment') }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  placeholder="Designation"
                                  isSearchable={true}
                                  options={desigationdrop}
                                  value={selectDesignation.value && selectDesignation}
                                  onInputChange={desigationdrop.label}
                                  onChange={(data) => { setSelectSearchDrop(data, "Designation") }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  placeholder="Category"
                                  isSearchable={true}
                                  options={categoryDropdown}
                                  value={selectCategory.value && selectCategory}
                                  onInputChange={categoryDropdown}
                                  onChange={(data) => { setSelectSearchDrop(data, 'Category') }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  placeholder="Stage"
                                  isSearchable={true}
                                  options={stageDropdown}
                                  value={selectStage.value && selectStage}
                                  onInputChange={stageDropdown.label}
                                  onChange={(data) => { setSelectSearchDrop(data, 'Stage') }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  placeholder="Status"
                                  isSearchable={true}
                                  options={optionsMeetStatusList}
                                  value={selectStatus.value !== '' && selectStatus}
                                  onInputChange={optionsMeetStatusList.label}
                                  onChange={(data) => { setSelectSearchDrop(data, "Status") }}
                                />
                              </div>
                            </div>
                          </Col>

                        </Row>
                        <Row>
                          <Col md={4}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                <span className="input-group-text" id="from-label">
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={setSelectSearchDrop}
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn onClickEffect={clearSerachBySelect}
                                btnName="Clear" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }
                </div>



                {/*TABLE START */}
                <MapByCategoryTable
                  tableHeader={tableHeader}
                  //   baseFareList={baseFareList}
                  // isLoading={isLoading}
                  //   salesBeeRefferal={salesBeeRefferal}
                  editRefferal={editRefferal}
                />
                {/*TABLE END */}



                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                  <AddMapByCategory
                    show={show}
                    salesBeeReferelId={salesBeeReferelId}
                    handleClose={handleClose}
                    applyFilters={applyFilters}
                  />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default MapByCategory;







