
import React, { useState } from "react";
import OrgChart from "react-orgchart";
import '../../../styles/HierarchyPage.scss';
import "react-orgchart/index.css";
import image from '../../../images/Admin1.png'
import { useDispatch, useSelector } from "react-redux";
import { loadDesignationHierarchy, loadUserDetailsHierarchy } from "../../../redux/action";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import Select from "react-select";
import ViewHierarchyDetails from "./ViewHierarchyDetails";
import Footer from "../../Footer";

function HierarchyPage() {

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    let hierarchyOption = [
        { value: "Designation", label: "Designation" },
        { value: "User", label: "User" },
    ]

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    let dispatch = useDispatch()
    const { hierarchyData } = useSelector((state) => state.data)
    console.log(hierarchyData, "hierarchyData")

    // converted keyName childrenItem to children(childrenItem is coming from api but children is coming from npm package so it is converted)
    function renameKeyRecursively(obj, oldKey, newKey) {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(item => renameKeyRecursively(item, oldKey, newKey));
        }

        const newObj = { ...obj };

        if (oldKey in newObj) {
            newObj[newKey] = newObj[oldKey];
            delete newObj[oldKey];
        }

        for (const key in newObj) {
            newObj[key] = renameKeyRecursively(newObj[key], oldKey, newKey);
        }

        return newObj;
    }

    const updatedData = renameKeyRecursively(hierarchyData, 'childItems', 'children');
    console.log(hierarchyData, "converted");
    const [selectedOption, setSelectedOption] = useState('Designation');

    // const updatedUserDetailsData = renameKeyRecursively(userDetailsHierarchy, 'childItems', 'children');
    // console.log(updatedUserDetailsData, "converted");


    function MyNodeComponent({ node }) {

        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(node);
        };

        const handleMouseLeave = () => {
            setIsHovered(null);
        };

        const [selectedNode, setSelectedNode] = useState(null);

        const handleNodeClick = () => {
            setSelectedNode(node); // Set the selected node when clicked
        };


        return (
            // <div className="initechNode"
            //     onMouseEnter={handleMouseEnter}
            //     onMouseLeave={handleMouseLeave}
            // >

            //     <div className="hierarchy-image">
            //         <img src={image} alt="" />
            //     </div>
            //     {isHovered == node && (
            //         <ViewHierarchyDetails
            //             profilePic={
            //                 selectedOption === 'Designation'
            //                     ? image
            //                     : node.profilePic
            //             }
            //             fullName={
            //                 selectedOption === 'Designation'
            //                 ? null
            //                 : node.fullName
            //             }

            //             code={
            //                 selectedOption === 'Designation'
            //                 ? null
            //                 : node.code
            //             }
            //             departmentName={
            //                 selectedOption === 'Designation'
            //                     ? node.departmentName
            //                     : node?.desingation?.departmentName
            //             }
            //             roleName={
            //                 selectedOption === 'Designation'
            //                     ? node?.role?.roleName
            //                     : node?.desingation?.designationName
            //             }
            //             isVisible={true} // Show details when the node is hovered
            //         />
            //     )}
            // </div>

            <div className="initechNode">
                <div className="hierarchy-image">
                    <img src={image} alt="" />
                </div>
                <div className="hierarchy-positions">
                    {selectedOption === 'Designation' ? (
                        <>
                            <b>{node.designationName}</b>
                            {/* <p>{node.role.roleName}</p> */}
                            {/* <p>{node.departmentName}</p> */}
                        </>
                    ) : (
                        <>
                            <b>{node.fullName}</b>
                            {/* <p>{node.desingation.designationName}</p> */}
                            {/* <p>{node.desingation.departmentName}</p> */}
                        </>
                    )}
                </div>
            </div>
        );
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event);

        // Based on the selected option, dispatch the appropriate action to load data

    };

    useEffect(() => {
        // Initially, load data based on the selected option (default: User)
        if (selectedOption == 'User') {
            dispatch(loadUserDetailsHierarchy());
        } else if (selectedOption == 'Designation') {

            dispatch(loadDesignationHierarchy());
        }
    }, [selectedOption]);


    // Page navigation
    const Navigate = useNavigate();
    const goToBackPage = () => {
        Navigate(-1);
    };
    // page navigation


    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />

            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
                {/* PAGE CONTENT START */}

                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        {/* Filter and Header Section */}
                        <div className="user-table">
                            <Row className="search-row">
                                <Col md={3} className="cat-col">
                                    <div className="page-header-text-div">
                                        <div
                                            className="back-btn"
                                            onClick={goToBackPage}
                                        >
                                            <img
                                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="page-header-text">
                                            Hierarchy
                                        </h6>
                                    </div>
                                </Col>
                                <Col md={9} className="cat-col">
                                    <div
                                        className="search-bar-header"
                                        style={{ float: "right" }}
                                    >
                                        <div className="page-top-bar-dropdowns page-top-Filters">
                                            <div style={{ width: "100%", padding: "0px 5px" }}>
                                                <Select
                                                    className="react-select-container-list"
                                                    classNamePrefix="react-select-list"
                                                    // defaultValue={optionsRoleList[0].label}
                                                    options={hierarchyOption}
                                                    placeholder="Select..."
                                                    onInputChange={hierarchyOption.label}
                                                    onChange={(data) => handleOptionChange(data.value)}
                                                    isSearchable={true}
                                                // isClearable={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {/* LEADS TABLE START */}
                        <div className="hierarchy-div" id="initechOrgChart">

                            <OrgChart tree={updatedData} NodeComponent={MyNodeComponent} />
                        </div>
                        {/* LEADS TABLE END */}

                        {/* PAGE CONTENT END*/}
                    </div>
                </div>
                {/* MAIN BODY END */}
            </div>
            <Footer />
        </div>
    );
}

export default HierarchyPage;



