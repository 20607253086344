import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import "../../../styles/Kpa.scss"
import "react-bootstrap-typeahead/css/Typeahead.css";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Rating from "react-rating";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import { loadSingleKpaData, postKpaData } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { FloatingLabel } from "react-bootstrap";

function UpdateKpaModel(props) {

    const kpaId = props.kpaId;
    let dispatch = useDispatch();
    const { getSingleKpaData } = useSelector((state) => state.data);
    useEffect(() => {
        if (kpaId) {
            dispatch(loadSingleKpaData(kpaId))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kpaId])
    const closeAddModal = () => {
        props.handleClose();
    };

    // code to refill the input field for edit

    // code to post the data
    const [rating, setRating] = useState(1);
    console.log(rating, 'rating')
    const [improvementAreas, setImprovementAreas] = useState();

    let postStatusUpdateData = (e) => {
        e.preventDefault();
        let data = {
            "kpaid": kpaId,
            "userID": getSingleKpaData.userID,
            "ratings": rating.toString(),
            "userComment": improvementAreas,
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        dispatch(postKpaData(requestOption, closeAddModal, kpaId))
        props.applyFilters();
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                className="main_model add-edit-basefare-model"
            >
                <div className="add-edit-basefare-heading">
                    <h6 className="add-edit-basefare-head">
                        Status Update
                    </h6>

                    <i className="fa fa-times" onClick={closeAddModal} />

                </div>
                <form>
                    <div className="container add-edit-basefare">
                        <div className="product-inputs">
                            <div className="mb-3" style={{ position: "relative" }}>
                                <Rating
                                    className="user-rating"
                                    initialRating={rating}
                                    emptySymbol="fa fa-star-o fa-2x"
                                    fullSymbol="fa fa-star fa-2x"
                                    onChange={(value) => setRating(value)}
                                    fractions={2} // Enable half-star ratings
                                />
                            </div>
                            <div className="row">
                                <div className="mb-3 input-address-location">
                                    <FloatingLabel
                                        label="Areas For improvement"
                                        className={`${improvementAreas
                                            ? "float-input-visible"
                                            : "float-hidden float-input"
                                            }`}
                                    >
                                        <textarea
                                            rows="3"
                                            cols="20"
                                            name="usrtxt"
                                            wrap="hard"
                                            className="form-control form-textarea add-user-input"
                                            id="exampleFormControlInput1"
                                            placeholder="Areas For improvement"
                                            value={improvementAreas}
                                            onChange={(e) => setImprovementAreas(e.target.value)}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>

                <div className="btns-save-cancel">
                    {!props.clickType && (
                        <SBSaveUpdateBtn
                            btnName="Update"
                            onClickEffect={postStatusUpdateData}
                        />
                    )}
                    <SbCancelBtn
                        onClickEffect={closeAddModal}
                        btnName="Cancel" />
                </div>
            </Modal>
        </div>
    );
}

export default UpdateKpaModel;





