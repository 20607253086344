import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../Skeleton Loading/SkeletonMeetPurpose';
import { useState } from 'react';
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import { loadClientCategory, loadClientStage, loadFilterReferalData, loadProductFilterData, loadProductsData, postProductFilterData } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import * as HeaderAuthentication from "../HeaderAuthentication";
import moment from 'moment';
import { useEffect } from 'react';
import EditProductFilterModal from './EditProductFilterModal';
import SbCancelBtn from '../SbButtons/SbCancelBtn';

function ProductFilterSubCategoryTable() {

    const { clientStageData } = useSelector((state) => state.data)
    const { productFilterData } = useSelector((state) => state.data)

    const [productDatas, setProductDatas] = useState([])
    useEffect(() => {
        if (productFilterData) {
            // let list = [...productFilterData]
            productFilterData.map((items) => {
                const exists = items.productPricing.some(priceItems => priceItems.type === "2Stage");
                console.log(exists, "existsexists")
                if (items.productPricing.length == 0) {
                    let pricingItem = []
                    clientStageData.map(stageItem => {
                        if (stageItem.status == 1) {
                            pricingItem.push({
                                subscriptionID: items.subscriptionID,
                                productID: items.productID,
                                productName: items.productName,
                                clientStageID: stageItem.stageID,
                                clientStageName: stageItem.name,
                                clientPrice: 0,
                                type: "2Stage",
                                priceBy: "Stage Initial",
                                status: 1
                            })
                        }
                    })
                    items.productPricing = pricingItem

                } else if(items.productPricing.length != 0 && !exists){
                    
                    // items.productPricing.map(priceItems => {
                        let pricingItem = []
                            
                    clientStageData.map(stageItem => {
                        if (stageItem.status == 1) {
                            pricingItem.push({
                                subscriptionID: items.subscriptionID,
                                productID: items.productID,
                                productName: items.productName,
                                clientStageID: stageItem.stageID,
                                clientStageName: stageItem.name,
                                clientPrice: 0,
                                type: "2Stage",
                                priceBy: "Stage Initial",
                                status: 1
                            })
                        }
                    })
                        items.productPricing = pricingItem
                        
                    // })
                    // console.log(find,"productFilterData")
                    // if(find){
                        
                    // }
                }
            })
            
            setProductDatas(productFilterData)
        }
    }, [productFilterData])

    let tableHeader = [
        { name: "PRODUCT CODE", align: "left", width: "150px" },
        { name: "PRODUCT NAME", align: "left", width: "150px" },
        { name: "PRODUCT CATEGORY", align: "left", width: "150px" },
        { name: "MRP", align: "left", width: "150px" },
        ...(clientStageData
            ? clientStageData
                .filter(data => data.status === 1)
                .map(data => ({ name: data.name, align: "center", width: "150px" }))
            : []),
        { name: "ACTION", align: "left", width: "150px" },
    ];


    let dispatch = useDispatch();
    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
    // console.log(resPassData, 'respass')

    const [filterArray, setFilterArray] = useState([])
    const [PageSize, setPageSize] = useState(20);
    const [pageCount, setPageCount] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [productId, setProductId] = useState('')
    const [show, setShow] = useState(false);
    const [clientCategoryInputValues, setClientCategoryInputValues] = useState();

    const applyFilters = () => {
        dispatch(loadFilterReferalData(filterArray, PageSize, currentPage, setIsLoading));
    };

    useEffect(() => {
        applyFilters(filterArray, setIsLoading)
    }, [filterArray, setIsLoading])


    useEffect(() => {
        fetchProductData(filterArray, PageSize, currentPage)
    }, [filterArray, PageSize, currentPage])

    const fetchProductData = () => {
        if (filterArray.length === 0) {
            dispatch(loadProductsData(currentPage, PageSize, setIsLoading))
        }
    }

    useEffect(() => {
        dispatch(loadClientStage())
        dispatch(loadProductFilterData())
    }, [])

    const [methodType, setMethodType] = useState([])
    const handleInputChange = (e, stageId, type, productId) => {

        if (methodType.length == 0) {
            const newData = [...methodType, { stageId: stageId, type: type }]
            setMethodType(newData)
        } else {
            console.log(methodType)
            methodType.filter(methodtype => {
                if (methodtype.stageId != stageId) {
                    const newData = [...methodType, { stageId: stageId, type: type }];
                    setMethodType(newData)
                }
            })
        }
        let productList = [...productDatas]
        productList.map(item => {
            if (item.productID == productId) {
                item.productPricing.map(subitem => {
                    if (subitem.clientStageID == stageId && subitem.type == type && subitem.productID == productId)
                        subitem.clientPrice = parseFloat(e.target.value);
                })
            }
        })
        setProductDatas(productList)
        console.log(productList)
    }

    const handleSave = (produId) => {
        if (methodType.length != 0) {
            let senddata = productDatas.filter(item => {
                return item.productID == produId;
            })
            const filteredArray = senddata[0].productPricing.filter(item1 =>
                methodType.some(item2 => item1.clientStageID == item2.stageId && item1.type == item2.type)
            );
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postTravelizeAuth,
                body: JSON.stringify(filteredArray),
            };
            dispatch(postProductFilterData(requestOption, setEditMode))
        }

    }

    const editProducts = (prodId) => {
        setEditMode(false)
        setProductId(prodId)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false);
        setEditMode(false)
    };

    const enableInputFields = () => {
        setEditMode(true); // Enable input fields
        setShow(false); // Close the modal
    };

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='product-pricing-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {tableHeader.map((name, i) => (
                                    < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                        style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {isLoading ?
                            <>{tableHeader.map((name, i) => (
                                <SkeletonMeetPurpose key={i} />
                            ))}
                            </>
                            :
                            <>
                                {productDatas && productDatas.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {productDatas && productDatas.filter(data => data.productStatus === 1).map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data'>

                                                <TableCell>
                                                    {data.productCode}
                                                </TableCell>
                                                <TableCell>
                                                    {data.productName}
                                                </TableCell>
                                                <TableCell>
                                                    {data.categoryName}
                                                </TableCell>
                                                <TableCell>
                                                    {data.productMRP}
                                                </TableCell>
                                                {/* Add input field for each item in clientCategoryData */}
                                                {/* {clientStageData && clientStageData.filter(category => category.status === 1).map((category, index) => ( */}
                                                {data.productPricing.map((item, index) => (
                                                    <>
                                                        {
                                                            item.type == "2Stage" &&
                                                            <TableCell key={index}>

                                                                <div className='table-input-div'>
                                                                    <input
                                                                        type="number"
                                                                        className={`${(editMode && productId == data.productID) ? "form-control table-input add-product-input" : "text-format-input"}`}
                                                                        // placeholder="Enter Value"
                                                                        // name="price"
                                                                        style={{ width: "100%", textAlign: "center" }}
                                                                        value={item.clientPrice}
                                                                        onChange={(e) => handleInputChange(e, item.clientStageID, item.type, item.productID)}
                                                                        disabled={!editMode || productId !== data.productID}
                                                                    />

                                                                </div>

                                                            </TableCell>
                                                        }
                                                    </>
                                                ))}

                                                <TableCell>
                                                    {/* {clientCategoryData.filter(category => category.status === 1 ).map((category, index) => ( */}
                                                    <>
                                                        {(editMode && (productId == data.productID)) ?
                                                            <SBSaveUpdateBtn

                                                                onClickEffect={(e) =>
                                                                    handleSave(data.productID) // Call handleSave when Save button is clicked
                                                                }
                                                                btnName="Save" // Change button text based on the modal and product ID
                                                            />
                                                            :
                                                            <SbCancelBtn

                                                                onClickEffect={(e) =>
                                                                    editProducts(data.productID) // Show the modal when Edit button is clicked
                                                                }
                                                                btnName="Edit" // Change button text based on the modal and product ID
                                                            />
                                                        }
                                                    </>
                                                    {/* ))} */}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
            {show && <EditProductFilterModal
                show={show}
                handleClose={handleClose}
                editProducts={editProducts}
                enableInputFields={enableInputFields}
            />}
        </div>
    )
}

export default ProductFilterSubCategoryTable;





