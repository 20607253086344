import React, { useState, useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import "../../styles/AddEditModel.scss";
import * as swal from "../Consturl/SwalAlert";
import moment from "moment";
import { baseURL } from "../BaseUrl";
import DatePicker from "react-datepicker";
import SetDateTimeModel from "./SetDateTimeModel";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import LoadingAnimation from "../LoadingAnimation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SetClientsMeetDate(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const subscriptionID = loginDetails.subscriptionId;
  const loginUserId = loginDetails.userId;

  const [clientList, setClientList] = useState([]);
  const [clientsData, setClientsData] = useState(props.clientsData);
  const [fromDate, setFromDate] = useState(new Date(props.fromDateofRoute));
  const [toDate, setToDate] = useState(new Date(props.toDateofRoute));
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const [clientId, setClientId] = useState(0);
  const [dateTimemodel, setDateTimemodel] = useState(false);
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useEffect(() => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/Get?PageSize=13664025&CurrentPage=1&EnabledStatus=1&SearchIDS=${props.filterdClientsIds}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          console.log("CLIENT-LIST-DROPDOWN::", res.result);
          setClientList(res.result);
        } else {
          setClientList([]);
        }
      });
  }, [props.filterdClientsIds]);

  console.log("FILTERS-CLIENTS::", props.filterdClientsIds);

  useEffect(() => {
    const setInputEle = () => {
      clientsData.routeClient.forEach((ele) => {
        ele.inputState = {
          clientName: "",
          contactperson: "",
          clientID: ele.clientID,
          meetingDate: "",
          meetingTime: "",
          purposeID: 0,
          status: 1,
          subscriptionID: ele.subscriptionID,
          userId: ele.userId,
        };
      });
    };
    setInputEle();
  }, [props.clientsData]);

  const handleCloseDateTime = () => {
    setDateTimemodel(false);
  };

  const setVisitDate = (date, clntID) => {
    let clientS = clientsData.routeClient.filter(
      (data) => data.clientID == clntID
    );
    clientS[0].inputState.meetingDate = date;
    setClientsData({ ...clientsData });
    // setSelectedDate(moment(date).format("DD/MM/YYYY"));
  };

  const setVisitTime = (val, clntID) => {
    let clientS = clientsData.routeClient.filter(
      (data) => data.clientID == clntID
    );
    clientS[0].inputState.meetingTime = val;
    setClientsData({ ...clientsData });
    // setSelectedTime(val);
  };

  const remmoveClientFromAdded = (clientId) => {
    let updatedClients = clientList.filter((data) => data.clientID != clientId);
    setClientList(updatedClients);
  };

  const saveRoutePlan = (e) => {
    e.preventDefault();
    let clientsVisitData = [];
    for (let i = 0; i < clientsData.routeClient.length; i++) {
      if (
        clientsData.routeClient[i].inputState.meetingDate !== "" &&
        clientsData.routeClient[i].inputState.meetingTime !== ""
      ) {
        clientsVisitData.push({
          clientID: clientsData.routeClient[i].clientID,
          meetingDate: moment(
            clientsData.routeClient[i].inputState.meetingDate
          ).format("DD/MM/YYYY"),
          meetingTime: clientsData.routeClient[i].inputState.meetingTime,
          purposeID: 0,
          status: 1,
          subscriptionID: clientsData.routeClient[i].subscriptionID,
          userId: clientsData.routeClient[i].userId,
        });
      } else {
        setFieldReq(true);
        setAlertMessage({
          alertType: "fail",
          message: "Required",
        });
      }
    }
    if (clientsVisitData.length == clientsData.routeClient.length) {
      let newVisitData = {
        subscriptionID: clientsData.subscriptionID,
        name: clientsData.name,
        frequency: clientsData.frequency,
        addedByID: clientsData.addedByID,
        status: 1,
        fromDate: clientsData.fromDate,
        toDate: clientsData.toDate,
        routeClient: clientsVisitData,
        routeType: "By Company",
        routeTypeDetails: "",
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(newVisitData),
      };

      fetch(`${baseURL}/api/Route/AddRoute`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchRoutePlans();
            swal.showAlert(
              "Success",
              `Route Plan Added ${res.message}`,
              "success"
            );
          } else {
            toast.error(`${res.errorMessage}`);
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };

  console.log("ADDED-CLIENTS::----", clientList);

  return (
    <div>
      {" "}
      <>
        {dateTimemodel ? (
          <SetDateTimeModel
            dateTimemodel={dateTimemodel}
            handleCloseDateTime={handleCloseDateTime}
            clientsData={clientsData}
            setVisitDate={setVisitDate}
            setVisitTime={setVisitTime}
            fromDate={fromDate}
            toDate={toDate}
            clientId={clientId}
          />
        ) : (
          ""
        )}
      </>
      <Modal
        show={props.clientsMeetDate}
        className="main_model add-edit-model clients-route-visit"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            SET CLIENT VISIT DATE-TIME &nbsp;(User : {props.routeUserName ?? ""}
            )
          </h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>

        <form>
          <div className="container add-edit add-rote-model">
            <div className="add-route-plan-model">
              <div className="model-inputs client-list-with-date-time mb-3">
                <div className="display-column-wise">
                  <div className="clients-data p-0 ">
                    {clientList.length > 0 ? (
                      clientList.map((data, i) => (
                        <div className="clientn-datetime-icon">
                          <div className="clients-details" key={i}>
                            <div className="pb-1">
                              <sapn className="tb-td-sh">Name : </sapn>&nbsp;
                              <strong className="color-green">
                                {data?.clientName?.toUpperCase()}
                              </strong>
                            </div>
                            <div className="pb-1">
                              <sapn className="tb-td-sh">Person : </sapn>&nbsp;
                              <span className="color-yellow">
                                {data?.contactPersonName}
                              </span>
                            </div>
                            <div className="pb-1">
                              <sapn className="tb-td-sh">City : </sapn>&nbsp;
                              <span className="color-yellow">
                                {data?.city?.cityName}
                              </span>
                            </div>
                            <div>
                              <sapn className="tb-td-sh">Location : </sapn>
                              &nbsp;
                              <span>{data?.clientLoc?.location}</span>
                            </div>
                          </div>
                          {clientsData.routeClient
                            .filter((item) => item.clientID == data?.clientID)
                            .map((clnt) => (
                              <div className="set-date-time-icon">
                                <div
                                  className="remove-icon"
                                  onClick={(e) =>
                                    remmoveClientFromAdded(data?.clientID)
                                  }
                                >
                                  <span className="material-symbols-outlined mb-2">
                                    delete
                                  </span>
                                </div>
                                <div
                                  className="text-center"
                                  onClick={() => {
                                    setDateTimemodel(true);
                                    setClientId(data?.clientID);
                                  }}
                                >
                                  <i className="bi bi-clock-fill" />
                                </div>
                                <div>
                                  <p className="color-yellow">
                                    {clnt?.inputState?.meetingDate
                                      ? moment(
                                          clnt?.inputState?.meetingDate
                                        ).format("DD/MM/YYYY")
                                      : "Set"}{" "}
                                    <br />
                                    {clnt?.inputState?.meetingTime ?? ""}
                                  </p>
                                </div>
                                {fieldReq &&
                                (clnt?.inputState?.meetingTime == "" ||
                                  clnt?.inputState?.meetingDate == "") ? (
                                  <small
                                    id={`Error${alertMessage.alertType}`}
                                    className="form-text text-muted "
                                  >
                                    {`${alertMessage.message}`}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                        </div>
                      ))
                    ) : (
                      <>
                        <LoadingAnimation />
                        &nbsp; Loading....
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              btnName="Save"
              onClickEffect={(e) => saveRoutePlan(e)}
            />
            <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
          </div>
        </form>
      </Modal>
    </div>
  );
}
