import React, { useState } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import RefferalTable from "./RefferalTable";
import AccessDenied from "../../../AccessDenied";
import RefferalProductTable from "./RefferalProductTable";
import Footer from "../../Footer";

function Refferal() {

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const [activeTab, setActiveTab] = useState('Company');

  const handleTabSelect = (key) => {
    // Add your custom logic here when a tab is clicked
    setActiveTab(key);
  };

  return (

    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="claims-table">
                  <Tabs
                    defaultActiveKey="Product"
                    id="uncontrolled-tab-example"
                    className="local-claim-nav-tab"
                    onSelect={handleTabSelect}
                  >
                    <Tab
                      eventKey="back"
                      title={
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                      }
                    >
                      Back
                    </Tab>
                    <Tab eventKey="Product" title="Product" >
                      <RefferalProductTable />
                    </Tab>
                    <Tab eventKey="Company" title="Company" >
                      {activeTab == "Company" && <RefferalTable />}
                    </Tab>
                  </Tabs>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Refferal;