import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import { useSelector } from "react-redux";
import "../../../styles/BaseFare.scss";

function BaseFareTable(props) {
  const { totalCount } = useSelector((state) => state.data);

  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) => row.motName === currentValue.motName
    );

    if (existingRow) {
      existingRow.designationInfo.push({
        designationLevel: currentValue.designationLevel,
        designationName: currentValue.designationName,
        motType: currentValue.motType,
        motBaseFare: currentValue.motBaseFare,
        baseFareAmount: currentValue.baseFareAmount,
        motUnit: currentValue.motUnit,
        isPolicyAvaialble: currentValue.isPolicyAvaialble,
        status: currentValue.status,
        dayLimit: currentValue.dayLimit,
        addedByUserFullName: currentValue.addedByUserFullName,
        customeBaseFareID: currentValue.customeBaseFareID,
        // Add more properties as needed
      });
    } else {
      accumulator.push({
        motName: currentValue.motName,
        designationInfo: [
          {
            designationLevel: currentValue.designationLevel,
            designationName: currentValue.designationName,
            motType: currentValue.motType,
            motBaseFare: currentValue.motBaseFare,
            baseFareAmount: currentValue.baseFareAmount,
            motUnit: currentValue.motUnit,
            isPolicyAvaialble: currentValue.isPolicyAvaialble,
            dayLimit: currentValue.dayLimit,
            status: currentValue.status,
            addedByUserFullName: currentValue.addedByUserFullName,
            customeBaseFareID: currentValue.customeBaseFareID,
            // Add more properties as needed
          },
        ],
        // Add other properties here
      });
    }
    return accumulator;
  };

  const mergedMotRows = props.baseFareList
    ? props.baseFareList.reduce(mergeRows, [])
    : [];

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.baseFareList,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="basefare-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                <TableCell
                    className={`thead-cell-products`}
                    style={{ minWidth:'150px' }}
                  >
                    NAME
                </TableCell>
                <TableCell
                    className={`thead-cell-products`}
                    colSpan={8}
                    style={{padding: '0'}}
                  >
                   <TableRow>
                   {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    className={`thead-cell-products`}
                    style={{ minWidth: name.width }}
                  >
                    {name.name}
                  </TableCell>
                ))}
                   </TableRow>
                </TableCell>
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                {props.tableHeader.map((name, i) => (
                  <SkeletonMeetPurpose key={i} />
                ))}
              </>
            ) : (
              <>
                {props.baseFareList && props.baseFareList.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>No Data Found...!</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {mergedMotRows.map((data, i) => (
                      <TableRow
                        align="center"
                        key={i}
                        className="client-list-row-data basefare-table"
                      >
                        <TableCell>
                          <span
                            style={{ cursor: "pointer", color: "#04a96d" }}
                            // onClick={() => props.editBasefare(data.customeBaseFareID, data.motID, 'view')}
                          >
                            <b>{data.motName}</b>
                          </span>
                        </TableCell>
                        <TableCell colSpan={8}>
                          {data.designationInfo.map((designation, index) => (
                            <TableRow
                              key={index}
                              className="table_row-inside-tabel-cell"
                            >
                              <TableCell style={{ minWidth: "300px" }}>
                                {designation.designationLevel !== 0 &&
                                designation.designationLevel !== "" ? (
                                  <>
                                    L{designation.designationLevel}-
                                    {designation.designationName}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                              <TableCell style={{ minWidth: "150px" }}>
                                {designation.motType}
                              </TableCell>
                              <TableCell style={{ minWidth: "150px" }}>
                                {designation.isPolicyAvaialble === false
                                  ? designation.motBaseFare
                                  : designation.baseFareAmount}
                              </TableCell>
                              <TableCell style={{ minWidth: "150px" }}>
                                {designation.motUnit}
                              </TableCell>
                              <TableCell style={{ minWidth: "160px" }}>
                                {designation.isPolicyAvaialble === false ? (
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      color: "rgb(255 191 19)",
                                    }}
                                  >
                                    Policy is Not Available
                                  </span>
                                ) : (
                                  // Render day limit only if the policy is available
                                  <>{designation.dayLimit}</>
                                )}
                              </TableCell>
                              <TableCell style={{ minWidth: "150px" }}>
                                {designation.addedByUserFullName}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ minWidth: "150px" }}
                              >
                                {designation.status === 1 ? (
                                  <span className="complete-status status-badge">
                                    Active
                                  </span>
                                ) : (
                                  <span className="pending-status status-badge">
                                    Inactive
                                  </span>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  minWidth: "200px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <button
                                  onClick={() =>
                                    props.editBasefare(
                                      designation.customeBaseFareID,
                                      data.motID
                                    )
                                  }
                                  className="btn client-history-link"
                                  style={{ padding: "10px 0px" }}
                                >
                                  {designation.customeBaseFareID === 0 ? (
                                    <span className="add-policy status-badge">
                                      Add Policy
                                    </span>
                                  ) : (
                                    <span>
                                      <i className="bi bi-pencil-fill"></i> Edit
                                    </span>
                                  )}
                                </button>
                                <button
                                  onClick={() =>
                                    props.editBasefare(
                                      designation.customeBaseFareID,
                                      designation.motID,
                                      "view"
                                    )
                                  }
                                  className="btn client-history-link"
                                  style={{ padding: "10px 0px" }}
                                >
                                  <span>
                                    <i className="bi bi-pencil-fill"></i> View
                                  </span>
                                </button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default BaseFareTable;
