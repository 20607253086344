import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import { dateFormatLocal } from '../../FormattedAmount';

function MapByCategoryTable(props) {
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                    style={{minWidth: name.width}}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>{props.tableHeader.map((name, i) => (
                                <SkeletonMeetPurpose key={i} />
                            ))}
                            </>
                            :
                            <>
                                {props.salesBeeRefferal && props.salesBeeRefferal.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={props.tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.salesBeeRefferal && props.salesBeeRefferal.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data'>
                                                <TableCell>
                                                    <strong>{data.addedByUserFullName}</strong><br/>
                                                    <strong className='color-yellow'>{data.addedByID}</strong>
                                                </TableCell>
                                                <TableCell>
                                                    {dateFormatLocal(data.addedOn)}
                                                </TableCell>
                                                <TableCell>
                                                    {data.name}
                                                </TableCell>
                                                <TableCell>
                                                    {data.email}
                                                </TableCell>
                                                <TableCell>
                                                    {data.mobile}
                                                </TableCell>
                                                <TableCell>
                                                    {data.note}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {data.commPer} %
                                                </TableCell>
                                                <TableCell align="center">
                                                    {data.projectName}
                                                </TableCell>
                                                <TableCell>
                                                    {data.type}
                                                </TableCell>
                                                <TableCell>
                                                <span className='follow-status status-badge'>{data.code}</span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {data.status === 1 ? <span className='complete-status status-badge'>Active</span> : <span className='pending-status status-badge'>Inactive</span>}
                                                </TableCell>

                                                {/* <TableCell className='action-class'>
                                                    <button
                                                        onClick={() =>props.editRefferal(data.salesBeeReferelID)}
                                                        className="btn client-history-link" >
                                                       <span><i className="bi bi-pencil-fill"></i> Edit</span>
                                                    </button>
                                                </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default MapByCategoryTable;
