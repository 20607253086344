import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Modal from "react-bootstrap/Modal";
import "../../styles/AddmeetingModel.scss";
import { baseURL } from "../BaseUrl";
import viewattachment from "../../images/No-Image.jpg";
import { SbPrintBtn } from "../SbButtons/SbCancelBtn";

function Viewmeetgattachmodel(props) {
  const [profileImageUrl, setProfileImageUrl] = useState([]);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log("MEETING-ID:", props.meetingId);

  useEffect(() => {
    fetchMeetingAttachment();
  }, [props.meetingId]);

  const fetchMeetingAttachment = () => {
    let requestOptions = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/GetAttachment/${props.meetingId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("Meeting-Attachments::", res.result);
        if (res.success) {
          setProfileImageUrl(res?.result?.listAttachments);
        } else {
          setProfileImageUrl("");
        }
      });
  };

  return (
    <div>
      <div className="model-add-client-body">
        <Modal
          show={props.meetingattachmentmodel}
          // onHide={props.handleCloseviewattachment}
          className="view-attachment-model"
        >
          <div className="view-attach-heading">
            <h6 className="view-attachment-head">VIEW ATTACHMENT</h6>
            <i
              className="fa fa-times"
              onClick={props.handleCloseviewattachment}
            ></i>
          </div>
          <div className="meetings-attachments" ref={componentRef}>
            {profileImageUrl !== "" && profileImageUrl !== null ? (
              <div className="row meeting-attachment-image">
                {/* <div className="meeting-attachment-image" key={i}> */}
                {profileImageUrl.map((data, i) => (
                  <div className="col-md-6 p-0" key={i}>
                    <img
                      src={`${baseURL}/${data}`}
                      // src={viewattachment}
                      alt=""
                      className="img-thumbnail"
                    />
                  </div>
                ))}
                {/* </div> */}
              </div>
            ) : (
              <div className="meeting-attachment-image">
                <img
                  src={viewattachment}
                  // src={viewattachment}
                  alt="Attachment-Image"
                  className="img-thumbnail"
                />
              </div>
            )}
          </div>

          <div className="attach-print-button">
            <SbPrintBtn onClickEffect={handlePrint} btnName="Print" />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Viewmeetgattachmodel;
