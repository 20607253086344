import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { useSelector } from "react-redux";
import { dateFormatLocal } from "../FormattedAmount";
import { Link } from "react-router-dom";

function TablePage(props) {
  const { totalCount } = useSelector((state) => state.data);

  return (
    <div
      className={`sb-table-div ${
        props.page === "dashboard" ? "sb-dashboard-table" : "sb-table-setion"
      } `}
    >
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.clientCategoryData,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table stickyHeader aria-label="sticky table table-responsive">
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    className={`thead-cell ${
                      name === "Action" || name === "Sl.No" || name === "Status"
                        ? "action-class"
                        : ""
                    }`}
                    style={{ minWidth: "50px" }}
                  >
                    &nbsp;{name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.page === "dashboard" && (
              <TableBody className="custom-table-body">
                {props.dBMeetingDetails.map((data, i) => (
                  <TableRow
                    align="center"
                    key={i}
                    className="client-list-row-data"
                  >
                    <TableCell align="left">
                      <div className="table-row-head-Text">
                        {data?.fullName}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {data?.directVisitMeetings}
                    </TableCell>
                    <TableCell align="center">
                      {data?.scheduleMeetings}
                    </TableCell>
                    <TableCell align="center">
                      {data?.myVisitMeetings}
                    </TableCell>
                    <TableCell align="center">{data?.totalMeeting}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {props.page !== "dashboard" && (
              <>
                {!props.clientCategoryData ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell>No data found...!</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {props.clientCategoryData
                      .filter((val) =>
                        val?.name?.toLowerCase().includes(props.searchValue)
                      )
                      .map((data, i) => (
                        <TableRow
                          align="center"
                          key={i}
                          className="client-list-row-data"
                        >
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell>
                            <div className="table-row-head-Text">
                              {data.name}
                            </div>
                          </TableCell>
                          {props.page === "holiday" && (
                            <TableCell>
                              {dateFormatLocal(data.fromDate)}
                            </TableCell>
                          )}
                          {props.page === "holiday" && (
                            <TableCell>
                              {dateFormatLocal(data.toDate)}
                            </TableCell>
                          )}
                          {props.page === "holiday" && (
                            <TableCell>{data?.state ?? "NA"}</TableCell>
                          )}
                          <TableCell>{data?.description}</TableCell>
                          {props.page === "clientCategory" && (
                            <TableCell>
                              {data.strReportingCategoryName}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {props.page === "holiday" && (
                              <>
                                {data.status === 1 ? (
                                  <span className="status-badge complete-status">
                                    Active
                                  </span>
                                ) : (
                                  <span className="status-badge pending-status">
                                    InActive
                                  </span>
                                )}
                              </>
                            )}
                            {props.page === "clientCategory" && (
                              <>
                                {data.status === 1 ? (
                                  <span className="status-badge complete-status">
                                    Active
                                  </span>
                                ) : (
                                  <span className="status-badge pending-status">
                                    InActive
                                  </span>
                                )}
                              </>
                            )}
                            {props.page === "stage" && (
                              <>
                                {data.status === 1 ? (
                                  <span className="status-badge complete-status">
                                    Active
                                  </span>
                                ) : (
                                  <span className="status-badge pending-status">
                                    InActive
                                  </span>
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell className="action-class">
                            {props.page === "holiday" ? (
                              <button
                                onClick={(e) =>
                                  props.handleEdit(data?.holidaysID)
                                }
                                className="btn client-history-link"
                              >
                                <i className="fa fa-edit" /> Edit
                              </button>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Group className="facility-details">
                                  <div
                                    className={`checkbox1 ${
                                      data.status === 1 ? "checkbox--on" : ""
                                    }`}
                                    name="status"
                                    onClick={(e) => {
                                      !props.btnStatus &&
                                      props.page === "clientCategory"
                                        ? props.handleStatus(data.categoryID)
                                        : props.page === "stage" &&
                                          props.handleStageStatus(data.stageID);
                                    }}
                                  >
                                    <div className="checkbox__ball"></div>
                                  </div>
                                </Form.Group>
                                {/* )} */}
                                {props.page === "clientCategory" && (
                                  <button
                                    onClick={(e) =>
                                      props.handleEditCategory(data?.categoryID)
                                    }
                                    className="btn client-history-link"
                                  >
                                    <i className="fa fa-edit" /> Edit
                                  </button>
                                )}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default TablePage;
