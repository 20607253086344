import React, { useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Phase.scss";
import { Button, Form, Image } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { optionsPageSizeList } from "../../DropdownsData";
import projectImage from "../../../images/products/phase-image.png";
import projectImage1 from "../../../images/products/phase-image1.png";
import completedIcon from "../../../images/completed-icon.jpg";
import pendingIcon from "../../../images/pending-icon.png";
import projectPersonImg from "../../../images/profile-1.png";
import editIcon from "../../../images/icons/pen-to-square-solid.svg";
import { Link } from "react-router-dom";
import AddPhase from "./AddPhase";
import EditPhase from "./EditPhase";

export default function Phase() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editPhase, setEditPhase] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditPhase(false);
  };

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            {/* USER TABLE START */}
            <div className="phase-table">
              <div className="seleting-menus">
                <div className="page-title">PHASE</div>

                <div className="page-top-bar-dropdowns">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Select"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Select"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Select"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />

                  <div className="input-group from-to-date-select">
                    <span className="input-group-text" id="from-label">
                      From
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={fromDate}
                        className="select from-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        onChange={(date) => setFromDate(date)}
                        maxDate={toDate}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>

                    <span className="input-group-text" id="to-label">
                      To
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={toDate}
                        className="select to-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        // maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-search-track"
                    // onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-add-project"
                    onClick={() => setShow(!show)}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-plus-square" /> &nbsp;
                      <h6>Add New</h6>
                    </span>
                  </button>
                </div>
              </div>
              <div className="table-responsive phase-scrollbar">
                <div className="phase-details">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 pl-0">
                      <div className="phase-left-part">
                        <div className="card">
                          <div className="row">
                            <div className="col-sm-6 p-0">
                              <div className="d-flex">
                                <div className="phase-img-status ">
                                  <Image
                                    src={projectImage}
                                    className="phase-img completed"
                                    alt="image"
                                  />
                                  <div className="phase-status completed">
                                    Completed
                                  </div>{" "}
                                </div>
                                <div className="phase-details">
                                  <span className="fz14 gryclr">
                                    <strong>01234567</strong>
                                  </span>
                                  <div className="p fz13 gryclr">
                                    Phase Name : Phase 01
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Phase Type : Planning
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Project Name : Project 01
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Phase Cost : 50,000
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Created On : 16/12/2022
                                  </div>
                                </div>
                              </div>
                              <div className="client-code">
                                <Link to="#" className="client-code-link">
                                  Clint Code :
                                </Link>
                                &nbsp; 82345675234
                              </div>
                            </div>

                            <div className="col-sm-6 p-0">
                              <div className="phase-person-deatils">
                                <div className="phase-person-status d-flex">
                                  <div className="phase-person-image">
                                    <Image
                                      src={projectPersonImg}
                                      className="phase-person-img"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="phase-details">
                                    <span className="fz14 gryclr">
                                      <strong>Raghav J</strong>
                                    </span>
                                    <div className="p fz13 gryclr">
                                      Start Date : 03-01-2023
                                    </div>
                                    <div className="p fz13 gryclr">
                                      End Date : 04-02-2023
                                    </div>
                                    <div className="p fz13 gryclr">
                                      Duration : 1 Month
                                    </div>
                                    <div className="p fz13 gryclr">
                                      Team Members : &nbsp;
                                      <Link to="#" className="team-count">
                                        5
                                      </Link>
                                    </div>
                                  </div>
                                  <Image
                                    src={editIcon}
                                    className="edit-icon"
                                    alt="edit-icon"
                                    onClick={() => setEditPhase(!editPhase)}
                                  />
                                  <Image
                                    src={completedIcon}
                                    className="status-icon"
                                    alt="status-icon"
                                  />
                                </div>
                                <div className="phase-task-download">
                                  <Link to="#" className="phase-task">
                                    <i className="fa fa-file-text-o" />
                                    Tasks : 50 &nbsp;
                                    <i className="fa fa-plus-circle" />
                                  </Link>
                                  <Link to="#" className="phase-download">
                                    <i className="fa fa-download" />
                                    Download
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 pr-0">
                      <div className="phase-right-part">
                        <div className="card">
                          <div className="row">
                            <div className="col-sm-6 p-0">
                              <div className="d-flex">
                                <div className="phase-img-status ">
                                  <Image
                                    src={projectImage1}
                                    className="phase-img pending"
                                    alt="image"
                                  />
                                  <div className="phase-status pending">
                                    Pending
                                  </div>{" "}
                                </div>
                                <div className="phase-details">
                                  <span className="fz14 gryclr">
                                    <strong>01234567</strong>
                                  </span>
                                  <div className="p fz13 gryclr">
                                    Phase Name : Phase 01
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Phase Type : Planning
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Project Name : Project 01
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Phase Cost : 50,000
                                  </div>
                                  <div className="p fz13 gryclr">
                                    Created On : 16/12/2022
                                  </div>
                                </div>
                              </div>
                              <div className="client-code">
                                <Link to="#" className="client-code-link">
                                  Clint Code :
                                </Link>
                                &nbsp; 82345675234
                              </div>
                            </div>

                            <div className="col-sm-6 p-0">
                              <div className="phase-person-deatils">
                                <div className="phase-person-status d-flex">
                                  <div className="phase-person-image">
                                    <Image
                                      src={projectPersonImg}
                                      className="phase-person-img"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="phase-details">
                                    <span className="fz14 gryclr">
                                      <strong>Raghav J</strong>
                                    </span>
                                    <div className="p fz13 gryclr">
                                      Start Date : 03-01-2023
                                    </div>
                                    <div className="p fz13 gryclr">
                                      End Date : 04-02-2023
                                    </div>
                                    <div className="p fz13 gryclr">
                                      Duration : 1 Month
                                    </div>
                                    <div className="p fz13 gryclr">
                                      Team Members : &nbsp;
                                      <Link to="#" className="team-count">
                                        5
                                      </Link>
                                    </div>
                                  </div>
                                  <Image
                                    src={editIcon}
                                    className="edit-icon"
                                    alt="edit-icon"
                                    onClick={() => setEditPhase(!editPhase)}
                                  />
                                  <Image
                                    src={pendingIcon}
                                    className="status-icon"
                                    alt="status-icon"
                                  />
                                </div>
                                <div className="phase-task-download">
                                  <Link to="#" className="phase-task">
                                    <i className="fa fa-file-text-o" />
                                    Tasks : 50 &nbsp;
                                    <i className="fa fa-plus-circle" />
                                  </Link>
                                  <Link to="#" className="phase-download">
                                    <i className="fa fa-download" />
                                    Download
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <button
                type="button"
                className="btn btn-download-excel"
                // onClick={exportExcelActiivity}
              >
                <i className="fa fa-download" /> &nbsp; Download
              </button>
              <span className="user-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<i className="fa fa-angle-double-right" />}
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<i className="fa fa-angle-double-left" />}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </span>
            </div>

            {/* ADD PRODUCT MODEL START */}
            <AddPhase show={show} handleClose={handleClose} />
            {/* ADD PRODUCT MODEL END */}

            {/* EDIT PRODUCT MODEL START */}
            {editPhase ? (
              <EditPhase editPhase={editPhase} handleClose={handleClose} />
            ) : (
              ""
            )}
            {/* EDIT PRODUCT MODEL END */}

            {/* PAGE CONTENT END*/}
          </div>
        </div>

        {/* MAIN BODY END */}
      </div>
    </div>
  );
}
