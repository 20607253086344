import * as types from "../ModalActionTypes/whatsappConfig_ActionType";


const initialState = {
    // #region initialize whatsapp comfig data
    whatsAppConfigData: [],
    singleWhatsAppConfigData: {},
    // #endregion
    // #region initialize whatsapp comfig data
    emailConfigData: [],
    // singleEmailConfigData: {},
    // #endregion
    loading: true,
};

const whastappConfigReducers = (state = initialState, action) => {
    switch (action.type) {
        // #region set payload whatsapp comfig data
        case types.Post_Whatsapp_Config_Data:
        case types.Get_Whatsapp_Config_Data:
            return {
                ...state,
                whatsAppConfigData: action.payload,
                loading: false,
            };
        case types.Get_SingleWhatsapp_Config_Data:
            return {
                ...state,
                singleWhatsAppConfigData: action.payload,
                loading: false,
            };
        // #endregion
        // #region set payload email comfig data
        // case types.Post_Whatsapp_Config_Data:
        case types.Post_Email_Config_Data:
        case types.Get_Email_Config_Data:
            return {
                ...state,
                emailConfigData: action.payload,
                loading: false,
            };
        // case types.Get_SingleWhatsapp_Config_Data:
        //     return {
        //         ...state,
        //         singleWhatsAppConfigData: action.payload,
        //         loading: false,
        //     };
        // #endregion
        default:
      return state;
  }
};
export default whastappConfigReducers;