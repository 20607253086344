import "../../styles/AddUserDetails.scss";
import { Col, FloatingLabel, Nav, Row, Tab } from "react-bootstrap";
import Select from "react-select";
import "../../styles/AddUserModel.scss";
import { useEffect, useRef, useState } from "react";
import DefaultLogo from "../../images/defalut-logo.png";
import { Link } from "react-router-dom";
import SalesBee from "../../images/MatricLogo.svg";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomeFieldsForCompany,
  loadCityList,
  loadClientCategoryDrop,
  loadClientDropDown,
  loadClientStageGrop,
  loadCustomFieldsForCompany,
  loadPostCompanyData,
  loadStateList,
  loadUpdateCompanyData,
  loadUploadSingleImage,
  loadZoneList,
} from "../../redux/action";
import * as HeaderAuthentication from "../HeaderAuthentication";
// import Geocode from "react-geocode";
import { baseURL } from "../BaseUrl";
import Skeleton from "react-loading-skeleton";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
import "../../styles/Clients.scss";
import CustomFieldsForCompany from "./CustomFieldsForCompany";
import { color } from "highcharts";

const AddClientData = (props) => {
  let editClientId = props.editClientId;
  const hiddenFileInput = useRef(null);

  let dispatch = useDispatch();
  const { singleClient } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { getCustomFieldsForCompany } = useSelector((state) => state.data);
  const formattedClientDropdown = clientDropdown.map((option) => ({
    ...option,
    label: `${option.label} - ${option.name}`,
  }));

  const [imgFile, setImgFile] = useState("");
  const [imgFileName, setImgFileName] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({
    value: 0,
    label: "",
  });
  const [selectedStage, setSelectedStage] = useState({ value: 0, label: "" });
  const [country, setCountry] = useState({ value: 0, label: "" });
  const [zoneId, setZoneId] = useState({ value: 0, label: "" });
  const [countryList, setCountryList] = useState([]);
  const [statusFlag, setStatusFlag] = useState(true);

  let cntyList = [{ value: 1, label: "India" }];

  useEffect(() => {
    dispatch(loadClientDropDown());
    dispatch(loadClientCategoryDrop());
    dispatch(loadClientStageGrop());
    props.setIsLoading(true);
    dispatch(loadZoneList(props.setIsLoading));
    setCountryList(cntyList);
    setLocation("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { categoryDropdown } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector((state) => state.data);
  const { zoneList } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (
          singleClient.countryID !== undefined &&
          singleClient.countryID !== 0
        ) {
          setCountry({ value: 1, label: "India" });
        }
        if (singleClient) {
          setClientData(singleClient);
          if (singleClient.enabledStatus === 1) {
            setStatusFlag(true);
          } else {
            setStatusFlag(false);
          }
          setLatLan({
            lat: Number(singleClient?.clientLoc?.latitude),
            lng: Number(singleClient?.clientLoc?.longitude),
          });
          setLocation(singleClient?.clientLoc?.location);
          setAddress(singleClient?.clientLoc?.location);
        }

        let categoryArray = [...categoryDropdown];
        categoryArray.forEach((category) => {
          if (category.value === singleClient.categoryID) {
            setSelectedCategory(category);
          }
        });
        let stageArray = [...stageDropdown];
        stageArray.forEach((stage) => {
          if (stage.value === singleClient.stageID) {
            setSelectedStage(stage);
          }
        });

        let zoneArray = [...zoneList];

        zoneArray.forEach((zone) => {
          if (zone.value === singleClient.zoneID) {
            setZoneId(zone);
            dispatch(loadStateList(zone.value));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editClientId, singleClient, zoneList]);

  const [stateId, setStateId] = useState({ value: 0, label: "" });

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (stateList) {
          stateList.forEach((state) => {
            if (state.value === singleClient.stateID) {
              setStateId(state);
              dispatch(loadCityList(state.value));
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList, editClientId]);

  const [cityId, setCityId] = useState({ value: 0, label: "" });

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (cityList) {
          cityList.forEach((city) => {
            if (city.value === singleClient.cityID) {
              setCityId(city);
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityList, editClientId]);

  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const [fieldReq, setFieldReq] = useState(false);

  useEffect(() => {
    let list = { ...clientLocation };
    if (latLan.lat == null && latLan.lng == null) {
      getLocation();
    } else {
      list.latitude = JSON.stringify(latLan.lat);
      list.longitude = JSON.stringify(latLan.lng);
    }

    if (location) {
      list.location = location;
    }

    clientData.clientLoc = list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLan, location]);

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported for your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLan({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
    setAddress("");
  };

  const [localImage, setLocalImage] = useState("");
  const [customeLocalImage, setCustomeLocalImage] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  // set state for post client data
  const [clientLocation, setClientLocation] = useState({
    latitude: "",
    longitude: "",
    location: "",
  });

  const [clientData, setClientData] = useState({
    clientName: "",
    logo: "",
    mobile: "",
    clientLoc: clientLocation,
    email: "",
    contactPersonName: "",
    landline: "",
    countryID: "",
    zoneID: "",
    stateID: "",
    cityID: "",
    pincode: "",
    categoryID: "",
    clientCategoryName: "",
    stageID: "",
    clientStageName: "",
    enabledStatus: 1,
  });
  // set state for post client data

  // Onchange Event
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(value)) {
        setClientData({ ...clientData, [name]: parseInt(value) });
      } else {
        e.preventDefault();
      }
    } else {
      setClientData({ ...clientData, [name]: value });
    }
  };

  const habdleEnableStatus = (e) => {
    if (e === 1) {
      setStatusFlag(true);
      clientData.enabledStatus = 1;
    } else if (e === 0) {
      setStatusFlag(false);
      clientData.enabledStatus = 0;
    }
  };
  // Onchange Event

  // handle select
  const handleSelectChange = (data, type) => {
    if (type === "category") {
      setSelectedCategory(data);
      clientData.categoryID = data.value;
      clientData.clientCategoryName = data.label;
    } else if (type === "stage") {
      setSelectedStage(data);
      clientData.stageID = data.value;
      clientData.clientStageName = data.label;
    } else if (type === "country") {
      setCountry(data);
      clientData.countryID = data.value;
    } else if (type === "zone") {
      setZoneId(data);
      dispatch(loadStateList(data.value));
      clientData.zoneID = data.value;
    } else if (type === "state") {
      setStateId(data);
      clientData.stateID = data.value;
      dispatch(loadCityList(data.value));
    } else if (type === "city") {
      setCityId(data);
      clientData.cityID = data.value;
    }
  };
  // handle select

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (props.companyReportingData.length !== 0 && editClientId) {
      let companyReportingClientsLabels = props.companyReportingData.map(
        (client) => {
          return { label: client.clientName, value: client.clientID, name: "" };
        }
      );
      setSelectedOptions(companyReportingClientsLabels);
    }
  }, [props.companyReportingData, editClientId]);

  const handleSelectOption = (selected) => {
    setSelectedOptions(selected);
  };

  const closeModal = () => {
    props.fetchClientData();
    props.setEditClientId("");
    setSelectedOptions([]);
    setLocation("");
    props.handleClose();
  };

  const [fieldValues, setFieldValues] = useState({});
  //  ADDING CLIENT START
  const addClientDetails = (e) => {
    e.preventDefault();
    if (
      // imgFile !== "" &&
      clientData.clientName !== "" &&
      clientData.contactPersonName !== "" &&
      Math.abs(clientData?.mobile).toString().length === 10 &&
      clientData.categoryID &&
      clientData.stageID &&
      location !== "" &&
      clientData.countryID &&
      clientData.zoneID &&
      clientData.stateID
    ) {
      const formData = new FormData();
      formData.append("Files", imgFile);
      formData.append("Directory", "Company");
      formData.append("FileNamePrefix", clientData.clientName);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.salesbeeImgAuth,
        body: formData,
      };
      let actionType;
      if (editClientId) {
        actionType = "update-data";
      } else {
        actionType = "post-data";
      }
      const categoryReportingClientIDsArray = selectedOptions.map((option) =>
        parseInt(option.value)
      );
      clientData.categoryReportingClientIds = categoryReportingClientIDsArray;

      if (imgFile !== "") {
        dispatch(
          loadUploadSingleImage(
            requestOption,
            clientData,
            closeModal,
            actionType
          )
        );
      } else {
        const clientDetails = {
          client: clientData,
        };

        let requestOption = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.postTravelizeAuth,
          body: JSON.stringify(clientDetails),
        };
        if (editClientId) {
          dispatch(
            loadUpdateCompanyData(
              requestOption,
              closeModal,
              customFields,
              editClientId,
              customeLocalImage
            )
          );
        } else {
          // dispatch(loadPostCompanyData(requestOption, closeModal));
          dispatch(
            loadPostCompanyData(
              requestOption,
              closeModal,
              customFields,
              customeLocalImage
            )
          )
            // .then(response => response.json())
            // .then(responseData => {
            //     const clientId = responseData.clientID;

            //     getCustomFieldsForCompany.forEach((field) => {
            //         field.tableID = clientId;
            //         field.value = fieldValues[field.fieldName] || "";
            //     });
            // console.log(getCustomFieldsForCompany, "getCustomFieldsForCompany getCustomFieldsForCompany")
            // let data={

            // }
            // let postCustomData = {
            //     method: "POST",
            //     mode: "cors",
            //     headers: HeaderAuthentication.postTravelizeAuth,
            //     body: JSON.stringify(data)
            // }
            // dispatch(addCustomeFieldsForCompany(postCustomData, closeModal))
            // console.log(data, 'data')
            // })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required...!",
      });
    }
  };
  //  ADDING CLIENT END

  // // GOOGLE MAPS LOCATION START

  // useEffect(() => {
  //   if (searchVal) {
  //     Geocode.fromAddress(searchVal).then(
  //       (response) => {
  //         const { lat, lng } = response.results[0].geometry.location;

  //         setLatLan({
  //           lat: lat,
  //           lng: lng,
  //         });
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   }
  // }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  // useEffect(() => {
  //   if (latLan.lat !== null && latLan.lat !== "") {
  //     Geocode.fromLatLng(latLan.lat, latLan.lng).then(
  //       (response) => {
  //         const address = response.results[0].formatted_address;

  //         setAddress(address);
  //         setLocation(address);
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   } else {
  //     addressFromGoogle();
  //   }
  // }, [latLan]);

  const addressFromGoogle = () => {
    if (googleMapsModel) {
      openGoogleMaps();
    }
  };

  // GOOGLE MAPS LOCATION END

  const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
  const [showMachineDetailsHeader, setShowMachineDetailsHeader] =
    useState(true);
  const [activeMenu, setActiveMenu] = useState(1);

  const toggleMachineDetails = () => {
    setShowMachineDetailsForm(true);
    setShowMachineDetailsHeader(true);
    setActiveMenu(1);
  };

  const [selectNavKey, setSelectNavKey] = useState("company");

  const handleTabItem = (navKey) => {
    setSelectNavKey(navKey);
  };

  const moveToNextTab = (e, selectNavKey) => {
    e.preventDefault();
    if (selectNavKey === "company") {
      if (
        clientData.clientName !== "" &&
        clientData.contactPersonName !== "" &&
        Math.abs(clientData?.mobile).toString().length === 10 &&
        clientData.categoryID &&
        clientData.stageID
      ) {
        handleTabItem("customForm");
      } else {
        setFieldReq(true);
        setAlertMessage({
          alertType: "fails",
          message: "Required...!",
        });
      }
    }
    // else if (selectNavKey === "address") {
    //     if (location !== "" &&
    //         clientData.countryID &&
    //         clientData.zoneID &&
    //         clientData.stateID) {
    //         handleTabItem("customForm")
    //         // handleTabItem("preview")
    //     } else {
    //         setFieldReq(true);
    //         setAlertMessage({
    //             alertType: "fails",
    //             message: "Required...!",
    //         });
    //     }
    // }
    // else if (selectNavKey === "customForm") {
    //     handleTabItem("preview")
    // }
  };

  const moveToPreviousTab = (e, selectNavKey) => {
    e.preventDefault();
    if (selectNavKey === "customForm") {
      handleTabItem("address");
    } else if (selectNavKey === "address") {
      handleTabItem("company");
    }
  };

  useEffect(() => {
    dispatch(loadCustomFieldsForCompany());
  }, []);

  const [customFields, setCustomFields] = useState([]);
  // useEffect(() => {
  //     if (getCustomFieldsForCompany && getCustomFieldsForCompany.length !== 0) {
  //         setCustomFields(getCustomFieldsForCompany)
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getCustomFieldsForCompany])

  const { getSingleCustomFieldsForCompany } = useSelector(
    (state) => state.data
  );

  // useEffect(() => {
  //     let updaterray = [];
  //     if (getSingleCustomFieldsForCompany.length !== 0 && getCustomFieldsForCompany.length !== 0) {
  //         const updatedCustomFields = getCustomFieldsForCompany.map(field => {
  //             const fieldValue = getSingleCustomFieldsForCompany.find(singleField => singleField.customeFieldID === field.customeFieldID);
  //             return {
  //                 ...field,
  //                 value: fieldValue ? fieldValue.value : ''
  //             };
  //         });
  //         updaterray = updatedCustomFields;
  //         // setCustomFields(updatedCustomFields);
  //     } else if (getSingleCustomFieldsForCompany.length === 0 && getCustomFieldsForCompany && getCustomFieldsForCompany.length !== 0) {
  //         // setCustomFields(getCustomFieldsForCompany)
  //         updaterray = getCustomFieldsForCompany;
  //     }
  //     setCustomFields(updaterray)
  //     console.log(updaterray,'updaterray')
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (
      getSingleCustomFieldsForCompany &&
      getSingleCustomFieldsForCompany.length !== 0 &&
      getCustomFieldsForCompany &&
      getCustomFieldsForCompany.length !== 0
    ) {
      const updatedCustomFields = getCustomFieldsForCompany.map((field) => {
        const fieldValue = getSingleCustomFieldsForCompany.find(
          (singleField) => singleField.customeFieldID === field.customeFieldID
        );
        return {
          ...field,
          value: fieldValue ? fieldValue.value : "",
        };
      });
      setCustomFields(updatedCustomFields);
    } else if (
      getSingleCustomFieldsForCompany &&
      getSingleCustomFieldsForCompany.length === 0 &&
      getCustomFieldsForCompany &&
      getCustomFieldsForCompany.length !== 0
    ) {
      setCustomFields(getCustomFieldsForCompany);
    }
  }, [getSingleCustomFieldsForCompany, getCustomFieldsForCompany]);

  return (
    <div id="main-page" style={{ height: "100vh" }}>
      <div className="page-wrapper" style={{ height: "100%" }}>
        <Tab.Container
          id="mi-addform-tab-panel"
          className="customeTab"
          defaultActiveKey={selectNavKey}
          style={{ height: "100%" }}
        >
          <Row
            className="mi-addform-row-panel customeTab-row"
            style={{ height: "100%" }}
          >
            <Col sm={2} className="mi-addform-col-panel">
              <div className="add-form-logo">
                <Link className="add-form-logo-link" to="/dashboard">
                  <img
                    src={SalesBee}
                    alt="product-title-img"
                    className="add-form-logo-img"
                  />
                </Link>
              </div>
              <Nav variant="pills" className="flex-column pt-4">
                <Nav.Item
                  className={`mi-tab-nav-item ${
                    selectNavKey !== "company" ? "filled-color" : "active"
                  }`}
                  style={{
                    pointerEvents: selectNavKey === "preview" ? "none" : "",
                  }}
                  onClick={() => handleTabItem("company")}
                >
                  <Nav.Link
                    eventKey="company"
                    className={`mi-tab-nav-item-link ${
                      selectNavKey === "company" ? "active" : ""
                    }`}
                  >
                    <span className="mi-tab-number-item">01</span>
                    <div className="mi-tab-item-text">Company Details</div>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className={`mi-tab-nav-item ${(selectNavKey === "preview" || selectNavKey === "customForm") ? 'filled-color' : selectNavKey === "company" ? 'empty-color' : 'active'}`}
                                    onClick={() => handleTabItem("address")}>
                                    <Nav.Link eventKey="address" className={`mi-tab-nav-item-link ${selectNavKey === "address" ? 'active' : ''}`}><span className="mi-tab-number-item">02</span><div className="mi-tab-item-text">Company Address</div></Nav.Link>
                                </Nav.Item> */}
                <Nav.Item
                  className={`mi-tab-nav-item ${
                    selectNavKey === "preview"
                      ? "filled-color"
                      : selectNavKey === "address" || selectNavKey === "company"
                      ? "empty-color"
                      : "active"
                  }`}
                  onClick={() => handleTabItem("customForm")}
                >
                  <Nav.Link
                    eventKey="customForm"
                    className={`mi-tab-nav-item-link ${
                      selectNavKey === "customForm" ? "active" : ""
                    }`}
                  >
                    <span className="mi-tab-number-item">02</span>
                    <div className="mi-tab-item-text">Custom Fields</div>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className={`mi-tab-nav-item ${selectNavKey !== "preview" ? 'empty-color' : 'active'}`}
                                    style={{ pointerEvents: selectNavKey === "company" ? 'none' : '' }} onClick={() => handleTabItem("preview")}>
                                    <Nav.Link eventKey="preview" className={`mi-tab-nav-item-link ${selectNavKey === "preview" ? 'active' : ''}`}><span className="mi-tab-number-item">04</span><div className="mi-tab-item-text">Pre-view Data</div></Nav.Link>
                                </Nav.Item> */}
              </Nav>
            </Col>
            <Col sm={10} className="p-0">
              <div className="add-user-details-second-div">
                <div className="add-user-details-second-div-header">
                  <div className="back-btn" onClick={closeModal}>
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="form-header-text">Add Company</h4>
                    {/* <p>Please enter company infomation and proceed to the next step.</p> */}
                  </div>
                </div>
                <form onSubmit={addClientDetails}>
                  <div className="add-form-template-div">
                    <Tab.Content>
                      {selectNavKey === "company" && (
                        <div>
                          <div className="mi-subform-header">
                            <div className="form-header-sub">
                              <h6>
                                Enter Company Details<span></span>
                              </h6>
                            </div>
                            {/* <div><p>Please enter company information and proceed to the next step.</p></div> */}
                          </div>
                          {/* <hr class="dashed" /> */}
                          <Row>
                            <Col md={4}>
                              <div className="mi-image-form-col">
                                <div className="add-user-main-logo-image mb-4">
                                  {editClientId && singleClient ? (
                                    <img
                                      className="add-user-images"
                                      src={
                                        localImage
                                          ? localImage
                                          : singleClient.logo
                                          ? `${baseURL}/${singleClient.logo}`
                                          : DefaultLogo
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="add-user-images"
                                      src={
                                        localImage ? localImage : DefaultLogo
                                      }
                                      alt="Product Images"
                                    />
                                  )}
                                  <div>
                                    <button
                                      type="button"
                                      className="btn upload-logo"
                                      onClick={uploadClick}
                                    >
                                      <i className="bi bi-camera-fill" />
                                    </button>
                                    <input
                                      id="getFile"
                                      type="file"
                                      name="filetobase64"
                                      accept="image/*"
                                      className="btn-primary upload"
                                      ref={hiddenFileInput}
                                      onChange={handleChange}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="Company Name"
                                  className={`${
                                    clientData.clientName || ""
                                      ? "float-input-visible"
                                      : "float-hidden float-input"
                                  }`}
                                >
                                  <input
                                    type="text"
                                    className="form-control form add-client-input model-input"
                                    id="exampleFormControlInput1"
                                    placeholder="Company Name"
                                    name="clientName"
                                    value={clientData?.clientName || ""}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error`}
                                  style={{
                                    visibility: `${
                                      fieldReq && clientData?.clientName === ""
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>

                              <div className="mb-2">
                                <FloatingLabel
                                  label="Mobile Number"
                                  className={`${
                                    clientData.mobile || ""
                                      ? "float-input-visible"
                                      : "float-hidden float-input"
                                  }`}
                                >
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    className="form-control form add-client-input model-input"
                                    id="exampleFormControlInput1"
                                    placeholder="Mobile Number"
                                    name="mobile"
                                    value={clientData?.mobile || ""}
                                    maxLength={10}
                                    onChange={handleInputChange}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{
                                    visibility: `${
                                      fieldReq &&
                                      Math.abs(clientData?.mobile).toString()
                                        .length !== 10
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {`${alertMessage.message} - 10 Numbers`}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="Landline"
                                  className={`${
                                    clientData.landline || ""
                                      ? "float-input-visible"
                                      : "float-hidden float-input"
                                  }`}
                                >
                                  <input
                                    type="number"
                                    autoComplete="off"
                                    className="form-control form add-client-input model-input"
                                    id="exampleFormControlInput1"
                                    placeholder="Landline"
                                    name="landline"
                                    value={clientData?.landline || ""}
                                    onChange={handleInputChange}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{ visibility: "hidden" }}
                                  className="form-text text-muted "
                                ></small>
                              </div>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="Select Zone"
                                  className={`${
                                    zoneId.value
                                      ? "float-visible-select"
                                      : "float-hidden float-select"
                                  }`}
                                >
                                  <Select
                                    className="react-select-container-list model-select"
                                    classNamePrefix="react-select-list"
                                    options={zoneList}
                                    placeholder="Select Zone"
                                    value={
                                      zoneId.value ? zoneId : zoneList.value
                                    }
                                    onInputChange={zoneList.label}
                                    onChange={(data) =>
                                      handleSelectChange(data, "zone")
                                    }
                                    isSearchable={true}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{
                                    visibility: `${
                                      fieldReq && clientData.zoneID === ""
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="Contact Person Name"
                                  className={`${
                                    clientData.contactPersonName || ""
                                      ? "float-input-visible"
                                      : "float-hidden float-input"
                                  }`}
                                >
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    className="form-control form add-client-input model-input"
                                    id="exampleFormControlInput1"
                                    placeholder="Contact Person Name"
                                    name="contactPersonName"
                                    value={clientData?.contactPersonName || ""}
                                    onChange={handleInputChange}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                  style={{
                                    visibility: `${
                                      fieldReq &&
                                      clientData?.contactPersonName === ""
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="Email"
                                  className={`${
                                    clientData.email || ""
                                      ? "float-input-visible"
                                      : "float-hidden float-input"
                                  }`}
                                >
                                  <input
                                    type="email"
                                    autoComplete="off"
                                    className="form-control form add-client-input model-input"
                                    id="exampleFormControlInput1"
                                    placeholder="Email"
                                    name="email"
                                    value={clientData?.email || ""}
                                    onChange={handleInputChange}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                  style={{
                                    visibility: `${
                                      fieldReq && clientData?.email === ""
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                >
                                  {alertMessage.message}{" "}
                                  <i className="fa fa-exclamation-circle" />
                                </small>
                              </div>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="Select Country"
                                  className={`${
                                    country.value
                                      ? "float-visible-select"
                                      : "float-hidden float-select"
                                  }`}
                                >
                                  <Select
                                    className="react-select-container-list model-select"
                                    classNamePrefix="react-select-list"
                                    options={countryList}
                                    placeholder="Select Country"
                                    value={
                                      country.value
                                        ? country
                                        : countryList.value
                                    }
                                    onInputChange={countryList.label}
                                    onChange={(data) =>
                                      handleSelectChange(data, "country")
                                    }
                                    isSearchable={true}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{
                                    visibility: `${
                                      fieldReq && clientData.countryID === ""
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                              <div className="mb-4">
                                <FloatingLabel
                                  label="Select State"
                                  className={`${
                                    stateId.value
                                      ? "float-visible-select"
                                      : "float-hidden float-select"
                                  }`}
                                >
                                  <Select
                                    className="react-select-container-list model-select"
                                    classNamePrefix="react-select-list"
                                    options={stateList}
                                    placeholder="Select State"
                                    value={
                                      stateId.value ? stateId : stateList.value
                                    }
                                    onInputChange={stateList.label}
                                    onChange={(data) =>
                                      handleSelectChange(data, "state")
                                    }
                                    isSearchable={true}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{
                                    visibility: `${
                                      fieldReq && clientData.stateID === ""
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <FloatingLabel
                                  label="Select Category"
                                  className={`${
                                    selectedCategory.value
                                      ? "float-visible-select"
                                      : "float-hidden float-select"
                                  }`}
                                >
                                  <Select
                                    className="react-select-container-list model-select"
                                    classNamePrefix="react-select-list"
                                    options={categoryDropdown}
                                    placeholder="Select Category"
                                    value={
                                      selectedCategory.value
                                        ? selectedCategory
                                        : categoryDropdown.value
                                    }
                                    onInputChange={categoryDropdown.label}
                                    onChange={(data) =>
                                      handleSelectChange(data, "category")
                                    }
                                    isSearchable={true}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{
                                    visibility: `${
                                      fieldReq &&
                                      (clientData.categoryID === "" ||
                                        clientData.categoryID === 0)
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="Select City"
                                  className={`${
                                    cityId.value
                                      ? "float-visible-select"
                                      : "float-hidden float-select"
                                  }`}
                                >
                                  <Select
                                    className="react-select-container-list model-select"
                                    classNamePrefix="react-select-list"
                                    options={cityList}
                                    placeholder="Select City"
                                    value={
                                      cityId.value ? cityId : cityList.value
                                    }
                                    onInputChange={cityList.label}
                                    onChange={(data) =>
                                      handleSelectChange(data, "city")
                                    }
                                    isSearchable={true}
                                    isDisabled={!stateId && true}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{
                                    visibility: `${
                                      fieldReq && cityId.value === 0
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <FloatingLabel
                                  label="PinCode"
                                  className={`${
                                    clientData.pincode
                                      ? "float-input-visible"
                                      : "float-hidden float-input"
                                  }`}
                                >
                                  <input
                                    type="number"
                                    autoComplete="off"
                                    className="form-control form add-client-input model-input"
                                    id="exampleFormControlInput1"
                                    placeholder="PinCode"
                                    name="pincode"
                                    value={clientData?.pincode}
                                    onChange={handleInputChange}
                                  />
                                </FloatingLabel>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <div className="mb-1">
                                <FloatingLabel
                                  label="Select Stage"
                                  className={`${
                                    selectedStage.value
                                      ? "float-visible-select"
                                      : "float-hidden float-select"
                                  }`}
                                >
                                  <Select
                                    className="react-select-container-list model-select"
                                    classNamePrefix="react-select-list"
                                    options={stageDropdown}
                                    placeholder="Select Stage"
                                    value={
                                      selectedStage.value
                                        ? selectedStage
                                        : stageDropdown.value
                                    }
                                    onInputChange={stageDropdown.label}
                                    onChange={(data) =>
                                      handleSelectChange(data, "stage")
                                    }
                                    isSearchable={true}
                                  />
                                </FloatingLabel>
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  style={{
                                    visibility: `${
                                      fieldReq &&
                                      (clientData.stageID === "" ||
                                        clientData.stageID === 0)
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                  }}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                              <div className={`${editClientId ? "mb-4" : ""}`}>
                                <FloatingLabel
                                  label="Select Company"
                                  className={`${
                                    selectedOptions.length !== 0
                                      ? "float-visible-select"
                                      : "float-hidden float-select"
                                  }`}
                                >
                                  <MultiSelect
                                    options={formattedClientDropdown}
                                    value={selectedOptions}
                                    onChange={handleSelectOption}
                                    labelledBy={"Select"}
                                    overrideStrings={{
                                      selectSomeItems: "Select Company",
                                    }}
                                  />
                                </FloatingLabel>
                              </div>
                              <div className="">
                                {editClientId && (
                                  <span className="enable-status d-flex">
                                    <h6>Enable</h6>
                                    <span className="enable-radio d-flex">
                                      <div className="form-check enable-radio-status">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="enableDisable"
                                          id="enableDisable1"
                                          checked={statusFlag}
                                          onChange={() => habdleEnableStatus(1)}
                                        />
                                        <label className="form-check-label">
                                          Yes
                                        </label>
                                      </div>
                                      <div className="form-check enable-radio-status">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="enableDisable"
                                          id="enableDisable2"
                                          checked={!statusFlag}
                                          onChange={() => habdleEnableStatus(0)}
                                        />
                                        <label className="form-check-label">
                                          No
                                        </label>
                                      </div>
                                    </span>
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={8}>
                              <div className="client-location-by-map">
                                <FloatingLabel
                                  label="Street Address/House No./Locality"
                                  className={`${
                                    location
                                      ? "float-input-visible"
                                      : "float-hidden float-input"
                                  }`}
                                >
                                  <textarea
                                    rows="3"
                                    cols="20"
                                    name="usrtxt"
                                    wrap="hard"
                                    className="form-control form-textarea add-user-input"
                                    id="exampleFormControlInput1"
                                    placeholder="Street Address/House No./Locality"
                                    value={location}
                                    readOnly={true}
                                    onChange={(e) => setLocation(e)}
                                  />
                                </FloatingLabel>

                                <i
                                  className="bi bi-geo-alt"
                                  title="Google Location"
                                  onClick={openGoogleMaps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                      {/* {selectNavKey === "address" &&
                                                <div>
                                                    <div className="mi-subform-header">
                                                        <div className='form-header-sub'>
                                                            <h6>Enter Company Address<span></span></h6>
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <Col md={4} >
                                                            <div className="mb-3">
                                                                <FloatingLabel
                                                                    label="Select Country"
                                                                    className={`${country.value
                                                                        ? "float-visible-select"
                                                                        : "float-hidden float-select"
                                                                        }`}
                                                                >
                                                                    <Select
                                                                        className="react-select-container-list model-select"
                                                                        classNamePrefix="react-select-list"
                                                                        options={countryList}
                                                                        placeholder="Select Country"
                                                                        value={country.value ? country : countryList.value}
                                                                        onInputChange={countryList.label}
                                                                        onChange={(data) => handleSelectChange(data, "country")}
                                                                        isSearchable={true}
                                                                    />
                                                                </FloatingLabel>
                                                                <small
                                                                    id={`Error${alertMessage.alertType}`}
                                                                    style={{ visibility: `${fieldReq && clientData.countryID === "" ? 'visible' : 'hidden'}` }}
                                                                    className="form-text text-muted "
                                                                >{alertMessage.message}{" "}
                                                                    <i
                                                                        className="fa fa-exclamation-circle"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </small>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="mb-2">
                                                                <FloatingLabel
                                                                    label="Select Zone"
                                                                    className={`${zoneId.value
                                                                        ? "float-visible-select"
                                                                        : "float-hidden float-select"
                                                                        }`}
                                                                >
                                                                    <Select
                                                                        className="react-select-container-list model-select"
                                                                        classNamePrefix="react-select-list"
                                                                        options={zoneList}
                                                                        placeholder="Select Zone"
                                                                        value={zoneId.value ? zoneId : zoneList.value}
                                                                        onInputChange={zoneList.label}
                                                                        onChange={(data) => handleSelectChange(data, "zone")}
                                                                        isSearchable={true}
                                                                    />
                                                                </FloatingLabel>
                                                                <small
                                                                    id={`Error${alertMessage.alertType}`}
                                                                    style={{ visibility: `${fieldReq && clientData.zoneID === "" ? 'visible' : 'hidden'}` }}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {alertMessage.message}{" "}
                                                                    <i
                                                                        className="fa fa-exclamation-circle"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </small>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="mb-2">
                                                                <FloatingLabel
                                                                    label="Select State"
                                                                    className={`${stateId.value
                                                                        ? "float-visible-select"
                                                                        : "float-hidden float-select"
                                                                        }`}
                                                                >
                                                                    <Select
                                                                        className="react-select-container-list model-select"
                                                                        classNamePrefix="react-select-list"
                                                                        options={stateList}
                                                                        placeholder="Select State"
                                                                        value={stateId.value ? stateId : stateList.value}
                                                                        onInputChange={stateList.label}
                                                                        onChange={(data) => handleSelectChange(data, "state")}
                                                                        isSearchable={true}
                                                                    />
                                                                </FloatingLabel>
                                                                <small
                                                                    id={`Error${alertMessage.alertType}`}
                                                                    style={{ visibility: `${fieldReq && clientData.stateID === "" ? 'visible' : 'hidden'}` }}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {alertMessage.message}{" "}
                                                                    <i
                                                                        className="fa fa-exclamation-circle"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </small>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={4} >
                                                            <div className="mb-1">
                                                                <FloatingLabel
                                                                    label="Select City"
                                                                    className={`${cityId.value
                                                                        ? "float-visible-select"
                                                                        : "float-hidden float-select"
                                                                        }`}
                                                                >
                                                                    <Select
                                                                        className="react-select-container-list model-select"
                                                                        classNamePrefix="react-select-list"
                                                                        options={cityList}
                                                                        placeholder="Select City"
                                                                        value={cityId.value ? cityId : cityList.value}
                                                                        onInputChange={cityList.label}
                                                                        onChange={(data) => handleSelectChange(data, "city")}
                                                                        isSearchable={true}
                                                                        isDisabled={!stateId && true}
                                                                    />
                                                                </FloatingLabel>
                                                                <small
                                                                    id={`Error${alertMessage.alertType}`}
                                                                    style={{ visibility: `${fieldReq && cityId.value === 0 ? 'visible' : 'hidden'}` }}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {alertMessage.message}{" "}
                                                                    <i
                                                                        className="fa fa-exclamation-circle"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </small>
                                                            </div>
                                                            <div className={`${editClientId ? "mb-4" : ''}`}>
                                                                <FloatingLabel
                                                                    label="PinCode"
                                                                    className={`${clientData.pincode
                                                                        ? "float-input-visible"
                                                                        : "float-hidden float-input"
                                                                        }`}
                                                                >
                                                                    <input
                                                                        type="number"
                                                                        autoComplete="off"
                                                                        className="form-control form add-client-input model-input"
                                                                        id="exampleFormControlInput1"
                                                                        placeholder="PinCode"
                                                                        name="pincode"
                                                                        value={clientData?.pincode}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </FloatingLabel>
                                                            </div>
                                                            <div className="">
                                                                {editClientId && (
                                                                    <span className="enable-status d-flex">
                                                                        <h6>Enable</h6>
                                                                        <span className="enable-radio d-flex">
                                                                            <div className="form-check enable-radio-status">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="enableDisable"
                                                                                    id="enableDisable1"
                                                                                    checked={statusFlag}
                                                                                    onChange={() => habdleEnableStatus(1)}
                                                                                />
                                                                                <label className="form-check-label">Yes</label>
                                                                            </div>
                                                                            <div className="form-check enable-radio-status">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="enableDisable"
                                                                                    id="enableDisable2"
                                                                                    checked={!statusFlag}
                                                                                    onChange={() => habdleEnableStatus(0)}
                                                                                />
                                                                                <label className="form-check-label">No</label>
                                                                            </div>
                                                                        </span>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col md={8}>
                                                            <div className="client-location-by-map" >
                                                                <FloatingLabel
                                                                    label="Street Address/House No./Locality"
                                                                    className={`${location
                                                                        ? "float-input-visible"
                                                                        : "float-hidden float-input"
                                                                        }`}
                                                                >
                                                                    <textarea
                                                                        rows="3"
                                                                        cols="20"
                                                                        name="usrtxt"
                                                                        wrap="hard"
                                                                        className="form-control form-textarea add-user-input"
                                                                        id="exampleFormControlInput1"
                                                                        placeholder="Street Address/House No./Locality"
                                                                        value={location}
                                                                        readOnly={true}
                                                                        onChange={(e) => setLocation(e)}
                                                                    />
                                                                </FloatingLabel>

                                                                <i
                                                                    className="bi bi-geo-alt"
                                                                    title="Google Location"
                                                                    onClick={openGoogleMaps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}
                      {selectNavKey === "customForm" && (
                        <div>
                          <div className="mi-subform-header">
                            <div className="form-header-sub">
                              <h6>
                                Add Custom Fields<span></span>
                              </h6>
                            </div>
                            {/* <div><p>Please enter additional custom fields and proceed to the next step.</p></div> */}
                          </div>
                          {/* <hr class="dashed" /> */}
                          <CustomFieldsForCompany
                            customFields={customFields}
                            setCustomFields={setCustomFields}
                            addClientDetails={addClientDetails}
                            fieldValues={fieldValues}
                            setFieldValues={setFieldValues}
                            setCustomeLocalImage={setCustomeLocalImage}
                          />
                        </div>
                      )}
                      {/* {selectNavKey === "preview" &&
                                                <div>
                                                    <div className="mi-subform-header">
                                                        <div className='form-header-sub'>
                                                            <h6>Preview Data<span></span></h6>
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <Col md={4} >
                                                            <div className="add-user-main-logo-image">
                                                                {editClientId && singleClient ? (
                                                                    <img
                                                                        className="add-user-images"
                                                                        src={
                                                                            localImage
                                                                                ? localImage
                                                                                : singleClient.logo
                                                                                    ? `${baseURL}/${singleClient.logo}`
                                                                                    : DefaultLogo
                                                                        }
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="add-user-images"
                                                                        src={localImage ? localImage : DefaultLogo}
                                                                        alt="Product Images"
                                                                    />
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="preview-data-value">
                                                                <div>Company Name:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{clientData.clientName}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>Mobile Number:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{clientData.mobile}</b></div>
                                                            </div>
                                                            {clientData.landline && <div className="preview-data-value">
                                                                <div>Landline:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{clientData.landline}</b></div>
                                                            </div>}
                                                            <div className="preview-data-value">
                                                                <div>Category:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{selectedCategory.label}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>Zone:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{zoneId.label}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>City:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{cityId.label}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>Location:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{location}</b></div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="preview-data-value">
                                                                <div>Contact Person:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{clientData.contactPersonName}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>Email:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{clientData.email}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>Stage:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{selectedStage.label}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>Country:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{country.label}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>State:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{stateId.label}</b></div>
                                                            </div>
                                                            <div className="preview-data-value">
                                                                <div>Pin code:&nbsp;&nbsp;&nbsp;</div>
                                                                <div><b>{clientData.pincode}</b></div>
                                                            </div>
                                                            {selectedOptions.length !== 0 && <div className="preview-data-value">
                                                                <div>Companies:&nbsp;&nbsp;&nbsp;</div>
                                                                {selectedOptions.map(item => <div><b>{item.label}</b></div>)}
                                                            </div>}

                                                        </Col>

                                                    </Row>
                                                    <div className='d-flex justify-content-center'>
                                                        <h6 style={{ color: "#ff847f" }}>Custom Fields</h6>
                                                    </div>
                                                    <hr class="dashed" />
                                                    <Row>
                                                        <Col md={4}></Col>
                                                        <Col md={4}>
                                                            {getCustomFieldsForCompany.map((header, i) => (
                                                                <div key={i} align={header.align} style={{ minWidth: `${header.width}` }} className="preview-data-value">
                                                                    {header.fieldName}:
                                                                </div>
                                                            ))}
                                                        </Col>
                                                        <Col md={4}>
                                                            {customFields.map((field, index) => (
                                                                <div key={index} className="preview-data-value">
                                                                    <b>{field.value}</b>
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}
                    </Tab.Content>
                  </div>
                  <div className="add-form-save-cancel">
                    {selectNavKey !== "customForm" ? (
                      <SBSaveUpdateBtn
                        btnName="Next"
                        onClickEffect={(e) => moveToNextTab(e, selectNavKey)}
                      />
                    ) : (
                      <SBSaveUpdateBtn
                        btnName={editClientId ? "Update" : "Save"}
                      />
                    )}
                    <SbCancelBtn
                      onClickEffect={(e) => moveToPreviousTab(e, selectNavKey)}
                      btnName="Previous"
                    />
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      {googleMapsModel === true && (
        <GoogleMapAddressModel
          googleMapsModel={googleMapsModel}
          addressFromGoogle={addressFromGoogle}
          openGoogleMaps={openGoogleMaps}
          latLan={latLan}
          searchLocation={searchLocation}
          searchVal={searchVal}
          onMarkerDragEnd={onMarkerDragEnd}
          address={address}
        />
      )}
    </div>
  );
};

export default AddClientData;
