import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form, Row, Col } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";

export default function EditRadioModel(props) {
  let newData = props.data;

  const [selectedData, setSelectedData] = useState(newData.inputData.type);
  const [radioInline, setRadioInline] = useState(newData.inputData.inline);
  const [labelName, setLabelName] = useState(newData.inputData.label);
  const [radioOptions, setRadioOptions] = useState(newData.inputData.values);

  //   const [typeVal, setTypeVal] = useState("");

  //   useEffect(() => {
  //     if (selectedData !== "" && selectedData !== null) {
  //       radioOptions.map((item) => {
  //         if (selectedData == item.value) {
  //           setTypeVal(item);
  //         }
  //       });
  //     }
  //   }, [selectedData]);

  const onChangeRadioLabel = (val, index, name, itemId) => {
    let sortedOptions = props.addedFieldNames.filter((ele) => ele.id == itemId);
    let newSortedOptions = sortedOptions[0].inputData.values;
    // sortedOptions[0].inputData.values[index][name] = val;
    // let sortedOptions = newData.inputData.values;

    newSortedOptions[index][name] = val;
    // setRadioOptions(sortedOptions);
    console.log("OPTIONS-LABELS:", newSortedOptions);
    // console.log("OPTIONS-EDITED:", newArray);
  };

  // console.log("PASSED-DATA:", newData);

  const onChangeRadioValue = (val, index, name, itemId) => {
    let sortedOptions = props.addedFieldNames.filter((ele) => ele.id == itemId);

    // let sortedOptions = newData.inputData.values;
    sortedOptions[index][name] = val;
    // setRadioOptions(sortedOptions);
    console.log("OPTIONS-VALUES:", sortedOptions);
  };

  const offOnSwitch = () => {
    setRadioInline(!radioInline);
  };

  const changeValue = (itemId) => {
    const newInputData = {
      id: itemId,
      name: "Radio Buttons",
      icon: newData.icon,
      inputData: {
        type: "radio",
        label: labelName,
        inline: radioInline,
        className: "radio-groups",
        name: "radio-group-selection",
        values: radioOptions,
      },
    };
    const newArray = props.addedFieldNames.map((item) => {
      if (item.id == itemId) {
        return newInputData;
      }
      return item;
    });
    props.setAddedFieldNames(newArray);
    props.handleClose();
  };
  //   console.log("NEW_ARR", radioOptions);

  return (
    <div>
      <>
        <Modal show={props.eitRadio} className="main_model add-edit-model">
          <div className="add-edit-heading">
            <h6 className="add-edit-head">EDIT RADIO OPTIONS</h6>
            <i className="fa fa-times" onClick={props.handleClose} />
          </div>

          <form>
            <div className="container add-edit">
              <div className="change-text-fields-part">
                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="Label Name"
                    className={`${
                      labelName
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      id={props.data.id}
                      type="text"
                      className="change-model"
                      placeholder="Label Name"
                      autoComplete="off"
                      value={labelName}
                      required
                      onChange={(e) => setLabelName(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
                <div className="model-inputs mb-3">
                  <div className="d-flex">
                    <Form.Label>Display Radio inline</Form.Label> &nbsp; &nbsp;
                    <Form.Check
                      type="switch"
                      id={props.data.id}
                      label={radioInline ? "Display inline" : "Display block"}
                      checked={radioInline}
                      onChange={offOnSwitch}
                    />
                  </div>
                </div>

                <div className="model-inputs mb-3">
                  <Form.Label className="radio-button-label">
                    Options
                  </Form.Label>
                  <div className="radio-options-with-label-n-values mt-2">
                    {radioOptions.map((val, i) => {
                      return (
                        <>
                          <Row className="mb-3" key={i}>
                            <Col sm={2} md={2} className="p-0">
                              <Form.Check
                                key={i}
                                type={newData.inputData?.type}
                                name={newData.inputData?.name}
                                className={newData.inputData?.className}
                                // inline={newData.inputData?.inline}
                                // label={val.label}
                                // value={val.value}
                              />
                            </Col>
                            <Col sm={5} md={5} className="pl-0">
                              <FloatingLabel
                                label="Label"
                                className={`${
                                  val.label
                                    ? "float-input-visible"
                                    : "float-hidden float-input"
                                }`}
                                style={{ width: "100%" }}
                              >
                                <Form.Control
                                  id={i}
                                  type="text"
                                  className="change-model"
                                  name={val.label}
                                  autoComplete="off"
                                  //   value={val.label}
                                  required
                                  onChange={(e) =>
                                    onChangeRadioLabel(
                                      e.target.value,
                                      i,
                                      "label",
                                      props.data.id
                                    )
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                            <Col sm={5} md={5} className="pr-0">
                              <FloatingLabel
                                label="Value"
                                className={`${
                                  val.label
                                    ? "float-input-visible"
                                    : "float-hidden float-input"
                                }`}
                                style={{ width: "100%" }}
                              >
                                <Form.Control
                                  id={props.data.id}
                                  type="text"
                                  className="change-model"
                                  name={val.value}
                                  autoComplete="off"
                                  // value={val.value}
                                  required
                                  onChange={(e) =>
                                    onChangeRadioValue(
                                      e.target.value,
                                      i,
                                      "value",
                                      props.data.id
                                    )
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="btns-save-cancel">
            <div className="model-save-button">
              <button
                onClick={() => changeValue(props.data.id)}
                type="button"
                className="btn btn-save-up"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
            <div className="model-cancel-button">
              <button
                onClick={props.handleClose}
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
}
