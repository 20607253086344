import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import profileimg from "../../images/profile-1.png";
import Leaveupdatemodel from "./Leaveupdatemodel";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsLeaveStatusList } from "../DropdownsData";
import { SkeletonStdTableBody } from "../Skeleton Loading/SkeletonStdTable";
import * as swal from "../Consturl/SwalAlert";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import { useNavigate } from "react-router-dom";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";

export default function LeavesApplied() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const todayDate = new Date();
  const futureDate = new Date(todayDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  const [leaveUpdatestatus, setLeaveUpdatestatus] = useState(false);

  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [status, setStatus] = useState("");
  const [leaveId, setLeaveId] = useState("");
  const [fromDate, setFromDate] = useState(todayDate);
  const [toDate, setToDate] = useState(futureDate);
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let tableHeader = [
    "NAME",
    "TYPE",
    "FROM",
    "TO",
    "DAY(S)",
    "SESSION",
    "REASON",
    "STATUS",
    "ACTIONS",
  ];

  const presentDate = new Date();
  const currentDate = moment(presentDate).format("DD/MM/YYYY");

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  var pageDataFrom, pageDataTill;
  pageDataFrom = currentPage * PageSize - PageSize + 1;
  pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const handleClose = () => setLeaveUpdatestatus(!leaveUpdatestatus);

  // FETCH SELECT DROPDOWN DATA
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN DATA

  // FETCH LEAVE DETAILS START
  useEffect(() => {
    fetchLeaveDetails();
  }, [filterUserId, fromDate, toDate, status, currentPage, PageSize]);

  const fetchLeaveDetails = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Leave/GetLeaves?PageSize=${PageSize}&CurrentPage=${currentPage}&status=${status}&userId=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format("DD/MM/YYYY")}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveDetails(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setLeaveDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
        setErrorMessage(error.message);
      });
  };
  // FETCH LEAVE DETAILS END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setStatus("");
    setFromDate(new Date());
    setToDate(new Date());
    setTotalData(0);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelActiivity = () => {
    exportExcel(
      `${baseURL}/api/Export/LeaveExcelExport?UserID=${filterUserId}&LeaveType=${leaveId}&Status=${status}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Leave.xlsx"
    );
  };
  //* EXPORT FILE END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div>
      {" "}
      <div id="main-page">
        {errorMessage ? (
          <InternetIssue />
        ) : (
          <>
            {userDetails !== null && userDetails !== "" ? (
              <>
                {/* MAIN BODY START */}
                <div className="page-wrapper">
                  {/* PAGE CONTENT START */}

                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={10}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userList}
                                  placeholder="Select User"
                                  value={
                                    filterUserId === "" ? "" : userList.value
                                  }
                                  onInputChange={userList.label}
                                  onChange={(data) =>
                                    setFilterUserId(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // defaultValue={optionsRoleList[0].label}
                                  options={optionsLeaveStatusList}
                                  placeholder="Select Status"
                                  value={
                                    status === ""
                                      ? ""
                                      : optionsLeaveStatusList.value
                                  }
                                  onInputChange={optionsLeaveStatusList.label}
                                  onChange={(data) => setStatus(data.value)}
                                  isSearchable={true}
                                />
                              </div>

                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                                className="input-group from-to-date-select"
                              >
                                <span
                                  className="input-group-text"
                                  id="from-label"
                                >
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date date-filter"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(date) =>
                                      setSelectSearchDrop(date)
                                    }
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fa fa-calendar-o calander-icon"
                                    style={{ right: "15px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <div className="leaves-table">
                    <div
                      className="table-responsive leaves-scroll"
                      onScroll={(e) =>
                        handleScroll(
                          e,
                          leaveDetails,
                          totalData,
                          setLoading,
                          setPageSize,
                          PageSize
                        )
                      }
                    >
                      <table className="table leaves-table">
                        <SbTableHeader tableHeader={tableHeader} />
                        <tbody>
                          {isLoading ? (
                            <>
                              {tableHeader.map((name, i) => (
                                <SkeletonStdTableBody key={i} />
                              ))}
                            </>
                          ) : leaveDetails !== "" ? (
                            leaveDetails?.map((data, i) => (
                              <tr className="leaves-list-row-data" key={i}>
                                <td>
                                  <span className="leaves-list-names d-flex">
                                    <img
                                      className="table-data-leaves-imgages"
                                      src={profileimg}
                                      alt="profile-img"
                                    />
                                    <div>
                                      <div className="table-row-head-Text">
                                        {data?.fullName}
                                      </div>
                                      <div className="table-row-sub-text">
                                        {data?.userCode}
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td>{data?.typeName}</td>
                                <td>{data?.fromDate}</td>
                                <td>{data?.toDate} </td>

                                <td>{data?.noOfDays}</td>
                                <td>{data?.session}</td>
                                <td
                                // style={{
                                //   maxWidth: "200px",
                                // }}
                                >
                                  {data?.lessRemarks === "NA" ? (
                                    data?.remarks ?? "NA"
                                  ) : (
                                    <OverlayTrigger
                                      placement={"bottom"}
                                      delay={{ show: 250, hide: 300 }}
                                      overlay={
                                        <Tooltip id={`tooltip-remarks`}>
                                          {data?.remarks}
                                        </Tooltip>
                                      }
                                    >
                                      <div>{data?.lessRemarks}</div>
                                    </OverlayTrigger>
                                  )}
                                </td>

                                <td align="center">
                                  <span
                                    className={`status-badge ${
                                      data?.status === "Approved"
                                        ? "complete-status"
                                        : data?.status === "Rejected"
                                        ? "cancel-status"
                                        : data?.status === "Applied"
                                        ? "follow-status"
                                        : data?.status === "Cancelled"
                                        ? "expire-status"
                                        : "pending-status"
                                    }`}
                                  >
                                    {data?.status}
                                  </span>
                                </td>

                                <td>
                                  <button
                                    className={
                                      data?.status === "Rejected" ||
                                      data?.status === "Cancelled"
                                        ? "btn leave-update-status hide"
                                        : data?.status === "Applied"
                                        ? "btn leave-update-status"
                                        : "btn leave-update-status"
                                    }
                                    style={{
                                      visibility:
                                        (data?.toDate <= currentDate &&
                                          data?.status === "Approved") ||
                                        (data?.toDate <= currentDate &&
                                          data?.status === "Rejected") ||
                                        (data?.toDate <= currentDate &&
                                          data?.status === "Cancelled")
                                          ? "hidden"
                                          : "visible",
                                    }}
                                    onClick={() => {
                                      setLeaveUpdatestatus(!leaveUpdatestatus);
                                      setLeaveId(data.leaveID);
                                    }}
                                  >
                                    <i className="bi bi-pencil-fill" />
                                    Update Status
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="no-data-found">
                              <td colSpan={8}>------ NO DATA FOUND ------</td>
                            </tr>
                          )}
                          {loading && <OnScrollLoading />}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* USER TABLE END */}
                  <div className="download-and-pagination">
                    <SbDownloadBtn
                      btnName="Download"
                      onClickEffect={exportExcelActiivity}
                    />
                  </div>

                  {/* PAGE CONTENT END*/}
                </div>

                {/* MAIN BODY END */}

                {/* ADD LEAVE UPDATE STATUS MODEL START */}
                {leaveUpdatestatus ? (
                  <Leaveupdatemodel
                    fetchLeaveDetails={fetchLeaveDetails}
                    leaveUpdatestatus={leaveUpdatestatus}
                    handleClose={handleClose}
                    leaveId={leaveId}
                  />
                ) : (
                  ""
                )}

                {/* /* ADD LEAVE UPDATE STATUS MODEL END */}
              </>
            ) : (
              <AccessDenied />
            )}
          </>
        )}
      </div>
    </div>
  );
}
