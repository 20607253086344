import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";

export default function EditHeader(props) {
  let newData = props.data;
  const [selectedData, setSelectedData] = useState(newData.inputData.type);
  const [alignText, setAlignText] = useState(newData.inputData.align);
  const [headerText, setHeaderText] = useState(newData.inputData.text);

  const headingTags = [
    { value: "h1", label: "H1" },
    { value: "h2", label: "H2" },
    { value: "h3", label: "H3" },
    { value: "h4", label: "H4" },
    { value: "h5", label: "H5" },
    { value: "h6", label: "H6" },
  ];

  const [typeVal, setTypeVal] = useState("");

  useEffect(() => {
    if (selectedData !== "" && selectedData !== null) {
      headingTags.map((item) => {
        if (selectedData == item.value) {
          setTypeVal(item);
        }
      });
    }
  }, [selectedData]);

  const changeValue = (itemId) => {
    const newInputData = {
      id: itemId,
      name: "Header",
      icon: newData.icon,
      inputData: {
        align: alignText,
        text: headerText,
        className: "dynamic-header",
        type: selectedData,
      },
    };
    const newArray = props.addedFieldNames.map((item) => {
      if (item.id == itemId) {
        return newInputData;
      }
      return item;
    });
    props.setAddedFieldNames(newArray);
    props.handleClose();
    // console.log("NEW_ARR", newArray);
  };

  return (
    <div>
      <>
        <Modal show={props.editHeader} className="main_model add-edit-model">
          <div className="add-edit-heading">
            <h6 className="add-edit-head">EDIT HEADER FIELD</h6>
            <i className="fa fa-times" onClick={props.handleClose} />
          </div>

          <form>
            <div className="container add-edit">
              <div className="change-text-fields-part">
                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="Header Text"
                    className={`${
                      headerText
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      id={props.data.id}
                      type="text"
                      className="change-model"
                      placeholder="Header"
                      autoComplete="off"
                      value={headerText}
                      required
                      onChange={(e) => setHeaderText(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="Text Alignment"
                    className={`${
                      alignText
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      id={props.data.id}
                      type="text"
                      className="change-model"
                      placeholder="Align Text"
                      autoComplete="off"
                      value={alignText}
                      required
                      onChange={(e) => setAlignText(e.target.value)}
                    />
                  </FloatingLabel>
                </div>

                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="Type"
                    className={`${
                      props.data.id || ""
                        ? "float-visible-select"
                        : "float-hidden float-select"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      defaultValue={headingTags[null]}
                      options={headingTags}
                      placeholder="Type"
                      value={typeVal}
                      onInputChange={headingTags.label}
                      onChange={(data) => setSelectedData(data.value)}
                      isSearchable={true}
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>
            </div>
          </form>
          <div className="btns-save-cancel">
            <div className="model-save-button">
              <button
                onClick={() => changeValue(props.data.id)}
                type="button"
                className="btn btn-save-up"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
            <div className="model-cancel-button">
              <button
                onClick={props.handleClose}
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
}
