import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import { Col, Row } from 'react-bootstrap';
import SbAddBtn from '../../SbButtons/SbAddBtn';
import DatePicker from "react-datepicker";
import SbCancelBtn from '../../SbButtons/SbCancelBtn';
import { loadProductReferralData } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const RefferalProductTable = () => {
    let tableHeader = [
        { name: "REFERRAL CODE", align: "left", width: "150px" },
        { name: "NAME", align: "left", width: "150px" },
        { name: "PRODUCT NAME", align: "left", width: "150px" },
        { name: "URL", align: "left", width: "150px" },
        { name: "DYNAMIC URL", align: "left", width: "150px" },
        { name: "ADDED ON", align: "left", width: "150px" },
        { name: "VIEW QR CODE", align: "left", width: "250px" },
    ];

    let dispatch = useDispatch();
    const { productReferralData } = useSelector((state) => state.data)
    console.log(productReferralData, "productReferralData")
    const [isLoading, setIsLoading] = useState(true);
    const [openFilterTab, setOpenFilterTab] = useState(false);

    useEffect(() => {
        dispatch(loadProductReferralData(setIsLoading))
    }, [])

    const [showModal, setShowModal] = useState("")

    const handleHover = (urL1) => {
        setShowModal(urL1)
    }

    const removeModal = () => {
        setShowModal("")
    }

    const [showUrlModal, setShowUrlModal] = useState("")
    const handleURLHover = (urL2) =>{
        setShowUrlModal(urL2)
    }
    const removeUrlModal = () =>{
        setShowUrlModal("")
    }

    return (
        <div id='main-page'>
            <div className="page-wrapper">
                {/* <div className="page-content"> */}
                    <div style={{ position: "relative" }}>
                        <Row className='search-row'>
                            <Col md={3} className='cat-col'>
                                <div className='page-header-text-div'>
                                    {/* <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div> */}
                                    <h6 className='page-header-text' > REFERRAL (PRODUCT) </h6>
                                </div>
                            </Col>
                            <Col md={5} className='cat-col'>
                                <div className='input-div'>
                                    <input type="search" placeholder='Find a Referal Code' className='form-control setting-input'
                                    // value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                                    />
                                    <i className='fa fa-search search-icon' ></i>
                                </div>
                            </Col>
                            <Col md={4} className='cat-col'>
                                <div className="search-bar-header" style={{ float: "right" }}>
                                    <div className="page-top-bar-dropdowns page-top-Filters">
                                        <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                                            <p className="card-head">
                                                <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                                            </p>
                                        </div>
                                        <SbAddBtn
                                        // onClickEffect={() => setShow(!show)} 
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {openFilterTab &&
                            <div className="page-filter-section">
                                <div style={{ width: "100%" }}>
                                    <Row>
                                        <Col md={4}>
                                            <div className="clients-filter-input-boxs filter-box-div">
                                                <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                                    <span className="input-group-text" id="from-label">
                                                        From - To
                                                    </span>
                                                    <span className="date-picker-with-icon">
                                                        <DatePicker
                                                            // selected={fromDate}
                                                            className="select from-date"
                                                            id="date-range-picker"
                                                            dateFormat="dd/MM/yyyy"
                                                            selectsRange
                                                            // startDate={fromDate}
                                                            // endDate={toDate}
                                                            // onChange={setSelectSearchDrop}
                                                            maxDate={new Date()}
                                                        />
                                                    </span>
                                                    <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="clients-filter-input-boxs filter-box-div">
                                                <SbCancelBtn
                                                    // onClickEffect={clearSerachBySelect}
                                                    btnName="Clear" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="sb-table-div sb-table-setion">
                        <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                            <TableContainer sx={{ maxHeight: 440 }} >
                                <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                                    <TableHead className='custom-table-header'>
                                        <TableRow>
                                            {tableHeader.map((name, i) => (
                                                < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                                    style={{ minWidth: name.width }}>
                                                    {name.name}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {isLoading ?
                                        <>{tableHeader.map((name, i) => (
                                            <SkeletonMeetPurpose key={i} />
                                        ))}
                                        </>
                                        :
                                        <>
                                            {productReferralData && productReferralData.length === 0 ?
                                                <TableBody>
                                                    <TableRow align="center" >
                                                        <TableCell align="center" colSpan={tableHeader.length}>
                                                            <div style={{ padding: "20px 20px" }}>
                                                                <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                                <span>No Data Found...!</span>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                                :
                                                <TableBody>
                                                    {productReferralData && productReferralData.map((data, i) => (
                                                        <TableRow align="center" className='client-list-row-data'>
                                                            <TableCell>
                                                                {data.referelCode}
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.addedByUserFullName !== null ? data.addedByUserFullName : '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.qrName}
                                                            </TableCell>
                                                            <TableCell onMouseEnter={() => handleHover(data.urL1)}
                                                                onMouseLeave={() => removeModal(data.urL1)}>
                                                                <span className="table-row-head-Text" style={{ cursor: "pointer" }}>
                                                                    {data.urL1 && data.urL1.length > 20
                                                                        ? <><b>{data.urL1.substring(0, 20) + "..."}</b></>
                                                                        : <><b>{data.urL1}</b></>}
                                                                </span>
                                                                {showModal === data.urL1 && (
                                                                    <div className="modal-box">
                                                                        <div style={{ position: "relative" }}>
                                                                            <span>{data.urL1}</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                            <TableCell onMouseEnter={()=>handleURLHover(data.urL2)}
                                                            onMouseLeave={()=>removeUrlModal(data.urL2)}>
                                                                <span className="table-row-head-Text" style={{ cursor: "pointer" }}>
                                                                    {data.urL2 && data.urL2.length > 20 
                                                                    ?  <><b>{data.urL2.substring(0,20) + "..."}</b></>
                                                                    :  <><b>{data.urL2}</b></>}
                                                                </span>
                                                                {showUrlModal === data.urL2 && (
                                                                    <div className="modal-box">
                                                                        <div style={{ position: "relative" }}>
                                                                            <span>{data.urL2}</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{moment(new Date(data.addedOn)).format("DD MMM YYYY")}</span>
                                                            </TableCell>
                                                            <TableCell className='action-class'>
                                                                {/* <button
                                                                    onClick={() => editRefferal(data.salesBeeReferelID)}
                                                                    className="btn client-history-link" >
                                                                    <span><i className="bi bi-pencil-fill"></i> Update</span>
                                                                </button> */}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>

                                            }
                                        </>
                                    }
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    {/* ADD & EDIT PRODUCT MODEL START */}
                    {/* {show && (
                        <AddRefferal
                            show={show}
                            salesBeeReferelId={salesBeeReferelId}
                            handleClose={handleClose}
                            applyFilters={applyFilters}
                        />
                    )}
                    {updateShow && (
                        <UpdateRefferal
                            show={updateShow}
                            salesBeeReferelId={salesBeeReferelId}
                            handleClose={handleCloseUpdate}
                            applyFilters={applyFilters}
                        />
                    )} */}
                    {/* ADD & EDIT PRODUCT MODEL END */}

                {/* </div> */}
            </div>
        </div>
    );
}

export default RefferalProductTable;