import React, { useState } from "react";
import { FieldNamesIcons } from "./FieldNamesIcons";
import { useDrag } from "react-dnd";
import { Col, Image } from "react-bootstrap";

export default function FormFields({ type, data, onDropPlayer }) {
  const [{ isDraggble }, dragRef] = useDrag({
    type: type,
    item: () => ({ ...data }),
    end: (data, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult && data) {
        onDropPlayer(data);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div>
      <div className="dynamic-inputs mb-1" ref={dragRef}>
        <div className="icon-with-fields">
          <div className="field-icon">
            <Image src={data.icon} className="icon" alt="" />
          </div>
          <div className="field-names" id={data.id}>
            {data.name}
          </div>
        </div>
      </div>
    </div>
  );
}
