import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Claims.scss";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PendingforApproval from "./PendingforApproval";
import ApprovedClaim from "./ApprovedClaim";
import RejectedClaim from "./RejectedClaim";
import { baseURL } from "../BaseUrl";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";

function ClaimsManager() {
  const [pendingforApproval, setPendingforApproval] = useState(false);
  const [approvedClaim, setApprovedClaim] = useState(false);
  const [rejectedClaim, setRejectedClaim] = useState(false);
  const [managerClaimDetails, setManagerClaimDetails] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [claimUserId, setClaimUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const goBackToClaimfromPendingPage = () => {
    setPendingforApproval(!pendingforApproval);
  };

  const goBackToClaimfromApprovedPage = () => {
    setApprovedClaim(!approvedClaim);
  };

  const goBackToClaimfromRejectedPage = () => {
    setRejectedClaim(!rejectedClaim);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
        // setErrorMessage(error.message);
      });
  };

  // GET MANAGER CLAIM DETAILS START
  useEffect(() => {
    fetchManagerClaimDetails();
  }, [filterUserId, fromDate, toDate, currentPage, PageSize]);

  const fetchManagerClaimDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Claim/GetClaim?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerClaimDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setManagerClaimDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
        // setErrorMessage(error.message);
      });
  };
  // GET MANAGER CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimAmountExcelExport?UserID=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "ClaimDetail-Manager.xlsx"
    );
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {!pendingforApproval && !approvedClaim && !rejectedClaim ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            {/* Filter and Header Section */}
            <div className="user-tables">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <h6 className="page-header-text">CLAIMS (Manager)</h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div className="cliam-filter-section">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Col md={10}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <div
                                  style={{
                                    width: "100%",
                                    padding: "0px 5px",
                                  }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={userList}
                                    placeholder="Select User"
                                    value={
                                      filterUserId === "" ? "" : userList.value
                                    }
                                    onInputChange={userList.label}
                                    onChange={(data) =>
                                      setFilterUserId(data.value)
                                    }
                                    isSearchable={true}
                                    // isClearable={true}
                                  />
                                </div>
                                <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                  className="input-group from-to-date-select"
                                >
                                  <span
                                    className="input-group-text"
                                    id="from-label"
                                  >
                                    From - To
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={fromDate}
                                      className="select from-date date-filter"
                                      id="date-range-picker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsRange
                                      startDate={fromDate}
                                      endDate={toDate}
                                      onChange={(date) =>
                                        setSelectSearchDrop(date)
                                      }
                                      maxDate={new Date()}
                                    />
                                  </span>
                                  <span>
                                    <i
                                      className="fa fa-calendar-o calander-icon"
                                      style={{ right: "15px" }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <SbCancelBtn
                                  onClickEffect={clearSerachBySelect}
                                  btnName="Clear"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Filter and Header Section */}

            {/* USER TABLE START */}
            <div className="claims-table">
              <div
                className="table-responsive claims-scrollbar"
                onScroll={(e) =>
                  handleScroll(
                    e,
                    managerClaimDetails,
                    totalData,
                    setLoading,
                    setPageSize,
                    PageSize
                  )
                }
              >
                <table className="table claims-manager-table">
                  <thead className="thead-light claims-manager-thead">
                    <tr>
                      <th>USER NAME</th>
                      <th>EMAIL ID</th>
                      <th>MOBILE</th>
                      <th>APPLIED</th>
                      <th>PENDING</th>
                      <th>APPROVED</th>
                      <th>REJECTED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                        <SkeletonTableData tdata="7" />
                      </>
                    ) : managerClaimDetails !== "" ? (
                      managerClaimDetails?.map((data, i) => (
                        <tr className="claims-list-row-data" key={i}>
                          <td>
                            <div className="table-row-head-Text">
                              {data?.fullName}
                            </div>
                          </td>
                          <td>
                            <div className="table-row-sub-text">
                              {data?.email}
                            </div>
                          </td>
                          <td>
                            {data?.countryCode} {data?.contact}
                          </td>
                          <td>{data?.appliedClaimvalue}</td>
                          <td>
                            {data?.manager?.pendingClaimValue != 0 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setPendingforApproval(!pendingforApproval);
                                  setClaimUserId(data?.userID);
                                }}
                              >
                                {data?.manager?.pendingClaimValue}
                              </Link>
                            ) : (
                              <Link to="#" className="claim-value-0">
                                0
                              </Link>
                            )}
                          </td>
                          <td>
                            {data?.manager?.approvedClaimValue != 0 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setApprovedClaim(!approvedClaim);
                                  setClaimUserId(data?.userID);
                                }}
                              >
                                {data?.manager?.approvedClaimValue}
                              </Link>
                            ) : (
                              <Link to="#" className="claim-value-0">
                                0
                              </Link>
                            )}
                          </td>
                          <td>
                            {data?.manager?.rejectedClaimValue != 0 ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setRejectedClaim(!rejectedClaim);
                                  setClaimUserId(data?.userID);
                                }}
                              >
                                {data?.manager?.rejectedClaimValue}
                              </Link>
                            ) : (
                              <Link to="#" className="claim-value-0">
                                0
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      "------ No Data Found ------"
                    )}
                    {loading && <OnScrollLoading />}
                  </tbody>
                </table>
              </div>

              <div className="download-and-pagination">
                <SbDownloadBtn onClickEffect={exportExcelClaim} />
              </div>
            </div>
            {/* USER TABLE END */}

            {/* PAGE CONTENT END*/}
          </div>
        </>
      ) : (
        <>
          {pendingforApproval ? (
            <PendingforApproval
              pendingforApproval={pendingforApproval}
              goBackToClaimfromPendingPage={goBackToClaimfromPendingPage}
              claimUserId={claimUserId}
              fromDate={fromDate}
              toDate={toDate}
              fetchManagerClaimDetails={fetchManagerClaimDetails}
            />
          ) : (
            ""
          )}

          {approvedClaim ? (
            <ApprovedClaim
              approvedClaim={approvedClaim}
              goBackToClaimfromApprovedPage={goBackToClaimfromApprovedPage}
              claimUserId={claimUserId}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            ""
          )}

          {rejectedClaim ? (
            <RejectedClaim
              rejectedClaim={rejectedClaim}
              goBackToClaimfromRejectedPage={goBackToClaimfromRejectedPage}
              claimUserId={claimUserId}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}

export default ClaimsManager;
