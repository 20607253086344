import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import * as swal from "../Consturl/SwalAlert";
import { SbBulkUpload } from "../SbButtons/SbAddBtn";
import LoadingAnimation from "../LoadingAnimation";

export default function LeavesBulkUpload(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };
  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });

  const [uploadExcelFile, setUploadExcelFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };

  // console.log("EXCEL-FILE:", uploadExcelFile);

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.addBulkUserModel, props.handleCloseBulkUserModel]);

  const addBulkLeaves = () => {
    if (uploadExcelFile != null || uploadExcelFile != "") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Import/ImportLeaveBalance?Directory=BulkLeaves`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("UPLOAD-FILE-RES::", res);
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
            props.handleClose();
            setIsLoading(false);
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
            setIsLoading(false);
          }
        });
    }
  };
  return (
    <div>
      {" "}
      <div>
        <Modal
          show={props.bulkUploadModel}
          // onHide={props.handleClose}
          className="main_model"
        >
          <div className="add-meet-purpose-heading">
            <h6 className="page-header-text">ADD BULK LEAVES</h6>
            <i className="fa fa-times" onClick={props.handleClose} />
          </div>
          <div className="container add-edit-meetpurpose">
            <div className="company-name">
              <div className="row mb-2">
                <input
                  className="form-control form add-meetpurpose-input"
                  id="bulkuserupload"
                  type="file"
                  name="bulkUpload"
                  accept=".xls, .xlsx"
                  onChange={handleChange}
                  placeholder="Add File"
                />
              </div>
            </div>
            <span className="bulk-temp-and-res-message">
              {isLoading ? (
                <>
                  <span className="color-green">
                    Checking excel file to upload please wait...
                  </span>
                  &nbsp;
                  <div className="m-1 pt-2">
                    <LoadingAnimation />
                  </div>
                </>
              ) : (
                <span
                  className={
                    bulkUploadResponse.res
                      ? "upload-res-message success"
                      : "upload-res-message false"
                  }
                >
                  {bulkUploadResponse.message}
                </span>
              )}
              <a
                href={`${baseURL}/TravelizeProData/SUB0000000001/Data/BulkLeaves/Excel_20231115123632465.xlsx`}
                className="btn template-download"
                download={"BulkUploadTemplate"}
              >
                <i className="fa fa-download" /> &nbsp;Template Download
              </a>
            </span>
          </div>
          <div className="btns-save-cancel">
            <div className="meetpurpose-save-button">
              <SbBulkUpload
                btnName="Bulk Upload"
                onClickEffect={addBulkLeaves}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
