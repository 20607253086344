import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/AddEditModel.scss";
import { Form, Image, Row } from "react-bootstrap";
import profileimg from "../../../images/Admin1.png";
import profileimg1 from "../../../images/profile-1.png";
import userImg from "../../../images/person.png";

export default function ChatModel(props) {
  const [localImage, setLocalImage] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [date, setDate] = useState(new Date());
  const [imgFile, setImgFile] = useState("");

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      {" "}
      <div>
        <Modal
          show={props.OpenChatModel}
          // onHide={props.handleClose}
          className="main_model add-edit-model chat-model"
        >
          <div className="add-edit-heading chat">
            {/* <i className="fa fa-ellipsis-h fz18" /> */}
            <h6 className="add-edit-head">Chat |</h6>
            <div className="chat-with-person">
              <Image
                src={userImg}
                className="chat-box-user-img"
                alt="User-Image"
              />
              <p>Nataraj Kumar</p>
            </div>
            <i className="fa fa-times" onClick={props.OpenChatModelfun} />
          </div>

          <div className="container-fluid add-edit chat-box">
            <div className="img-with-chat-comment team-member-float-left">
              <Image
                src={profileimg}
                className="chat-box-inner-image"
                alt="chat-user-image"
              />
              <p>
                How can i help you ? How can i help you ?How can i help you ?
                How can i help you ? How can i help you ? Now ia can here your
                voice how i should know ur alone it seems
              </p>
            </div>
            <div className="img-with-chat-comment user-float-right">
              <Image
                src={profileimg1}
                className="chat-box-inner-image"
                alt="chat-user-image"
              />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div className="img-with-chat-comment team-member-float-left">
              <Image
                src={profileimg}
                className="chat-box-inner-image"
                alt="chat-user-image"
              />
              <p>
                How can i help you ? How can i help you ?How can i help you ?
                How can i help you ? How can i help you ? Now ia can here your
                voice how i should know ur alone it seems
              </p>
            </div>
            <div className="img-with-chat-comment user-float-right">
              <Image
                src={profileimg1}
                className="chat-box-inner-image"
                alt="chat-user-image"
              />
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </div>

          <div className="chat-text-attach-smile-send">
            <Form.Control
              as="textarea"
              rows="1"
              cols="20"
              name="usrtxt"
              wrap="hard"
              className="form-control chat-input"
              id="addressArea"
              placeholder="Type here ..."
              required
              // value={location}
              // onChange={(e) => setLocation(e)}
            />

            <button
              //   onClick={postinvoicedata}
              type="button"
              className="btn btn-smile"
              data-bs-dismiss="modal"
            >
              <i className="fa fa-smile-o" />
            </button>
            <button
              //   onClick={postinvoicedata}
              type="button"
              className="btn btn-attach"
              data-bs-dismiss="modal"
            >
              <i className="fa fa-paperclip" />
            </button>
            <button
              //   onClick={postinvoicedata}
              type="button"
              className="btn btn-send"
              data-bs-dismiss="modal"
            >
              <i className="fa fa-paper-plane" />
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
