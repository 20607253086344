import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Claims.scss";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import AccessDenied from "../../AccessDenied";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import Footer from "../Footer";

export default function ClaimSummary() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [pendingforApproval, setPendingforApproval] = useState(false);
  const [approvedClaim, setApprovedClaim] = useState(false);
  const [rejectedClaim, setRejectedClaim] = useState(false);
  const [claimSummaryDetails, setClaimSummaryDetails] = useState([]);
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [managerID, setManagerID] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [claimUserId, setClaimUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   useEffect(() => {
  //     dispatch(loadUserDropdown());
  //   }, []);

  useEffect(() => {
    fetchDropdownData();
  }, [managerID]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindManagerDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          let resultByManager;
          if (managerID) {
            resultByManager = res.result.filter(
              (data) => data.managerId == managerID
            );
          } else {
            resultByManager = res.result;
          }
          // console.log("USER-DROPDOWN::", resultByManager);
          setUserList(
            resultByManager.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  // GET CLAIM DETAILS START
  useEffect(() => {
    fetchclaimSummaryDetails();
  }, [filterUserId, managerID, fromDate, toDate, currentPage, PageSize]);

  const fetchclaimSummaryDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Claim/GetClaimSummary?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ManagerID=${managerID}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setClaimSummaryDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setClaimSummaryDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  // GET CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerID("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimSummaryExcelExport?UserID=${filterUserId}&ManagerID=${managerID}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Claim-Summary.xlsx"
    );
  };

  return (
    <div>
      <div id="main-page">
        {userDetails !== null && userDetails !== "" ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}

              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="search-row">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <h6 className="page-header-text">CLAIMS SUMMARY</h6>
                        </div>
                      </Col>
                      <Col md={9} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div className="cliam-filter-section">
                              <div style={{ width: "100%" }}>
                                <Row>
                                  <Col md={10}>
                                    <div className="clients-filter-input-boxs filter-box-div">
                                      <div
                                        style={{
                                          width: "100%",
                                          padding: "0px 5px",
                                        }}
                                      >
                                        <Select
                                          className="react-select-container-list"
                                          classNamePrefix="react-select-list"
                                          options={managerList}
                                          placeholder="Select Manager"
                                          value={
                                            managerID == ""
                                              ? ""
                                              : managerList.value
                                          }
                                          onInputChange={managerList.label}
                                          onChange={(data) =>
                                            setManagerID(data.value)
                                          }
                                          isSearchable={true}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          padding: "0px 5px",
                                        }}
                                      >
                                        <Select
                                          className="react-select-container-list"
                                          classNamePrefix="react-select-list"
                                          options={userList}
                                          placeholder="Select User"
                                          value={
                                            filterUserId === ""
                                              ? ""
                                              : userList.value
                                          }
                                          onInputChange={userList.label}
                                          onChange={(data) =>
                                            setFilterUserId(data.value)
                                          }
                                          isSearchable={true}
                                          // isClearable={true}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          padding: "0px 5px",
                                        }}
                                        className="input-group from-to-date-select"
                                      >
                                        <span
                                          className="input-group-text"
                                          id="from-label"
                                        >
                                          From - To
                                        </span>
                                        <span className="date-picker-with-icon">
                                          <DatePicker
                                            selected={fromDate}
                                            className="select from-date date-filter"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            startDate={fromDate}
                                            endDate={toDate}
                                            onChange={(date) =>
                                              setSelectSearchDrop(date)
                                            }
                                            maxDate={new Date()}
                                          />
                                        </span>
                                        <span>
                                          <i
                                            className="fa fa-calendar-o calander-icon"
                                            style={{ right: "15px" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    <div className="clients-filter-input-boxs filter-box-div">
                                      <SbCancelBtn
                                        onClickEffect={clearSerachBySelect}
                                        btnName="Clear"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <div className="claims-table">
                    <div
                      className="table-responsive claims-summary-scrollbar"
                      onScroll={(e) =>
                        handleScroll(
                          e,
                          claimSummaryDetails,
                          totalData,
                          setLoading,
                          setPageSize,
                          PageSize
                        )
                      }
                    >
                      <table className="table claims-manager-table">
                        <thead className="thead-light claims-manager-thead">
                          <tr>
                            <th>USER DETAILS</th>

                            <th>
                              LOCAL CLAIM
                              <div className="approved-claim-manager">
                                Manager
                              </div>
                            </th>
                            <th>
                              OS CLAIM
                              <div className="approved-claim-manager">
                                Manager
                              </div>
                            </th>
                            <th>
                              LOCAL CLAIM
                              <div className="approved-claim-manager">
                                Admin
                              </div>
                            </th>
                            <th>
                              OS CLAIM
                              <div className="approved-claim-manager">
                                Admin
                              </div>
                            </th>
                            <th>BY MANAGER</th>
                            <th>BY ADMIN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                              <SkeletonTableData tdata="7" />
                            </>
                          ) : claimSummaryDetails !== "" ? (
                            claimSummaryDetails?.map((data, i) => (
                              <tr className="claims-list-row-data" key={i}>
                                <td>
                                  <strong> {data?.FullName} </strong>
                                  <br />
                                  <span>{data?.Email}</span>
                                  <br />
                                  <span>
                                    {data?.CountryCode} {data?.Contact}
                                  </span>
                                </td>
                                <td>
                                  <p>
                                    Applied :&nbsp;
                                    <strong>
                                      {data?.LocalAppliedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Pending :&nbsp;
                                    <strong>
                                      {data?.LocalManagerPendingClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Rejected :&nbsp;
                                    <strong>
                                      {data?.LocalManagerRejectedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Approved :&nbsp;
                                    <strong>
                                      {data?.LocalManagerApprovedClaimValue}
                                    </strong>
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    Applied :&nbsp;
                                    <strong>{data?.OSAppliedClaimValue}</strong>
                                  </p>
                                  <p>
                                    Pending :&nbsp;
                                    <strong>
                                      {data?.OSManagerPendingClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Rejected :&nbsp;
                                    <strong>
                                      {data?.OSManagerRejectedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Approved :&nbsp;
                                    <strong>
                                      {data?.OSManagerApprovedClaimValue}
                                    </strong>
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    Applied :&nbsp;
                                    <strong>
                                      {data?.LocalAppliedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Pending :&nbsp;
                                    <strong>
                                      {data?.LocalAdminPendingClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Rejected :&nbsp;
                                    <strong>
                                      {data?.LocalAdminRejectedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Approved :&nbsp;
                                    <strong>
                                      {" "}
                                      {data?.LocalAdminApprovedClaimValue}
                                    </strong>
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    Applied :&nbsp;
                                    <strong>{data?.OSAppliedClaimValue}</strong>
                                  </p>
                                  <p>
                                    Pending :&nbsp;
                                    <strong>
                                      {data?.OSAdminPendingClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Rejected :&nbsp;
                                    <strong>
                                      {data?.OSAdminRejectedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Approved :&nbsp;
                                    <strong>
                                      {data?.OSAdminApprovedClaimValue}
                                    </strong>
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    Total Applied :&nbsp;
                                    <strong>
                                      {" "}
                                      {data?.TotalAppliedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Total Pending :&nbsp;
                                    <strong>
                                      {data?.TotalManagerPendingClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Total Rejected :&nbsp;
                                    <strong>
                                      {data?.TotalManagerRejectedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Total Approved :&nbsp;
                                    <strong>
                                      {data?.TotalManagerApprovedClaimValue}
                                    </strong>
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    Total Applied :&nbsp;
                                    <strong>
                                      {" "}
                                      {data?.TotalAppliedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Total Pending :&nbsp;
                                    <strong>
                                      {data?.TotalAdminPendingClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Total Rejected :&nbsp;
                                    <strong>
                                      {" "}
                                      {data?.TotalAdminRejectedClaimValue}
                                    </strong>
                                  </p>
                                  <p>
                                    Total Approved :&nbsp;
                                    <strong>
                                      {data?.TotalAdminApprovedClaimValue}
                                    </strong>
                                  </p>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="text-center">
                                ----No Data Found----
                              </td>
                            </tr>
                          )}
                          {loading && <OnScrollLoading />}
                        </tbody>
                      </table>
                    </div>
                    <div className="download-and-pagination">
                      <SbDownloadBtn onClickEffect={exportExcelClaim} />
                    </div>
                  </div>
                  {/* USER TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            </div>
            <Footer />
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </div>
  );
}
