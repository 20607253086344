import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { loadPassbookCardData, loadUserReferralsData } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import "../../styles/PassBook.scss";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ReferenceInfoTable from "./ReferenceInfoTable";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import moment from "moment";
import Footer from "../Footer";
import { exportExcel } from "../DownloadFile";
import { baseURL } from "../BaseUrl";

function ReferenceInfo() {
  let dispatch = useDispatch();
  const location = useLocation();

  const { userReferralsDataDropDown } = useSelector((state) => state.data);
  const { cardData } = useSelector((state) => state.data);

  // PAGINATION START
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [refferalOptions, setRefferalOptions] = useState([])
  const [filterArray, setFilterArray] = useState([])
  const [selectedRefferal, setSelectedRefferal] = useState({ value: "", label: "" })
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  useEffect(() => {
    if (userReferralsDataDropDown.length !== 0) {
      const refferalData = userReferralsDataDropDown.map((user) => ({
        value: user.gfRefProfileID,
        label: user.userName,
      }));
      setRefferalOptions(refferalData)
    }
  }, [userReferralsDataDropDown])

  useEffect(() => {
    if (location.state) {
      const { refId } = location.state;
      let list = []
      refferalOptions.map(item => {
        if (item.value == refId) {
          setSelectedRefferal(item)
          if (filterArray.length !== 0) {
            list = filterArray.filter((item) => item.key !== "GFRefProfileID");
          }
          list.push({ key: "GFRefProfileID", value: refId });
          setFilterArray(list);
        }
      })
    }
  }, [location.state, refferalOptions])

  useEffect(() => {
    dispatch(loadUserReferralsData());
  }, []);

  useEffect(() => {
    fetchReferalInfo(filterArray, PageSize, currentPage)
  }, [filterArray, PageSize, currentPage])

  const fetchReferalInfo = () => {
    dispatch(loadPassbookCardData(filterArray, PageSize, currentPage, setIsLoading, setLoading));
  };

  const handleDropdownFilter = (data, type) => {
    let list = []
    if (type === "refferal") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "GFRefProfileID");
      }
      list.push({ key: "GFRefProfileID", value: data.value });
      setSelectedRefferal(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({ key: "FromDate", value: moment(new Date(start)).format("DD/MM/YYYY") });
      }
      setFromDate(start);
      if (end) {
        list.push({ key: "ToDate", value: moment(new Date(end)).format("DD/MM/YYYY") });
      }
      setToDate(end);
    }
    setFilterArray(list);
  }

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const [openFilterTab, setOpenFilterTab] = useState(false);

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  const [gfrefId, setGfrefId] = useState();

  const selectedUserPassbook = (gfrefId) => {
    setGfrefId(gfrefId);
    navigate(`/passbook/${gfrefId}`);
  };

  const clearSerachBySelect = () => {
    setFilterArray("")
    setSelectedRefferal({ value: "", label: "" })
    setFromDate("")
    setToDate("")
  }

  const exportReferenceInfoDetails = () => {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        param = param + "&" + item.key + "=" + item.value;
      })
    }
    exportExcel(
      `${baseURL}/api/Export/GFRefRevExcelExport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      "ReferenceInfo.xlsx"
    );
  }


  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">REFERENCE INFO</h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          placeholder="Find a Referal Code"
                          className="form-control setting-input"
                          id="metricInfo_search_bar"
                        //   value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon"></i>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab &&
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={7}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={refferalOptions}
                                  placeholder="Select Refferal"
                                  value={selectedRefferal.value && selectedRefferal}
                                  onInputChange={refferalOptions.label}
                                  onChange={(data) =>
                                    handleDropdownFilter(data, "refferal")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              {/* <div
                                  style={{
                                   width: "100%",
                                   padding: "0px 5px",
                                  }}
                                >
                                <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={optionsStatusList}
                                placeholder="Select Status"
                                value={status}
                                onInputChange={optionsStatusList.label}
                                onChange={(data) =>
                                setSelectSearchDrop(data, "status")
                                }
                                isSearchable={true}
                                />
                              </div> */}
                              <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                <span className="input-group-text" id="from-label">
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(data) => handleDropdownFilter(data, "date")}
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn onClickEffect={clearSerachBySelect}
                                btnName="Clear" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }

                </div>
                <ReferenceInfoTable
                  cardData={cardData}
                  isLoading={isLoading}
                  selectedUserPassbook={selectedUserPassbook}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                />
              </div>

              <div className="download-and-pagination">
                <SbDownloadBtn
                  btnName="Download"
                  onClickEffect={exportReferenceInfoDetails}
                />
              </div>

              {/* PAGE CONTENT END*/}
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default ReferenceInfo;
