import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import { SkeletonStdTableBody } from "../Skeleton Loading/SkeletonStdTable";
import * as swal from "../Consturl/SwalAlert";
import { Col, Row } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import { useNavigate } from "react-router-dom";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import AddLeaves from "./AddLeaves";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import LeavesBulkUpload from "./LeavesBulkUpload";
import EditLeaves from "./EditLeaves";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { exportExcel } from "../DownloadFile";
import moment from "moment";

export default function LeaveBalance() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [leaveBalance, setLeaveBalance] = useState(false);
  const [editLeaveBalance, setEditLeaveBalance] = useState(false);
  const [userList, setUserList] = useState([]);
  const [bulkUploadModel, setBulkUploadModel] = useState(false);
  const [filterUserId, setFilterUserId] = useState("");
  const [status, setStatus] = useState(2);
  const [leaveTypeID, setLeaveTypeID] = useState(0);
  const [leaveBalaneID, setLeaveBalaneID] = useState(0);
  const [leaveBalanceDetails, setLeaveBalanceDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let tableHeader = [
    "NAME",
    "YEAR",
    "LEAVE TYPE",
    "ALLOTTED",
    "AVAILED",
    "AVAILABLE",
    "ACTIONS",
  ];

  const handleBulkUpload = () => {
    setBulkUploadModel(!bulkUploadModel);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  var pageDataFrom, pageDataTill;

  pageDataFrom = currentPage * PageSize - PageSize + 1;
  pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const handleOpenClose = () => {
    setLeaveBalance(!leaveBalance);
  };

  const handleEditOpenClose = () => {
    setEditLeaveBalance(!editLeaveBalance);
  };

  // FETCH SELECT DROPDOWN DATA
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN DATA

  // FETCH LEAVE DETAILS START
  useEffect(() => {
    fetchLeaveBalanceDetails();
  }, [filterUserId, status, currentPage, PageSize, leaveTypeID]);

  const fetchLeaveBalanceDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/LeaveBalance/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Status=${status}&UserId=${filterUserId}&LeaveTypeID=${leaveTypeID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveBalanceDetails(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setLeaveBalanceDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };
  // FETCH LEAVE DETAILS END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setStatus(2);
    setLeaveTypeID(0);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelDownload = () => {
    exportExcel(
      `${baseURL}/api/Export/LeaveBalanceExcelExport?UserID=${filterUserId}&LeaveType=${leaveTypeID}&Status=${status}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "LeaveBalance.xlsx"
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div>
      <div id="main-page">
        {errorMessage ? (
          <InternetIssue />
        ) : (
          <>
            {userDetails !== null && userDetails !== "" ? (
              <>
                {/* MAIN BODY START */}
                <div className="page-wrapper">
                  {/* PAGE CONTENT START */}

                  {/* Filter and Header Section */}
                  <div className="user-table">
                    {/* <Row className="search-row">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbDropDownBtn
                            openAddModal={handleOpenClose}
                            pageName="Leave"
                            addBulkModel={handleBulkUpload}
                          />
                        </div>
                      </div>
                    </Row> */}

                    {/* {filter && ( */}
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={4}></Col>
                          <Col md={8}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userList}
                                  placeholder="Select User"
                                  value={
                                    filterUserId === "" ? "" : userList.value
                                  }
                                  onInputChange={userList.label}
                                  onChange={(data) =>
                                    setFilterUserId(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // defaultValue={optionsRoleList[0].label}
                                  options={optionsStatusList}
                                  placeholder="Select Status"
                                  value={
                                    status === 2 ? "" : optionsStatusList.value
                                  }
                                  onInputChange={optionsStatusList.label}
                                  onChange={(data) => setStatus(data.value)}
                                  isSearchable={true}
                                />
                              </div>
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                              <SbDropDownBtn
                                openAddModal={handleOpenClose}
                                pageName="Leave"
                                addBulkModel={handleBulkUpload}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <div className="leaves-table">
                    <div
                      className="table-responsive leaves-scroll"
                      onScroll={(e) =>
                        handleScroll(
                          e,
                          leaveBalanceDetails,
                          totalData,
                          setLoading,
                          setPageSize,
                          PageSize
                        )
                      }
                    >
                      <table className="table leaves-table">
                        <SbTableHeader tableHeader={tableHeader} />
                        <tbody>
                          {isLoading ? (
                            <>
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                            </>
                          ) : leaveBalanceDetails !== "" ? (
                            leaveBalanceDetails?.map((data, i) => (
                              <tr className="leaves-list-row-data" key={i}>
                                <td>
                                  <span className="leaves-list-names d-flex">
                                    <img
                                      className="table-data-leaves-imgages"
                                      src={profileimg}
                                      alt="profile-img"
                                    />
                                    <div className="table-row-head-Text">
                                      {data?.userFullName}
                                    </div>
                                  </span>
                                </td>
                                <td>{data?.year}</td>
                                <td>{data?.leaveTypeName}</td>
                                <td>{data?.allowed}</td>
                                <td>{data?.taken}</td>
                                <td>{data?.remain}</td>
                                {/* <td>{data?.carriedForward}</td> */}

                                <td>
                                  <button
                                    className="btn leave-update-status"
                                    onClick={() => {
                                      setEditLeaveBalance(!editLeaveBalance);
                                      setLeaveBalaneID(data.leaveBalanceID);
                                    }}
                                  >
                                    <i className="bi bi-pencil-fill" />
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="no-data-found">
                              <td colSpan={8}>------ NO DATA FOUND ------</td>
                            </tr>
                          )}
                          {loading && <OnScrollLoading />}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* USER TABLE END */}

                  <div className="download-and-pagination">
                    <SbDownloadBtn
                      btnName="Download"
                      onClickEffect={exportExcelDownload}
                    />
                  </div>
                  {/* PAGE CONTENT END*/}
                </div>

                {/* MAIN BODY END */}

                <>
                  {leaveBalance ? (
                    <AddLeaves
                      leaveBalance={leaveBalance}
                      handleClose={handleOpenClose}
                      fetchLeaveBalanceDetails={fetchLeaveBalanceDetails}
                    />
                  ) : (
                    ""
                  )}

                  {editLeaveBalance ? (
                    <EditLeaves
                      handleClose={handleEditOpenClose}
                      leaveBalaneID={leaveBalaneID}
                      fetchLeaveBalanceDetails={fetchLeaveBalanceDetails}
                    />
                  ) : (
                    ""
                  )}

                  {bulkUploadModel ? (
                    <LeavesBulkUpload
                      bulkUploadModel={bulkUploadModel}
                      handleClose={handleBulkUpload}
                    />
                  ) : (
                    ""
                  )}
                </>
              </>
            ) : (
              <AccessDenied />
            )}
          </>
        )}
      </div>
    </div>
  );
}
