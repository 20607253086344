import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import { dateFormatLocal } from '../../FormattedAmount';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { loadFilterReferalData } from '../../../redux/action';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import SbAddBtn from '../../SbButtons/SbAddBtn';
import DatePicker from "react-datepicker";
import SbCancelBtn from '../../SbButtons/SbCancelBtn';
import AddRefferal from './AddRefferal';
import UpdateRefferal from './UpdateRefferal';
import profileimg from "../../../images/profile-1.png";
import { baseURL } from "../../BaseUrl";

function RefferalTable(props) {

    let tableHeader = [
        { name: "REFERRAL COMPANY", align: "left", width: "250px" },
        // { name: "EMAIL", align: "left", width: "150px" },
        // { name: "MOBILE", align: "left", width: "150px" },
        { name: "REFERRED BY", align: "left", width: "150px" },
        { name: "REFERRED ON", align: "left", width: "150px" },
        { name: "NOTE", align: "left", width: "250px" },
        { name: "COMMISSION", align: "center", width: "100px" },
        { name: "PRODUCT", align: "center", width: "150px" },
        { name: "REF. TYPE", align: "left", width: "150px" },
        { name: "REFFERAL CODE", align: "center", width: "150px" },
        { name: "STATUS", align: "center", width: "100px" },
        { name: "Action", align: "center", width: "100px" }
    ];

    let dispatch = useDispatch();
    const { salesBeeRefferal } = useSelector((state) => state.data)
    console.log(salesBeeRefferal)
    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    // PAGINATION START
    const [PageSize, setPageSize] = useState(20);
    const [pageCount, setPageCount] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [show, setShow] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [isLoading, setIsLoading] = useState(true);

    let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));


    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const handleClose = () => {
        setShow(false);
    };


    const [openFilterTab, setOpenFilterTab] = useState(false);

    // Page navigation
    let navigate = useNavigate();
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation


    //code for edit

    const [updateShow, setUpdateShow] = useState(false);
    const [salesBeeReferelId, setSalesBeeReferelId] = useState()
    const editRefferal = (refId) => {
        setSalesBeeReferelId(refId)
        setUpdateShow(!updateShow)
    }
    const handleCloseUpdate = () => {
        setSalesBeeReferelId("")
        setUpdateShow(!updateShow)
    }


    //code for filter

    // const [fromDate, setFromDate] = useState(
    //   new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    // );
    const [fromDate, setFromDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );

    const [toDate, setToDate] = useState(new Date());
    const [filterArray, setFilterArray] = useState([])

    //code for search bar

    const [searchValue, setSearchValue] = useState('')

    const setSearchData = (value) => {
        let list;
        if (filterArray.length !== 0) {
            list = filterArray.filter(item => item.key !== "Text")
        } else {
            list = []
        }
        if (value) {
            list.push({ key: "Text", value: value })
        } else {
            list = filterArray.filter(item => item.key !== "Text")
        }
        setSearchValue(value)
        setFilterArray(list)
    }

    const setSelectSearchDrop = (data) => {
        const [start, end] = data;
        let list = []
        if (filterArray.length !== 0) {
            if (start) {
                list = filterArray.filter((item) => item.key !== "FromDate");
            }
            if (end) {
                list = filterArray.filter((item) => item.key !== "ToDate");
            }
        }
        if (start && end == null) {
            list.push({ key: "FromDate", value: moment(new Date(start)).format("DD/MM/YYYY") });
        }
        setFromDate(start);
        if (end) {
            list.push({ key: "ToDate", value: moment(new Date(end)).format("DD/MM/YYYY") });
        }
        setToDate(end);
        setFilterArray(list);
    };

    const applyFilters = () => {
        dispatch(loadFilterReferalData(filterArray, PageSize, currentPage, setIsLoading));
    };

    useEffect(() => {
        applyFilters(filterArray, setIsLoading)
    }, [filterArray, setIsLoading])

    const clearSerachBySelect = () => {
        // setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
        setFromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
        setToDate(new Date());
        setFilterArray([]); // Clear the filter array
    }

    return (
        <div id='main-page'>
            <div className="page-wrapper">
                {/* <div className="page-content"> */}
                <div style={{ position: "relative" }}>
                    <Row className='search-row'>
                        <Col md={3} className='cat-col'>
                            <div className='page-header-text-div'>
                                {/* <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div> */}
                                <h6 className='page-header-text' > REFERRAL (COMPANY) </h6>
                            </div>
                        </Col>
                        <Col md={5} className='cat-col'>
                            <div className='input-div'>
                                <input type="search" placeholder='Find a Referal Code' className='form-control setting-input'
                                    value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                                />
                                <i className='fa fa-search search-icon' ></i>
                            </div>
                        </Col>
                        <Col md={4} className='cat-col'>
                            <div className="search-bar-header" style={{ float: "right" }}>
                                <div className="page-top-bar-dropdowns page-top-Filters">
                                    <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                                        <p className="card-head">
                                            <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                                        </p>
                                    </div>
                                    <SbAddBtn onClickEffect={() => setShow(!show)} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {openFilterTab &&
                        <div className="page-filter-section">
                            <div style={{ width: "100%" }}>
                                <Row>
                                    <Col md={4}>
                                        <div className="clients-filter-input-boxs filter-box-div">
                                            <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                                <span className="input-group-text" id="from-label">
                                                    From - To
                                                </span>
                                                <span className="date-picker-with-icon">
                                                    <DatePicker
                                                        selected={fromDate}
                                                        className="select from-date"
                                                        id="date-range-picker"
                                                        dateFormat="dd/MM/yyyy"
                                                        selectsRange
                                                        startDate={fromDate}
                                                        endDate={toDate}
                                                        onChange={setSelectSearchDrop}
                                                        maxDate={new Date()}
                                                    />
                                                </span>
                                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="clients-filter-input-boxs filter-box-div">
                                            <SbCancelBtn onClickEffect={clearSerachBySelect}
                                                btnName="Clear" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </div>

                <div className="sb-table-div sb-table-setion">
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                        <TableContainer sx={{ maxHeight: 440 }} >
                            <Table stickyHeader aria-label="sticky table table-responsive" className='referral-company-table'>
                                <TableHead className='custom-table-header'>
                                    <TableRow>
                                        {tableHeader.map((name, i) => (
                                            < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                                style={{ minWidth: name.width }}>
                                                {name.name}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {isLoading ?
                                    <>{tableHeader.map((name, i) => (
                                        <SkeletonMeetPurpose key={i} />
                                    ))}
                                    </>
                                    :
                                    <>
                                        {salesBeeRefferal && salesBeeRefferal.length === 0 ?
                                            <TableBody>
                                                <TableRow align="center" >
                                                    <TableCell align="center" colSpan={tableHeader.length}>
                                                        <div style={{ padding: "20px 20px" }}>
                                                            <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                            <span>No Data Found...!</span>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                            :
                                            <TableBody>
                                                {salesBeeRefferal && salesBeeRefferal.map((data, i) => (
                                                    <TableRow align="center" key={i} className='client-list-row-data'>
                                                        <TableCell>
                                                            <span className="users-list-name">
                                                                <div>
                                                                    <img
                                                                        className="table-data-users-imgages"
                                                                        src={
                                                                            data?.image
                                                                                ? `${baseURL}/${data?.image}`
                                                                                : profileimg
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="table-row-head-Text">
                                                                        {data?.name}
                                                                    </div>
                                                                    <div className="table-row-sub-text">
                                                                        {data?.email}
                                                                    </div>
                                                                    <div className="table-row-sub-text">
                                                                        {data?.mobile}
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <p>{data.addedByUserFullName}</p>
                                                                <p>{data.addedByID}</p>
                                                            </TableCell>
                                                            <TableCell>
                                                                {dateFormatLocal(data.addedOn)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.note}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {data.commPer} %
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {data.projectName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.type}
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className='follow-status status-badge'>{data.code}</span>
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {data.status === 1 ? <span className='complete-status status-badge'>Active</span> : <span className='pending-status status-badge'>Inactive</span>}
                                                            </TableCell>

                                                            <TableCell className='action-class'>
                                                                <button
                                                                    onClick={() => editRefferal(data.salesBeeReferelID)}
                                                                    className="btn client-history-link" >
                                                                    <span><i className="bi bi-pencil-fill"></i> Update</span>
                                                                </button>
                                                            </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                        }
                                    </>
                                }
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                    <AddRefferal
                        show={show}
                        salesBeeReferelId={salesBeeReferelId}
                        handleClose={handleClose}
                        applyFilters={applyFilters}
                    />
                )}
                {updateShow && (
                    <UpdateRefferal
                        show={updateShow}
                        salesBeeReferelId={salesBeeReferelId}
                        handleClose={handleCloseUpdate}
                        applyFilters={applyFilters}
                    />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* </div> */}
            </div>
        </div>
    )
}

export default RefferalTable;
