import React from "react";
import "../styles/InternetIssue.scss";
import internetslow from "../images/internet_slow.png";
import { Image } from "react-bootstrap";

function InternetIssue() {
  return (
    <div>
      <div className="internet-issue-page">
        <div>
          <Image
            src={internetslow}
            alt="Internet-Slow"
            className="internet-slow-img" />
          <p className="internet-issue-head fz16">Connection Error.</p> <br />
          <p className="internet-issue-title fz14">
            Please try after some time or Refresh the page once.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InternetIssue;
