import React, { useEffect, useState } from 'react';
import { loadTrackData } from '../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import GoogleMapComp from './GoogleMapComp';
import NoDataImg from "../images/no-data-found.gif";
import '../styles/userTrack.scss'
import SalesBee from "../images/MatricLogo.svg";
import PageNotFound from '../PageNotFound';
import { baseURL } from "../Components/BaseUrl";

function UserTrackerPage() {

  let dispatch = useDispatch();

  const { trackData } = useSelector(state => state.data);
  const [trackTkData, setTrackTkData] = useState('')
  const [trackStatus, setTrackStatus] = useState(false)

  useEffect(() => {
    var urlstr = window.location.href;
    const answer_array = urlstr.split('=').slice(1);
    if (answer_array.length === 0) {
      setTrackStatus(true)
    }
    setTrackTkData(answer_array.toString())
    dispatch(loadTrackData(answer_array.toString()))
  }, [])

  const [accessStatus, setAccessStatus] = useState(false)
  const [latLng, setLatLng] = useState({ lat: "", lng: "" })
  useEffect(() => {
    if (trackData.length !== 0) {
      if (trackData[0].cordLoc?.latitude === "") {
        setAccessStatus(true)
      }
      setLatLng({ lat: parseFloat(trackData[0]?.cordLoc?.latitude), lng: parseFloat(trackData[0]?.cordLoc?.longitude) })
    }
  }, [trackData])

  return (
    <div>
      {trackStatus ?
        <PageNotFound />
        :
        <div>
          {/* {(trackData && trackData[0]?.cordLoc?.latitude === "") */}
          {accessStatus ?
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}
              <div className='user-track-page'>
                <div className="user-page-content">
                  {/* TRACKER TABLE START */}
                  <div className="user-track-expired">
                    <h2 >Access Denied</h2>
                    <p>Sorry...! Link has been expired. You can not track this user.</p>
                    <h5>Thank you...!</h5>
                  </div>

                </div>
              </div>
              {/* MAIN BODY END */}
            </div>
            :
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}
              <div className='user-track-page'>
                <div className="user-page-content">
                  {/* <div className='track-page-header'>
                    <img
                      src={SalesBee}
                      alt="product-title-img"
                      className="salesbee-logo track-user-logo"
                    />
                  </div> */}
                  {/* TRACKER TABLE START */}
                  <div className="track-user-header">
                    <img
                      src={SalesBee}
                      alt="product-title-img"
                      className="salesbee-logo track-user-logo"
                    />
                    <span className="user-page-title">Executive Location Tracking</span>
                  </div>
                  <div className="tracker-table">

                    <div className="table-responsive user-tracker-tabel-scroll">
                      <table className="table trackers-list-table">
                        <thead className="thead-light trackers-list-thead">
                          <tr>
                            <th>NAME</th>
                            <th>MOBILE</th>
                            <th>LOCATION</th>
                            <th>DATE</th>
                            <th>WHEN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trackData && (
                            <tr className="track-list-row-data">
                              <td>
                                <span className="track-list-name d-flex">
                                  <img
                                    className="table-data-track-imgages"
                                    src={`${baseURL}/${trackData[0]?.profileIcon}`}
                                    alt="John Doe"
                                  />
                                  <h6>{trackData[0]?.fullName}</h6>
                                </span>
                              </td>
                              <td>{trackData[0]?.mobile}</td>
                              <td style={{ maxWidth: "200px" }}>
                                {trackData[0]?.cordLoc?.location}
                              </td>
                              <td>
                                {moment(trackData[0]?.date).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}
                              </td>
                              <td>{trackData[0]?.minsHrsAgo}</td>
                            </tr>

                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* TRACKER TABLE END */}
                  <div className="tracker-page-google-map" style={{ width: "100%" }}>
                    <div className="google-map-box">
                      {trackData[0]?.cordLoc?.latitude ? <GoogleMapComp
                        className="google-component"
                        pageType="Tracker"
                        latLan={latLng}
                        trackUserDetails={trackData[0]}
                      />
                        :
                        <>
                          <img
                            src={NoDataImg}
                            className="mCS_img_loaded"
                            style={{ width: "98%" }}
                            alt=""
                          />
                        </>
                      }

                    </div>
                  </div>
                  {/* PAGE CONTENT END*/}
                </div>
              </div>
              {/* MAIN BODY END */}
            </div>
          }
        </div>
      }
    </div>
  )
}

export default UserTrackerPage
