import React, { useEffect, useState } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
// import "../../styles/Whatsapp.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import Footer from "../Footer";
import TravelReportTable from "./TravelReportTable";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSingleTravelData,
  loadTravelReportAttachments,
  loadUserDropdown,
} from "../../redux/action";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import moment from "moment";
import TravelReportAttachments from "./TravelReportAttachments";
import TravelReportDataTable from "./TravelReportDataTable";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { exportExcel } from "../DownloadFile";
import { baseURL } from "../BaseUrl";

function TravelReport() {
  let tableHeader = [
    { name: "DATE", align: "left", width: "120px" },
    { name: "EMPLOYEE NAME", align: "left", width: "150px" },
    { name: "FROM PLACE", align: "left", width: "250px" },
    { name: "TO PLACE", align: "left", width: "250px" },
    { name: "DEALER CODE", align: "left", width: "100px" },
    { name: "DEALER", align: "left", width: "180px" },
    { name: "VISIT TYPE", align: "left", width: "120px" },
    { name: "VISIT TIME", align: "left", width: "80px" },
    { name: "MODE OF TRAVEL", align: "left", width: "100px" },
    { name: "KM", align: "left", width: "100px" },
    { name: "AMOUNT", align: "left", width: "120px" },
    { name: "DA (FOOD)", align: "left", width: "120px" },
    { name: "STAY", align: "left", width: "120px" },
    { name: "OTHERS", align: "left", width: "120px" },
    { name: "TOTAL", align: "left", width: "120px" },
    { name: "ACTION", align: "center", width: "100px" },
  ];

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const dispatch = useDispatch();
  const { getTravelAttachments } = useSelector((state) => state.data);
  // console.log(getTravelAttachments, "getTravelAttachments");
  const { userDropdown } = useSelector((state) => state.data);
  const { getSingleTravelReportData } = useSelector((state) => state.data);
  console.log(getSingleTravelReportData, "getSingleTravelReportData");

  // PAGINATION START
  const [filterArray, setFilterArray] = useState([]);
  const [PageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  const [userId, setuserId] = useState({
    value: "",
    label: "",
  });

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let list = [];
    if (fromDate && toDate) {
      list.push({
        key: "FromDate",
        value: moment(new Date(fromDate)).format("DD/MM/YYYY"),
      });
      list.push({
        key: "ToDate",
        value: moment(new Date(toDate)).format("DD/MM/YYYY"),
      });
    }
    setFilterArray(list);
  }, []);

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "user") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => {
          return item.key !== "UserID";
        });
      }
      list.push({ key: "UserID", value: data.value });
      setuserId(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => {
            return item.key !== "FromDate";
          });
        }
        if (end) {
          list = filterArray.filter((item) => {
            return item.key !== "ToDate";
          });
        }
      }
      if (start && end === null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "toDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  useEffect(() => {
    dispatch(loadUserDropdown());
  }, []);

  useEffect(() => {
    fetchTravelReportData(filterArray, currentPage, PageSize);
  }, [filterArray, currentPage, PageSize]);

  const fetchTravelReportData = () => {
    dispatch(
      loadTravelReportAttachments(
        filterArray,
        currentPage,
        PageSize,
        setIsLoading,
        setLoading
      )
    );
  };

  const [clientId, setClientId] = useState("");
  const handleIconClick = (clientid) => {
    setClientId(clientid);
    navigate(`/travelReportAttachment/${clientid}`);
    fetchSingleData(clientid);
  };

  const handleDownloadIconClick = (clientid) => {
    setClientId(clientid);
    navigate(`/travelReportTableData/${clientid}`);
    fetchSingleData(clientid);
  };

  const fetchSingleData = (clientid) => {
    dispatch(
      loadSingleTravelData(clientid, filterArray, currentPage, PageSize)
    );
  };

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const [openFilterTab, setOpenFilterTab] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  const clearSerachBySelect = () => {
    setuserId({ value: "", label: "" });
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    setFilterArray("");
  };

  const exportReferenceInfoDetails = () => {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        param = param + "&" + item.key + "=" + item.value;
      });
    }
    exportExcel(
      `${baseURL}/api/export/TravelReportExcelExport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      "TravelReport.xlsx"
    );
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">TRAVEL REPORT</h6>
                      </div>
                    </Col>
                    {/* <Col md={5} className='cat-col'>
                                            <div className='input-div'>
                                                <input type="search" placeholder='Find a Product' className='form-control setting-input'
                                                // id="metricInfo_search_bar" value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                                                />
                                                <i className='fa fa-search search-icon' ></i>
                                            </div>
                                        </Col> */}
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={3}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userDropdown}
                                  placeholder="Select User"
                                  value={userId.value && userId}
                                  onInputChange={userDropdown.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "user")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                <span
                                  className="input-group-text"
                                  id="from-label"
                                >
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "date")
                                    }
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fa fa-calendar-o calander-icon"
                                    style={{ right: "15px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row> */}

                        {/* </Row> */}
                      </div>
                    </div>
                  )}
                </div>

                {/*TABLE START */}

                <TravelReportTable
                  tableHeader={tableHeader}
                  getTravelAttachments={getTravelAttachments}
                  isLoading={isLoading}
                  loading={loading}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                  handleIconClick={handleIconClick}
                  handleDownloadIconClick={handleDownloadIconClick}
                  clientId={clientId}
                  setClientId={setClientId}
                />

                {/*TABLE END */}

                <div className="download-and-pagination">
                  <SbDownloadBtn
                    btnName="Download"
                    onClickEffect={exportReferenceInfoDetails}
                  />
                </div>

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default TravelReport;
