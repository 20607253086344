import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../HeaderAuthentication";
import { addUserBankDetails, loadSingleUserRefferalData } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";

const bankNames = [
    { value: 'sbi', label: 'State Bank of India' },
    { value: 'hdfc', label: 'HDFC Bank' },
    { value: 'icici', label: 'ICICI Bank' },
    { value: 'axis', label: 'Axis Bank' },
    { value: 'pnbc', label: 'Punjab National Bank' },
    { value: 'bob', label: 'Bank of Baroda' },
    { value: 'canara', label: 'Canara Bank' },
    { value: 'union', label: 'Union Bank of India' },
    { value: 'boi', label: 'Bank of India' },
    { value: 'kotak', label: 'Kotak Mahindra Bank' },
    { value: 'yes', label: 'Yes Bank' },
    { value: 'idbi', label: 'IDBI Bank' },
    { value: 'indus', label: 'IndusInd Bank' },
    { value: 'allahabad', label: 'Allahabad Bank' },
    { value: 'central', label: 'Central Bank of India' },
    // Add more banks as needed
];

function UserRefferalsBank(props) {

    let dispatch = useDispatch();
    const { singleUserReferralsData } = useSelector((state) => state.data)

    const gfReferelProfileId = props.gfReferelProfileId;

    const [panNumber, setPanNumber] = useState()
    const [gfRefProfileID, setGfRefProfileID] = useState(0)
    const [gfRefPaymentDetailsID, setGfRefPaymentDetailsID] = useState(0)
    const [aadharNumber, setAadharNumber] = useState()
    const [gstNumber, setGstNumber] = useState()
    const [bankName, setBankName] = useState()
    const [accountNumber, setAccountNumber] = useState()
    const [accountHolderName, setAccountHolderName] = useState()
    const [accountType, setAccountType] = useState()
    const [ifscCode, setIfscCode] = useState()
    const [branch, setBranch] = useState()
    const [upiId, setUpiId] = useState()

    const [panNumberError, setPanNumberError] = useState("");
    const [aadharNumberError, setAadharNumberError] = useState("");
    const [gstNumberError, setGstNumberError] = useState("");
    const [bankNameError, setBankNameError] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [accountHolderNameError, setAccountHolderNameError] = useState("");
    const [accountTypeError, setAccountTypeError] = useState("");
    const [ifscCodeError, setIfscCodeError] = useState("");
    const [branchError, setBranchError] = useState("");
    const [upiIdError, setUpiIdError] = useState("");

    // Validation functions (These functions also update the corresponding state variables)
    const validatePANCard = (panCard) => {
        const panCardRegex = /[A-Z]{5}[0-9]{4}[A-Z]/;
        const valid = panCardRegex.test(panCard);
        setPanNumberError(valid ? "" : "Invalid PAN card number");
        return valid;
    };

    const validateAadharNumber = (aadharNumber) => {
        if (aadharNumber === "") {
            setAadharNumberError("");
            return true; // No validation error for an empty value
        }

        const aadharRegex = /^\d{12}$/;
        const valid = aadharRegex.test(aadharNumber);
        setAadharNumberError(valid ? "" : "Invalid Aadhar number");
        return valid;
    };

    const validateGSTNumber = (gstNumber) => {
        if (gstNumber === "") {
            setGstNumberError("");
            return true; // No validation error for an empty value
        }

        const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}\d{1}$/;
        const valid = gstRegex.test(gstNumber);
        setGstNumberError(valid ? "" : "Invalid GST number");
        return valid;
    };

    const validateBankName = (bankName) => {
        const validBankNames = ["sbi", "hdfc", "icici", "axis", "pnbc", "bob", "canara", "union", "boi", "kotak", "yes", "idbi", "indus", "allahabad", "central"];
        const valid = validBankNames.includes(bankName.toLowerCase());
        setBankNameError(valid ? "" : "Invalid bank name");
        return valid;
    };

    const validateAccountNumber = (accountNumber) => {
        const accountNumberRegex = /^\d{9,18}$/;
        const valid = accountNumberRegex.test(accountNumber);
        setAccountNumberError(valid ? "" : "Invalid account number");
        return valid;
    };

    const validateAccountHolderName = (accountHolderName) => {
        const nameRegex = /^[A-Za-z\s]+$/;
        const valid = nameRegex.test(accountHolderName);
        setAccountHolderNameError(valid ? "" : "Invalid account holder name");
        return valid;
    };

    const validateAccountType = (accountType) => {
        const validAccountTypes = ["savings", "current"];
        const valid = validAccountTypes.includes(accountType.toLowerCase());
        setAccountTypeError(valid ? "" : "Invalid account type");
        return valid;
    };

    const validateIFSCCode = (ifscCode) => {
        const ifscRegex = /^[A-Z]{4}\d{7}$/;
        const valid = ifscRegex.test(ifscCode);
        setIfscCodeError(valid ? "" : "Invalid IFSC code");
        return valid;
    };

    const validateBranch = (branch) => {
        const branchRegex = /^[A-Za-z0-9\s]+$/;
        const valid = branchRegex.test(branch);
        setBranchError(valid ? "" : "Invalid branch name");
        return valid;
    };

    const validateUPIID = (upiId) => {
        if (upiId === "") {
            setUpiIdError("");
            return true; // No validation error for an empty value
        }

        const upiRegex = /^\w+@\w+$/;
        const valid = upiRegex.test(upiId);
        setUpiIdError(valid ? "" : "Invalid UPI ID");
        return valid;
    };


    // code to post the data

    let postUserBankDetailsData = (e) => {
        e.preventDefault()

        // Perform validation
        const isPANValid = validatePANCard(panNumber);
        const isAadharValid = validateAadharNumber(aadharNumber);
        const isGSTValid = validateGSTNumber(gstNumber);
        const isBankNameValid = validateBankName(bankName);
        const isAccountNumberValid = validateAccountNumber(accountNumber);
        const isAccountHolderNameValid = validateAccountHolderName(accountHolderName);
        const isAccountTypeValid = validateAccountType(accountType);
        const isIFSCValid = validateIFSCCode(ifscCode);
        const isBranchValid = validateBranch(branch);
        const isUPIValid = validateUPIID(upiId);

        // If any of the fields are invalid, prevent the data from being sent
        if (
            !isPANValid ||
            !isAadharValid ||
            !isGSTValid ||
            !isBankNameValid ||
            !isAccountNumberValid ||
            !isAccountHolderNameValid ||
            !isAccountTypeValid ||
            !isIFSCValid ||
            !isBranchValid ||
            !isUPIValid
        ) {
            return;
        }

        let data = {
            "gfRefProfileID": gfRefProfileID,
            "subscriptionID": props.filteredData[0].subscriptionID,
            "userID": props.filteredData[0].userID,
            "addedByID": props.filteredData[0].addedByID,
            "contactPersonId": props.filteredData[0].contactPersonId,
            "userName": props.filteredData[0].userName,
            "email": props.filteredData[0].email,
            "isVerified": props.filteredData[0].isVerified,
            "mobileNumber": props.filteredData[0].mobileNumber,
            "countryCode": props.filteredData[0].countryCode,
            "image": props.filteredData[0].image,
            "referelCode": props.filteredData[0].referelCode,
            "projectName": props.filteredData[0].projectName,
            "type": props.filteredData[0].type,
            "addedOn": props.filteredData[0].addedOn,
            "updatedOn": props.filteredData[0].updatedOn,
            "status": props.filteredData[0].status,
            "gfRefPaymentDetailsID": gfRefPaymentDetailsID,
            "panCard": panNumber,
            "adharCard": aadharNumber,
            "gsT_VAT_Number": gstNumber,
            "bankName": bankName,
            "accountType": accountType,
            "accountNumber": accountNumber,
            "accountName": accountHolderName,
            "ifscCode": ifscCode,
            "branch": branch,
            "upiid": upiId
        }

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data),
        };
        dispatch(addUserBankDetails(requestOption, closeAddModal, gfRefProfileID))
    }

    useEffect(() => {
        if (gfReferelProfileId) {
            dispatch(loadSingleUserRefferalData(gfReferelProfileId))
        }
    }, [gfReferelProfileId])

    useEffect(() => {
        if (gfReferelProfileId && singleUserReferralsData) {
            if (Object.keys(singleUserReferralsData).length !== 0) {
                setGfRefProfileID(singleUserReferralsData[0].gfRefProfileID)
                setGfRefPaymentDetailsID(singleUserReferralsData[0].gfRefPaymentDetailsID)
                setPanNumber(singleUserReferralsData[0].panCard)
                setAadharNumber(singleUserReferralsData[0].adharCard)
                setGstNumber(singleUserReferralsData[0].gsT_VAT_Number)
                setBankName(singleUserReferralsData[0].bankName)
                setAccountNumber(singleUserReferralsData[0].accountNumber)
                setAccountHolderName(singleUserReferralsData[0].accountName)
                setAccountType(singleUserReferralsData[0].accountType)
                setIfscCode(singleUserReferralsData[0].ifscCode)
                setBranch(singleUserReferralsData[0].branch)
                setUpiId(singleUserReferralsData[0].upiid)
            }
        }

    }, [gfReferelProfileId, singleUserReferralsData])

    const closeAddModal = () => {
        setPanNumber('')
        setAadharNumber('')
        setGstNumber('')
        setBankName('')
        setAccountNumber('')
        setAccountHolderName('')
        setAccountType('')
        setIfscCode('')
        setBranch('')
        setUpiId('')
        props.setGfReferelProfileId('')
        props.applyFilters();
        props.handleClose();
        // dispatch(loadSingleUserRefferalData(null))
    };

    const [isAddBankFormVisible, setAddBankFormVisible] = useState(true);
    const [isAddUpiFormVisible, setAddUpiFormVisible] = useState(false);

    const showAddBankForm = () => {
        setAddBankFormVisible(true);
        setAddUpiFormVisible(false);
    };

    const showAddUpiForm = () => {
        setAddBankFormVisible(false);
        setAddUpiFormVisible(true);
    };

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                className="main_model add-edit-basefare-model"
            >
                <div className="add-edit-basefare-heading">
                    <h6 className="add-edit-basefare-head">
                        {gfReferelProfileId && singleUserReferralsData && Object.keys(singleUserReferralsData).length !== 0 ? "EDIT BANK DETAILS" : "ADD BANK DETAILS"}
                    </h6>

                    <i className="fa fa-times" onClick={closeAddModal} />

                </div>
                {/* <div className="btns-save-cancel">
                    <p onClick={showAddBankForm} className="add-bank-upi" >Add Bank</p> /
                    <p onClick={showAddUpiForm} className="add-bank-upi" >Add UPI Id</p>
                </div> */}
                <form>
                    <div className="container add-edit-basefare">
                        <div className="product-inputs">
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter Pan Number"
                                    className={`${panNumber
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="text"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Pan Number"
                                        name="panNumber"
                                        value={panNumber}
                                        onChange={(e) => {
                                            setPanNumber(e.target.value);
                                            setPanNumberError("");
                                        }}
                                        disabled={props.clickType}
                                        required
                                    />
                                </FloatingLabel>
                                {panNumberError && <p className="text-danger" style={{ marginTop: "8px" }}>{panNumberError}</p>}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter Aadhar Number"
                                    className={`${aadharNumber
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="number"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Aadhar Number"
                                        name="aadharNumber"
                                        value={aadharNumber}
                                        onChange={(e) => {
                                            setAadharNumber(e.target.value);
                                            setAadharNumberError("")
                                        }}
                                        disabled={props.clickType}
                                        required={aadharNumber !== ""}
                                    />
                                </FloatingLabel>
                                {aadharNumberError && <p className="text-danger" style={{ marginTop: "8px" }}>{aadharNumberError}</p>}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter GST Number"
                                    className={`${gstNumber
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="text"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter GST Number"
                                        name="gstNumber"
                                        value={gstNumber}
                                        onChange={(e) => {
                                            setGstNumber(e.target.value);
                                            setGstNumberError("")
                                        }}
                                        disabled={props.clickType}
                                        required={gstNumber !== ""}
                                    />
                                </FloatingLabel>
                                {gstNumberError && <p className="text-danger" style={{ marginTop: "8px" }}>{gstNumberError}</p>}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Select Bank"
                                    className={`${bankName
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={bankNames}
                                        placeholder="Select Bank"
                                        value={bankNames.find((bank) => bank.value === bankName)}
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                setBankName(selectedOption.value);
                                                setBankNameError("");
                                            } else {
                                                setBankName("");
                                            }
                                        }}
                                        // value={
                                        //     bankName == 0
                                        //         ? ""
                                        //         : bankNames.value
                                        // }
                                        // onInputChange={bankNames.label}
                                        // onChange={(data) => {
                                        //     setBankName(data.value);
                                        //     setBankNameError("");
                                        // }}
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                                {bankNameError && <p className="text-danger" style={{ marginTop: "8px" }}>{bankNameError}</p>}
                            </div>

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter Account Number"
                                    className={`${accountNumber
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="number"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Account Number"
                                        name="accountNumber"
                                        value={accountNumber}
                                        onChange={(e) => {
                                            setAccountNumber(e.target.value);
                                            setAccountNumberError("");
                                        }}
                                        disabled={props.clickType}
                                        required
                                    />
                                </FloatingLabel>
                                {accountNumberError && <p className="text-danger" style={{ marginTop: "8px" }}>{accountNumberError}</p>}
                            </div>

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter Account Name"
                                    className={`${accountHolderName
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="text"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Account Name"
                                        name="accountHolderName"
                                        value={accountHolderName}
                                        onChange={(e) => {
                                            setAccountHolderName(e.target.value);
                                            setAccountHolderNameError("");
                                        }}
                                        disabled={props.clickType}
                                        required
                                    />
                                </FloatingLabel>
                                {accountHolderNameError && <p className="text-danger" style={{ marginTop: "8px" }}>{accountHolderNameError}</p>}
                            </div>

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter Account Type"
                                    className={`${accountType
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="text"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Account Type"
                                        name="accountType"
                                        value={accountType}
                                        onChange={(e) => {
                                            setAccountType(e.target.value);
                                            setAccountTypeError("");
                                        }}
                                        disabled={props.clickType}
                                        required
                                    />
                                </FloatingLabel>
                                {accountTypeError && <p className="text-danger" style={{ marginTop: "8px" }}>{accountTypeError}</p>}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter IFSC Code"
                                    className={`${ifscCode
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="text"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter IFSC Code"
                                        name="ifscCode"
                                        value={ifscCode}
                                        onChange={(e) => {
                                            setIfscCode(e.target.value);
                                            setIfscCodeError("");
                                        }}
                                        disabled={props.clickType}
                                        required
                                    />
                                </FloatingLabel>
                                {ifscCodeError && <p className="text-danger" style={{ marginTop: "8px" }}>{ifscCodeError}</p>}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Enter Branch"
                                    className={`${branch
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="text"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Branch"
                                        name="branch"
                                        value={branch}
                                        onChange={(e) => {
                                            setBranch(e.target.value);
                                            setBranchError("");
                                        }}
                                        disabled={props.clickType}
                                        required
                                    />
                                </FloatingLabel>
                                {branchError && <p className="text-danger" style={{ marginTop: "8px" }}>{branchError}</p>}
                            </div>
                            <div className="mb-3">
                                <FloatingLabel
                                    label="Enter UPI Id"
                                    className={`${upiId
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                >
                                    <input
                                        type="text"
                                        className="form-control form add-basefare-input"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter UPI Id"
                                        name="upiId"
                                        value={upiId}
                                        onChange={(e) => {
                                            setUpiId(e.target.value);
                                            setUpiIdError("");
                                        }}
                                        disabled={props.clickType}
                                    />
                                </FloatingLabel>
                                {upiIdError && (
                                    <p className="text-danger" style={{ marginTop: "8px" }}>
                                        {upiIdError}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                </form>

                <div className="btns-save-cancel">
                    <SBSaveUpdateBtn
                        btnName={gfReferelProfileId && singleUserReferralsData && Object.keys(singleUserReferralsData).length !== 0 ? "Update" : "Save"}
                        onClickEffect={postUserBankDetailsData}
                    />
                    <SbCancelBtn
                        onClickEffect={closeAddModal}
                        btnName="Cancel" />
                </div>
            </Modal>
        </div>
    );
}

export default UserRefferalsBank;





