import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { Col, Row } from "react-bootstrap";
import "../../../styles/category.scss";
import "../../../styles/CommonStyle.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadShiftData } from "../../../redux/action";
import AddShift from "./AddShift";
import ShiftTable from "./ShiftTable";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";

function Shift() {
  let dispatch = useDispatch();
  const { shiftData } = useSelector((state) => state.data);

  let tableHeader = [
    { name: "Sl.No", align: "left", width: "auto" },
    { name: "Shift Name", align: "left", width: "100px" },
    { name: "CheckIn Time", align: "center", width: "auto" },
    { name: "CheckOut Time", align: "center", width: "auto" },
    { name: "Early CheckIn By", align: "center", width: "auto" },
    { name: "Late CheckIn By", align: "center", width: "auto" },
    { name: "Auto CheckOut By", align: "center", width: "auto" },
    { name: "Full Day Work", align: "center", width: "auto" },
    { name: "Half Day Work", align: "center", width: "auto" },
    { name: "Is Night Shift", align: "center", width: "auto" },
    { name: "Validation", align: "center", width: "auto" },
    { name: "Action", align: "center", width: "80px" },
  ];

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadShiftData());
  }, []);

  const [show, setShow] = useState(false);

  const shiftModuleOpen = () => {
    setShow(true);
  };
  const shiftModuleClose = () => {
    setShow(false);
  };

  const [shiftId, setShiftId] = useState("");
  const handleEdit = (id) => {
    shiftModuleOpen();
    setShiftId(id);
  };

  const [searchValue, setsearchValue] = useState("");
  const clearSerachValue = () => {
    setsearchValue("");
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* addHoliday modal page */}
      {show && (
        <AddShift
          show={show}
          shiftModuleClose={shiftModuleClose}
          shiftId={shiftId}
          setShiftId={setShiftId}
          shiftData={shiftData}
        />
      )}
      {/* addHoliday modal page */}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <Row className="search-row">
              <Col md={3} className="cat-col">
                <div className="page-header-text-div">
                  <div className="back-btn" onClick={goToBackPage}>
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <h6 className="page-header-text">Shift List</h6>
                </div>
              </Col>
              <Col md={5} className="cat-col">
                <div className="input-div">
                  <input
                    type="search"
                    id="metricInfo_search_bar"
                    placeholder="Search Shift"
                    className="form-control setting-input"
                    value={searchValue}
                    onChange={(e) => setsearchValue(e.target.value)}
                  />
                  <i className="fa fa-search search-icon"></i>
                </div>
              </Col>
              <Col md={4} className="cat-col">
                <div className="search-bar-header" style={{ float: "right" }}>
                  <div className="page-top-bar-dropdowns page-top-Filters">
                    <SbAddBtn onClickEffect={shiftModuleOpen} />
                  </div>
                </div>
              </Col>
            </Row>
            <ShiftTable
              tableHeader={tableHeader}
              shiftData={shiftData}
              handleEdit={handleEdit}
              searchValue={searchValue}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shift;
