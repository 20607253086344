import React, { useState, useCallback, useEffect } from "react";
import Topbar from "../../Topbar";
import Sidenavbar from "../../Sidenavbar";
import { baseURL } from "../../BaseUrl";
import GoogleMapComp from "../../GoogleMapComp";
import NoDataImg from "../../../images/no-data-found.gif";
import profileimg from "../../../images/profile-1.png";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import "../../../styles/Tracker.scss";
import { loadUserDropdown } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form, Image } from "react-bootstrap";
import Select from "react-select";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import OpenStreenMap from "../../OpenStreenMap";

export default function CheckInDetailsInMap() {
  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [recentActivities, setRecentActivities] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [trackUserDetails, setTrackUserDetails] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [centerLatLan, setCenterLatLan] = useState("");

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    dispatch(loadUserDropdown());
  }, []);

  /* FETCH RECENT ACTIVITY DATA START  */
  useEffect(() => {
    fetchRecentActivities();
  }, [filterUserId]);

  const fetchRecentActivities = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(`${baseURL}/api/DashBoard/GetRecentActivities`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          let sortedResult;
          {
            filterUserId !== "" && filterUserId !== null
              ? (sortedResult = res.result.filter(
                  (data) => data.userID == filterUserId
                ))
              : (sortedResult = res.result);
          }

          setRecentActivities(res.result);
          let userCheckIns = [];
          for (let i = 0; i < sortedResult.length; i++) {
            userCheckIns.push({
              lat: +sortedResult[i].cordLoc.latitude,
              lng: +sortedResult[i].cordLoc.longitude,
              location: sortedResult[i].cordLoc.location,
              fullName: sortedResult[i].fullName,
              checkInTime: sortedResult[i].checkInTime,
              lastSeen: sortedResult[i].date,
            });
          }
          setTrackUserDetails(userCheckIns);
          //   let centerCords = +(userCheckIns.length - 1);
          //   alert(centerCords);
          setCenterLatLan({
            lat: userCheckIns[0].lat,
            lng: userCheckIns[0].lng,
          });
        } else {
          setRecentActivities("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
  };
  //* CLEAR SELECT END *//

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div>
      <div id="main-page">
        {userDetails !== null && userDetails !== "" ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* TRACKER TABLE START */}
                  <div className="checkin-map-table">
                    <Row className="search-row">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToBackPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">VIEW USERS</h6>
                        </div>
                      </Col>
                      <Col md={3} className="cat-col">
                        {" "}
                      </Col>
                      <Col md={3} className="cat-col">
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={userDropdown}
                          placeholder="Select User"
                          value={filterUserId == "" ? "" : userDropdown.value}
                          onInputChange={userDropdown.label}
                          onChange={(data) => setFilterUserId(data.value)}
                          isSearchable={true}
                        />
                      </Col>
                      <Col md={3} className="cat-col">
                        <div className="clients-filter-input-boxs filter-box-div">
                          <SbCancelBtn
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="checkin-details-table">
                      <div className="tracker-page-google-map">
                        <div className="google-map-box">
                          {trackUserDetails !== "" &&
                          trackUserDetails.length > 0 ? (
                            <div className="row">
                              <div
                                className="col-xl-9 col-lg-9 col-md-9 pr-0"
                                style={{ position: "relative" }}
                              >
                                {/* <GoogleMapComp
                                  className="google-component"
                                  pageType="CheckInByMap"
                                  trackUserDetails={trackUserDetails}
                                  centerLatLan={centerLatLan}
                                /> */}
                                <OpenStreenMap
                                  className="google-component"
                                  pageType="CheckInByMap"
                                  trackUserDetails={trackUserDetails}
                                  centerLatLan={[
                                    centerLatLan.lat,
                                    centerLatLan.lng,
                                  ]}
                                />
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-3">
                                <div className="checkin-details-column">
                                  {trackUserDetails.map((data, i) => (
                                    <div
                                      className="card view-on-map-card"
                                      key={i}
                                    >
                                      <div className="card-header ">
                                        <div className="check-in-detail dflex-j-start">
                                          <img
                                            className="table-data-meeting-track-user-img"
                                            src={profileimg}
                                            alt="ProfileImg"
                                          />
                                          <span
                                            className="checkin-user-detail"
                                            style={{ textAlign: "left" }}
                                          >
                                            <p>
                                              <span className="color-green">
                                                User :
                                              </span>
                                              &nbsp;
                                              <strong className="color-yellow">
                                                {data?.fullName}
                                              </strong>
                                            </p>
                                            <p>
                                              <span className="color-green">
                                                Check-In :
                                              </span>
                                              &nbsp;
                                              <strong>
                                                {data?.checkInTime}
                                              </strong>
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                      <p
                                        className="userschekin-location"
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        <span className="color-green">
                                          Location :{" "}
                                        </span>
                                        &nbsp;
                                        {data?.location}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-center">
                              --- NO DATA FOUND ---
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* TRACKER TABLE END */}
                  {/* PAGE CONTENT END*/}
                </div>
              </div>
              {/* MAIN BODY END */}
            </div>
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </div>
  );
}
