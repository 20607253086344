import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/MeetingPurpose.scss";
import { FloatingLabel, Form } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
import * as swal from "../../Consturl/SwalAlert";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditPurposeModel(props) {
  const [enableStatus, setEnableStatus] = useState(null);
  const [meetPurpose, setMeetPurpose] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const purposeID = props.purposeId;

  useEffect(() => {
    fetchByEditpurposeId();
  }, [purposeID]);

  const fetchByEditpurposeId = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/MeetingPurpose/Get/${purposeID}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("EDIT-BY-ID-MEET-PURPOSE::", res);
        setMeetPurpose(res.result.purpose);
        setEnableStatus(res.result.status);
      });
  };

  //  EDIT MEETING PURPOSE START
  const editMeetingPurpose = (e) => {
    e.preventDefault();
    if (meetPurpose) {
      const purposeData = {
        purpose: meetPurpose,
        purposeId: purposeID,
        status: JSON.parse(enableStatus),
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(purposeData),
      };
      fetch(`${baseURL}/api/MeetingPurpose/EditPurpose`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("EDIT-MEET-PURPOSE::", res);
          if (res.success) {
            props.handleClose();
            props.fetchMeetingPurposeData();
            swal.showAlert(
              "Success",
              `Meeting Purpose Updated Successfully`,
              "success"
            );
          } else {
            // props.handleClose();
            // swal.showAlert(
            //   "Fail",
            //   `Somethin went wrong, ${res.errorMessage}...!`,
            //   "warning"
            // );
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setShowAlert(true);
    }
  };
  //  EDIT MEETING PURPOSE START END

  return (
    <div>
      <Modal
        show={props.editpurposemodel}
        onHide={props.handleClose}
        className="main_model add-meet-purpose-model"
      >
        <div className="add-meet-purpose-heading">
          <h6 className="page-header-text">EDIT PURPOSE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-meetpurpose">
            <div className="company-name">
              <div className="mb-2">
                <FloatingLabel
                  label="Meeting Purpose"
                  className={`${meetPurpose
                      ? "float-input-visible"
                      : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="text"
                    className="form-control form add-input"
                    id="exampleFormControlInput1"
                    placeholder="Meeting Purpose"
                    value={meetPurpose}
                    onChange={(e) => setMeetPurpose(e.target.value)}
                  />
                </FloatingLabel>
                {showAlert === true && !meetPurpose && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Meeting Purpose
                  </small>
                )}
              </div>

              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => {
                          setEnableStatus(1);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => {
                          setEnableStatus(0);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={editMeetingPurpose} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default EditPurposeModel;
