import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form, Row, Col } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function FormNameModel(props) {
  const [newFormName, setNewFormName] = useState("");

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const addFormName = () => {
    let newFormNameData = {
      customePagesID: 0,
      subscriptionID: resPassData.subscriptionId,
      addedByID: resPassData.userId,
      // addedByUserFullName: "string",
      // addedOn: "string",
      status: 1,
      pageName: newFormName,
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newFormNameData),
    };
    fetch(`${baseURL}/api/CustomePages/AddCustomePages`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("CUS-FORM-RES::", res.result);
          props.setCustomPageId(res.result);
          props.setFormNameCreated(newFormName);
          props.setGotoCreateForm(false);
          swal.showAlert("Success", `${res.message}`, "success");
        } else {
          // props.handleClose();
          // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          toast.error(res.errorMessage);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        // swal.showAlert("Fail", `${error}`, "warning");
        toast.error(`${error}`);
      });
  };

  return (
    <div>
      {" "}
      <Modal show={props.show} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">FORM NAME</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>

        <form>
          <div className="container add-edit">
            <div className="change-text-fields-part">
              <div className="model-inputs mb-3 mt-3">
                <FloatingLabel
                  label="Enter Form Name"
                  className={`${newFormName
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    id="customformName"
                    type="text"
                    className="custom-form-name"
                    placeholder="Enter Form Name"
                    autoComplete="off"
                    onChange={(e) => setNewFormName(e.target.value)}
                    required
                  />
                </FloatingLabel>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={addFormName} btnName="Next" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
