import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Topbar.scss";
import profileimg from "../images/admin-1.png";
import { baseURL } from "./BaseUrl";
import TopBarToolTip from "./Users/TopBarToolTip";
import {
  Carousel,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import SbCancelBtn from "./SbButtons/SbCancelBtn";
import { SBSaveUpdateBtn } from "./SbButtons/SbAddBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadCreditsCount } from "../redux/action";
import DarkMode from "./DarkMode";

function Topbar(props) {
  let themeColor = localStorage.getItem("theme");
  const userLgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();
  const { creditCount } = useSelector((state) => state.data);

  const companyName = userLgnDetails?.subscription?.company?.companyName;

  const menuIconClick = props.menuIconClick;
  const menuCollapse = props.menuCollapse;
  const toggleshift = props.toggleshift;
  const toggleMenu = props.toggleMenu;

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
    setShowlogOut(false);
  };

  const [showLogOut, setShowlogOut] = useState(false);

  const logoutModal = () => {
    setShowlogOut(true);
  };
  const cancelModale = () => {
    setShowlogOut(false);
  };

  useEffect(() => {
    let Flag = "GetApiCredits";
    dispatch(loadCreditsCount(Flag, 50, 1));
  }, []);

  const [remainingCredits, setRemainingCredits] = useState([]);
  // console.log(creditCount, "creditCount");

  useEffect(() => {
    if (creditCount && creditCount.length != 0) {
      let creditsData = creditCount.filter(
        (item) => item.SubscriptionID == userLgnDetails.subscriptionId
      );
      setRemainingCredits(creditsData);
    }
  }, [creditCount]);

  let daysLeft = sessionStorage.getItem("daysLeft");

  const [showRequest, setShowRequest] = useState(false);
  const [showAllCredit, setShowAllCredit] = useState(false);
  const [itemName, setItemName] = useState("");
  const [rmnBalance, setRmnBalance] = useState("");
  const requestCredits = (itemName, rmnBlnce) => {
    setRmnBalance(rmnBlnce);
    setItemName(itemName);
    setShowRequest(true);
  };
  const cancelRequestModale = () => {
    setShowRequest(false);
  };

  const openAllModal = () => {
    setShowRequest(false);
    setShowAllCredit(true);
  };
  const cancelAllCreditModale = () => {
    setShowAllCredit(false);
  };

  return (
    <div>
      {/* Logout modal */}
      {showLogOut && (
        <Modal
          show={showLogOut}
          onHide={cancelModale}
          className="logout-modal-div"
        >
          <div className="cat-col">
            <div>
              <div className="log-out-div">
                <h6>Are you sure want to logout?</h6>
              </div>
              {/* <hr /> */}
              <div className="sb-logout-btn">
                <SBSaveUpdateBtn onClickEffect={logout} btnName="Yes" />
                <SbCancelBtn btnName="No" onClickEffect={cancelModale} />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* Logout modal */}

      {/* Credit Request modal */}
      {showRequest && (
        <Modal
          show={showRequest}
          onHide={cancelRequestModale}
          className="category-modal-div Qr-modal"
        >
          <Row className="modale-header-div">
            <Col md={9} xs={9} className="cat-col">
              <h6 className="page-header-text">Credits Top Up</h6>
            </Col>
            <Col md={3} xs={3} className="cat-col" align="right">
              <button
                onClick={cancelRequestModale}
                className="bi bi-x close-popup"
              ></button>
            </Col>
          </Row>
          <div className="cat-col">
            <div className="top-image">
              <img src={`../../images/Topbar/${itemName}.gif`} alt="" />
            </div>
            <div className="top-image">
              <p className="head-text">
                Your <span className="primary-color">{itemName}</span> Credits
                Are Low
              </p>
              <p>
                Remainig balance is{" "}
                <span className="subbordinate-link">{rmnBalance}</span>
              </p>
            </div>
            <div className="top-btns">
              <SBSaveUpdateBtn btnName="Request For Credit" />
              <SbCancelBtn btnName="View All" onClickEffect={openAllModal} />
            </div>
          </div>
        </Modal>
      )}
      {/* Credit Request modal */}

      {/* Credit Request modal */}
      {showAllCredit && (
        <Modal
          show={showAllCredit}
          onHide={cancelAllCreditModale}
          className="credit-list-modal"
        >
          <Row className="modale-header-div">
            <Col md={9} xs={9} className="cat-col">
              {/* <h6 className="page-header-text">Credits Top Up</h6> */}
            </Col>
            <Col md={3} xs={3} className="cat-col" align="right">
              <button
                onClick={cancelAllCreditModale}
                className="bi bi-x close-popup"
              ></button>
            </Col>
          </Row>
          <Row>
            {remainingCredits.map((item, i) => (
              <Col md={3} className="credit-box-div">
                <div className="credit-col-box">
                  <div className="top-image">
                    <img
                      src={`../../images/Topbar/${item.ApiName}.gif`}
                      alt=""
                    />
                  </div>
                  <div className="top-image">
                    <p className="head-text">
                      Your <span className="primary-color">{item.ApiName}</span>{" "}
                      Credits
                    </p>
                    <p>
                      Remainig balance is{" "}
                      <span className="subbordinate-link">
                        {item.RemainingCreadits}
                      </span>
                    </p>
                  </div>
                  <div className="top-btns">
                    <SBSaveUpdateBtn btnName="Request For Credit" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className="top-btns">
            <SBSaveUpdateBtn btnName="Request For All Credits" />
            <SbCancelBtn
              btnName="Close"
              onClickEffect={cancelAllCreditModale}
            />
          </div>
        </Modal>
      )}
      {/* Credit Request modal */}

      <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          <div className="dropdown list-unstyled topbar-nav float-end">
            <span className="your-days-left">
              <Link to="/settings/account" className="go-to-accounts">
                {daysLeft < 30 ? (
                  daysLeft === 0 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription has Expired.
                    </small>
                  ) : daysLeft === 1 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end <b>Today</b>
                    </small>
                  ) : daysLeft < 10 ? (
                    <small className="days-left-in-number red">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end in <b>{daysLeft} days</b>
                    </small>
                  ) : (
                    <small className="days-left-in-number">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end in <b>{daysLeft} days</b>
                    </small>
                  )
                ) : (
                  ""
                )}
              </Link>
            </span>
            {!showRequest && (
              <div className="top-bar-popup">
                <div className="carousel-div">
                  <Carousel
                    fade
                    pause="hover"
                    autoPlay={false}
                    controls={false}
                    indicators={false}
                    interval={2000}
                  >
                    {remainingCredits.length != 0 &&
                      remainingCredits.map((item, i) => (
                        <Carousel.Item className="first-popup-div" key={i}>
                          <div className="first-popup-div-text">
                            <p className="carousel-text1 color-green">
                              {item.ApiName}
                            </p>
                            {item.RemainingCreadits <= 10 ? (
                              <p>
                                <span
                                  className="credit-rqst-btn"
                                  onClick={() =>
                                    requestCredits(
                                      item.ApiName,
                                      item.RemainingCreadits
                                    )
                                  }
                                >
                                  Need More credits?
                                </span>
                              </p>
                            ) : (
                              <p className="carousel-text1">
                                <span className="sb-remaining-credits">
                                  {item.ConsumedCreadits}
                                </span>
                                &nbsp; /{" "}
                                <span className="total-credit">
                                  {item.TotalCreadits}
                                </span>
                              </p>
                            )}
                          </div>
                        </Carousel.Item>
                      ))}
                  </Carousel>
                </div>
              </div>
            )}
            <div style={{ padding: "0px 20px" }}>
              <DarkMode />
            </div>
            <div className="dropdown header-item">
              <button
                type="button"
                className="btn navdrp"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <div style={{ width: "50px", marginRight: "10px" }}>
                    <img
                      className="sidbar logo-img rounded-circle header-profile-user"
                      style={{ width: "40px" }}
                      src={
                        userLgnDetails?.profilePic !== "" &&
                        userLgnDetails?.profilePic !== null &&
                        userLgnDetails?.profilePic !== "no_img.png"
                          ? `${baseURL}/${userLgnDetails?.profilePic}`
                          : profileimg
                      }
                      alt=""
                    />
                  </div>

                  <span className="text-start ms-xl-2 d-flex align-items-center">
                    <span className="d-none d-xl-inline-block fw-medium user-name-text fs-16">
                      <span>
                        <span className="sb-user-name">
                          {userLgnDetails?.fullName.length > 20
                            ? userLgnDetails?.fullName.substring(0, 18) + "..."
                            : userLgnDetails?.fullName}
                        </span>
                        <br />
                        <span className="sb-company-name fz15">
                          {companyName && companyName.length > 20
                            ? companyName.substring(0, 18) + "..."
                            : companyName}
                        </span>
                      </span>
                    </span>
                    <span>
                      &nbsp;&nbsp;
                      <i
                        className="fa fa-angle-down fs-12 ms-1"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                  </span>
                </span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-end custom-sb-drop"
                data-popper-placement="bottom-end"
              >
                <div
                  className="dropdown-item"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TopBarToolTip
                    image={`../images/Topbar/Setting${
                      themeColor ? themeColor : "light"
                    }.png`}
                    link="/settings"
                    tpName="Settings"
                  />
                  <div className="icon-divider"></div>
                  <TopBarToolTip
                    image={`../images/Topbar/Profile-image.png`}
                    link="/settings/Profile"
                    tpName="Profile"
                  />
                  <div className="icon-divider"></div>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => <Tooltip {...props}>Log Out</Tooltip>}
                    placement="top"
                  >
                    <div onClick={logoutModal} style={{ cursor: "pointer" }}>
                      <img
                        src={`../images/Topbar/Logout.png`}
                        width="22px"
                        alt="logout"
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>

          <div className="list-unstyled topbar-nav">
            <li>
              <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
                onClick={toggleMenu}
              >
                {!props.type &&<div
                  className={`closemenu ${toggleshift.style}`}
                  onClick={menuIconClick}
                >
                  {menuCollapse ? (
                    <span className="fa fa-indent"></span>
                  ) : (
                    <span className="fa fa-dedent"></span>
                  )}
                </div>}
              </button>
            </li>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Topbar;
