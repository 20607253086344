import React, { useEffect, useState } from "react";
import Select from "react-select";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import * as HeaderData from "../HeaderAuthentication";
import "../../styles/subscriptionmodule.scss";
import "../../styles/CommonStyle.scss";
import {
  loadAllSubscriptions,
  loadMainModules,
  loadPageBreadCome,
  loadPostMenuItems,
  loadRolesDropDown,
  loadSubscription,
  loadSubscriptionBySubsId,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MeetingManagement from "./MeetingManagement";
import Footer from "../Footer";
import MenuFormat from "../MenuFormat";

function SuscriberPage() {
  let dispatch = useDispatch();

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const { allSubcriptions } = useSelector((state) => state.data);
  const { mainModules } = useSelector((state) => state.data);
  const { allSubsById } = useSelector((state) => state.data);

  const menuItems = MenuFormat(allSubcriptions);

  // const [menuItems, setMenuItems] = useState([]);
  // const [submenuAtt, setSubmenuAtt] = useState([]);
  // const [submenuClm, setSubmenuClm] = useState([]);
  // const [submenuStg, setSubmenuStg] = useState([]);
  // const [submenuCtnr, setSubmenuCtnr] = useState([]);

  // useEffect(() => {
  //   let activities = [];
  //   let subActAtt = [];
  //   let subActClm = [];
  //   let subActStng = [];
  //   let subActCntr = [];
  //   if (allSubcriptions?.length != 0) {
  //     allSubcriptions?.map((item) => {
  //       if (item.parentModuleName === null) {
  //         activities.push({
  //           name: item.moduleName,
  //           subNames: [],
  //           status: false,
  //         });
  //       } else if (item.parentModuleName === "Attendance") {
  //         subActAtt.push({ name: item.moduleName, status: false });
  //       } else if (item.parentModuleName === "Claims") {
  //         subActClm.push({ name: item.moduleName, status: false });
  //       } else if (item.parentModuleName === "Settings") {
  //         subActStng.push({ name: item.moduleName, status: false });
  //       } else if (item.parentModuleName === "Connector") {
  //         subActCntr.push({ name: item.moduleName, status: false });
  //       }
  //     });
  //   } else {
  //     activities.push({ name: "Dashboard", subNames: [] });
  //   }
  //   setMenuItems(activities);
  //   setSubmenuAtt(subActAtt);
  //   setSubmenuClm(subActClm);
  //   setSubmenuStg(subActStng);
  //   setSubmenuCtnr(subActCntr);
  // }, [allSubcriptions]);

  // useEffect(() => {
  //   if (menuItems) {
  //     // let list = [...menuItems]
  //     menuItems.map((activities) => {
  //       if (activities.name === "Attendance") {
  //         activities.subNames = submenuAtt;
  //       }
  //       if (activities.name === "Claims") {
  //         activities.subNames = submenuClm;
  //       }
  //       if (activities.name === "Settings") {
  //         activities.subNames = submenuStg;
  //       }
  //       if (activities.name === "Connector") {
  //         activities.subNames = submenuCtnr;
  //       }
  //     });
  //     // setMenuItems(list)
  //   }
  // }, [menuItems, submenuAtt, submenuClm, submenuStg, submenuCtnr]);

  const { subcriptionList } = useSelector((state) => state.data);
  const { rolesDropdown } = useSelector((state) => state.data);

  const [userList, setUserList] = useState([]);
  // let sortedArray;
  if (userList.length != 0) {
    let sortedArray = userList.sort(
      (a, b) => parseInt(a.subscriptionId) - parseInt(b.subscriptionId)
    );
  }
  useEffect(() => {
    if (subcriptionList) {
      let optiondata = [];
      subcriptionList.map((data) => {
        if (data.subscriptionId != null) {
          optiondata.push({
            value: data.subscriptionId,
            label: data.subscriptionId,
            client: data?.company?.companyName,
          });
        }
      });
      setUserList(optiondata);
    }
  }, [subcriptionList]);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const getOptionValue = ({ label }) => (
    <div>
      <div>{label.value}</div>
    </div>
  );

  const getOptionLabel = ({ label, client }) => `${label} ${client}`;

  const formatOptionLabel = ({ label, client }) => (
    <div className="menu-item-column">
      <div style={{ fontSize: "11px" }}>
        <b>{label}</b>
      </div>
      <div style={{ fontSize: "10px" }}>{client}</div>
    </div>
  );

  const [selectedSubscribe, setSelectedSubscribe] = useState({
    value: "",
    label: "",
  });
  const setFilterUserId = (selected) => {
    setSelectedSubscribe(selected);
  };

  const [selectedRole, setSelectedRole] = useState({ value: "", label: "" });
  const setFilterRoleId = (selected) => {
    setSelectedRole(selected);
  };

  useEffect(() => {
    let breadCome = {
      mainpage: "Subscription",
    };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadSubscription());
    dispatch(loadAllSubscriptions());
    dispatch(loadRolesDropDown());
    if (selectedSubscribe) {
      dispatch(loadMainModules(selectedSubscribe.value));
      dispatch(loadSubscriptionBySubsId(selectedSubscribe.value));
    }
  }, [selectedSubscribe]);

  const [moduleData, setModuleData] = useState("");
  const [moduleId, setModuleId] = useState("");

  const navigateToModules = (data, moduleId) => {
    setModuleData(data);
    setModuleId(moduleId);
  };

  const [menusStatus, setMenusStatus] = useState([]);
  useEffect(() => {
    allSubsById.some((item) => {
      // console.log(item.mainModuleID, "item.mainModuleID")
      if (item.mainModuleID === 1 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 2 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 3 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 4 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 5 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 6 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 7 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 8 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
      if (item.mainModuleID === 9 && item.enable === 1) {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: true },
        ]);
      } else {
        setMenusStatus([
          ...menusStatus,
          { id: item.mainModuleID, status: false },
        ]);
      }
    });

    // setMenusStatus({
    //     md1: meetingItems, md2: projectItems, md3: hrItems,
    //     md4: orderItems, md5: stockItems, md6: whsItems
    // })
  }, [allSubsById]);

  const activateMenu = (e) => {
    let menuItems = allSubsById.filter((item) => {
      if (item.mainModuleID === e) {
        return item;
      }
    });
    if (menuItems.length === 1) {
      menuItems.map((item) => {
        if (item.enable === 0) {
          item.enable = 1;
          item.roleID = selectedRole.value;
        } else {
          item.enable = 0;
          item.roleID = selectedRole.value;
        }
      });
    }

    if (menuItems.length !== 0) {
      var postData = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(menuItems),
      };

      dispatch(loadPostMenuItems(postData));
    }
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* top bar and sidebar start */}
      <div className="top-and-sidebar">
        <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

        <Topbar
          toggleMenu={toggleMenu}
          menuCollapse={menuCollapse}
          toggleshift={toggleshift}
        />
      </div>
      {/* top bar and sidebar end */}

      {/* page content start */}
      {!moduleData ? (
        <div className="page-wrapper">
          <div className={`main-body ${toggleshift.style}`}>
            {/* <div className='page-header-div'>
                            <p ><span className='breadcome-link'  onClick={goToPage} >Dashboard</span>&nbsp;&nbsp;<i className='fa fa-angle-right'></i>&nbsp;&nbsp;<span className='breadcome-link' onClick={goToSettingPage} >Settings</span>&nbsp;&nbsp;<i className='fa fa-angle-right'></i>&nbsp;&nbsp;<span className='breadcome-text'>Subscription</span></p>
                        </div> */}
            <div className="page-content">
              <div className="clients-table">
                <Row className="search-row">
                  <Col md={3} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={goToBackPage}>
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6
                        className="page-header-text"

                      >
                        Subscription Module Access
                      </h6>
                    </div>
                  </Col>
                  <Col md={9} className="cat-col">
                    <div
                      className="search-bar-header"
                      style={{ float: "right" }}
                    >
                      <div className="page-top-bar-dropdowns page-top-Filters">
                        <div className="page-top-bar-dropdowns">
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userList}
                            placeholder="Select Subscription Id"
                            getOptionValue={getOptionValue}
                            getOptionLabel={getOptionLabel}
                            value={selectedSubscribe.label && selectedSubscribe}
                            // onInputChange={userList.label}
                            formatOptionLabel={formatOptionLabel}
                            onChange={setFilterUserId}
                            isSearchable={true}
                          />
                        </div>
                        <div
                          className="page-top-bar-dropdowns"
                          style={{ position: "relative", zIndex: "2" }}
                        >
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={rolesDropdown}
                            placeholder="Select Roles"
                            value={selectedRole.value && selectedRole}
                            // onInputChange={userList.label}
                            onChange={setFilterRoleId}
                            isSearchable={true}
                            isDisabled={!selectedSubscribe.value}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="user-table subsription-page">
                {selectedSubscribe.value && selectedRole.value ? (
                  <div>
                    <Row className="sb-subscription-module">
                      {mainModules &&
                        mainModules.map((modules, i) => (
                          <Col md={4} style={{ padding: "0px 45px" }} key={i}>
                            <Card
                              className={`sb-module-card ${modules.pMenuCount == 0 && "sb-disable-card"
                                }`}
                            >
                              <div
                                onClick={(e) => {
                                  modules.pMenuCount !== 0 &&
                                    navigateToModules(
                                      modules.mainModuleName,
                                      modules.mainModuleID
                                    );
                                }}
                                align="center"
                              >
                                <div className="sb-sbscription-img">
                                  <Card.Img
                                    className="sb-module-card-img"
                                    variant="top"
                                    src={modules.mainModuleIcon}
                                  />
                                </div>
                                <Card.Body>
                                  <Card.Title className="sb-card-title">
                                    {modules.mainModuleName}
                                  </Card.Title>
                                  <Card.Text>
                                    {modules.mainModuleDescription}
                                  </Card.Text>
                                </Card.Body>
                              </div>
                              <Card.Body>
                                <div className="sb-module-footer">
                                  <p>
                                    <i className="fa fa-desktop sb-menu-count-icon"></i>
                                    &nbsp;&nbsp;{modules.pMenuCount}
                                  </p>
                                  <span className="sb-menu-count-devider"></span>
                                  <input
                                    id={"module-" + i}
                                    className="css-checkbox"
                                    type="checkbox"
                                  />
                                  <OverlayTrigger
                                    placement={"top"}
                                    overlay={
                                      <Tooltip id={`tooltip-dashboard ` + i}>
                                        You can add menu Item by clicking on
                                        Right mark
                                      </Tooltip>
                                    }
                                  >
                                    <label
                                      htmlFor="module-1"
                                      name="demo_lbl_2"
                                      className="css-label"
                                      onClick={(e) =>
                                        activateMenu(modules.mainModuleID)
                                      }
                                    ></label>
                                  </OverlayTrigger>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Row className="sb-subscription-module">
                      <Col md={12}>
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="sb-product-details-div default-img">
                            <div className="d-flex align-items-center justify-content-center">
                              <img
                                src="../../../images/DefaultImages/Subscription.svg"
                                alt=""
                              />
                            </div>
                            <br />
                            <div className="d-flex align-items-center justify-content-center">
                              <p>
                                Please Select Subscription and Role to Get list
                                of Modules
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {moduleData && (
            <MeetingManagement
              moduleId={moduleId}
              selectedSubscribe={selectedSubscribe}
              selectedRole={selectedRole}
              setModuleData={setModuleData}
              moduleData={moduleData}
            />
          )}
          {/* {moduleData === "Project" &&
                        <ProjectManagement />} */}
        </>
      )}
      <Footer />
      {/* page content end */}
    </div>
  );
}

export default SuscriberPage;
